import React, { useContext, Fragment, useEffect } from "react";
import { IconButton, Button, Hidden } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import axios from "../../utils/axios1";
// import { toast } from "react-toastify";
import { authContext } from "../../ProvideAuth";

// const handleLogOut = auth => {
//   if (auth.isLoggedIn) {
//     // const token = "Bearer " + auth.jwt;
//     // // change this to work from the auth.user as well
//     // const id = localStorage.getItem("user");
//     // axios
//     //   .get(`/logout/${id} `, { headers: { Authorization: token } })
//     //   .then(response => {
//     //     toast(<p>{response.data.message}</p>, {
//     //       autoClose: 1000,
//     //     });
//     //     localStorage.clear();
//     //     setTimeout(() => {
//     //       history.push("/auth/login");
//     //     }, 1000);
//     //   })
//     //   .catch(error => {
//     //     // handle this better by ensuring the user is logged out immaterial
//     //     // of whatever the response comes and then throw the error again
//     //     if (error.response.data !== "") {
//     //       toast.error(<p>{error.response.data.error}</p>, {
//     //         autoClose: 3000,
//     //       });
//     //       // eslint-disable-next-line no-cond-assign
//     //       if ((error.response.status = "401")) {
//     //         toast.error(<p>{error.response.data.error}</p>, {
//     //           autoClose: 3000,
//     //         });
//     //         localStorage.clear();
//     //         history.push("/auth/login");
//     //       }
//     //     } else {
//     //       alert(error.response.statusText);
//     //     }
//     //   });
//     auth.signOut();
//   }
// };

const LogoutButton = ({ modeTextColor }) => {
  const auth = useContext(authContext);
  console.log(modeTextColor);
  useEffect(() => console.log("rendering logout button"), []);
  return (
    <Fragment>
      <Hidden mdUp>
        <IconButton style={modeTextColor} data-testid="mso-logout-icon" onClick={() => auth.signOut()}>
          <ExitToAppIcon />
        </IconButton>
      </Hidden>

      <Hidden smDown>
        <Button
          variant="contained"
          data-testid="mso-logout-button"
          color="secondary"
          onClick={() => auth.signOut()}
          startIcon={<ExitToAppIcon />}
        >
          Logout
        </Button>
      </Hidden>
    </Fragment>
  );
};

export default LogoutButton;
