import React from "react";
import "./PromoCode.css";
import { Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const PromocodeCard = ({ id, identifier, removePromoCodeHandler }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} container>
        <div className="box">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            style={{ marginLeft: "8%" }}
            justify="flex-end"
          >
            <CancelIcon
             data-testid={`remove-promocode-${id.info.attributes.title}-for-appointment-icon`}
              onClick={() => removePromoCodeHandler(id, identifier)}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <div className="inner">
            <p style={{ fontWeight: 600 }}>{id.info.attributes.title}</p>
            <div className="coupon">
              <span className="scissors">✂</span>
              <span className="code">
                {id.info.attributes.discount}% Discount
              </span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PromocodeCard;
