import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { putRequest } from "../../../api/Api";
import {
  Avatar,
  Card,
  CardContent,
  Link,
  Typography,
  colors,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { createTimeIstTimeStamp } from '../../../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexWrap: "wrap",
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  header: {
    maxWidth: "100%",
    width: 205,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      flexBasis: "100%",
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: "80px",
    height: "80px",
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
  actions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
    nameStyling: {
      [theme.breakpoints.down("sm")]: {
        width: 90,
      },
      [theme.breakpoints.up("sm")]: {
        width: 110,
      },
    },
  },
}));

const AppointmentCard = (props) => {
  const {
    project,
    handleCount,
    handleAppointmentStatusOpen,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const statusColors = {
    "Patient Not Present": colors.orange[600],
    Cancelled: colors.red[600],
    Confirmed: colors.green[600],
  };

  // TODO - update this to ensure we show only, or we
  // can delegate this logic to the patient page... which
  // might be better actually

  const StatusChangeLink = (value, appointmentId, patientId) => {
    return (
      <Link
        color="inherit"
        data-testid="appointment-status-change-link"
        style={{
          color: statusColors[value],
          fontSize: "15px",
        }}
        onClick={() => handleAppointmentStatusOpen(appointmentId, patientId)}
      >
        {value}
      </Link>
    );
  };

  const arrivalStatusChangeHandler = (data) => {
    if (window.confirm("Do you want to change Arrival status of patient ?")) {

      let doctor_id = data.doctorId.id;
      var formData = new FormData();
      formData.append("appointment[patient_id]", data.patientId);
      formData.append("appointment[doctor_type]", data.doctorType);
      formData.append("appointment[doctor_id]", doctor_id);
      formData.append("appointment[is_pregnant]", data.isPregnant);

      formData.append(
        "appointment[appointment_datetime]",
        data.appointmentDateTime
      );
      formData.append(
        "appointment[payment_received]",
        data.paymentRecieved
      );
      formData.append("appointment[consulting_language]", data.consultationLanguage);
      for (let i = 0 ;i< data.promoCodeId.length; i++){
        formData.append("appointment[promo_code_id][]", data.promoCodeId[i].id);
      }
      
      formData.append("appointment[is_patient_arrived]", parseInt(1));
      formData.append("appointment[camp_id]", data.campId ? data.campId.id : "");
      let token = "Bearer " + localStorage.getItem("jwt");
      const body = {
        token: token,
        endPoint: "appointments",
        formData: formData,
        params: [data.appointmentId],
      };
      putRequest(body).then((data) => {
        if (data.error) {
          toast.error(<p>{data.error}</p>, { autoClose: 3000 });
        } else {
          toast(<p>{data.message}</p>, {
            className: "custom",
            autoClose: 2000,
          });
          handleCount();
        }
      });
    }
  };

  let isNotLockedForEditing = true;
  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)} data-testid={`patient-appointment-card-${project.attributes.patient.name.toLowerCase()}`}>
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Avatar
              alt="Author"
              className={classes.avatar}
              src={project.attributes.patient.profile_photo}
            ></Avatar>
            <div>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Patient Name
              </Typography>
              <div>
                <Typography
                  color="textPrimary"
                  className={classes.nameStyling}
                  variant="body2"
                  style={{ fontSize: "14px", fontWeight: 300 }}
                >
                  {project.attributes.patient.name}
                </Typography>
              </div>
            </div>
          </div>
          {project.attributes.doctor !== null ? (
            <React.Fragment>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Doctor
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                  {project.attributes.doctor.name}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Fee
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                  Rs {project.attributes.doctor.fee}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Payment Received
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                  Rs{" "}
                  {project.attributes.payment_received
                    ? Math.max(0,project.attributes.payment_received)
                    :project.attributes.is_patient_arrived === 1 ? `${0} (Follow-up)` : 0}
                </Typography>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Category
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                  {project.attributes.doctor_category.category_title}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Fee
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                  Rs {project.attributes.doctor_category?.fee}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Payment Received
                </Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 300 }}
                  variant="h6"
                >
                        Rs{project.attributes.payment_received
                    ? Math.max(0,project.attributes.payment_received)
                    :project.attributes.is_patient_arrived === 1 ? `${0} (Follow-up)` : 0}
                </Typography>
              </div>
            </React.Fragment>
          )}
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Arrival Status
            </Typography>
            {project.attributes.is_patient_arrived === 0 &&
            project.attributes.status.value !== "Cancelled" ? (
              <Typography
                style={{ fontSize: "unset", fontWeight: 400, color: "#de4315" }}
                variant="h6"
              >
                <Link
                  color="inherit"
                  data-testid="patientarrivalstatus-notarrived-link"
                  // variant="h6"
                  onClick={() =>
                    arrivalStatusChangeHandler({
                      appointmentId: project.attributes.id,
                      patientId: project.attributes.patient.id,
                      doctorType: project.attributes.doctor_type,
                      doctorId: project.attributes.doctor,
                      isPregnant: project.attributes.is_pregnant,
                      appointmentDateTime: project.attributes.appointment_datetime,
                      paymentRecieved: project.attributes.payment_received,
                      promoCodeId: project.attributes.promo_codes,
                      campId: project.attributes.camp_appointment,
                      consultationLanguage:project.attributes.consulting_language?.key
                    })
                  }
                >
                  Not Arrived{" "}
                </Link>
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color:
                    project.attributes.is_patient_arrived === 0
                      ? "#de4315"
                      : "#118503",
                }}
                variant="h6"
                data-testid="patientarrivalstatus-arrived"
              >
                {project.attributes.is_patient_arrived === 0
                  ? "Not Arrived"
                  : "Arrived"}
              </Typography>
            )}
          </div>
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Appointment Date Time
            </Typography>
            <Typography
              style={{ fontSize: "14px", fontWeight: 300 }}
              variant="h6"
            >
              {moment.utc(project.attributes.appointment_datetime)
               .local()
               .format("DD MMM YYYY HH:mm")}
            </Typography>
          </div>
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Status
            </Typography>
            {project.attributes.flow_board !== null && (
              <>
                {project.attributes.flow_board.status_key <= 3 &&
                  project.attributes.status.value === "Confirmed" &&
                  StatusChangeLink(
                    project.attributes.status.value,
                    project.attributes.id,
                    project.attributes.patient.id
                  )}
              </>
            )}
            {project.attributes.flow_board === null && (
              <>
                {project.attributes.status.value === "Confirmed" &&
                  StatusChangeLink(
                    project.attributes.status.value,
                    project.attributes.id,
                    project.attributes.patient.id
                  )}
              </>
            )}
            {(project.attributes.status.value === "Cancelled" ||
              project.attributes.flow_board?.status_key > 3) && (
                <Typography
                  style={{
                    fontSize: "14px",
                    color: statusColors[project.attributes.status.value],
                    fontWeight: 300,
                  }}
                  variant="h6"
                >
                  {" "}
                  {project.attributes.status.value}
                </Typography>
              )}
          </div>
          {/* )} */}
          {/* {project.attributes.status.value !== "Confirmed" && (
            <div className={classes.stats}>
              <Typography
                style={{ fontWeight: 500, marginLeft: 20 }}
                variant="body2"
              >
                Status
              </Typography>
              <Typography
                style={{ fontSize: "unset", color: "red", fontWeight: 300 }}
                variant="h6"
              >
                {project.attributes.status.value}
              </Typography>
            </div>
          )} */}
          {project.attributes.status.value === "Confirmed" &&
          project.attributes.is_patient_arrived === 1 ? (
            <Button
              data-testid={`addvisitdataofpatient-button-${project.attributes.patient.name.toLowerCase()}`}
              component={RouterLink}
              to={`/patient_page/${project.attributes.patient.id}`}
              // variant="h6"
            >
              {project.attributes.flow_board?.status_key < 3
                ? `Add Visit Data`
                : `View Visit Data`}
            </Button>
          ) : null}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

AppointmentCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
};

export default AppointmentCard;
