import React, { useState, useEffect } from "react";
// import clsx from 'clsx';
import { makeStyles } from "@material-ui/styles";
import { getRequest, putRequest } from "../../api/Api";
import { Link as RouterLink } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import CardFooter from "../../components/Card/CardFooter";
import "../../components/toast.css";
import { useForm, Controller } from "react-hook-form";
import Button from "../CustomButtons/Button";
import Alert from "@material-ui/lab/Alert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";
import { blockButton } from '../../utils/helper'
import AstrickInLabel from '../AstrickInLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  footer: {
    // [theme.breakpoints.up("xs")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  asterisk: {
    color: "red"
  },
  labelRoot: {
    fontSize: 11,
  },
}));

const LifeStyle = (props) => {
  const {
    patient,
    steps,
    step,
    handleNext,
    handleBack,
    setShowLifestyleForm,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
    correctionCountHandler,
  } = props;
  const [currentStatus, setCurrentStatus] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [title, setTitle] = useState([]);
  const [lifestyle, setLifestyle] = useState([]);
  const [sleepStatus, setSleepStatus] = useState({});
  const [alcoholRemark, setAlcoholRemark] = useState();
  const [tabaccoRemark, setTabaccoRemark] = useState();
  const [sleepRemark, setSleepRemark] = useState();
  const [sleepFrequency, setSleepFrequency] = useState({});
  const [alcoholUnit, setAlcoholUnit] = useState([]);
  const [tabaccoUnit, setTabaccoUnit] = useState([]);
  const classes = useStyles();

  const { handleSubmit, errors, control, setValue, watch } = useForm();
  const alcohols = watch("alcohol_current_status");
  const tabaccos = watch("tobacco_current_status");

  const fetchCurrentStatus = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_lifestyle_current_status_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCurrentStatus(data);
        setSleepStatus(data[0]);
        setValue("sleep_current_status", data[0].key || "");
      }
    });
  };

  const fetchFrequency = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_lifestyle_frequency_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setFrequencyList(data);
        setSleepFrequency(data[0]);
        setValue("sleep_frequency", data[0].key || "");
      }
    });
  };

  const fetchTitle = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_lifestyle_title_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setTitle(data);
      }
    });
  };

  const fetchAlcoholUnits = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_alcohol_unit_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAlcoholUnit(data);
      }
    });
  };

  const fetchLifestyle = () => {
    const endPoint = "get_patient_lifestyles_by_patient_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    // let param = [props.match.params.id];
    let param = [patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setLifestyle(data.data);
      }
    });
  };

  const fetchTabaccoUnits = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_tobacco_unit_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setTabaccoUnit(data);
      }
    });
  };

  useEffect(() => {

    fetchTabaccoUnits();
    fetchLifestyle();
    fetchAlcoholUnits();
    fetchCurrentStatus();
    fetchFrequency();
    fetchTitle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lifestyle.length !== 0) {
      for (let i = 0; i < lifestyle.length; i++) {
        if (lifestyle[i].attributes.title.key === "alcohol") {
          if (lifestyle[i].attributes.current_status !== null) {
            setValue(
              "alcohol_current_status",
              lifestyle[i].attributes.current_status.key || ""
            );
          }
          setValue("alcohol_id", lifestyle[i].attributes.id || "");
          setAlcoholRemark(lifestyle[i].attributes.remark);
        } else if (lifestyle[i].attributes.title.key === "tobacco") {
          setValue("taboco_id", lifestyle[i].attributes.id || "");
          if (lifestyle[i].attributes.current_status !== null) {
            setValue(
              "tobacco_current_status",
              lifestyle[i].attributes.current_status.key || ""
            );
          }
          setTabaccoRemark(lifestyle[i].attributes.remark);
        } else if (lifestyle[i].attributes.title.key === "sleep_pattern") {
          setValue("sleep_id", lifestyle[i].attributes.id || "");
          setSleepRemark(lifestyle[i].attributes.remark);
          if (lifestyle[i].attributes.current_status !== null) {
            setValue(
              "sleep_current_status",
              lifestyle[i].attributes.current_status.key || ""
            );
          }
          if (lifestyle[i].attributes.frequency !== null) {
            setValue(
              "sleep_frequency",
              lifestyle[i].attributes.frequency.key || ""
            );
          }
          setValue("sleep_quantity", lifestyle[i].attributes.quantity || "");
          setValue(
            "sleep_start_datetime",
            lifestyle[i].attributes.start_date || ""
          );
          setValue(
            "sleep_end_datetime",
            lifestyle[i].attributes.end_date || ""
          );
        }
      }
    }
  }, [lifestyle, setValue]);

  const AlcoholFrequency = () => {
    let alcohol_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "alcohol"
    );
    let default_val = "";
    if (alcohol_details && alcohol_details.attributes.frequency !== null) {
      default_val = alcohol_details.attributes.frequency.key;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <FormControl
          style={{ minWidth: 86 }}
          error={Boolean(errors.alcohol_frequency)}
        >
          <InputLabel id="demo-simple-select-label">Frequency</InputLabel>

          <Controller
            as={
              <Select>
                {frequencyList.map((option) => (
                  <MenuItem 
                    data_cy ={`alcohol-frequency-option-${option.key}`}
                    key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            }
            name="alcohol_frequency"
            id="alcohol_frequency"
            // rules={{ required: "Frequency is required" }}
            inputProps={{
              data_cy : `alcohol-frequency-dropdown`
            }}
            control={control}
            defaultValue={default_val}
          />
          <FormHelperText>
            {errors.alcohol_frequency && errors.alcohol_frequency.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const AlcoholQuantity = () => {
    let alcohol_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "alcohol"
    );
    let default_val = "";
    let alcohol_unit = "";
    if (alcohol_details && alcohol_details.attributes.quantity) {
      default_val = alcohol_details.attributes.quantity;
    }
    if (alcohol_details && alcohol_details.attributes.quantity) {
      alcohol_unit = alcohol_details.attributes.unit;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.alcohol_quantity)}
          name="alcohol_quantity"
          // rules={{ required: "Quantity is required" }}
          inputProps={{
            data_cy : `alcohol-quantity-input`
          }}
          control={control}
          defaultValue={default_val}
          label="Quantity"
          type="text"
          helperText={
            errors.alcohol_quantity && errors.alcohol_quantity.message
          }
          fullWidth
        />
        <FormControl
          style={{ minWidth: 47, marginTop: -45, marginLeft: 64 }}
          error={Boolean(errors.alcohol_unit)}
        >
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>

          <Controller
            as={
              <Select>
                {alcoholUnit.map((option) => (
                  <MenuItem 
                    data_cy ={`alcohol-unit-option-${option.key}`}
                    key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            }
            name="alcohol_unit"
            // rules={{ required: "Frequency is required" }}
            inputProps={{
              data_cy : `alcohol-unit-dropdown`
            }}
            control={control}
            defaultValue={alcohol_unit}
          />
          <FormHelperText>
            {errors.alcohol_unit && errors.alcohol_unit.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const AlcoholStartDate = () => {
    let alcohol_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "alcohol"
    );
    let default_val = "";
    if (alcohol_details && alcohol_details.attributes.start_date) {
      default_val = alcohol_details.attributes.start_date;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.alcohol_start_datetime)}
          name="alcohol_start_datetime"
          style={{ width: 136, marginTop: 14 }}
          label="Start Date"
          inputProps={{
            data_cy : `alcohol-start-date-datepicker`
          }}
          type="date"
          // rules={{ required: "Start Date is required" }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue={default_val}
          helperText={
            errors.alcohol_start_datetime &&
            errors.alcohol_start_datetime.message
          }
        />
      </Grid>
    );
  };

  const AlcoholEndDate = (data) => {
    let alcohol_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "alcohol"
    );
    let default_val = "";
    if (alcohol_details && alcohol_details.attributes.end_date) {
      default_val = alcohol_details.attributes.end_date;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.alcohol_end_datetime)}
          name="alcohol_end_datetime"
          label="End Date"
          style={{ width: 136, marginTop: 14 }}
          inputProps={{
            data_cy : `alcohol-end-date-datepicker`
          }}
          type="date"
          disabled={data === "disable" ? true : false}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue={default_val}
          helperText={
            errors.alcohol_end_datetime && errors.alcohol_end_datetime.message
          }
        />
      </Grid>
    );
  };

  const TobaccoFrequency = () => {
    let tobacco_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "tobacco"
    );
    let default_val = "";
    if (tobacco_details && tobacco_details.attributes.frequency !== null) {
      default_val = tobacco_details.attributes.frequency.key;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <FormControl
          style={{ minWidth: 86 }}
          error={Boolean(errors.tobacco_frequency)}
        >
          <InputLabel id="demo-simple-select-label">Frequency</InputLabel>

          <Controller
            as={
              <Select>
                {frequencyList.map((option) => (
                  <MenuItem 
                    data_cy ={`tobacco-frequency-option-${option.key}`}
                     key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            }
            name="tobacco_frequency"
            // rules={{ required: "Frequency is required" }}
            inputProps={{
              data_cy : `tobacco-frequency-dropdown`
            }}
            control={control}
            defaultValue={default_val}
          />
          <FormHelperText>
            {errors.tobacco_frequency && errors.tobacco_frequency.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const TobaccoQuantity = () => {
    let tobacco_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "tobacco"
    );
    let default_val = "";
    let tobacco_unit = "";
    if (tobacco_details && tobacco_details.attributes.quantity) {
      default_val = tobacco_details.attributes.quantity;
    }
    if (tobacco_details && tobacco_details.attributes.quantity) {
      tobacco_unit = tobacco_details.attributes.unit;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.tobacco_quantity)}
          name="tobacco_quantity"
          // rules={{ required: "Quantity is required" }}
          inputProps={{
            data_cy : `tobacco-quantity-input`
          }}
          control={control}
          defaultValue={default_val}
          label="Quantity"
          type="text"
          helperText={
            errors.tobacco_quantity && errors.tobacco_quantity.message
          }
          fullWidth
        />
        <FormControl
          style={{ minWidth: 47, marginTop: -45, marginLeft: 64 }}
          error={Boolean(errors.tobacco_unit)}
        >
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>

          <Controller
            as={
              <Select>
                {tabaccoUnit.map((option) => (
                  <MenuItem 
                   data_cy ={`tobacco-unit-option-${option.key}`}
                    key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            }
            name="tobacco_unit"
            inputProps={{
              data_cy : `tobacco-unit-dropdown`
            }}
            // rules={{ required: "Frequency is required" }}
            control={control}
            defaultValue={tobacco_unit}
          />
          <FormHelperText>
            {errors.tobacco_unit && errors.tobacco_unit.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const TobaccoStartDate = () => {
    let tobacco_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "tobacco"
    );
    let default_val = "";
    if (tobacco_details && tobacco_details.attributes.start_date) {
      default_val = tobacco_details.attributes.start_date;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.tobacco_start_datetime)}
          name="tobacco_start_datetime"
          inputProps={{
            data_cy : `tobacco-start-date-datepicker`
          }}
          style={{ width: 136, marginTop: 14 }}
          label="Start Date"
          type="date"
          // rules={{ required: "Start Date is required" }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue={default_val}
          helperText={
            errors.tobacco_start_datetime &&
            errors.tobacco_start_datetime.message
          }
        />
      </Grid>
    );
  };

  const TobaccoEndDate = (data) => {
    let tobacco_details = lifestyle.find(
      (pick) => pick.attributes.title.key === "tobacco"
    );
    let default_val = "";
    if (tobacco_details && tobacco_details.attributes.end_date) {
      default_val = tobacco_details.attributes.end_date;
    }
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.tobacco__end_datetime)}
          name="tobacco__end_datetime"
          label="End Date"
          inputProps={{
            data_cy : `tobacco-end-date-datepicker`
          }}
          style={{ width: 136, marginTop: 14 }}
          type="date"
          disabled={data === "disable" ? true : false}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue={default_val}
          helperText={
            errors.tobacco__end_datetime && errors.tobacco__end_datetime.message
          }
        />
      </Grid>
    );
  };
  const SleepFrequency = () => {
    return (
      <Grid item xs={12} sm={2} md={2}>
        <FormControl
          style={{ minWidth: 86 }}
          error={Boolean(errors.sleep_frequency)}
        >
          <InputLabel id="demo-simple-select-label">Frequency <AstrickInLabel/></InputLabel>

          <Controller
            as={
              <Select>
                <MenuItem 
                  data_cy ={`sleep-frequency-option-${sleepFrequency.key}`}
                  key={sleepFrequency.key} value={sleepFrequency.key}>
                  {sleepFrequency.value}
                </MenuItem>
              </Select>
            }
            name="sleep_frequency"
            inputProps={{
              data_cy : `sleep-frequency-dropdown`
            }}
            // rules={{ required: "Frequency is required" }}
            control={control}
            defaultValue=""
          />
          <FormHelperText>
            {errors.sleep_frequency && errors.sleep_frequency.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const SleepQuantity = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={2} md={2}>
          <Controller
            as={<TextField />}
            error={Boolean(errors.sleep_quantity)}
            name="sleep_quantity"
            rules={{ required: "Quantity is required" }}
            inputProps={{
              data_cy : `sleep-quantity-input`
            }}
            control={control}
            defaultValue=""
            required
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk,
                root: classes.labelRoot,
              }
            }}
            label="Quantity"
            type="text"
            helperText={errors.sleep_quantity && errors.sleep_quantity.message}
            fullWidth
          />
        </Grid>
        <Typography
          style={{ marginTop: -22, marginLeft: 60, color: "#253ba6" }}
        >
          hours
        </Typography>
      </React.Fragment>
    );
  };

  const SleepStartDate = () => {
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.sleep_start_datetime)}
          name="sleep_start_datetime"
          style={{ width: 136, marginTop: 14 }}
          label="Start Date"
          inputProps={{
            data_cy : `sleep-start-date-datepicker`
          }}
          type="date"
          // rules={{ required: "Start Date is required" }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue=""
          helperText={
            errors.sleep_start_datetime && errors.sleep_start_datetime.message
          }
        />
      </Grid>
    );
  };

  const SleepEndDate = (data) => {
    return (
      <Grid item xs={12} sm={2} md={2}>
        <Controller
          as={<TextField />}
          error={Boolean(errors.sleep_end_datetime)}
          name="sleep_end_datetime"
          inputProps={{
            data_cy : `sleep-end-date-datepicker`
          }}
          label="End Date"
          style={{ width: 136, marginTop: 14 }}
          type="date"
          disabled={data === "disable" ? true : false}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
          defaultValue=""
          helperText={
            errors.sleep_end_datetime && errors.sleep_end_datetime.message
          }
        />
      </Grid>
    );
  };

  const onSubmit = (data) => {
    if (data.alcohol_end_datetime === undefined) {
      data.alcohol_end_datetime = "";
    }
    if (data.tobacco__end_datetime === undefined) {
      data.tobacco__end_datetime = "";
    }
    if (data.sleep_end_datetime === undefined) {
      data.sleep_end_datetime = "";
    }

    if (data.alcohol_frequency === undefined) {
      data.alcohol_frequency = "";
    }
    if (data.alcohol_quantity === undefined) {
      data.alcohol_quantity = "";
    }
    if (data.alcohol_unit === undefined) {
      data.alcohol_unit = "";
    }
    if (data.alcohol_start_datetime === undefined) {
      data.alcohol_start_datetime = "";
    }

    if (data.tobacco_frequency === undefined) {
      data.tobacco_frequency = "";
    }
    if (data.tobacco_quantity === undefined) {
      data.tobacco_quantity = "";
    }
    if (data.tobacco_unit === undefined) {
      data.tobacco_unit = "";
    }
    if (data.tobacco_start_datetime === undefined) {
      data.tobacco_start_datetime = "";
    }

    var alcohol = {};
    var tobacco = {};
    var sleep_pattern = {};

    alcohol.title = title[0].key;
    alcohol.frequency = data.alcohol_frequency;
    alcohol.quantity = data.alcohol_quantity;
    alcohol.unit = data.alcohol_unit;
    alcohol.start_date = data.alcohol_start_datetime;
    alcohol.end_date = data.alcohol_end_datetime;
    alcohol.current_status = data.alcohol_current_status;
    if (data.alcohol_id !== "") {
      alcohol.id = data.alcohol_id;
    }

    tobacco.title = title[1].key;
    tobacco.frequency = data.tobacco_frequency;
    tobacco.quantity = data.tobacco_quantity;
    tobacco.unit = data.tobacco_unit;
    tobacco.start_date = data.tobacco_start_datetime;
    tobacco.end_date = data.tobacco__end_datetime;
    tobacco.current_status = data.tobacco_current_status;
    if (data.taboco_id !== "") {
      tobacco.id = data.taboco_id;
    }

    sleep_pattern.title = title[2].key;
    sleep_pattern.frequency = data.sleep_frequency;
    sleep_pattern.quantity = data.sleep_quantity;
    sleep_pattern.unit = "hours";
    sleep_pattern.start_date = data.sleep_start_datetime;
    sleep_pattern.end_date = data.sleep_end_datetime;
    sleep_pattern.current_status = data.sleep_current_status;
    if (data.sleep_id !== "") {
      sleep_pattern.id = data.sleep_id;
    }

    var formData = new FormData();

    Object.keys(alcohol).forEach((key) => {
      formData.append(
        `patient[patient_lifestyles_attributes][0][${key}]`,
        alcohol[key]
      );
    });

    Object.keys(tobacco).forEach((key) => {
      formData.append(
        `patient[patient_lifestyles_attributes][1][${key}]`,
        tobacco[key]
      );
    });

    Object.keys(sleep_pattern).forEach((key) => {
      formData.append(
        `patient[patient_lifestyles_attributes][2][${key}]`,
        sleep_pattern[key]
      );
    });

    if (
      !isReviewOnly &&
      patient.attributes.last_visit.is_flagged === 0
      // medicalProblemLength === 0 &&
      // vitalLength === 0
    ) {
      handleNext();
    }else{
      blockButton('lifestyle',true)
    }
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "save_patient_lifestyles",
      formData: formData,
      params: [patient.attributes.id],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        blockButton('lifestyle',false)
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        if (
          isReviewOnly ||
          patient.attributes.last_visit.is_flagged === 1 ||
          medicalProblemLength > 0 ||
          vitalLength > 0
        ) {
          setTimeout(() => {
            setShowLifestyleForm(false);
            correctionCountHandler();
          }, 1000);
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "0px" }}>
            <CardHeader
              style={{ width: "30%", padding: "8px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>LifeStyle</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="alcohol_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="taboco_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="sleep_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {alcoholRemark ? (
                      <Alert style={{ width: 230 }} severity="warning">
                        {alcoholRemark}
                      </Alert>
                    ) : null}
                    <br />
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      container
                      style={{ flexGrow: 1 }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="body2">
                        Alcohol
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={2} md={2}>
                      <FormControl
                        style={{ minWidth: 120 }}
                        error={Boolean(errors.alcohol_current_status)}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Current Status <AstrickInLabel/>
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              {currentStatus.map((option) => (
                                <MenuItem
                                  data_cy ={`alcohol-current-status-option-${option.key}`}
                                  key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          inputProps={{
                            data_cy : `alcohol-current-status-dropdown`
                          }}
                          name="alcohol_current_status"
                          rules={{ required: "Current Status is required" }}
                          control={control}
                          defaultValue=""
                        />
                        <FormHelperText>
                          {errors.alcohol_current_status &&
                            errors.alcohol_current_status.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {alcohols === "quit" && (
                      <React.Fragment>{AlcoholEndDate()}</React.Fragment>
                    )}
                    {alcohols === "current" && (
                      <React.Fragment>
                        {AlcoholFrequency()}
                        {AlcoholQuantity()}
                        {AlcoholStartDate()}
                        {AlcoholEndDate("disable")}
                      </React.Fragment>
                    )}
                    {alcohols === "never" && null}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {tabaccoRemark ? (
                      <Alert style={{ width: 230 }} severity="warning">
                        {tabaccoRemark}
                      </Alert>
                    ) : null}
                    <br />
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      container
                      style={{ flexGrow: 1 }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="body2">
                        Tobacco/Cigarette
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={2} md={2}>
                      <FormControl
                        style={{ minWidth: 120 }}
                        error={Boolean(errors.tobacco_current_status)}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Current Status <AstrickInLabel/>
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              {currentStatus.map((option) => (
                                <MenuItem
                                  data_cy ={`tobacco-current-status-option-${option.key}`}
                                  key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          name="tobacco_current_status"
                          inputProps={{
                            data_cy : `tobacco-current-status-dropdown`
                          }}
                          rules={{ required: "Current Status is required" }}
                          control={control}
                          defaultValue=""
                        />
                        <FormHelperText>
                          {errors.tobacco_current_status &&
                            errors.tobacco_current_status.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {tabaccos === "quit" && (
                      <React.Fragment>{TobaccoEndDate()}</React.Fragment>
                    )}
                    {tabaccos === "current" && (
                      <React.Fragment>
                        {TobaccoFrequency()}
                        {TobaccoQuantity()}
                        {TobaccoStartDate()}
                        {TobaccoEndDate("disable")}
                      </React.Fragment>
                    )}
                    {tabaccos === "never" && null}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {sleepRemark ? (
                      <Alert style={{ width: 230 }} severity="warning">
                        {sleepRemark}
                      </Alert>
                    ) : null}
                    <br />
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      container
                      style={{ flexGrow: 1 }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="body2">
                        {`Sleep\u00A0Pattern`}
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={2} md={2}>
                      <FormControl
                        style={{ minWidth: 120, marginTop: -4 }}
                        error={Boolean(errors.sleep_current_status)}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Current Status <AstrickInLabel/>
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem
                                data_cy ={`sleep-current-status-option-${sleepStatus.key}`}
                                key={sleepStatus.key}
                                value={sleepStatus.key}
                              >
                                {sleepStatus.value}
                              </MenuItem>
                            </Select>
                          }
                          name="sleep_current_status"
                          inputProps={{
                            data_cy : `sleep-current-status-dropdown`
                          }}
                          rules={{ required: "Current Status is required" }}
                          control={control}
                          defaultValue=""
                        />
                        <FormHelperText>
                          {errors.sleep_current_status &&
                            errors.sleep_current_status.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <React.Fragment>
                      {SleepFrequency()}
                      {SleepQuantity()}
                      {SleepStartDate()}
                      {SleepEndDate("disable")}
                    </React.Fragment>
                  </Grid>
                </Grid>
                <br />
                {isReviewOnly ||
                patient.attributes.last_visit.is_flagged === 1 ||
                medicalProblemLength > 0 ||
                vitalLength > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justify="flex-end"
                  >
                    <CardFooter
                      style={{ float: "right" }}
                      className={classes.footer}
                    >
                      <Button
                        data-testid="lifeStyle-submit-button"
                        style={{ width: 72 }}
                        type="submit"
                        id="lifestyle"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          color: "rgb(61, 170, 153)",
                          border: "1px solid rgb(226 226 226)",
                          backgroundColor: "rgb(255 255 255)",
                          boxShadow: "none",
                          width: 72,
                          marginLeft: "3%",
                        }}
                        data-testid="lifestyle-cancel-button"
                        onClick={() => setShowLifestyleForm(false)}
                      >
                        Cancel
                      </Button>

                      <ToastContainer />
                    </CardFooter>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    {step !== 0 && (
                      <Button
                        onClick={handleBack}
                        data-testid="lifestyle-back-button"
                        style={{ backgroundColor: "grey" }}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      // onClick={handleNext}
                      data-testid="lifeStyle-next-button"
                      type="submit"
                      style={{
                        backgroundColor: "#4d93dc",
                        marginLeft: "1%",
                      }}
                      className={classes.button}
                    >
                      {step === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                )}
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LifeStyle;
