import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  conatiner: {
    display: "flex",
    flexDirection: "column",
    gap: 60,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  const { open } = props;

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <div className={classes.conatiner}>
          <CircularProgress style={{ color: "#31f4f5" }} />
          <br />
          {props.msg && (
            <Typography
              style={{ color: "white", padding: 12, fontStyle: "italic" }}
              variant="h4"
              align="center"
            >
              {props.msg} ...
            </Typography>
          )}
        </div>
      </Backdrop>
    </div>
  );
}
