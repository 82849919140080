import React, { Component } from "react";

import { Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Card from "../../components/Card/Card";
import "../../components/toast.css";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import TextField from "@material-ui/core/TextField";
import convert from "convert-units";
import round from "../../utils/round";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../CustomButtons/Button";
import CardHeader from "../../components/Card/CardHeader";
import { Link as RouterLink } from "react-router-dom";
import { getRequest, putRequest } from "../../api/Api";
import Chip from "@material-ui/core/Chip";
import axios from "../../utils/axios1";
import { blockButton } from '../../utils/helper'
import { withStyles } from "@material-ui/core/styles";
import AstrickInLabel from '../AstrickInLabel'

const styles = (theme) => ({
  // footer: {
  //   [theme.breakpoints.up("xs")]: {
  //     display: "none",
  //   },
  //   [theme.breakpoints.up("sm")]: {
  //     display: "none",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     display: "none",
  //   },
  // },
});

class Vitals extends Component {
  static defaultProps = {
    formatter: new Intl.NumberFormat("en-GB"),
  };

  constructor(props) {
    super(props);
    this.state = {
      weight_kg: "",
      weight_lbs: "",
      height_cm: "",
      height_in: "",
      head_circumference_cm: "",
      head_circumference_in: "",
      waist_circumference_cm: "",
      waist_circumference_in: "",
      temperature_f: "",
      temperature_c: "",
      bps: "",
      bpd: "",
      pulse: "",
      respiration: "",
      temp_location: "",
      oxygen_saturation: "",
      bmi: "",
      bmi_status: "",
      other_notes: "",
      tempLocOption: [],
      patient: "",
      lastVisitVitalData: [],
      vitalId: "",
    };
  }
  fetchVitalTempLoc = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_vitals_temp_location_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({ tempLocOption: data });
      }
    });
  };

  fetchPatientVitalsById = (patientId) => {
    const endPoint = "get_last_visit_patient_vitals";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patientId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data.data);
        this.setState({
          lastVisitVitalData: data.data,
        });
      }
    });
  };

  fetchPatientById = () => {
    const endPoint = "patients";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [this.props.patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          patient: data.data.attributes.last_visit_id,
        });
        this.fetchPatientVitalsById(data.data.attributes.id);
      }
    });
  };

  componentDidMount() {
    this.fetchVitalTempLoc();
    this.fetchPatientById();

    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      axios
        .get(
          `/get_patient_vitals_by_visit_id/${this.props.patient.attributes.id}/${this.props.patient.attributes.last_visit_id}`,
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          // console.log(response.data.data.attributes);
          if (response.data.data.length !== 0) {
            this.setState({
              visit_id: response.data.data[0].attributes.visit_id,
              patient_id: response.data.data[0].attributes.patient.id,
              remark: response.data.data[0].attributes.remark,
            });
            // this.fetchPatientById(response.data.data.attributes.patient.id);
            console.log(response.data.data[0].attributes.bps);
            this.setState({
              vitalId: response.data.data[0].attributes.id,
              weight_kg: response.data.data[0].attributes.weight_kg,
              weight_lbs: response.data.data[0].attributes.weight_lbs,
              height_cm: response.data.data[0].attributes.height_cm,
              height_in: response.data.data[0].attributes.height_in,
              head_circumference_cm:
                response.data.data[0].attributes.head_circumference_cm,
              head_circumference_in:
                response.data.data[0].attributes.head_circumference_in,
              waist_circumference_cm:
                response.data.data[0].attributes.waist_circumference_cm,
              waist_circumference_in:
                response.data.data[0].attributes.waist_circumference_in,
              temperature_f: response.data.data[0].attributes.temperature_f,
              temperature_c: response.data.data[0].attributes.temperature_c,
              bps: response.data.data[0].attributes.bps,
              bpd: response.data.data[0].attributes.bpd,
              pulse: response.data.data[0].attributes.pulse,
              respiration: response.data.data[0].attributes.respiration,
              temp_location: response.data.data[0].attributes.temp_location.key,
              oxygen_saturation:
                response.data.data[0].attributes.oxygen_saturation,
              bmi: response.data.data[0].attributes.bmi,
              bmi_status: response.data.data[0].attributes.bmi_status,
              other_notes: response.data.data[0].attributes.other_notes,
              vital_id: response.data.data[0].attributes.id,
            });
          }
          // console.log(response.data);
        })
        .catch((error) => {
          if (error.response.data !== "") {
            alert(error.response.data.error);
          } else {
            alert(error.response.statusText);
          }
        });
    }
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleOxygenSaturation = (evt) => {
    const oxygen_saturation = evt.target.value.replace(/\D/g, "");
    this.setState({ oxygen_saturation: oxygen_saturation });
  };

  //////////////////////////////// For height//////////////////////////////////////////////////////////
  handleCmChange = (evt) => {
    const height_cm = evt.target.value.replace(/\D/g, "");
    this.setCm(height_cm);
  };
  handleInchChange = (evt) => {
    const height_in = evt.target.value.replace(/\D/g, "");
    this.setInch(height_in);
  };
  setCm = (cm) => {
    const inch = convert(cm)
      .from("cm")
      .to("in");
    this.updateHeight(cm, inch);
  };
  setInch = (inch) => {
    const cm = convert(inch)
      .from("in")
      .to("cm");
    this.updateHeight(cm, inch);
  };

  updateHeight = (height_cm, height_in) => {
    let c = round(height_cm);
    let i = round(height_in);

    if (isNaN(c) || isNaN(i) || c === 0) {
      c = i = "";
    }

    this.setState({ height_cm: c, height_in: i });
  };

  /////////////////////////////// For Head circumference //////////////////////////////////////////////////
  handleHeadCircCmChange = (evt) => {
    const head_circumference_cm = evt.target.value.replace(/\D/g, "");
    this.setHeadCircCm(head_circumference_cm);
  };
  handleHeadCircInchChange = (evt) => {
    const head_circumference_in = evt.target.value.replace(/\D/g, "");
    this.setHeadCircInch(head_circumference_in);
  };
  setHeadCircCm = (cm) => {
    const inch = convert(cm)
      .from("cm")
      .to("in");
    this.updateHeadCirc(cm, inch);
  };
  setHeadCircInch = (inch) => {
    const cm = convert(inch)
      .from("in")
      .to("cm");
    this.updateHeadCirc(cm, inch);
  };

  updateHeadCirc = (head_circumference_cm, head_circumference_in) => {
    let c = round(head_circumference_cm);
    let i = round(head_circumference_in);

    if (isNaN(c) || isNaN(i) || c === 0) {
      c = i = "";
    }

    this.setState({ head_circumference_cm: c, head_circumference_in: i });
  };

  /////////////////////////////// For Waist circumference //////////////////////////////////////////////////
  handleWaistCircCmChange = (evt) => {
    const waist_circumference_cm = evt.target.value.replace(/\D/g, "");
    this.setWaistCircCm(waist_circumference_cm);
  };
  handleWaistCircInchChange = (evt) => {
    const waist_circumference_in = evt.target.value.replace(/\D/g, "");
    this.setWaistCircInch(waist_circumference_in);
  };
  setWaistCircCm = (cm) => {
    const inch = convert(cm)
      .from("cm")
      .to("in");
    this.updateWaistCirc(cm, inch);
  };
  setWaistCircInch = (inch) => {
    const cm = convert(inch)
      .from("in")
      .to("cm");
    this.updateWaistCirc(cm, inch);
  };

  updateWaistCirc = (waist_circumference_cm, waist_circumference_in) => {
    let c = round(waist_circumference_cm);
    let i = round(waist_circumference_in);

    if (isNaN(c) || isNaN(i) || c === 0) {
      c = i = "";
    }

    this.setState({ waist_circumference_cm: c, waist_circumference_in: i });
  };

  ///////////////////////////////////  For Temperature ////////////////////////////////////////////////////
  handleFChange = (evt) => {
    const temperature_f = evt.target.value;
    // evt.target.value.replace(/\D/g, "");
    this.setF(temperature_f);
  };

  handleCChange = (evt) => {
    const temperature_c = evt.target.value.replace(/\D/g, "");
    this.setC(temperature_c);
  };

  setF = (F) => {
    const C = convert(F)
      .from("F")
      .to("C");
    this.updateTemp(F, C);
  };

  setC = (C) => {
    const F = convert(C)
      .from("C")
      .to("F");
    this.updateTemp(F, C);
  };

  updateTemp = (temperature_f, temperature_c) => {
    let k = temperature_f;
    let p = temperature_c;

    if (isNaN(k) || isNaN(p) || k === 0) {
      k = p = "";
    }

    this.setState({ temperature_f: k, temperature_c: p });
  };
  ///////////////////////////////////  For Weight ////////////////////////////////////////////////////
  handleKiloChange = (evt) => {
    const weight_kg = evt.target.value.replace(/\D/g, "");
    this.setKilos(weight_kg);
  };

  handlePoundChange = (evt) => {
    const weight_lbs = evt.target.value.replace(/\D/g, "");
    this.setPounds(weight_lbs);
  };

  setKilos = (kilos) => {
    const pounds = convert(kilos)
      .from("kg")
      .to("lb");
    this.updateWeights(kilos, pounds);
  };

  setPounds = (pounds) => {
    const kilos = convert(pounds)
      .from("lb")
      .to("kg");
    this.updateWeights(kilos, pounds);
  };

  updateWeights = (weight_kg, weight_lbs) => {
    let k = round(weight_kg);
    let p = round(weight_lbs);

    if (isNaN(k) || isNaN(p) || k === 0) {
      k = p = "";
    }

    this.setState({ weight_kg: k, weight_lbs: p });
  };

  ///////////////////// end ////////////////////////////////////////////

  format = (n) => {
    let x = this.props.formatter.format(n);
    if (x === "0") x = "";
    return x;
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    const {
      weight_kg,
      weight_lbs,
      height_cm,
      height_in,
      head_circumference_cm,
      head_circumference_in,
      waist_circumference_cm,
      waist_circumference_in,
      temperature_f,
      temperature_c,
      bps,
      bpd,
      pulse,
      respiration,
      temp_location,
      oxygen_saturation,
      other_notes,
      vitalId,
    } = this.state;

    if (this.state.temp_location === "") {
      alert("Please Enter Temp Location");
      return false;
    }

    const patient_vital = {
      // patient_id: this.props.match.params.id,
      visit_id: this.state.patient,
      weight_kg,
      weight_lbs,
      height_cm,
      height_in,
      head_circumference_cm,
      head_circumference_in,
      waist_circumference_cm,
      waist_circumference_in,
      temperature_f,
      temperature_c,
      bps,
      bpd,
      pulse,
      respiration,
      temp_location,
      oxygen_saturation,
      // bmi,
      // bmi_status,
      other_notes,
    };
    if (vitalId !== "") {
      patient_vital.id = vitalId;
    }
    console.log(patient_vital);

    var formData = new FormData();

    Object.keys(patient_vital).forEach((key) => {
      formData.append(
        `patient[patient_vitals_attributes][0][${key}]`,
        patient_vital[key]
      );
    });
    if (
      !this.props.isReviewOnly &&
      this.props.patient.attributes.last_visit.is_flagged === 0
      // this.props.medicalProblemLength === 0 &&
      // this.props.vitalLength === 0
    ) {
      this.props.handleNext();
    }else{
      blockButton('vital',true)
    }
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "save_patient_vitals",
      formData: formData,
      params: [this.props.patient.attributes.id],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        blockButton('vital',false)
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        if (
          this.props.isReviewOnly ||
          this.props.patient.attributes.last_visit.is_flagged === 1 ||
          this.props.medicalProblemLength > 0 ||
          this.props.vitalLength > 0
        ) {
          setTimeout(() => {
            this.props.setShowVitalForm(false);
            this.props.correctionCountHandler();
          }, 1000);
        }
      }
    });
  };

  render() {
    const kilos = (
      <TextField
        required
        id="weight_kg"
        name="weight_kg"
        type="number"
        label="kg"
        fullWidth
        inputProps={{
          data_cy :`weight-in-kg-input`,
          step: "any" 
        }}
        onChange={this.handleKiloChange}
        value={this.format(this.state.weight_kg)}
      />
    );

    const pounds = (
      <TextField
        id="weight_lbs"
        name="weight_lbs"
        inputProps={{
          readOnly: true,
          step: "any"
        }}
        label="lbs"
        fullWidth
        onChange={this.handlePoundChange}
        value={this.format(this.state.weight_lbs)}
      />
    );
    const HeightCm = (
      <TextField
        required
        id="height_cm"
        name="height_cm"
        type="number"
        inputProps={{ step: "any" }}
        label="cm"
        fullWidth
        onChange={this.handleCmChange}
        value={this.format(this.state.height_cm)}
      />
    );

    const HeightInch = (
      <TextField
        id="height_in"
        name="height_in"
        inputProps={{
          readOnly: true,
          step: "any"
        }}
        label="in"
        fullWidth
        onChange={this.handleInchChange}
        value={this.format(this.state.height_in)}
      />
    );
    const headCircumferenceCm = (
      <TextField
        // required
        id="head_circumference_cm"
        name="head_circumference_cm"
        label="cm"
        type="number"
        inputProps={{
          data_cy :`head-circumference-in-cm-input`,
          step: "any" 
        }}
        fullWidth
        onChange={this.handleHeadCircCmChange}
        value={this.state.head_circumference_cm}
      />
    );
    const headCircumferenceIn = (
      <TextField
        // required
        id="head_circumference_in"
        name="head_circumference_in"
        inputProps={{
          readOnly: true,
          step: "any"
        }}
        label="in"
        fullWidth
        onChange={this.handleHeadCircInchChange}
        value={this.state.head_circumference_in}
      />
    );

    const waistCircumferenceCm = (
      <TextField
        // required
        id="waist_circumference_cm"
        name="waist_circumference_cm"
        label="cm"
        type="number"
        inputProps={{
          data_cy :`waist-circumference-in-cm-input`,
          step: "any" 
        }}
        fullWidth
        onChange={this.handleWaistCircCmChange}
        value={this.state.waist_circumference_cm}
      />
    );
    const waistCircumferenceIn = (
      <TextField
        // required
        id="waist_circumference_in"
        name="waist_circumference_in"
        inputProps={{
          readOnly: true,
          step: "any"
        }}
        label="in"
        fullWidth
        onChange={this.handleWaistCircInchChange}
        value={this.state.waist_circumference_in}
      />
    );
    const temperatureInF = (
      <TextField
        required
        id="temperature_f"
        name="temperature_f"
        type="number"
        inputProps={{ step: "any",min: 0  }}
        label="F"
        fullWidth
        onChange={this.handleFChange}
        value={this.state.temperature_f}
      />
    );
    const temperatureInC = (
      <TextField
        required
        id="temperature_c"
        name="temperature_c"
        label="C"
        inputProps={{
          data_cy :`temperaturein-c-input`,
          step: "any",min: 0
        }}
        type="number"
        fullWidth
        onChange={this.handleCChange}
        value={this.format(this.state.temperature_c)}
      />
    );
    const root = {
      flexGrow: 1,
      padding: "17px",
    };
    const { classes } = this.props;
    return (
      <div style={root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ marginTop: "0px" }}>
              <CardHeader
                style={{ width: "25%", padding: "8px" }}
                color="success"
              >
                <h4>Vital</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onFormSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ float: "right" }}>
                        {this.state.remark ? (
                          <Alert severity="warning">{this.state.remark}</Alert>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Weight <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      {kilos}
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      {pounds}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      {this.state.lastVisitVitalData[0] !== undefined ? (
                        <Chip
                          color="primary"
                          style={{ marginTop: "5%" }}
                          label={`Last Visit :${this.state.lastVisitVitalData[0].attributes.weight_kg} kg /${this.state.lastVisitVitalData[0].attributes.weight_lbs} lbs`}
                          variant="outlined"
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Height <AstrickInLabel/> :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      {HeightCm}
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      {HeightInch}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      {this.state.lastVisitVitalData[0] !== undefined ? (
                        <Chip
                          color="primary"
                          style={{ marginTop: "5%" }}
                          label={`Last Visit :${this.state.lastVisitVitalData[0].attributes.height_cm} cm /${this.state.lastVisitVitalData[0].attributes.height_in} in`}
                          variant="outlined"
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        BP Systolic <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        required
                        id="bps"
                        name="bps"
                        type="number"
                        label="mmHg"
                        inputProps={{
                          data_cy :`bps-input`,
                          step: "any",min: 0 
                        }}
                        value={this.state.bps}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginTop: "30px",
                          // marginLeft: "32px",
                        }}
                        variant="body2"
                      >
                        BP Diastolic <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        required
                        id="bpd"
                        name="bpd"
                        inputProps={{ step: "any",min: 0 }}
                        label="mmHg"
                        type="number"
                        value={this.state.bpd}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Pulse <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        required
                        id="pulse"
                        name="pulse"
                        type="number"
                        label="per min"
                        inputProps={{
                          data_cy :`pulse-input`,
                          step: "any",min: 0
                        }}
                        value={this.state.pulse}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginTop: "30px",
                          // marginLeft: "32px",
                        }}
                        variant="body2"
                      >
                        Respiration <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        required
                        id="respiration"
                        name="respiration"
                        inputProps={{
                          data_cy :`respiration-input`,
                          step: "any",min: 0 
                        }}
                        type="number"
                        label="per min"
                        value={this.state.respiration}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Temperature <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                      {temperatureInF}
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                      {temperatureInC}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Temp Location <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        style={{ minWidth: 220 }}
                        id="temp_location"
                        required
                        inputProps={{
                          data_cy :`temp-location-dropdown`,
                        }}
                        select
                        name="temp_location"
                        label="Select"
                        defaultValue=""
                        value={this.state.temp_location}
                        onChange={this.onChange}
                      >
                        {this.state.tempLocOption.map((option) => (
                          <MenuItem 
                            data_cy ={`temp-location-option-${option.key}`}
                            key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginTop: "23px",
                          // marginLeft: "26px",
                        }}
                        variant="body2"
                      >
                        Oxygen Saturation <AstrickInLabel/>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        required
                        id="oxygen_saturation"
                        name="oxygen_saturation"
                        inputProps={{
                          data_cy :`oxygen-saturation-input`,
                          step: "any",min: 0 
                        }}
                        type="number"
                        label="%"
                        value={this.format(this.state.oxygen_saturation)} // onKeyPress={this.checkInput}
                        onChange={this.handleOxygenSaturation}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Head Circumference :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={5} md={5}>
                      {headCircumferenceCm}
                    </Grid>
                    <Grid item xs={6} sm={5} md={5}>
                      {headCircumferenceIn}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Waist Circumference :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={5} md={5}>
                      {waistCircumferenceCm}
                    </Grid>
                    <Grid item xs={6} sm={5} md={5}>
                      {waistCircumferenceIn}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography
                        style={{ fontWeight: 500, marginTop: "23px" }}
                        variant="body2"
                      >
                        Other Notes:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      md={10}
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        id="other_notes"
                        inputProps={{
                          data_cy :`other-notes-input`,
                        }}
                        name="other_notes"
                        label=""
                        fullWidth
                        value={this.state.other_notes}
                        onChange={this.onChange}
                      />
                    </Grid>
                    {this.props.isReviewOnly ||
                    this.props.patient.attributes.last_visit.is_flagged === 1 ||
                    this.props.medicalProblemLength > 0 ||
                    this.props.vitalLength > 0 ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        container
                        justify="flex-end"
                      >
                        <CardFooter className={classes.footer}>
                          <Button
                            data-testid="vital-submit-button"
                            style={{ width: 72 }}
                            type="submit"
                            id="vital"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{
                              color: "rgb(61, 170, 153)",
                              border: "1px solid rgb(226 226 226)",
                              backgroundColor: "rgb(255 255 255)",
                              boxShadow: "none",
                              width: 72,
                              marginLeft: "3%",
                            }}
                            data-testid="vital-cancel-button"
                            onClick={() => this.props.setShowVitalForm(false)}
                          >
                            Cancel
                          </Button>
                          <ToastContainer />
                        </CardFooter>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        {this.props.step !== 0 && (
                          <Button
                            onClick={this.props.handleBack}
                            data-testid="vital-back-button"
                            style={{ backgroundColor: "grey" }}
                            className={classes.button}
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          // onClick={handleNext}
                          data-testid="vital-next-button"
                          type="submit"
                          style={{
                            backgroundColor: "#4d93dc",
                            marginLeft: "1%",
                          }}
                          className={classes.button}
                        >
                          {this.propsstep === this.props.steps.length - 1
                            ? "Finish"
                            : "Next"}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Vitals);
