import React from "react";
import lockIcon from "../../assets/img/lockIcon.png";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  resetRoot: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: 22,
    marginTop: 20,
  },
  content: {
    border: "1px solid #bcbbbb",
    padding: "16px 14px",
    width: "32%",
    marginTop: "20px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  description: {
    color: "#495057",
    fontSize: 16,
    textAlign: "center",
    marginTop: -1,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  linkStyl: {
    display: "flex",
    justifyContent: "center",
    textDecoration:"underline",
    alignItems: "center",
    color: "black",
    fontWeight: 600,
    marginTop: 12,
  },
}));

function ResetLinkMessage({ email }) {
  const classes = useStyles();

  const hideEmail = (email) => {
    let [name, domain] = email.split("@");
    name = name[0] + "*".repeat(name.length - 2) + name[name.length - 1];
    return name + "@" + domain;
  };

  return (
    <div className={classes.resetRoot}>
      <div className={classes.header}>
        <img src={lockIcon} style={{ height: 80 }} alt="" />
        <Typography className={classes.heading}>Reset your password</Typography>
      </div>
      <div className={classes.content}>
        <div>
          <p className={classes.description}>
            Check your email{" "}
            <span style={{ fontWeight: 600, padding: "0 5px", color: "black" }}>
              {hideEmail(email)}
            </span>
            for a link to reset your password. If it doesn’t appear within a few
            minutes, check your spam folder.
          </p>
        </div>
        <a className={classes.linkStyl} href="/login">
          <span style={{ fontSize: 14 }}>Return to Log in</span>
        </a>
      </div>
    </div>
  );
}

export default ResetLinkMessage;
