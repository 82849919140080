import moment from 'moment'

const checkActionType = (slackData) => {
  let dataToSend = {};
  if (slackData.actionType === "errorLogging") {
    dataToSend.errorMessage =
  ` *Error received*

          Portal Name: *${`MSO PORTAL`}*
          Centre Name: *${localStorage.getItem('centreTitle')}*
          Store Code: *${localStorage.getItem('storeCode')}*
          Error: *${slackData.errorMessage}*
          Error Code: *${slackData.errorCode}*
          Endpoint: *${slackData.endPoint}*
          Method: *${slackData.method}*
          Date: *${moment().format("DD-MM-YYYY HH:mm")}* 
          `
    dataToSend.env = process.env.REACT_APP_ENV_NAME;
    // dataToSend.env = "production";
    dataToSend.actionType = slackData.actionType;
  } else if (slackData.actionType === "balanceChecking") {
    dataToSend.centreName = slackData.centreName;
    dataToSend.msoNumber = slackData.msoNumber;
    dataToSend.balance = slackData.balance;
    dataToSend.env = process.env.REACT_APP_ENV_NAME;
    // dataToSend.env = "production";
    dataToSend.actionType = slackData.actionType;
    dataToSend.oldBalance = slackData.oldBalance;
    dataToSend.storeCode = slackData.storeCode;
  }
  return dataToSend;
};

export const slackDebugger = (slack) => {
  let slackDataToSend = checkActionType(slack);
  fetch("https://cool-dragonfly-67.deno.dev", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(slackDataToSend),
  }).then((response) => {
    response.json().then((data) => {
      console.log(response.status);
      if (response.status === 200) {
        console.log(data.message);
      } else {
        alert(data.error);
      }
    });
  });
};

// https://cool-dragonfly-67.deno.dev
// http://localhost:8080
