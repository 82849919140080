import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import CustomButton from "../../components/CustomButtons/Button";
import {
  Dialog,
  Grid,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { getRequest } from "../../api/Api";
import axios from "../../utils/axios1";
import { toast } from "react-toastify";
import { blockButton } from '../../utils/helper'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  header: {
    margin: "0 auto",
    padding: 12,
    color: "blue",
  },
  content: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
  labelHead: {
    color: "#1079c5",
    fontSize: 17,
    fontWeight: 500,
  },
}));

const FlowboardStatusChange = props => {
  const { open, onClose, handleCount, updateFlowboardData } = props;
  const classes = useStyles();
  const [statusChange, setStatus] = useState([]);
  const [statusValue, setStatusValue] = useState("");

  useEffect(() => {
    let isMounted = true;
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_mso_flowboard_status", token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setStatus(data);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleStatus = e => {
    setStatusValue(e.target.value);
  };

  const updateFlowboardStatus = () => {
    if (statusValue === "") {
      alert("Please select status");
      return false;
    }
    blockButton('statuschange',true);
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      axios
        .put(
          `/update_flow_board_status/${updateFlowboardData.appointmentId}/${updateFlowboardData.patientId}`,
          { status: statusValue },
          { headers: { Authorization: token } },
        )
        .then(response => {
          toast(<p>{response.data.message}</p>, {
            className: "custom",
            autoClose: 1000,
          });
          setTimeout(() => {
            onClose();
            handleCount();
            setStatusValue("");
          }, 1000);
        })
        .catch(error => {
          blockButton('statuschange',false);
          if (error.response.data !== "") {
            toast.error(<p>{error.response.data.error}</p>, {
              autoClose: 3000,
            });
          } else {
            alert(error.response.statusText);
          }
        });
    }
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      // fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid container justify="center" item xs={12} sm={12} md={12}>
            <Typography style={{ fontSize: 15, fontWeight: 500 }}>
              Change Flowboard Status
            </Typography>
          </Grid>
          <Grid container justify="center" item xs={12} sm={12} md={12}>
            <TextField
              style={{ minWidth: 250 }}
              id="status"
              select
              label="Status"
              name="status"
              value={statusValue || ""}
              inputProps={{
                data_cy : `status-change-dropdown`,
              }}
              onChange={handleStatus}
            >
              {statusChange.map(option => (
                <MenuItem 
                 data_cy ={`status-change-option-${option.key}`}
                  key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container justify="center" style={{ flexGrow: 1 }}>
            <CustomButton
              style={{ width: 72, backgroundColor: "rgb(61, 170, 153)" }}
              onClick={updateFlowboardStatus}
              id="statuschange"
            >
              Submit
            </CustomButton>
            <CustomButton
              style={{ width: 72, backgroundColor: "#909090" }}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

FlowboardStatusChange.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default FlowboardStatusChange;
