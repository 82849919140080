import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import "./VonageVideoCall.css";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";

import {
  toggleAudio,
  toggleVideo,
  toggleAudioSubscribtion,
  toggleVideoSubscribtion,
  initializeSession,
  stopStreaming,
} from "./VonageApiSetUp";

const VonageVideoCall = (props) => {
  const [isVideoCallStarted, setIsVideoCallStarted] = useState(false);
  const [isStreamSubscribed, setIsStreamSubscribed] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isAudioSubscribed, setIsAudioSubscribed] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isVideoSubscribed, setIsVideoSubscribed] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleVideoCallSubscribtion = (value) => {
    setIsSubscribed(value);
  };

  const startVideoCallHandler = (data) => {
    setIsVideoCallStarted(true);
    console.log(data)
    initializeSession({
      apiKey: data.vonage_api_key,
      sessionId: data.vonage_session_id,
      token: data.vonage_token_mso,
      name: data.patient_name,
      visitId:data.visit_id
    });
    handleVideoCallSubscribtion(true);
  };

  useEffect(()=>{
    let vonageData = localStorage.getItem('vonageData')
    startVideoCallHandler(JSON.parse(vonageData))
  },[])

  useEffect(() => {
    function handleCloseVideo() {
      const item = localStorage.getItem('callEndedStatus')
      if (item === '1') {
        stopStreaming();
        localStorage.removeItem("vonageData");
        localStorage.setItem("callEndedStatus", 0);
        setIsVideoCallStarted(false);
        handleVideoCallSubscribtion(false);
        window.close();
      }
    }
    window.addEventListener('storage', handleCloseVideo)
    return () => {
      window.removeEventListener('storage', handleCloseVideo)
    }
  }, [])

  useEffect(() => {
    setIsStreamSubscribed(isSubscribed);
  }, [isSubscribed]);

  const onToggleAudio = (action) => {
    setIsAudioEnabled(action);
    toggleAudio(action);
  };
  const onToggleVideo = (action) => {
    setIsVideoEnabled(action);
    toggleVideo(action);
  };
  const onToggleAudioSubscribtion = (action) => {
    setIsAudioSubscribed(action);
    toggleAudioSubscribtion(action);
  };
  const onToggleVideoSubscribtion = (action) => {
    setIsVideoSubscribed(action);
    toggleVideoSubscribtion(action);
  };

  const renderToolbar = () => {
    return (
      <>
        {isVideoCallStarted && (
          <div className="video-toolbar">
            {isAudioEnabled ? (
              <Tooltip title="mic on">
                <MicIcon
                  onClick={() => onToggleAudio(false)}
                  className="on-icon"
                />
              </Tooltip>
            ) : (
              <Tooltip title="mic off">
                <MicOffIcon
                  onClick={() => onToggleAudio(true)}
                  className="off-icon"
                />
              </Tooltip>
            )}
            {isVideoEnabled ? (
              <Tooltip title="camera on">
                <VideocamIcon
                  onClick={() => onToggleVideo(false)}
                  className="on-icon"
                />
              </Tooltip>
            ) : (
              <Tooltip title="camera off">
                <VideocamOffIcon
                  onClick={() => onToggleVideo(true)}
                  className="off-icon"
                />
              </Tooltip>
            )}

            {isStreamSubscribed && (
              <>
                {isAudioSubscribed ? (
                  <Tooltip title="sound on">
                    <VolumeUpIcon
                      onClick={() => onToggleAudioSubscribtion(false)}
                      className="on-icon"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="sound off">
                    <VolumeOffIcon
                      onClick={() => onToggleAudioSubscribtion(true)}
                      className="off-icon"
                    />
                  </Tooltip>
                )}
                {isVideoSubscribed ? (
                  <Tooltip title="screen on">
                    <VisibilityIcon
                      onClick={() => onToggleVideoSubscribtion(false)}
                      className="on-icon"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="screen of">
                    <VisibilityOffIcon
                      onClick={() => onToggleVideoSubscribtion(true)}
                      className="off-icon"
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <Grid container>
          <div className="video-container">
            <div
              id="subscriber"
              className={`${
                isStreamSubscribed ? "main-video" : "additional-video"
              }`}
            >
              {isStreamSubscribed && renderToolbar()}
            </div>
            <div
              id="publisher"
              className={`${
                isStreamSubscribed ? "additional-video" : "main-video"
              }`}
            >
              {!isStreamSubscribed && renderToolbar()}
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default VonageVideoCall;
