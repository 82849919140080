import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import Button from "../CustomButtons/Button";
import { getRequest } from "../../api/Api";
import { toast } from "react-toastify";
import { postRequest } from "../../api/Api";
import { useForm, Controller } from "react-hook-form";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { blockButton } from '../../utils/helper'
const useStyles = makeStyles((theme) => ({
  issueType: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 225,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 325,
    },
  },
}));

const RaiseIssueForm = (props) => {
  const classes = useStyles();
  const {
    selectedPatientId,
    selectedVisitId,
    patientInfo,
    resetPatientId,
    setPatientListing,
  } = props;
  const { handleSubmit, watch, errors, control } = useForm();
  const [issueType, setissueType] = useState([]);
  const issueFormRef = useRef(null);

  const issueDescription = watch("description");
  const issueTypeValue = watch("issueType");

  useEffect(() => {
    issueFormRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatientId]);

  useEffect(() => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const Endpoint = "get_issue_type_list";
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setissueType(data);
      }
    });
  }, []);

  const onSubmit = (data) => {
    var formData = new FormData();
    formData.append("issue[patient_id]", selectedPatientId);
    formData.append("issue[visit_id]", selectedVisitId);
    formData.append("issue[issue_type]", data.issueType);
    formData.append(
      `issue[issue_descriptions_attributes][0][description]`,
      data.description
    );
    blockButton('raiseissue',true);
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "issues",
      formData: formData,
    };
    postRequest(body).then((data) => {
      if (data.error) {
        blockButton('raiseissue',false);
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        resetPatientId();
        setPatientListing([]);
      }
    });
  };

  return (
    <div ref={issueFormRef}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: 0, marginLeft: "1%" }} data-testid={`raise-issue-card`}>
            <CardHeader
              style={{ width: "140px", padding: "6px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Raise Issue</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                      Patient Name
                    </Typography>
                    <Typography>{patientInfo}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      error={Boolean(errors.issueType)}
                      // style={{ minWidth: 325 }}
                      className={classes.issueType}
                    >
                      <InputLabel id="issue-type-label">Issue Type</InputLabel>

                      <Controller
                        as={
                          <Select>
                            {issueType.map((option) => (
                              <MenuItem 
                                data_cy ={`issuetype-option-${option.key}`}
                                key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="issueType"
                        inputProps={{
                          data_cy :`issuetype-dropdown`
                        }}
                        rules={{ required: "Issue Type is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.issueType && errors.issueType.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.description)}
                      name="description"
                      inputProps={{
                        data_cy :`issuetype-input`
                      }}
                      control={control}
                      defaultValue=""
                      multiline
                      rowsMax="4"
                      rules={{ required: "Description is required" }}
                      label="Description"
                      helperText={
                        errors.description && errors.description.message
                      }
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    container
                    justify="flex-end"
                    direction="row"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Button
                      data-testid="raiseissue-submit-button"
                      type="submit"
                      id="raiseissue"
                    >
                      Submit
                    </Button>
                    <Button
                    data-testid="raiseissue-cancel-button"
                      style={{
                        color: "rgb(61, 170, 153)",
                        border: "1px solid rgb(226 226 226)",
                        backgroundColor: "rgb(255 255 255)",
                        boxShadow: "none",
                        marginLeft: "3%",
                      }}
                      onClick={resetPatientId}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RaiseIssueForm;
