import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import { getRequest } from "../../api/Api";
import Table from "../../components/Table/Table";
import Loader from "components/Loader/Loader";
import VitalForm from "../../components/Forms/Vitals";
import EditIcon from "@material-ui/icons/Edit";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  table: {
    minWidth: 320,
  },
});

function createData(name, value) {
  return { name, value };
}

const useStyles = makeStyles(styles);

const VitalVerticalTable = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [vital, setVital] = useState([]);
  const [showVitalForm, setShowVitalForm] = useState(false);
  const [vitalCount, setVitalCount] = useState();
  const fetchPatientVitalsById = () => {
    const endPoint = "get_patient_vitals_by_visit_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patient.patient.attributes.id, patient.visitId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (data.data.length !== 0) {
          const rows = [
            createData("Weight", `${data.data[0].attributes.weight_kg} kg`),
            createData("Height", `${data.data[0].attributes.height_cm} cm`),
            createData(
              "Blood Pressure",
              `${data.data[0].attributes.bpd} bpd/${data.data[0].attributes.bps} bps`
            ),
            createData("Pulse", `${data.data[0].attributes.pulse}/min`),
            createData(
              "Respiration",
              `${data.data[0].attributes.respiration}/min`
            ),
            createData(
              "Temperature",
              `${data.data[0].attributes.temperature_f} F`
            ),
            createData(
              "Temp. Location",
              `${
                data.data[0].attributes.temp_location !== null
                  ? data.data[0].attributes.temp_location.value
                  : ""
              }`
            ),
            createData(
              "Oxygen Saturation",
              `${data.data[0].attributes.oxygen_saturation} %`
            ),
            createData("BMI", `${data.data[0].attributes.bmi} kg/m2`),
            createData("BMI Status", `${data.data[0].attributes.bmi_status}`),
          ];
          setVital(rows);
        } else {
          setVital([]);
        }
      }
    });
  };
  useEffect(() => {
    if (patient.patientInfo) {
      if (patient.patientInfo.attributes.flagged_count.vitals > 0) {
        setVitalCount(patient.patientInfo.attributes.flagged_count.vitals);
      } else {
        setVitalCount(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patientInfo]);
  
  useEffect(() => {
    if(!showVitalForm){
    fetchPatientVitalsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVitalForm]);
  return (
    <Fragment>
      <div className={classes.root}>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            {showVitalForm ? (
              <VitalForm
                patient={patient.patient}
                vitalLength={patient.vitalLength}
                medicalProblemLength={patient.medicalProblemLength}
                setShowVitalForm={(value) => setShowVitalForm(value)}
                correctionCountHandler={patient.correctionCountHandler}
                isReviewOnly={patient.isReviewOnly}
              />
            ) : (
              <Card
                style={{
                  boxShadow:
                    "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                }}
                data-testid={`vital-detail-card`}
              >
                <CardHeader
                  style={{ width: "85px", marginTop: 0, marginLeft: 0 }}
                  color="success"
                >
                  <h4 className={classes.cardTitleWhite}>Vitals</h4>
                </CardHeader>
                {patient.patient.attributes.last_visit.is_sent_for_review ===
                  0 &&
                patient.patient.attributes.last_visit
                  .is_sent_to_patient_pool === 0 ? (
                  <Grid
                    item
                    container
                    justify="flex-end"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <EditIcon
                      data-testid="vital-edit-icon"
                      onClick={() => setShowVitalForm(true)}
                    />
                  </Grid>
                ) : null}
                <CardBody>
                  {vitalCount > 0 ? (
                    <Typography
                      align="center"
                      style={{ color: "red", fontSize: 15, fontWeight: 500 }}
                    >
                      Correction required
                    </Typography>
                  ) : null}
                  <Table rows={vital} />
                </CardBody>
              </Card>
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default VitalVerticalTable;
