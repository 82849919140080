import React, { useEffect, useState, Fragment } from "react";
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Loader from "./Loader/Loader";
import { getRequest } from "../api/Api";


const useStyles = makeStyles(theme => ({
  list: {
    width: "100%",
    backgroundColor: "white",
    padding: 5,
  },
  content: {
    padding: 0,
  },
  itemDivider: {
    borderBottomColor: "rgb(175 157 157 / 20%)",
  },
}));

const CategorySelectionList = props => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [categories, setCategories] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_doctor_categories_with_fee", token).then(data => {
        if (data.error) {
          console.log(data.error);
          setShowSpinner(false);
        } else {
          if (isMounted) {
            setCategories(data);
            // console.log(data);
            setShowSpinner(false);
          }
        }
      });
    }, 500);
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Fragment>
      {showSpinner ? (
        <Loader />
      ) : (
        <CardContent className={classes.content} xs={12}>
          <List className={classes.list}>
            {categories.map((category, i) => (
              <ListItem
                classes={{ divider: classes.itemDivider }}
                divider={i < categories.length - 1}
                key={category.id}
                data-testid={`doctor-category-${category.id}`}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{ fontWeight: 500, fontSize: 15 }}
                    >
                      {category.category_title}
                    </Typography>
                  }
                  secondary={
                    <Fragment>
                      <Typography>
                        {category.online_doctors} doctors online now
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ color: "#546871", fontSize: 14 }}
                      >
                        Avg. wait time: {category.tentative_wait_time} minutes{" "}
                        <br />{" "}
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>
                        Standard Fees: ₹ {category.fee}
                      </Typography>
                    </Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    data-testid={`select-category-button-${category.category_title ? category.category_title.toLowerCase(): ''}`} 
                    className={classes.buttonSelector}
                    onClick={() => {
                      props.onSelectCategory(category.category_title,category.id,);
                      props.setCategoryInfo({
                        id: category.id,
                        name: category.category_title,
                        fee: category.fee,
                      });
                    }}
                  >
                    Select
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CardContent>
      )}
    </Fragment>
  );
};

export default CategorySelectionList;
