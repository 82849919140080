import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";

import { Dialog, Grid, Typography, Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import MedicalData from "./MedicalProblem";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VitalData from "./Vital";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { getRequest } from "../../../../api/Api";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          data-testid="timeline-cancel-button"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  header: {
    margin: "0 auto",
    padding: 12,
    color: "blue",
  },
  content: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
  labelHead: {
    color: "#1079c5",
    fontSize: 17,
    fontWeight: 500,
  },
}));

const TimelineModal = (props) => {
  const {
    patientId,
    visitId,
    vitalData,
    medicalProblemData,
    displayDate,
    open,
    onClose,
  } = props;
  const classes = useStyles();
  const [prescriptionUrl, setPrescriptionUrl] = useState();

  useEffect(() => {
    let isMounted = true;
    const token = "Bearer " + localStorage.getItem("jwt");
    if (visitId && open) {
      getRequest(
        `get_prescription_by_visit_id/${patientId}/${visitId}`,
        token
      ).then((data) => {
        if (data.error) {
          console.log(data.error);
          if (data.error === "Prescription has not yet been generated.") {
            setPrescriptionUrl(null);
          }
        } else {
          if (isMounted) {
            setPrescriptionUrl(data.prescription_pdf_url);
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [patientId, visitId,open]);

  const pdfopenHandler = (prescriptionUrl) => {
    let link = document.createElement("a");
    link.setAttribute("rel", "noopener noreferrer");
    link.setAttribute("target", "about:blank");
    link.setAttribute("href", prescriptionUrl);
    link.click();
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle
        style={{ color: "blue", textAlign: "center" }}
        id="customized-dialog-title"
        onClose={onClose}
      >
        {moment
          .utc(displayDate)
          .local()
          .format("Do MMM  YYYY")}
      </DialogTitle>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.labelHead}>
                  Medical Problem
                </Typography>
                <br />
                <MedicalData medicalProblemData={medicalProblemData} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.labelHead}>Vital</Typography>
                <br />
                <VitalData vitalData={vitalData} />
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <br />
                <br />
                {prescriptionUrl ? <ArrowForwardIcon /> : <InfoIcon />}
              </Grid>
              <Grid item xs={12} sm={11} md={11}>
                <br />
                <br />
                {prescriptionUrl ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    data-testid={`prescription-view-button`}
                    className={classes.button}
                    onClick={() => pdfopenHandler(prescriptionUrl)}
                    startIcon={<VisibilityIcon />}
                  >
                    View Prescription
                  </Button>
                ) : (
                  <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                    Prescription is not available
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  data-testid="timeline-cancel-button"
                  style={{ float: "right", backgroundColor: "#9a9a9a" }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

TimelineModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default TimelineModal;
