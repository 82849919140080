import React from "react";
import { Grid, Typography, Card, Link } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

const SelectDataType = ({ dataToShow, heading }) => {
  const { url } = useRouteMatch();
  return (
    <>
      <Grid container style={{ marginTop: "9%" }}>
        <Grid item direction="column" container xs={12} sm={12} md={12}>
          <Typography align="center" style={{ fontSize: 18, fontWeight: 600 }}>
            {heading}
          </Typography>
        </Grid>

        {dataToShow.map((type) => (
          <Grid
            item
            xs={12 / dataToShow.length}
            container
            direction="column"
            justify="center"
            alignItems="center"
            key={type.displayName}
          >
            <br />
            <br />
            <Card
              style={{
                width: "80%",
                // height: "50%",
                minHeight: 180,
                display: "flex",
                flexDirection: "row",
                justify: "center",
                alignItems: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}
              key={type.displayName}
              data-testid={`select-type-${type.submitValue}`}
              component={Link}
              href={`${url}?type=${type.submitValue}`}
            >
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                {type.displayName}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SelectDataType;
