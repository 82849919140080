import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper, Tooltip, IconButton, Divider } from "@material-ui/core";
import InputBox from "../../components/Chat/InputBox";
import SendButton from "../../components/Chat/SendButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    border: "1px solid #cecece",
  },
  file: {
    display: "none",
  },
  divider: {
    width: 1,
    height: 24,
  },
}));

const ChatForm = (props) => {
  const classes = useStyles();
  const [description, setDescription] = useState([]);
  const fileInputRef = useRef(null);

  const onSubmit = (data) => {
    console.log(description);
  };

  const attachmentHandler = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                  <InputBox
                    changed={(e) => setDescription(e.target.value)}
                    placeholder="Type message here"
                  />
                </Paper>
                <SendButton description={description} changed={onSubmit} />
                <Divider className={classes.divider} />
                <Tooltip title="Attach File">
                  <IconButton onClick={attachmentHandler} edge="end">
                    <AttachFileIcon />
                  </IconButton>
                </Tooltip>
                <input
                  className={classes.file}
                  ref={fileInputRef}
                  type="file"
                />
              </div>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatForm;
