import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import ChatBoxDetail from "./ChatBoxDetail";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    // maxHeight: "100%",
    backgroundColor: "beige",
  },
  inner: {
    padding: theme.spacing(2),
  },
}));

const ChatDetailHolder = (props) => {
  const { messages } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container></Grid>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <div
            style={{
              maxHeight: "400px",
              WebkitOverflowScrolling: "touch",
              position: "relative",
            }}
          >
            {chats.map((message) => {
              return <ChatBoxDetail key={message.id} message={message} />;
            })}
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ChatDetailHolder;

const chats = [
  {
    id: "1",
    sender: {
      otherUser: false,
      name: "Admin",
      avatar: "/images/avatars/admin1.png",
    },
    content:
      "Hey, how are you doing man long time no see. Lets catch up this weekend.Hey, how are you doing man long time no see. Lets catch up this weekend",
    created_at: "5 min ago",
  },
  {
    id: "2",
    sender: {
      otherUser: true,
      name: "Doctor",
      avatar: "/images/avatars/doctor.png",
    },
    content:
      "Hey bro , i am doing good what about you . Yeah sure lets meet this weekend",
    created_at: "1 min ago",
  },
  {
    id: "3",
    sender: {
      otherUser: false,
      name: "Admin",
      avatar: "/images/avatars/admin1.png",
    },
    content:
      "Hey, how are you doing man long time no see. Lets catch up this weekend.Hey, how are you doing man long time no see. Lets catch up this weekend",
    created_at: "5 min ago",
  },
  {
    id: "4",
    sender: {
      otherUser: true,
      name: "Doctor",
      avatar: "/images/avatars/doctor.png",
    },
    content:
      "Hey bro , i am doing good what about you . Yeah sure lets meet this weekend",
    created_at: "1 min ago",
  },
  {
    id: "5",
    sender: {
      otherUser: false,
      name: "Admin",
      avatar: "/images/avatars/admin1.png",
    },
    content:
      "Hey, how are you doing man long time no see. Lets catch up this weekend.Hey, how are you doing man long time no see. Lets catch up this weekend",
    created_at: "5 min ago",
  },
  {
    id: "6",
    sender: {
      otherUser: true,
      name: "Doctor",
      avatar: "/images/avatars/doctor.png",
    },
    content:
      "Hey bro , i am doing good what about you . Yeah sure lets meet this weekend",
    created_at: "1 min ago",
  },
];
