import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { authContext } from "./ProvideAuth";
import Loader from "./components/Loader/Loader";

function LoginSwitcher({ children }) {
  const auth = useContext(authContext);
  return (
    <Route
      render={() =>
        auth.isLoggedIn ? (
          auth.isChecking ? (
            <Loader />
          ) : (
            children
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default LoginSwitcher;
