import React, { useEffect, useState } from "react";
import DoctorList from "./DoctorCategory/DoctorList";
import { Grid, Typography, useTheme } from "@material-ui/core";
import DoctorBio from "./ViewDoctorBio/ViewBio";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import FilterDoctorForm from "./DoctorCategory/FilterDoctor";
import FilterListIcon from "@material-ui/icons/FilterList";
// Make this accordion a simplified component in the Components list
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CreateAppointmentForm from "./BookAppointment/CreateAppointment/CreateAppointment";
import PatientPage from "./PatientList/PatientList";
import CategorySelectionList from "../components/CategorySelectionList";
import SelectAppointmentType from "../components/SelectDataType";
import ShowCurrentBalance from "../components/ShowCurrentBalance";
import { getRequest } from "../api/Api";
import CategoryLanguage from "./DoctorCategory/CategoryLanguage";

const FilterComponent = ({
  setFilterDoctor,
  setFilterByOption,
  handleAccordian,
  setDoctorLanguageForAppointment,
  expanded,
  setIsReset,
  setIsShowOnlineDoc
}) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      md={12}
      style={{ padding: 8 }}
      sm={12}
      xs={12}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordian("panel1")}
        data-testid="filteraccordian-doctor-button"
        style={{ flexGrow: 1 }}
      >
        <AccordionSummary
          expandIcon={<FilterListIcon />}
          id="doctor-filteration-form"
        >
          <Typography style={{ fontSize: 16, fontWeight: 500 }}>
            Filter Doctors
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterDoctorForm
            setFilterDoctor={setFilterDoctor}
            setDoctorLanguageForAppointment={setDoctorLanguageForAppointment}
            setFilterByOption={setFilterByOption}
            handleAccordian={handleAccordian}
            setIsReset={setIsReset}
            setIsShowOnlineDoc={setIsShowOnlineDoc}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const SelectPatientForAppointment = (props) => {
  const { selectedDoctorId, appointmentType, setPatientId } = props;
  return (
    <PatientPage
      // doctorId={selectedDoctorId}
      appointmentType={appointmentType} //Change to have this be used to render the select patient button as well
      updatePatientId={(value) => setPatientId(value)}
    />
  );
};

const ShowAppointmentForm = (props) => {
  const [patientId, setPatientId] = useState(props.patientId || undefined);
  // on cancel should also set patient to undefined
  return patientId ? (
    <CreateAppointmentForm
      patientId={patientId}
      doctorConsultationLanguage={props.doctorConsultationLanguage}
      appointmentType={props.appointmentType}
      doctorLanguageForAppointment={props.doctorLanguageForAppointment}
      doctorId={props.selectedDoctorId}
      category={props.category}
      doctorInfo={props.doctorInfo}
      categoryInfo={props.categoryInfo}
      user={props.user}
      // add an onCancel property to ensure proper handling of the
      onCancel={props.onCancel}
    />
  ) : (
    <SelectPatientForAppointment
      selectedDoctorId={props.selectedDoctorId}
      appointmentType={props.appointmentType}
      setPatientId={setPatientId}
    />
  );
};

const SelectDoctorForAppointment = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDoctorId, setSelectedDoctorId] = useState(undefined);
  const [doctorInfo, setDoctorInfo] = useState(undefined);
  const [showListOnMobile, setShowListOnMobile] = useState(true);
  const [showAppointmentSection, setShowAppointmentSection] = useState(false);
  const [doctorLanguageForAppointment,setDoctorLanguageForAppointment] = useState(null)
  const [doctorConsultationLanguage,setDoctorConsultationLanguage] = useState('')
  const [filterDoctors, setFilterDoctor] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [filterByOption, setFilterByOption] = useState();
  const [expanded, setExpanded] = useState(false);
  const [isShowOnlineDoc,setIsShowOnlineDoc] = useState(true)


  const handleAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (matches && selectedDoctorId) {
      setShowListOnMobile(false);
    } else {
      setShowListOnMobile(true);
    }
  }, [matches, selectedDoctorId]);

  return (
    <Grid container>
      {showListOnMobile ? (
        <Grid item md={5} sm={12} xs={12}>
          <FilterComponent
            setFilterDoctor={setFilterDoctor}
            setFilterByOption={setFilterByOption}
            setDoctorLanguageForAppointment={setDoctorLanguageForAppointment}
            handleAccordian={handleAccordian}
            expanded={expanded}
            setIsReset={setIsReset}
            setIsShowOnlineDoc={setIsShowOnlineDoc}
          />
          <DoctorList
            setDoctorId={setSelectedDoctorId}
            filterDoctors={filterDoctors}
            isReset={isReset}
            setIsReset={setIsReset}
            showAppointmentSection={setShowAppointmentSection}
            setDoctorInfo={setDoctorInfo}
            isShowOnlineDoc={isShowOnlineDoc}
            setIsShowOnlineDoc={setIsShowOnlineDoc}
          />
        </Grid>
      ) : null}
      <Grid item md={7} sm={12} xs={12} style={{ padding: 8 }}>
        {!!selectedDoctorId ? (
          <DoctorBio
            selectedDoctorId={selectedDoctorId}
            user={props.user}
            doctorLanguageForAppointment={doctorLanguageForAppointment}
            resetDoctorId={() => {
              setSelectedDoctorId(undefined);
              setShowAppointmentSection(false);
            }}
            onBookAppointment={(value) => {
              setShowAppointmentSection(true);
              setDoctorConsultationLanguage(value)
            }}
          />
        ) : null}
        {!!showAppointmentSection ? (
          <ShowAppointmentForm
            selectedDoctorId={selectedDoctorId}
            doctorConsultationLanguage={doctorConsultationLanguage}
            appointmentType={props.appointmentType}
            patientId={props.patientId}
            user={props.user}
            doctorLanguageForAppointment={doctorLanguageForAppointment}
            doctorInfo={doctorInfo}
            onCancel={() => {
              setShowAppointmentSection(false);
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

const SelectCategoryForAppointment = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [categoryInfo, setCategoryInfo] = useState(undefined);
  const [openCategoryLanguage, setOpenCategoryLanguage] = useState(false);
  const [selectedValueLanguage, setSelectedValueLanguage] = useState('')
  const [categoryData,setCategoryData] = useState(null)

  const handleClickOpenConsulatationLanguage = (cat,id) => {
    setSelectedCategory(undefined);
    setOpenCategoryLanguage(true);
    setCategoryData({
      title:cat,
      id:id
    })
  };
  const handleCloseConsulatationLanguage = (value) => {
    setOpenCategoryLanguage(false);
    setSelectedValueLanguage(value);
  };

  return (
    <Grid container style={{ padding: "1%" }}>
      <Grid item md={10} xs={12}>
        <CategorySelectionList
          setCategoryInfo={setCategoryInfo}
          onSelectCategory={(cat,catId) => {
            handleClickOpenConsulatationLanguage(cat,catId)

          }}
        />
        {selectedCategory ? (
          <Grid item md={12} xs={12}>
            <ShowAppointmentForm
              categoryInfo={categoryInfo}
              user={props.user}
              appointmentType={props.appointmentType}
              doctorConsultationLanguage={selectedValueLanguage}
              patientId={props.patientId}
              onCancel={() => {
                setSelectedCategory(undefined);
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <CategoryLanguage
       openCategoryLanguage={openCategoryLanguage}
       categoryData={categoryData}
       setSelectedCategory={setSelectedCategory}
       onClose={handleCloseConsulatationLanguage}  
      />
    </Grid>
  );
};

const BookAppointmentPage = () => {
  let { patientId } = useParams();
  const [user, setUser] = useState();
  useEffect(() => {
    let isMounted = true;

    const fetchUserDetail = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("current_user", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setUser(data.data);
          }
        }
      });
    };

    fetchUserDetail();
    return () => {
      isMounted = false;
    };
  }, []);
  // Show the appointment section only when the doctorId and patientId both exist

  // Show the patient selection or creation section when doctorId is present but a patientId doesn't exist
  const query = new URLSearchParams(useLocation().search).get("type");
  // const [appointmentType, setAppointmentType] = useState(
  //   query.get("type") || undefined,
  // );

  if (query === "category") {
    // pass appointmentType and the patientId using props
    return (
      <>
        {/* <ShowCurrentBalance style={{ padding: 8 }} user={user} />
        <SelectCategoryForAppointment
          appointmentType={query}
          user={user} 
          patientId={patientId}
        /> */}
      </>
    );
  } else if (query === "specific") {
    return (
      <>
        <ShowCurrentBalance style={{ padding: 8 }} user={user} />
        <SelectDoctorForAppointment
          // TODO - change this up to use either the one in Type.js in the
          // views/BookAppointment/CreateAppointment or the specific on here
          appointmentType="individual"
          user={user}
          patientId={patientId}
        />
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <SelectAppointmentType
            heading="Choose to consult any Doctor"
            dataToShow={appointmentTypes}
          />
        </Grid>
      </Grid>
    );
  }
};

const BookAppointmentSelection = () => {
  // SelectAppointmentType
  // SelectDoctorOrCategory
  // SelectOrCreatePatient
  // BookAppointment
  // IfAppointmentOfToday, open patient page, else open FutureAppointmentPage
};

const BookAppointmentIndexPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:patientId`}>
        <BookAppointmentPage />
      </Route>
      <Route path={`${path}`}>
        <BookAppointmentPage />
      </Route>
    </Switch>
  );
};

// export default BookAppointmentPage;
export default BookAppointmentIndexPage;

const appointmentTypes = [
  // { displayName: "By Category", submitValue: "category" },
  { displayName: "Doctor", submitValue: "specific" },
];
