import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import CardFooter from "../../components/Card/CardFooter";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "./CurrentMedication.css";
import Button from "../CustomButtons/Button";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { getRequest, putRequest } from "../../api/Api";
import { Grid, TextField, Hidden } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "../../components/toast.css";
import { blockButton } from '../../utils/helper'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  footer: {
    // [theme.breakpoints.up("xs")]: {
    //   display: "none",
    // },
  },
}));

const Allergy = (props) => {
  const [allergy, setAllergy] = useState([]);
  const {
    patient,
    steps,
    step,
    handleNext,
    handleBack,
    setShowAllergyForm,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
    correctionCountHandler,
  } = props;
  const classes = useStyles();
  const { control, handleSubmit, watch, errors } = useForm({
  });

  const allWatchedValues = watch({ nest: true });

  const {
    fields: allergiesFields,
    append: allergiesAppend,
    prepend: allergiesPrepend,
    remove: allergiesRemove,

  } = useFieldArray({ control, name: "allergies" });

  const addExistingAllergy = (item) => {
    allergiesPrepend({
      id: item.attributes.id,
      observation: item.attributes.observation,
      remark: item.attributes.remark,
    });
  };

  useEffect(() => {
    if (allergy.length !== 0) {
      const timer = setTimeout(
        () => allergy.forEach((item) => addExistingAllergy(item)),
        10
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allergy]);



  useEffect(() => {
    
    const fetchPatientAllergyById = () => {
      const endPoint = "get_patient_allergies_by_patient_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      // let param = [props.match.params.id];
      let param = [patient.attributes.id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setAllergy(data.data);
        }
      });
    };

    fetchPatientAllergyById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    var formData = new FormData();
    if (Object.keys(data).length === 0 ) {
      handleNext();
    } else {
      for (let i = 0; i < data.allergies.length; i++) {
        if (data.allergies[i].id === "" || isNaN(data.allergies[i].id)) {
          delete data.allergies[i].id;
        }
        if (data.allergies[i].observation === "") {
          alert("Please Enter Observation");
          return false;
        }
        Object.keys(data.allergies[i]).forEach((key) => {
          formData.append(
            `patient[patient_allergies_attributes][${i}][${key}]`,
            data.allergies[i][key]
          );
        });
      }
      if (
        !isReviewOnly &&
        patient.attributes.last_visit.is_flagged === 0
        // medicalProblemLength === 0 &&
        // vitalLength === 0
      ) {
        handleNext();
      }else{
        blockButton('allergy',true)
      }
      let token = "Bearer " + localStorage.getItem("jwt");
      const body = {
        token: token,
        endPoint: "save_patient_allergies",
        formData: formData,
        params: [patient.attributes.id],
      };
      putRequest(body).then((data) => {
        if (data.error) {
          blockButton('allergy',false)
          toast.error(<p>{data.error}</p>, { autoClose: 3000 });
        } else {
          toast(<p>{data.message}</p>, {
            className: "custom",
            autoClose: 1000,
          });
          if (
            isReviewOnly ||
            patient.attributes.last_visit.is_flagged === 1 ||
            medicalProblemLength > 0 ||
            vitalLength > 0
          ) {
            setTimeout(() => {
              setShowAllergyForm(false);
              correctionCountHandler();
            }, 1000);
          }
        }
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "0px" }}>
            <CardHeader
              style={{ width: "30%", padding: "8px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Allergy</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} sm={12} md={12}>
                  <table className="crud" id="tab_logic">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      {allergiesFields.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>
                              <Controller
                                as={<input />}
                                name={`allergies[${index}].id`}
                                control={control}
                                defaultValue={item.id}
                                type="hidden"
                              />
                            </td>
                            <td>
                              <Controller
                                as={<TextField />}
                                error={Boolean(errors.observation)}
                                name={`allergies[${index}].observation`}
                                control={control}
                                inputProps={{
                                  data_cy :`allergy-observation-input`
                                }}
                                defaultValue={item.observation}
                                multiline
                                rowsMax="4"
                                label="Observation"
                                type="text"
                                fullWidth
                              />
                            </td>
                            <td>
                              {!item.id || isNaN(item.id) ? (
                                <>
                                  <HighlightOffIcon
                                    data-testid={`allergy-deleterow-${
                                      Object.keys(allWatchedValues).length !== 0 ?
                                      allWatchedValues.allergies[index] !==
                                      undefined
                                        ? allWatchedValues.allergies[
                                            index
                                          ].observation.toLowerCase()
                                        : "" : ''
                                    }-icon`}
                                    className={classes.icon}
                                    onClick={() => allergiesRemove(index)}
                                  />
                                </>
                              ) : null}
                            </td>
                            {item.remark !== null &&
                            item.remark !== undefined ? (
                              <td style={{ border: "none" }}>
                                <Alert severity="warning">{item.remark}</Alert>
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <section>
                    <AddCircleIcon
                      data-testid="allergy-addrow-icon"
                      onClick={() => {
                        allergiesAppend({
                          observation: "",
                        });
                      }}
                    />
                  </section>
                </Grid>
                {isReviewOnly ||
                patient.attributes.last_visit.is_flagged === 1 ||
                medicalProblemLength > 0 ||
                vitalLength > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justify="flex-end"
                  >
                    <CardFooter className={classes.footer}>
                      <Button
                        data-testid="allergy-submit-button"
                        style={{ width: 72 }}
                        type="submit"
                        id="allergy"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          color: "rgb(61, 170, 153)",
                          border: "1px solid rgb(226 226 226)",
                          backgroundColor: "rgb(255 255 255)",
                          boxShadow: "none",
                          width: 72,
                          marginLeft: "3%",
                        }}
                        data-testid="allergy-cancel-button"
                        onClick={() => setShowAllergyForm(false)}
                      >
                        Cancel
                      </Button>
                      <ToastContainer />
                    </CardFooter>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    {step !== 0 && (
                      <Button
                        onClick={handleBack}
                        data-testid="allergy-back-button"
                        style={{ backgroundColor: "grey" }}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      // onClick={handleNext}
                      data-testid="allergy-next-button"
                      type="submit"
                      style={{
                        backgroundColor: "#4d93dc",
                        marginLeft: "1%",
                      }}
                      className={classes.button}
                    >
                      {step === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                )}
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Allergy;
