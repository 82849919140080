import React from "react";
import { Grid, Typography, CardContent, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SecurityImg from "../../assets/img/secure.jpg";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";

const styles = (theme) => ({
  divider: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

const useStyles = makeStyles(styles);

const SecurityTypes = ({ securityType, amount }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={4} container direction="column">
        <Typography
          style={{
            textAlign: "center",
            width: "100%",
            fontSize: 17,
            fontWeight: 500,
          }}
        >
          {securityType}
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            width: "100%",
            fontSize: 18,
            lineHeight: 3,
            fontWeight: 600,
          }}
        >
          {amount}
        </Typography>
      </Grid>
    </>
  );
};

const SecurityAmount = ({ user }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Card
        style={{
          width: "80%",
          marginTop: 20,
        }}
        data-testid={`mso-security-card`}
      >
        <CardHeader>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img src={SecurityImg} style={{ width: 62 }} alt="security" />
            <Typography
              style={{
                textAlign: "center",
                width: "100%",
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Security
            </Typography>
          </Grid>{" "}
        </CardHeader>
        <CardContent className={classes.cardContent}>
          <SecurityTypes
            securityType="Equipment Security"
            amount={`₹ ${user.attributes.centre.equipment_security}`}
          />
          <Divider className={classes.divider} />
          <br />
          <SecurityTypes
            securityType="Consultation Security"
            amount={`₹ ${user.attributes.centre.consultation_security}`}
          />
          <Divider className={classes.divider} />
          <br />
          <SecurityTypes
            securityType="Rolling Advance"
            amount={`₹ ${user.attributes.centre.current_credit_balance}`}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SecurityAmount;
