import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  CardHeader,
} from "@material-ui/core";

import gradients from "../../utils/gradients";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: gradients.custom,
    color: theme.palette.primary.contrastText,
  },
  content: {
    paddingTop: 0,
  },
  itemDivider: {
    borderBottomColor: "rgba(255,255,255,0.2)",
  },
}));

const DashboardOption = (props) => {
  const {
    className,
    todayDashboardData,
    yesterdayDashboardData,
    date,
    ...rest
  } = props;
  const [dashboardOption, setDashboarOption] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (todayDashboardData) {
      const options = [
        {
          option: "Total Appointments",
          value: todayDashboardData.total_appointments,
        },
        {
          option: "Online Doctors",
          value: todayDashboardData.online_doctors,
        },
        {
          option: "Patients Waiting",
          value: todayDashboardData.patients_waiting,
        },
        {
          option: "Patients Checked Out",
          value: todayDashboardData.checked_out_patients,
        },
      ];
      setDashboarOption(options);
    }
    if (yesterdayDashboardData) {
      const options = [
        {
          option: "Total Appointments",
          value: yesterdayDashboardData.total_appointments,
        },
        {
          option: "Average Consultation Time",
          value: yesterdayDashboardData.average_consultation_time,
        },
        {
          option: "Doctor Consulted",
          value: yesterdayDashboardData.doctor_consulted,
        },
        {
          option: "Patients Checked Out",
          value: yesterdayDashboardData.checked_out_patients,
        },
      ];
      setDashboarOption(options);
    }
  }, [todayDashboardData, yesterdayDashboardData]);

  return (
    <Card className={clsx(classes.root, className)} data-testid={date}>
      <CardHeader
        title={date}
        style={{ textAlign: "center" }}
        titleTypographyProps={{ color: "inherit" }}
      />
      <CardContent
        className={classes.content}
        style={{ paddingLeft: "1em", paddingRight: "1em" }}
      >
        <List>
          {dashboardOption.map((dahsboard, i) => (
            <ListItem
              classes={{ divider: classes.itemDivider }}
              divider={i < dashboardOption.length - 1}
              // divider
              key={dahsboard.option}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <ListItemText
                primary={dahsboard.option}
                primaryTypographyProps={{ color: "inherit", variant: "body1" }}
              />
              <Typography color="inherit">{dahsboard.value}</Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default DashboardOption;

DashboardOption.propTypes = {
  className: PropTypes.string,
};
