import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { Link as RouterLink } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { postRequest, getRequest } from "../../api/Api";
import { blockButton } from '../../utils/helper'
import { ToastContainer, toast } from "react-toastify";
import "../../components/toast.css";
import "react-toastify/dist/ReactToastify.min.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "600",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    minWidth: 150,
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    marginBottom: "2px",
    fontSize: "14px",
  },
});

const useStyles = makeStyles(styles);

const CreatePromocode = (props) => {
  const classes = useStyles();

  const { handleSubmit, errors, control } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [user, setUser] = useState();

  useEffect(() => {
    let isMounted = true;

    const fetchUserDetail = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("current_user", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setUser(data.data);
          }
        }
      });
    };

    fetchUserDetail();
    return () => {
      isMounted = false;
    };
  }, []);
  if (startDate !== null) {
    var isSelectedDateToday = new Date().getDate() === startDate.getDate();
    var isSelectedDateInFuture = +startDate > +new Date();
  }
  let minTimeHour = new Date().getHours();
  console.log(minTimeHour);
  if (!isSelectedDateToday) {
  }
  minTimeHour = 0;

  const date = new Date();
  let currentMins = date.getMinutes();
  let currentHour = date.getHours();
  if (isSelectedDateInFuture) {
    currentHour = 0;
    currentMins = 0;
  }
  const handleStartDate = (value) => {
    setStartDate(value);
    console.log(value);
  };
  const CustomInputStartDate = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        data_cy ={`promocode-startdate-datepicker`}
        type="text"
        placeholder="Select Start Date Time "
        readOnly={true}
      />
    );
  });

  const CustomInputEndDate = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        data_cy ={`promocode-enddate-datepicker`}
        className={classes.input}
        type="text"
        placeholder="Select End Date Time "
        readOnly={true}
      />
    );
  });

  const createPromocodeRequest = (body) => {
    postRequest(body).then((data) => {
      if (data.error) {
        blockButton('promo',false);
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        console.log(data);
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        setTimeout(() => {
          props.history.push("/account/promocode");
        }, 1000);
      }
    });
  };

  const onSubmit = (data) => {
    if (data.discount > 100) {
      alert("Discount is grater than 100 %. Please check");
      return false;
    }

    let StartDate = moment(startDate).format("YYYY-MM-DD HH:mm");
    let EndDate = moment(data.end_datetime).format("YYYY-MM-DD HH:mm");
    const newStartDate = new Date(StartDate).toISOString();
    const newEndDate = new Date(EndDate).toISOString();
    var formData = new FormData();
    formData.append("promo_code[title]", data.title);
    formData.append("promo_code[discount_type]", "percentage");
    formData.append("promo_code[discount]", data.discount);
    formData.append("promo_code[start_datetime]", newStartDate);
    formData.append("promo_code[end_datetime]", newEndDate);
    formData.append("promo_code[max_usage]", data.max_usage);
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "promo_codes",
      formData: formData,
    };
    if (parseInt(user.attributes.centre.revenue_share) < data.discount) {
      if (
        window.confirm(
          "Discount is greater than Revenue Share. Do you wish to continue ?"
        )
      ) {
        blockButton('promo',true);
        createPromocodeRequest(body);
      }
    } else {
      blockButton('promo',true);
      createPromocodeRequest(body);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          <Card style={{ marginTop: "24px" }}  data-testid={`create-promocode-card`}>
            <CardHeader
              style={{ width: "147px", padding: "14px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Promo Code</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.title)}
                      name="title"
                      inputProps={{
                        data_cy : `promocode-title-input`
                      }}
                      rules={{ required: "Title is required" }}
                      control={control}
                      defaultValue=""
                      label="Title"
                      type="text"
                      helperText={errors.title && errors.title.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.discount)}
                      name="discount"
                      inputProps={{
                        data_cy : `promocode-discount-input`,
                        max: 100,
                        min: 1 
                      }}
                      type="number"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Discount is required",
                        minLength: 1,
                      }}
                      label="Discount(%)"
                      placeholder="%"
                      helperText={errors.discount && errors.discount.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.max_usage)}
                      name="max_usage"
                      inputProps={{
                        data_cy :`promocode-maxusage-input`,
                        min: 1 
                      }}
                      rules={{ required: "Max Usage is required" }}
                      control={control}
                      defaultValue=""
                      label="Max Usage"
                      type="number"
                      helperText={errors.max_usage && errors.max_usage.message}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <ReactDatePicker
                      error={Boolean(errors.start_datetime)}
                      control={control}
                      valueName="selected" // DateSelect value's name is selected
                      selected={startDate}
                      onChange={(date) => handleStartDate(date)}
                      name="start_datetime"
                      rules={{ required: "Start Date is required" }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      timeCaption="time"
                      minTime={
                        new Date(
                          new Date().setHours(currentHour, currentMins, 0, 0)
                        )
                      }
                      maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                      dateFormat="yyyy-MM-dd HH:mm "
                      helperText={
                        errors.start_datetime && errors.start_datetime.message
                      }
                      minDate={moment().toDate()}
                      isClearable
                      customInput={<CustomInputStartDate />}
                      popperPlacement="bottom-start"
                      popperModifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                        },
                      }}
                    />
                    {errors.start_datetime && (
                      <div style={{ color: "red" }}>
                        {" "}
                        Start Date is required
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<ReactDatePicker />}
                      error={Boolean(errors.end_datetime)}
                      control={control}
                      valueName="selected" // DateSelect value's name is selected
                      onChange={([selected]) => selected}
                      name="end_datetime"
                      rules={{ required: "End Date is required" }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      timeCaption="time"
                      dateFormat="yyyy-MM-dd HH:mm "
                      helperText={
                        errors.end_datetime && errors.end_datetime.message
                      }
                      minDate={moment().toDate()}
                      isClearable
                      customInput={<CustomInputEndDate />}
                      popperPlacement="bottom-start"
                      popperModifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                        },
                      }}
                    />
                    {errors.end_datetime && (
                      <div style={{ color: "red" }}> End Date is required</div>
                    )}
                  </Grid>
                </Grid>
                <CardFooter style={{ float: "right" }}>
                  <Button data-testid="create-promocode-button"  id="promo" type="submit">Create</Button>
                  <Button data-testid="cancel-promocode-button"  component={RouterLink} to="/account/promocode">
                    Cancel
                  </Button>
                </CardFooter>
                <ToastContainer />
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreatePromocode;
