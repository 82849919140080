import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  inner: {
    display: "flex",
    // maxWidth: 500
  },
  body: {
    // backgroundColor: "#cfe2c5",
    backgroundColor: "rgb(52 144 130)",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const IssueContent = (props) => {
  const { message } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div>
          <div className={classes.body}>
            <div className={classes.content}>
              <Typography color="inherit" variant="body1">
                {message.description}
              </Typography>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.time} variant="body2">
              {moment(message.created_at).format("YYYY-MM-DD")}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueContent;
