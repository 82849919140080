import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Grid from "@material-ui/core/Grid";
import PromocodeHistoryContainer from "./PromocodeHistoryContainer";
import PromoCode from "./Promocode";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

const useStyles = makeStyles((theme) => ({
  customTab: {
    [theme.breakpoints.up("sm")]: {
      width: "42%",
    },
    [theme.breakpoints.up("md")]: {
      width: "32%",
    },
  },
}));

const PromocodeTab = (props) => {
  const classes = useStyles();

  return (
    <div style={{ marginTop: "10px", padding: 4 }}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="success"
            className={classes.customTab}
            tabs={[
              {
                tabName: "Promocode",
                test:"navigate-to-promocode",
                tabContent: <PromoCode />,
              },
              {
                tabName: "Promocode Expired",
                test:"navigate-to-promocode-expired",
                tabContent: <PromocodeHistoryContainer />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default PromocodeTab;
