import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/UseTable/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";

const styles = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 7,
    marginTop: -30,
    float: "right",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "Weight(kg)", label: "Weight(kg)" },
  { id: "Height(cm)", label: "Height(cm)" },
  { id: "BPS", label: "BPS" },
  { id: "BPD", label: "BPD" },
  { id: "Pulse", label: "Pulse" },
  { id: "Respiration", label: "Respiration" },
  { id: "Temperature(F)	", label: "Temperature(F)" },
  { id: "BMI(kg^m2)", label: "BMI(kg^m2)" },
  { id: "BMI Status", label: "BMI Status" },
];

const VitalData = (props) => {
  const classes = useStyles();
  const { vitalData } = props;
  const { TblContainer, TblHead } = useTable(vitalData, headCells);
  console.log(vitalData);
  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            <TblContainer>
              <TblHead style={{ backgroundColor: "rgb(175 170 170)" }} />
              <TableBody>
                {vitalData.map((item) => (
                  <StyledTableRow key={item.id}>
                    <TableCell>{item.attributes.weight_kg}</TableCell>
                    <TableCell>{item.attributes.height_cm}</TableCell>
                    <TableCell>{`${item.attributes.bps} bps`}</TableCell>
                    <TableCell>{`${item.attributes.bpd} bpd`}</TableCell>
                    <TableCell>{item.attributes.pulse}</TableCell>
                    <TableCell>{item.attributes.respiration}</TableCell>
                    <TableCell>{item.attributes.temperature_f}</TableCell>
                    <TableCell>{item.attributes.bmi}</TableCell>
                    <TableCell>{item.attributes.bmi_status}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default VitalData;
