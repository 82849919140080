import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import validate from "validate.js";
import lockIcon from "../../assets/img/lockIcon.png";
import API from "../../utils/baseUrl";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PasswordGuidlines from "../../components/PasswordGuidlines/PasswordGuidlines";
import { useParams,useHistory } from "react-router-dom";

const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },
  },
  confirmNewPassword: {
    presence: { allowEmpty: false, message: "is required" },
    equality: {
      attribute: "newPassword",
      message: "^The passwords do not match",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-around",
    },
  },
  heading: {
    fontSize: 29,
    marginTop: 35,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
    [theme.breakpoints.up("md")]: {
      width: "30vw",
      padding: 5,
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#84cfc4",
    fontWeight: 600,
    padding: 8,
    "&:hover": {
      backgroundColor: "#84cfc4",
    },
  },
}));

const ResetPassword = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isRuleFollowed, setIsRuleFollowed] = useState(false);
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const { className, ...rest } = props;
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${API}/password_resets/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: {
            password: formState.values.newPassword,
          },
        }),
      });

      if (res.status !== 200) {
        const { message } = await res.json();
        toast.error(
          <p style={{ lineHeight: 2, fontSize: 13 }}>
            {Array.isArray(message) ? message.join("\n") : message}
          </p>,
          { autoClose: 3000 }
        );
      } else {
        setLoading(false);
        toast.success(<p>Password changed successfully</p>, {
          autoClose: 2000,
        });
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <div className={classes.root}>
        <div>
          <div className={classes.paper}>
            <img src={lockIcon} style={{ height: 90 }} alt="" />
            <Typography className={classes.heading}>
              Create your new Password
            </Typography>
            <Typography
              style={{
                color: "rgb(99, 115, 129)",
                textAlign: "center",
                fontSize: 15,
                marginTop: 20,
              }}
            >
              Almost Done , Please enter your new password <br /> and you're all
              set to go.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                error={hasError("newPassword")}
                type={showPassword.newPassword ? "text" : "password"}
                fullWidth
                helperText={
                  hasError("newPassword")
                    ? formState.errors.newPassword[0]
                    : null
                }
                label="New Password"
                margin="normal"
                name="newPassword"
                required
                onChange={handleChange}
                value={formState.values.newPassword || ""}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            newPassword: !showPassword.newPassword,
                          }))
                        }
                      >
                        {showPassword.newPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={hasError("confirmNewPassword")}
                fullWidth
                type={showPassword.confirmPassword ? "text" : "password"}
                helperText={
                  hasError("confirmNewPassword")
                    ? formState.errors.confirmNewPassword[0]
                    : null
                }
                label="Confirm New Password"
                margin="normal"
                name="confirmNewPassword"
                required
                onChange={handleChange}
                value={formState.values.confirmNewPassword || ""}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            confirmPassword: !showPassword.confirmPassword,
                          }))
                        }
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ marginTop: 4 }}>
                <PasswordGuidlines
                  checkRules={setIsRuleFollowed}
                  password={formState.values.newPassword}
                />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!formState.isValid || !isRuleFollowed}
                className={classes.submit}
              >
                Create Password
              </Button>
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "black",
                  fontWeight: 600,
                  marginTop: 12,
                }}
                href="/login"
              >
                <ArrowBackIosIcon style={{ width: 16, height: 16 }} />
                <span style={{ fontSize: 14 }}>Return to Log in</span>
              </a>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
