import React, { useState } from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Button from "./CustomButtons/Button";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const styles = (theme) => ({
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    fontSize: "14px",
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      top: 10,
      padding: "9px 25px",
    },
    [theme.breakpoints.down("sm")]: {
      top: 10,
      padding: "12px 9px",
      fontSize: 13,
    },
  },
  buttonGrid: {
    [theme.breakpoints.up("sm")]: {
      height: 0,
    },
  },
});
const useStyles = makeStyles(styles);

const FilterData = ({
  sendData,
  clearCounterHandler,
  buttonText,
  testing,
  headingText,
  downLoadCsv,
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  let startNow = new Date();
  let endNow = new Date();

  const { handleSubmit } = useForm();

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        data_cy={`${props.test}-date-datepicker`}
        value={props.value}
        className={classes.input}
        type="text"
        readOnly={true}
      />
    );
  });

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const StartDate = () => {
    return (
      <React.Fragment>
        <ReactDatePicker
          valueName="selected"
          selected={startDate}
          onChange={(date) => handleStartDate(date)}
          name="start_date"
          filterDate={(date) => {
            return moment() > date;
          }}
          isClearable
          minDate={
            testing === "transaction"
              ? new Date(startNow.setDate(startNow.getDate() - 45))
              : ""
          }
          customInput={<CustomInput test="start" />}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          defaultValue=""
          dateFormat="yyyy/MM/dd "
          dropdownMode="select"
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </React.Fragment>
    );
  };
  const EndDate = () => {
    return (
      <React.Fragment>
        <ReactDatePicker
          valueName="selected"
          onChange={(date) => handleEndDate(date)}
          selected={endDate}
          name="end_date"
          filterDate={(date) => {
            return moment() > date;
          }}
          isClearable
          minDate={
            testing === "transaction"
              ? new Date(endNow.setDate(endNow.getDate() - 45))
              : ""
          }
          customInput={<CustomInput test="end" />}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          defaultValue=""
          dateFormat="yyyy/MM/dd "
          dropdownMode="select"
          popperPlacement="bottom-end"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </React.Fragment>
    );
  };
  const clearData = () => {
    clearCounterHandler();
    setStartDate();
    setEndDate();
  };
  const SearchButton = () => {
    return (
      <Button
        className={classes.button}
        style={{ backgroundColor: "#2979ff" }}
        type="submit"
        data-testid={`search-${testing}-button`}
      >
        {buttonText}
      </Button>
    );
  };
  const DownloadCsvButton = () => {
    return (
      <Button
        className={classes.button}
        style={{ backgroundColor: "#2979ff" }}
        onClick={downloadCsvHandler}
        data-testid={`download-csv-button`}
      >
        Download csv
      </Button>
    );
  };
  const ClearButton = () => {
    return (
      <Button
        variant="contained"
        className={classes.button}
        onClick={clearData}
        data-testid={`clear-${testing}-button`}
        style={{
          backgroundColor: "#dcdde0",
          color: "black",
        }}
      >
        Clear
      </Button>
    );
  };

  const validationChecker = () => {
    if (!startDate) {
      alert("Please select Start Date");
      return false;
    }
    if (!endDate) {
      alert("Please select End Date");
      return false;
    }
    if (
      new Date(moment(startDate).format("YYYY-MM-DD")).getTime() <=
      new Date(moment(endDate).format("YYYY-MM-DD")).getTime()
    ) {
      console.log("");
      return true;
    } else {
      alert("Start date should not be greater than end date");
      return false;
    }
  };
  const downloadCsvHandler = () => {
    let validate = validationChecker();
    if (validate) {
      downLoadCsv({
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      });
    }
  };
  const onSubmit = () => {
    let validate = validationChecker();

    if (validate) {
      sendData({
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid container direction="column" item xs={12} sm={12} md={12}>
            <Typography variant="h6">{headingText}</Typography>
            <Divider style={{ width: "100%" }} />
          </Grid>
          <Grid container direction="column" item xs={6} sm={6} md={3}>
            <label>Start Date : </label>
            {StartDate()}
          </Grid>
          <Grid container direction="column" item xs={6} sm={6} md={3}>
            <label>End Date : </label>
            {EndDate()}
          </Grid>
          <Grid
            item
            container
            // direction="row"
            justify="flex-end"
            xs={4}
            sm={4}
            md={2}
            className={classes.buttonGrid}
            alignItems="center"
          >
            <SearchButton />
          </Grid>
          { testing === "transaction" &&(
          <Grid
            item
            container
            // direction="row"
            justify="flex-end"
            xs={4}
            sm={4}
            md={2}
            className={classes.buttonGrid}
            alignItems="center"
          >
            <DownloadCsvButton />
          </Grid>)}
          <Grid
            item
            container
            className={classes.buttonGrid}
            xs={4}
            sm={4}
            md={2}
            alignItems="center"
          >
            <ClearButton />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FilterData;
