import { successColor } from "../../material-dashboard-react";

const dashboardStyle = (theme) => ({
  successText: {
    color: successColor[0],
  },
  dashboardRoot: {
    flexGrow: 1,
    padding: theme.spacing(1),
    scrollbarGutter: true,
  },
});

export default dashboardStyle;
