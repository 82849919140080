import React, { useState, useEffect } from "react";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
import moment from "moment";
const PromocodeHistory = ({ headCells, tableData }) => {
  const [prmoHistory, setPromoHistory] = useState([]);
  useEffect(() => {
    let promocodeArray = [];
    console.log(tableData);
    for (let i = 0; i < tableData.length; i++) {
      promocodeArray.push({
        title: tableData[i].attributes.title,
        discount: tableData[i].attributes.discount,
        code: tableData[i].attributes.code,
        maxUsage: tableData[i].attributes.max_usage,
        usedCount: tableData[i].attributes.used_count,
        status: tableData[i].attributes.status === 1 ? "Active" : "Inactive",
        startDate: moment(tableData[i].attributes.start_datetime).format(
          "YYYY-MM-DD HH:mm"
        ),
        endDate: moment(tableData[i].attributes.end_datetime).format(
          "YYYY-MM-DD HH:mm"
        ),
      });
    }
    setPromoHistory(promocodeArray);
  }, [tableData]);
  return (
    <div>
      <TransactionTable tableHead={headCells} tableData={prmoHistory} />
    </div>
  );
};

export default PromocodeHistory;
