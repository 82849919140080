import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Link } from "@material-ui/core";
import { withRouter } from "react-router";

import { putRequest } from "../../api/Api";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import { blockButton } from '../../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 45,
    height: 30,
    marginTop: "-2%",
  },
}));

const SendForReview = (props) => {
  const classes = useStyles();
  const { patient } = props;

  const blockSendForReview = (value)=>{
    document.getElementById("sendreview").style.display = value;
  }


  const SendForReview = () => {
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "send_patient_data",
      formData: "",
      params: [patient.attributes.id, patient.attributes.last_visit_id],
    };
    blockSendForReview('none')
    putRequest(body).then((data) => {
      if (data.error) {
        blockSendForReview('block')
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        localStorage.removeItem("isShowStepper")
        localStorage.removeItem("isReviewOnly")
        setTimeout(() => {
          props.history.push(`/`);
        }, 1000);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
        <div id="sendreview">
        <Link
          color="inherit"
          variant="h6"
          onClick={SendForReview}
        >
          <Tooltip
            title="Send for review"
            data-testid="sendforreview-icon"
            aria-label="Send for review" 
          >
            <Fab className={classes.fab}>
              <SendIcon  />
            </Fab>
          </Tooltip>
          </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SendForReview);
