import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Avatar,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
  },
  backButton: {
    marginRight: theme.spacing(2),
    "@media (min-width: 864px)": {
      display: "none",
    },
  },
  user: {
    flexShrink: 0,
    flexGrow: 1,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const ChatBoxHeader = (props) => {
  const { conversation, setSelectedUser, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Toolbar {...rest} className={clsx(classes.root, className)}>
      <Tooltip title="Back">
        <IconButton
          className={classes.backButton}
          edge="start"
          onClick={() => setSelectedUser(undefined)}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Tooltip>
      <Avatar className={classes.avatar} src={conversation.otherUser.avatar} />
      <div className={classes.user}>
        <Typography variant="h6">{conversation.otherUser.name}</Typography>
      </div>
    </Toolbar>
  );
};

export default ChatBoxHeader;
