import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Toolbar, IconButton, Tooltip, Divider, List } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputBox from "../../components/Chat/InputBox";
import UserListItem from "./UserListItem";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
  },
}));

const UserList = (props) => {
  const {
    conversations,
    selectedUser,
    setSelectedUser,
    className,
    ...rest
  } = props;
  const query = new URLSearchParams(useLocation().search).get("type");

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {query === "user" && (
        <Toolbar>
          <Tooltip title="Search">
            <IconButton edge="end">
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <InputBox
            style={{ flexGrow: 1, marginLeft: "10%" }}
            placeholder="Search Users"
          />
        </Toolbar>
      )}

      <Divider style={{ backgroundColor: "#dcdada" }} />
      <List disablePadding>
        {conversations.map((conversation, i) => (
          <UserListItem
            active={conversation.id === selectedUser}
            conversation={conversation}
            divider={i < conversations.length - 1}
            key={conversation.id}
            setSelectedUser={setSelectedUser}
          />
        ))}
      </List>
    </div>
  );
};

export default UserList;
