import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import PathologyReport from "../Forms/PathologyReport";
import RadioReport from "../Forms/RadiologyReport";
import Document from "../Forms/DocumentUpload";
import baseUrl from "../../utils/baseUrl";
import "../../components/toast.css";
import Alert from "@material-ui/lab/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getRequest } from "../../api/Api";

import {
  TableContainer,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button,
} from "@material-ui/core";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
});

const useStyles = makeStyles(styles);
const Documents = (props) => {
  const classes = useStyles();
  const {
    patient,
    steps,
    step,
    handleNext,
    handleBack,
    setShowStepper,
    setIsReviewOnly,
    setShowdocumentsForm,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
  } = props;
  const [documents, setDocument] = useState([]);
  const [count, setCount] = useState(0);
  const [pathologyCount, setPathologyCount] = useState(0);
  const [radioCount, setRadioCount] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const fetchPatientDocumentById = () => {
      const endPoint = "get_patient_documents_by_visit_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [patient.attributes.id, patient.attributes.last_visit_id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if(isMounted){
          setDocument(data.data);
          }
        }
      });
    };
  
    fetchPatientDocumentById();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patient.attributes.id,
    patient.attributes.last_visit_id,
    count,
    radioCount,
    pathologyCount,
  ]);

  useEffect(() => {}, [documents]);

  const handleCount = () => {
    setCount(count + 1);
  };
  const handleRadioCount = () => {
    setRadioCount(radioCount + 1);
  };

  const handlePathologyCount = () => {
    setPathologyCount(pathologyCount + 1);
  };
  const deleteDocument = (id) => {
    // childRef.current.getAlert()
    const document_data = {
      id: id,
      _destroy: "1",
    };
    var formData = new FormData();

    Object.keys(document_data).forEach((key) => {
      formData.append(
        `patient[patient_documents_attributes][0][${key}]`,
        document_data[key]
      );
    });

    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ": " + pair[1]);
    // }

    if (window.confirm("Do you want to Delete this document ?")) {
      if (
        localStorage.getItem("jwt") !== "" ||
        localStorage.getItem("jwt") !== undefined
      ) {
        let token = "Bearer " + localStorage.getItem("jwt");
        fetch(`${baseUrl}/save_patient_documents/${patient.attributes.id}`, {
          method: "PUT",
          headers: {
            Authorization: token,
          },
          body: formData,
        }).then((response) => {
          response.json().then((data) => {
            // console.log(response.status);
            if (response.status === 200) {
              toast(<p>{data.message}</p>, {
                className: "custom",
                autoClose: 1000,
              });
              const newArray = documents.filter(function(el) {
                return el.attributes.id !== id;
              });
              setDocument(newArray);
            } else {
              toast.error(<p>{data.error}</p>, { autoClose: 3000 });
            }
          });
        });
      }
    }
  };
  const finishHandler = ()=>{
    localStorage.setItem("isShowStepper",false);
    localStorage.setItem("isReviewOnly",true);
    setShowStepper(false);
    setIsReviewOnly(true);
    handleNext();
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4}>
          <Document
            patientid={patient.attributes.id}
            handleCount={handleCount}
            visitid={patient.attributes.last_visit_id}
            // ref={childRef}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PathologyReport
            patientid={patient.attributes.id}
            visitid={patient.attributes.last_visit_id}
            handlePathologyCount={handlePathologyCount}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <RadioReport
            patientid={patient.attributes.id}
            visitid={patient.attributes.last_visit_id}
            handleRadioCount={handleRadioCount}
          />
        </Grid>
        {isReviewOnly ||
        patient.attributes.last_visit.is_flagged === 1 ||
        medicalProblemLength > 0 ||
        vitalLength > 0 ? (
          <Grid item xs={12} sm={12} md={12} container justify="center">
            <CardFooter style={{ float: "right" }} className={classes.footer}>
              <Button
                style={{
                  color: "rgb(61, 170, 153)",
                  border: "1px solid rgb(226 226 226)",
                  backgroundColor: "rgb(255 255 255)",
                  boxShadow: "none",
                  width: 72,
                }}
                data-testid="document-cancel-button"
                onClick={() => setShowdocumentsForm(false)}
              >
                Cancel
              </Button>

              <ToastContainer />
            </CardFooter>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
          >
            {step !== 0 && (
              <Button
                onClick={handleBack}
                data-testid="document-back-button"
                style={{ backgroundColor: "grey" }}
                className={classes.button}
              >
                Back
              </Button>
            )}
            <Button
              variant="contained"
              onClick={finishHandler}
              type="submit"
              data-testid="finish-button"
              style={{
                backgroundColor: "#4d93dc",
                marginLeft: "1%",
              }}
              className={classes.button}
            >
              {step === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Card
            style={{
              marginTop: "8px",
              boxShadow:
                "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
            }}
          >
            <CardHeader
              style={{ width: "30%", padding: "7px", marginTop: "-17px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Documents</h4>
            </CardHeader>
            <CardBody>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <TableCell
                        style={{ backgroundColor: "#6a7075", color: "white" }}
                      >
                        Document Type
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#6a7075", color: "white" }}
                      >
                        Document
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#6a7075", color: "white" }}
                      >
                        Action
                      </TableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((doc) => (
                      <StyledTableRow hover key={doc.id}>
                        <TableCell>{doc.attributes.document_type}</TableCell>
                        <TableCell>
                          <a
                            href={doc.attributes.document}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid = {`view-file-${doc.attributes.id}-link`}
                          >
                            view
                          </a>
                        </TableCell>
                        <TableCell>
                          <Link
                            color="inherit"
                            data-testid = {`delete-file-${doc.attributes.id}-link`}
                            onClick={() => deleteDocument(doc.attributes.id)}
                            variant="h6"
                          >
                            <Tooltip
                              title="Delete Document"
                              aria-label="Delete Document"
                            >
                              <Fab className={classes.fab}>
                                <DeleteIcon />
                              </Fab>
                            </Tooltip>
                          </Link>
                        </TableCell>
                        {doc.attributes.remark !== null ? (
                          <TableCell>
                            <Alert severity="warning">
                              {doc.attributes.remark}
                            </Alert>
                          </TableCell>
                        ) : null}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ToastContainer />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Documents;
