import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Dialog, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: (props) => props.width,
      padding: 8,
    },
  },
  header: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 600,
    },
    margin: "0 auto",
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 720,
    margin: "0 auto",
  },
}));

const Modal = (props) => {
  const { open, onClose,width, title, children, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Dialog  TransitionComponent={Transition} open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.header}>
          <Typography align="center" gutterBottom variant="h3">
            {title}
          </Typography>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </Dialog>
  );
};

export default Modal;
