import React from "react";
import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
}));

const InputBox = ({ changed, placeholder, style, ...other }) => {
  const classes = useStyles();

  return (
    <div>
      <Input
        className={classes.input}
        {...other}
        style={style}
        disableUnderline
        onChange={changed}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputBox;
