import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import CardFooter from "../../components/Card/CardFooter";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "./CurrentMedication.css";
import Button from "../CustomButtons/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../../components/toast.css";
import {
  Select,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  Hidden,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CancelIcon from "@material-ui/icons/Cancel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Alert from "@material-ui/lab/Alert";
import { getRequest, putRequest } from "../../api/Api";
import { blockButton } from '../../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  // footer: {
  //   [theme.breakpoints.up("xs")]: {
  //     display: "none",
  //   },
  // },
}));

const CurrentMedication = (props) => {
  const [currentmedication, setCurrentMedication] = useState([]);
  const [drugType, setDrugType] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [medicineDelete, setMedicineDelete] = useState([]);
  const {
    patient,
    steps,
    step,
    handleNext,
    handleBack,
    setShowCurrentMedicationForm,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
    correctionCountHandler,
  } = props;
  const classes = useStyles();

  const { control, handleSubmit,watch, errors } = useForm({
    defaultValues: {
      current: [
        {
          id: "",
          drug_name: "",
          strength: "",
          drug_type: "",
          frequency: "",
          duration: "",
        },
      ],
    },
  });

  const allWatchedValues = watch({ nest: true });

  const {
    fields: currentFields,
    append: currentAppend,
    prepend: currentPrepend,
    remove: currentRemove,
  } = useFieldArray({ control, name: "current" });

  const addExistingMedicine = (item) => {
    currentPrepend({
      id: item.attributes.id,
      drug_name: item.attributes.drug_name,
      strength: item.attributes.strength,
      drug_type:
        item.attributes.drug_type !== null ? item.attributes.drug_type.key : "",
      frequency:
        item.attributes.frequency !== null ? item.attributes.frequency.id : "",
      duration: item.attributes.duration,
      remark: item.attributes.remark,
    });
  };

  useEffect(() => {
    if (currentmedication.length !== 0) {
      const timer = setTimeout(
        () => currentmedication.forEach((item) => addExistingMedicine(item)),
        10
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentmedication]);

  const fetchCurrentMedication = () => {
    const endPoint = "get_patient_current_medications_by_visit_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    // let param = [props.match.params.id, props.match.params.type];
    let param = [patient.attributes.id, patient.attributes.last_visit_id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCurrentMedication(data.data);
      }
    });
  };

  const fetchDrugType = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_drug_type_list", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setDrugType(data);
        console.log(data)
      }
    });
  };

  const fetchFrequency = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_drug_frequencies", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setFrequency(data);
        console.log(data)
      }
    });
  };



  useEffect(() => {
    fetchDrugType();
    fetchFrequency();
    fetchCurrentMedication();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let idsArray = [];

  const deleteCurrentMedicine = (id) => {
    if (medicineDelete.includes(id)) {
      idsArray = medicineDelete.filter((el) => el !== id);
    } else {
      idsArray = [...medicineDelete, id];
    }
    setMedicineDelete(idsArray);
  };

  const onSubmit = (data) => {
    if (
      data.current[0].drug_name === "" &&
      data.current[0].frequency === "" &&
      data.current[0].drug_type === "" &&
      data.current[0].strength === "" &&
      data.current[0].duration === ""
    ) {
      handleNext();
    } else {
      var formData = new FormData();
      if (Object.keys(data).length === 0 && data.constructor === Object) {
      } else {
        for (let i = 0; i < data.current.length; i++) {
          if (data.current[i].id === "" || isNaN(data.current[i].id)) {
            delete data.current[i].id;
          }
          data.current[i].visit_id = patient.attributes.last_visit_id;

          if (medicineDelete.includes(data.current[i].id)) {
            data.current[i]._destroy = "1";
          }
        }
        for (let i = 1; i < data.current.length; i++) {
          if (data.current[i].drug_name === "") {
            alert("Please Enter Drug Name");
            return false;
          }
          if (data.current[i].frequency === "") {
            alert("Please Enter Frequency");
            return false;
          }
        }

        for (let i = 0; i < data.current.length; i++) {
          Object.keys(data.current[i]).forEach((key) => {
            formData.append(
              `patient[current_medications_attributes][${i}][${key}]`,
              data.current[i][key]
            );
          });
        }
        if (
          !isReviewOnly &&
          patient.attributes.last_visit.is_flagged === 0
          // medicalProblemLength === 0 &&
          // vitalLength === 0
        ) {
          handleNext();
        }else{
          blockButton('currentmed',true)
        }
        let token = "Bearer " + localStorage.getItem("jwt");
        const body = {
          token: token,
          endPoint: "save_current_medications",
          formData: formData,
          params: [patient.attributes.id],
        };
        putRequest(body).then((data) => {
          if (data.error) {
            blockButton('currentmed',false)
            toast.error(<p>{data.error}</p>, { autoClose: 3000 });
          } else {
            toast(<p>{data.message}</p>, {
              className: "custom",
              autoClose: 1000,
            });
            if (
              isReviewOnly ||
              patient.attributes.last_visit.is_flagged === 1 ||
              medicalProblemLength > 0 ||
              vitalLength > 0
            ) {
              setTimeout(() => {
                setShowCurrentMedicationForm(false);
                correctionCountHandler();
              }, 1000);
            }
          }
        });
      }
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "23px" }}>
            <CardHeader
              style={{ width: "32%", padding: "6px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Current Medication</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} sm={12} md={12}>
                  <table className="crud" id="tab_logic">
                    <Hidden smUp>
                      <thead>
                        <tr>
                          <th component="th" className="text-center">
                            Drug Name
                          </th>
                          <th className="text-center">Strength/Drug Type</th>
                          <th className="text-center">Freq/Dur</th>
                          <th className="text-center">Delete </th>
                        </tr>
                      </thead>
                    </Hidden>
                    <Hidden xsDown>
                      <thead>
                        <tr>
                          <th className="text-center">Drug Name</th>
                          <th className="text-center">Strength</th>
                          <th className="text-center">Drug Type </th>
                          <th className="text-center">Frequency</th>
                          <th className="text-center">Duration </th>
                          <th className="text-center"> </th>
                        </tr>
                      </thead>
                    </Hidden>
                    <tbody>
                      {currentFields.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>
                              <Controller
                                as={<input />}
                                name={`current[${index}].id`}
                                control={control}
                                defaultValue={item.id}
                                type="hidden"
                              />
                            </td>
                            <td style={{ display: "none" }}>
                              <Controller
                                as={<input />}
                                name={`current[${index}].visit_id`}
                                control={control}
                                defaultValue={item.id}
                                type="hidden"
                              />
                            </td>
                            <Hidden xsDown>
                              <td>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].drug_name`}
                                  inputProps={{
                                    data_cy :`current-medication-drug-name-input`
                                  }}
                                  control={control}
                                  label="Drug Name"
                                  style={{ width: 100 }}
                                  defaultValue={item.drug_name}
                                />
                              </td>
                              <td>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].strength`}
                                  inputProps={{
                                    data_cy :`current-medication-strength-input`
                                  }}
                                  control={control}
                                  label="mg"
                                  style={{ width: 100 }}
                                  defaultValue={item.strength}
                                />
                              </td>
                              <td>
                                <FormControl
                                  style={{ minWidth: 100 }}
                                  error={Boolean(errors.drug_type)}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Drug Type
                                  </InputLabel>
                                  <Controller
                                    as={
                                      <Select>
                                        {drugType.map((option) => (
                                          <MenuItem
                                            key={option.key}
                                            value={option.key}
                                          >
                                            {option.value}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                    name={`current[${index}].drug_type`}
                                    inputProps={{
                                      data_cy :`current-medication-drug-type-dropdown`
                                    }}
                                    control={control}
                                    defaultValue={item.drug_type}
                                  />
                                </FormControl>
                              </td>
                              <td>
                                <FormControl
                                  style={{ minWidth: 100 }}
                                  error={Boolean(errors.frequency)}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Frequency
                                  </InputLabel>
                                  <Controller
                                    as={
                                      <Select required={allWatchedValues.current[index]?.drug_name?.trim()}>
                                        {frequency.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.acronym} ({option.title})
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                    inputProps={{
                                      data_cy :`current-medication-frequency-dropdown`
                                    }}
                                    name={`current[${index}].frequency`}
                        
                                    control={control}
                                    defaultValue={item.frequency}
                                  />
                                </FormControl>
                              </td>
                              <td>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].duration`}
                                  inputProps={{
                                    data_cy :`current-medication-duration-input`
                                  }}
                                  control={control}
                                  label="Duration"
                                  style={{ width: 100 }}
                                  defaultValue={item.duration}
                                />
                              </td>
                            </Hidden>

                            <Hidden smUp>
                              <td>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].drug_name`}
                                  inputProps={{
                                    data_cy :`current-medication-drug-name-input`
                                  }}
                                  control={control}
                                  label="Drug Name"
                                  style={{ width: 75 }}
                                  defaultValue={item.drug_name}
                                />
                              </td>
                              <td>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].strength`}
                                  inputProps={{
                                    data_cy :`current-medication-strength-input`
                                  }}
                                  control={control}
                                  label="mg"
                                  style={{ width: 60 }}
                                  defaultValue={item.strength}
                                />
                                <FormControl
                                  style={{ width: 90 }}
                                  error={Boolean(errors.drug_type)}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Drug Type
                                  </InputLabel>
                                  <Controller
                                    as={
                                      <Select>
                                        {drugType.map((option) => (
                                          <MenuItem
                                            key={option.key}
                                            value={option.key}
                                          >
                                            {option.value}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                    inputProps={{
                                      data_cy :`current-medication-drug-type-dropdown`
                                    }}
                                    name={`current[${index}].drug_type`}
                                    control={control}
                                    defaultValue={item.drug_type}
                                  />
                                </FormControl>
                              </td>
                              <td>
                                <FormControl
                                  style={{ width: 70 }}
                                  error={Boolean(errors.frequency)}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Frequency
                                  </InputLabel>
                                  <Controller
                                    as={
                                      <Select>
                                        {frequency.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.acronym} ({option.title})
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                    inputProps={{
                                      data_cy :`current-medication-frequency-dropdown`
                                    }}
                                    name={`current[${index}].frequency`}
                                    control={control}
                                    defaultValue={item.frequency}
                                  />
                                </FormControl>
                                <Controller
                                  as={<TextField />}
                                  name={`current[${index}].duration`}
                                  inputProps={{
                                    data_cy :`current-medication-duration-input`
                                  }}
                                  control={control}
                                  label="Duration"
                                  style={{ width: 50 }}
                                  defaultValue={item.duration}
                                />
                              </td>
                            </Hidden>
                            <td>
                              {item.id === "" || isNaN(item.id) ? (
                                <HighlightOffIcon
                                  className={classes.icon}
                                  onClick={() => currentRemove(index)}
                                  data-testid={`current-medication-removerow-${
                                    allWatchedValues.current[index] ?
                                    allWatchedValues.current[
                                      index
                                    ].drug_name ?
                                         allWatchedValues.current[
                                          index
                                        ].drug_name.toLowerCase() : '':''
                                  }-icon`}
                                />
                              ) : (
                                <React.Fragment>
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(id) =>
                                            deleteCurrentMedicine(item.id)
                                          }
                                          value={item.id}
                                          color="primary"
                                        />
                                      }
                                    />
                                  </FormGroup>
                                  {medicineDelete.includes(item.id) ? (
                                    <CancelIcon
                                      className={classes.icon}
                                      style={{ color: "red" }}
                                    />
                                  ) : null}
                                </React.Fragment>
                              )}
                            </td>
                            {item.remark !== null &&
                            item.remark !== undefined ? (
                              <td style={{ border: "none" }}>
                                <Alert severity="warning">{item.remark}</Alert>
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <section>
                    <AddCircleIcon
                    data-testid="add-new-current-medication-row-icon"
                      onClick={() => {
                        currentAppend({
                          drug_name: "",
                          strength: "",
                          drug_type: "",
                          frequency: "",
                          duration: "",
                        });
                      }}
                    />
                  </section>
                </Grid>
                {isReviewOnly ||
                patient.attributes.last_visit.is_flagged === 1 ||
                medicalProblemLength > 0 ||
                vitalLength > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justify="flex-end"
                  >
                    <CardFooter className={classes.footer}>
                      <Button
                        data-testid="currentmedication-submit-button"
                        style={{ width: 72 }}
                        id="currentmed"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          color: "rgb(61, 170, 153)",
                          border: "1px solid rgb(226 226 226)",
                          backgroundColor: "rgb(255 255 255)",
                          boxShadow: "none",
                          width: 72,
                          marginLeft: "3%",
                        }}
                        onClick={() => setShowCurrentMedicationForm(false)}
                        data-testid="current-medication-cancel-button"
                      >
                        Cancel
                      </Button>

                      <ToastContainer />
                    </CardFooter>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    {step !== 0 && (
                      <Button
                        onClick={handleBack}
                        data-testid="current-medication-back-button"
                        style={{ backgroundColor: "grey" }}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      type="submit"
                      data-testid="currentmedication-next-button"
                      style={{
                        backgroundColor: "#4d93dc",
                        marginLeft: "1%",
                      }}
                      className={classes.button}
                    >
                      {step === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                )}
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentMedication;
