import React from "react";
import { Grid, Typography } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const styles = (theme) => ({
  currentBal: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      // justifyContent: "flex-end",
      justifyContent: "space-between",
    },
  },
  currentBalHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 600,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  doctorMsg: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
  },
  doctorMsgWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 12,
    },
  },
});
const useStyles = makeStyles(styles);

const ShowCurrentBalance = ({ style, user }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={style}
      item
      xs={12}
      sm={12}
      md={12}
      className={classes.currentBal}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        {user &&
          user.attributes.subscription.subscription_status === "expired" && (
            <>
              <InfoIcon style={{ color: "#2979ff" }} />
              <Typography className={classes.doctorMsg}>
                Doctor fees are subject to your subscription status
              </Typography>
            </>
          )}
      </div>
      <div className={classes.doctorMsgWrapper}>
        <AccountBalanceWalletIcon />
        <Typography
          style={{ marginTop: 2 }}
          className={classes.currentBalHeading}
        >
          Current Balance :{" "}
          {`₹ ${user ? user.attributes.centre.current_credit_balance : ""}`}
        </Typography>
      </div>
    </Grid>
  );
};

export default ShowCurrentBalance;
