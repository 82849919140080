import React, { createContext, useState } from "react";
export const refreshContext = createContext(null);

export const RefreshPrescription = ({ children }) => {
  const [prescriptioncount, setPrescriptionCount] = useState(0);
  return (
    <refreshContext.Provider
      value={{
        setPrescriptionCount,
        prescriptioncount,
      }}
    >
      {children}
    </refreshContext.Provider>
  );
};
