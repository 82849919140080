import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { blue } from "@material-ui/core/colors";
import { getRequest } from "../../api/Api";
import TranslateIcon from "@material-ui/icons/Translate";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../components/Loader/Loader";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    top: 2,
    right: 0,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          data-testid="language-cancel-button"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <br />
      <Typography style={{ marginTop: "2%" }} variant="h6">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const CategoryLanguage = (props) => {
  const classes = useStyles();
  const [consultingLanguageList, setConsultingLanguageList] = useState([]);
  const [errorMessage,setErrorMessage] = useState('')
  const [
    isShowconsultingLanguageList,
    setIsShowConsultingLanguageList,
  ] = useState(false);
  const {
    onClose,
    selectedValue,
    categoryData,
    setSelectedCategory,
    openCategoryLanguage,
  } = props;

  useEffect(() => {
    if (openCategoryLanguage && categoryData.id) {
      setIsShowConsultingLanguageList(true);
      setErrorMessage('')
      const fetchCategoryConsultingLanguage = (doctorData) => {
        const token = "Bearer " + localStorage.getItem("jwt");
        getRequest(
          `get_consulting_languages_by_doctor_category_id/${categoryData.id}`,
          token
        ).then((data) => {
          if (data.error) {
            console.log(data.error);
            setErrorMessage(data.error)
            setIsShowConsultingLanguageList(false);
          } else {
            setConsultingLanguageList(data);
            setIsShowConsultingLanguageList(false);
          }
        });
      };
      fetchCategoryConsultingLanguage();
    }
  }, [openCategoryLanguage, categoryData?.id]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    console.log(categoryData);
    console.log(value);
    setSelectedCategory(categoryData?.title);
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="language-title"
      open={openCategoryLanguage}
    >
      <DialogTitle
        style={{ color: "blue", textAlign: "center" }}
        id="language-dialog-title"
        onClose={onClose}
      >
        Please choose your preferred consultation language
      </DialogTitle>
      <Typography align="center" style={{ fontSize: 16, fontWeight: 600 }}>
        {categoryData?.title}
      </Typography>
      {isShowconsultingLanguageList ? (
        <Loader />
      ) : (
        <>
          {errorMessage ?
          <Typography style={{ margin:23,fontWeight:600 }}>{errorMessage}</Typography>:
        <List>
          {consultingLanguageList.map((consultingLanguage) => (
            <ListItem
            data-testid ={`language-option-${consultingLanguage.key}`}
              button
              onClick={() => handleListItemClick(consultingLanguage.key)}
              key={consultingLanguage.key}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <TranslateIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={consultingLanguage.value} />
            </ListItem>
          ))}
        </List>}
        </>
      )}
    </Dialog>
  );
};

export default CategoryLanguage;
