import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import { getRequest } from "../../api/Api";
import Table from "../../components/Table/Table";
import Loader from "components/Loader/Loader";
import MedicalProblemForm from "../../components/Forms/MedicalProblem";
import EditIcon from "@material-ui/icons/Edit";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  table: {
    minWidth: 320,
  },
});

function createData(name, value) {
  return { name, value };
}

const useStyles = makeStyles(styles);

const MedicalProblemVerticalTable = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [medicalProblemData, setMedicalProblemData] = useState([]);
  const [showMedicalForm, setShowMedicalForm] = useState(false);
  const [medicalProblemCount, setMedicalProblemCount] = useState();

  useEffect(() => {
    let isMounted = true;
    const fetchPatientedicalProblemById = () => {
      const endPoint = "get_patient_medical_problems_by_visit_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [patient.patient.attributes.id, patient.visitId];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (data.data.length !== 0) {
            const rows = [
              createData(
                "Cheif Complaint",
                data.data[0].attributes.complaint_title !== null
                  ? data.data[0].attributes.complaint_title
                  : ""
              ),
              createData(
                "Associated Symptoms",
                data.data[0].attributes.associated_symptoms 
                  ? data.data[0].attributes.associated_symptoms
                  : 'N/A'
              ),
              createData(
                "Start Date",
                data.data[0].attributes.start_date !== null
                  ? data.data[0].attributes.start_date
                  : ""
              ),
              createData(
                "Current Status",
                data.data[0].attributes.current_status !== null
                  ? data.data[0].attributes.current_status
                    ? data.data[0].attributes.current_status.value
                    : ""
                  : ""
              ),
            ];
            if (isMounted) {
              setMedicalProblemData(rows);
            }
          } else {
            if (isMounted) {
              setMedicalProblemData([]);
            }
          }
        }
      });
    };
    if(!showMedicalForm){
    fetchPatientedicalProblemById();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMedicalForm]);

  useEffect(()=>{
    if (patient.patientInfo) {
      if (patient.patientInfo.attributes.flagged_count.medical_problems > 0) {
        setMedicalProblemCount(
          patient.patientInfo.attributes.flagged_count.medical_problems
        );
      } else {
        setMedicalProblemCount(0);
      }
    }
  },[patient.patientInfo])

  return (
    <Fragment>
      {medicalProblemData ? (
        <div className={classes.root}>
          <Grid>
            <Grid item xs={12} sm={12} md={12}>
              {showMedicalForm ? (
                <MedicalProblemForm
                  vitalLength={patient.vitalLength}
                  medicalProblemLength={patient.medicalProblemLength}
                  patient={patient.patient}
                  correctionCountHandler={patient.correctionCountHandler}
                  setShowMedicalForm={(value) => setShowMedicalForm(value)}
                  isReviewOnly={patient.isReviewOnly}
                />
              ) : (
                <Card
                  style={{
                    boxShadow:
                      "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                  }}
                  data-testid={`medicalproblem-detail-card`}
                >
                  <CardHeader
                    style={{ width: "110px", marginTop: 0, marginLeft: 0 }}
                    color="success"
                  >
                    <h4 className={classes.cardTitleWhite}>Medical Problem</h4>
                  </CardHeader>
                  {patient.patient.attributes.last_visit.is_sent_for_review ===
                    0 &&
                  patient.patient.attributes.last_visit
                    .is_sent_to_patient_pool === 0 ? (
                    <Grid
                      item
                      container
                      justify="flex-end"
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <EditIcon
                        data-testid="medicalproblem-edit-icon"
                        onClick={() => setShowMedicalForm(true)}
                      />
                    </Grid>
                  ) : null}
                  <CardBody>
                    {medicalProblemCount > 0 ? (
                      <Typography
                        align="center"
                        style={{ color: "red", fontSize: 15, fontWeight: 500 }}
                      >
                        Correction required
                      </Typography>
                    ) : null}
                    <Table rows={medicalProblemData} />
                  </CardBody>
                </Card>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default MedicalProblemVerticalTable;
