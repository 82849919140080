import React, { useState, useEffect } from "react";
import { getRequest } from "../../api/Api";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import IssueContent from "./IssueContent";
import { Typography, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ResolutionContent from "./ResolutionContent";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    // maxHeight: "100%",
  },
  inner: {
    padding: theme.spacing(2),
  },
}));

const IssueContentHolder = (props) => {
  const { selectedIssueId, resetPatientId } = props;

  const classes = useStyles();

  const [isssueData, setisssueData] = useState([]);
  const [issueType, setIssueType] = useState();
  const [patientName, setPatientName] = useState("");
  const [resolutionData, setResolutionData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const Endpoint = `issues/${selectedIssueId}`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setisssueData(data.data.attributes.issue_descriptions);
        console.log(data.data);
        setPatientName(data.data.attributes.patient.name);
        setIssueType(data.data.attributes.issue_type.value);
      }
    });

    getRequest(
      `issue_resolutions?issue_id=${selectedIssueId}`,
      token,
      params
    ).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setResolutionData(data.data);
      }
    });
  }, [selectedIssueId]);

  const handleAccordian = (panel) => (event, isExpanded) => {
    console.log(panel);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          container
          justify="center"
          direction="column"
          xs={11}
          sm={11}
          md={11}
        >
          <Typography
            style={{ fontWeight: 600, fontSize: 16, marginTop: "1%" }}
            align="center"
          >
            {issueType}
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: 12, marginTop: "1%" }}
            align="center"
          >
            ({patientName} )
          </Typography>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          direction="row"
          xs={1}
          sm={1}
          md={1}
          style={{ marginTop: "1%" }}
        >
          <CancelIcon data-testid="cancel-issue-detail" onClick={resetPatientId} />
        </Grid>
      </Grid>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <div
            style={{
              maxHeight: "400px",
              WebkitOverflowScrolling: "touch",
              position: "relative",
            }}
          >
            {isssueData.map((message) => {
              return (
                <IssueContent key={message.created_at} message={message} />
              );
            })}
          </div>
        </div>
      </PerfectScrollbar>
      {resolutionData.length !== 0 ? (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordian("panel1")}
          style={{ flexGrow: 1 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="doctor-filteration-form"
          >
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
              Resolution
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.inner}>
              {resolutionData.map((resolutionData) => {
                return (
                  <ResolutionContent
                    key={resolutionData.id}
                    resolutionData={resolutionData}
                  />
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
};

export default IssueContentHolder;
