import React,{ useContext } from "react";
import logo from "../assets/img/chikitsamitra.png";
import { Typography, Divider, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PhoneTwoToneIcon from "@material-ui/icons/PhoneTwoTone";
import { authContext } from "../ProvideAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: -25,
  },
  header: {
    maxWidth: 600,
    margin: "0 auto",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  title: {
    color: "rgb(108 107 107)",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  warningHeading: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      paddingRight: 5,
      paddingTop: 2,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 19,
      paddingRight: 15,
    },
  },
  logoStyl: {
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 60,
    },
    [theme.breakpoints.up("sm")]: {
      width: 90,
      height: 80,
    },
  },
  heading: {
    color: "rgb(80 79 79)",
    fontWeight: 600,
    marginTop: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 19,
    },
  },
  contactUs: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("sm")]: {
      gap: 5,
      // alignItems: "center",
    },
  },
  storeDetail: {
    fontSize: 15,
    fontWeight: 600,
    color: "#606262",
  },
  message: {
    color: "#464444",
    marginTop: 14,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 17,
    },
  },
  phoneNumberSection: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
    },
  },
}));

function SupportTeamPhone() {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.phoneNumberSection}>
      {supportPhone.map((item) => (
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={6}
          direction="column"
          justify="center"
          key={item.phone}
        >
          <address
            style={{
              fontSize: 14,
              fontStyle: "normal",
              marginTop: 2,
              display: "flex",
              padding: "2px 0px",
              gap: 4,
            }}
          >
            <PhoneTwoToneIcon style={{ fontSize: 14, marginTop: 1 }} />
            <span style={{ color: "#555A5D" }}>{item.name}</span>
            <a style={{ textDecoration: "none" }} href={`tel:${item.phone}`}>
              {item.phone}
            </a>
          </address>
        </Grid>
      ))}
    </Grid>
  );
}

function SubscriptionExpire({ username, isShowSkipBtn, closeAlert }) {
  const classes = useStyles();
  const { user } = useContext(authContext);

  let storeCode = localStorage.getItem("storeCode");
  let centreTitle = localStorage.getItem("centreTitle");
  let contentToShow = user.subscription.subscription_status === "expiringSoon"
    ? "Your plan is about to expire soon. Kindly pay the subscription amount to continue using the OCM Digital Consultation services without any interruptions."
    : user.subscription.subscription_status === "expired"  &&
     "Your plan has expired. Kindly resubscribe by making the payment and start using your OCM Digital Consultation services again.";

  let heading = user.subscription.subscription_status === "expiringSoon"
    ? "Your Subscription is expiring Soon !"
    : user.subscription.subscription_status === "expired" &&
     "Please Renew Your Subscription !";

  return (
    <>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <img src={logo} className={classes.logoStyl} alt="logo" />
          <Typography className={classes.title}>Chikitsamitra</Typography>
        </div>
        <div>
          <Typography
            style={{ color: isShowSkipBtn ? "#dc9a22" : "red" }}
            className={classes.warningHeading}
          >
            Warning
          </Typography>
        </div>
      </div>
      <Typography className={classes.heading}>
        {centreTitle} ({storeCode}) {heading}
      </Typography>
      <div>
        <Typography variant="h5" className={classes.message} component="p">
          Dear {username}, {contentToShow}
        </Typography>
      </div>
      {isShowSkipBtn && (
        <div style={{ display: "flex", marginTop: 16 }}>
          <Button
            style={{
              border: "1px solid #9b9b9b",
              backgroundColor: "#f7f7f7",
              height: 35,
              fontSize: 13,
            }}
            onClick={closeAlert}
          >
            Skip
          </Button>
        </div>
      )}
      <Divider style={{ marginTop: 20, backgroundColor: "#d1d1d1" }} />
      <div className={classes.contactUs}>
        <Typography
          variant="h6"
          style={{ color: "rgb(72 72 72)" }}
          component="p"
        >
          To avoid disconnection of digital consultation services please talk to
          our digital Support Team on :
        </Typography>
        <div>
          <SupportTeamPhone />
        </div>
      </div>
    </>
  );
}

export default SubscriptionExpire;

export const supportPhone = [
  { name: "Mrs. Nishi Shukla", phone: "9219816189" },
  { name: "Mrs. Viadehi", phone: "8887533451" },
  { name: "Mrs. Purvi Rastogi", phone: "9219816163" },
  { name: "Dr. Neha Singh", phone: "9569945331" },
];
