import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Notification from "../../assets/img/notification.png";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inner: {
    textAlign: "center",
  },
  image: {
    maxWidth: 400,
  },
  title: {
    margin: theme.spacing(4, 0, 1, 0),
  },
}));

const ChatBoxImageHolder = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.inner}>
        <img
          alt="Select conversation"
          className={classes.image}
          src={Notification}
        />
        <Typography className={classes.title} variant="h4">
          Select User To Chat
        </Typography>
      </div>
    </div>
  );
};

export default ChatBoxImageHolder;
