import React from "react";
import SendIcon from "@material-ui/icons/Send";
import { Tooltip, IconButton } from "@material-ui/core";

const SendButton = ({ changed, description,...other }) => {
  return (
    <div>
      <Tooltip title="Send">
        <IconButton
        {...other}
          color={description.length > 0 ? "primary" : "default"}
          onClick={changed}
        >
          <SendIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default SendButton;
