import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import { getRequest } from "../../api/Api";
import LifeStyleForm from "../../components/Forms/LifeStyle";
import EditIcon from "@material-ui/icons/Edit";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  table: {
    minWidth: 320,
  },
});

const useStyles = makeStyles(styles);

const LifestyleVerticalTable = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [lifestyleList, setlifestyleList] = useState([]);
  const [showLifestyleForm, setShowLifestyleForm] = useState(false);
  const [lifestyleCount, setLifestyleCount] = useState();

  const fetchPatientLifestyle = () => {
    const endPoint = "get_patient_lifestyles_by_patient_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patient.patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setlifestyleList(data.data);
      }
    });
  };
  useEffect(() => {
    if(!showLifestyleForm){
    fetchPatientLifestyle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLifestyleForm]);

  useEffect(() => {
    if (patient.patientInfo) {
      if (patient.patientInfo.attributes.flagged_count.lifestyles > 0) {
        setLifestyleCount(
          patient.patientInfo.attributes.flagged_count.lifestyles
        );
      } else {
        setLifestyleCount(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patientInfo]);

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            {showLifestyleForm ? (
              <LifeStyleForm
                patient={patient.patient}
                vitalLength={patient.vitalLength}
                medicalProblemLength={patient.medicalProblemLength}
                correctionCountHandler={patient.correctionCountHandler}
                setShowLifestyleForm={(value) => setShowLifestyleForm(value)}
                isReviewOnly={patient.isReviewOnly}
              />
            ) : (
              <Card
                style={{
                  boxShadow:
                    "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                }}
                data-testid={`lifestyle-detail-card`}
              >
                <CardHeader
                  style={{ width: "105px", marginTop: 0, marginLeft: 0 }}
                  color="success"
                >
                  <h4 className={classes.cardTitleWhite}>Lifestyle</h4>
                </CardHeader>
                {patient.patient.attributes.last_visit.is_sent_for_review ===
                  0 &&
                patient.patient.attributes.last_visit
                  .is_sent_to_patient_pool === 0 ? (
                  <Grid
                    item
                    container
                    justify="flex-end"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <EditIcon
                      data-testid="lifestyle-edit-icon"
                      onClick={() => setShowLifestyleForm(true)}
                    />
                  </Grid>
                ) : null}
                <CardBody>
                  {lifestyleCount > 0 ? (
                    <Typography
                      align="center"
                      style={{ color: "red", fontSize: 15, fontWeight: 500 }}
                    >
                      Correction required
                    </Typography>
                  ) : null}
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "white" }}>
                            Title
                          </TableCell>
                          <TableCell style={{ backgroundColor: "white" }}>
                            Result
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lifestyleList.map((lifestyle) => (
                          <TableRow hover key={lifestyle.attributes.id}>
                            <TableCell>
                              {lifestyle.attributes.title !== null
                                ? lifestyle.attributes.title.value
                                : ""}
                            </TableCell>
                            {lifestyle.attributes.current_status === null ? (
                              <TableCell></TableCell>
                            ) : (
                              <TableCell>
                                <Typography style={{ fontWeight: 400 }}>
                                  {lifestyle.attributes.current_status.value}
                                </Typography>
                                {lifestyle.attributes.current_status !==
                                null ? (
                                  lifestyle.attributes.current_status.value ===
                                  "Current" ? (
                                    <>
                                      <Typography style={{ fontWeight: 400 }}>
                                        {lifestyle.attributes.frequency !== null
                                          ? lifestyle.attributes.frequency.value
                                          : ""}
                                      </Typography>
                                      <Typography style={{ fontWeight: 400 }}>
                                        {lifestyle.attributes.quantity}{" "}
                                        {lifestyle.attributes.unit}
                                      </Typography>
                                      <Typography style={{ fontWeight: 400 }}>
                                        {lifestyle.attributes.start_date !==
                                        null
                                          ? `Since ${lifestyle.attributes.start_date}`
                                          : null}
                                      </Typography>
                                    </>
                                  ) : lifestyle.attributes.current_status
                                      .value === "Never" ? null : lifestyle
                                      .attributes.current_status.value ===
                                    "Quit" ? (
                                    <Typography style={{ fontWeight: 400 }}>
                                      On {lifestyle.attributes.end_date}
                                    </Typography>
                                  ) : null
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default LifestyleVerticalTable;
