import React, { useState, useEffect } from "react";
import CustomTabs from "../CustomTabs/CustomTabs";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilterPatient from "./FilterPatient";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { getRequest } from "../../api/Api";
import PatientList from "./PatientList";
import IssueList from "./IssueList";
import NoData from "../../assets/img/nodata.png";

import RaiseIssueForm from "./RaiseIssueForm";
import { ToastContainer } from "react-toastify";
import "../toast.css";
import "react-toastify/dist/ReactToastify.min.css";
import AddDetailToIssue from "./AddDetailToIssue";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 4,
  },
  customTab: {
    [theme.breakpoints.up("sm")]: {
      width: "46%",
    },
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
  },
}));

const RaiseIssue = () => {
  const classes = useStyles();
  const [patientListing, setPatientListing] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [selectedVisitId, setSelectedVisitId] = useState(undefined);
  const [selectedIssueId, setSelectedIssueId] = useState(undefined);
  const [showListOnMobile, setShowListOnMobile] = useState(true);
  const [patientInfo, setPatientInfo] = useState(undefined);
  const [showOldIssueListOnMobile, setShowOldIssueListOnMobile] = useState(
    true
  );
  const [isShowFilterData, setIsShowFilterData] = useState(false);
  const [selectedIssueData, setSelectedIssueData] = useState();
  const [expanded, setExpanded] = useState("panel1");
  const theme = useTheme();
  const newIssue = useMediaQuery(theme.breakpoints.down("sm"));
  const oldIssue = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFilterData = (data, filterby, patientId) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    let Endpoint;
    if (filterby === "by date") {
      Endpoint = `visits?filter_by=raise_issue&from_date=${moment(
        data.start_date
      ).format("YYYY-MM-DD")}&to_date=${moment(data.end_date).format(
        "YYYY-MM-DD"
      )}`;
    } else if (filterby === "by patient") {
      Endpoint = `visits?filter_by=raise_issue&patient_id=${patientId}&from_date=&to_date=`;
    }
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatientListing(data);
        console.log(data)
      }
    });
  };

  const handleSelectPatientChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (newIssue && selectedPatientId) {
      setShowListOnMobile(false);
    } else {
      setShowListOnMobile(true);
    }
  }, [newIssue, selectedPatientId]);

  useEffect(() => {
    if (oldIssue && selectedIssueId) {
      setShowOldIssueListOnMobile(false);
    } else {
      setShowOldIssueListOnMobile(true);
    }
  }, [oldIssue, selectedIssueId]);

  return (
    <div className={classes.root} style={{ marginTop: "2px" }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
            className={classes.customTab}
            style={{ padding: 8 }}
            headerColor="success"
            tabs={[
              {
                tabName: "New Issue",
                test: "navigate-to-new-issue",
                tabContent: (
                  <React.Fragment>
                    <div id="new">
                      <Grid container>
                        {showListOnMobile ? (
                          <Grid
                            item
                            xs={12}
                            container
                            sm={12}
                            md={6}
                            // style={{ marginLeft: "-1%" }}
                          >
                            <Accordion
                              style={{ flexGrow: 1 }}
                              data-testid="filter-patient-button"
                              expanded={expanded === "panel1"}
                              onChange={handleSelectPatientChange("panel1")}
                            >
                              <AccordionSummary
                                expandIcon={<FilterListIcon />}
                                id="patient-filter"
                              >
                                <Typography
                                  style={{ fontSize: 16, fontWeight: 500 }}
                                >
                                  Select Patient
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <FilterPatient
                                  handleFilterData={handleFilterData}
                                  setPatientListing={setPatientListing}
                                  setIsShowFilterData={setIsShowFilterData}
                                />
                              </AccordionDetails>
                            </Accordion>
                            {patientListing.length !== 0 ? (
                              <PatientList
                                patientListing={patientListing}
                                setSelectedPatientId={setSelectedPatientId}
                                setSelectedVisitId={setSelectedVisitId}
                                setPatientInfo={setPatientInfo}
                              />
                            ) : null}
                            {isShowFilterData && patientListing.length === 0 && (
                              <Grid
                                justify="center"
                                alignItems="center"
                                item
                                container
                                xs={12}
                                sm={12}
                                md={12}
                              >
                                <img
                                  style={{ width: 290 }}
                                  src={NoData}
                                  alt="nodata"
                                />
                              </Grid>
                            )}
                          </Grid>
                        ) : null}
                        {!!selectedPatientId ? (
                          <Grid item xs={12} sm={12} md={6}>
                            <RaiseIssueForm
                              selectedVisitId={selectedVisitId}
                              selectedPatientId={selectedPatientId}
                              patientInfo={patientInfo}
                              setPatientListing={setPatientListing}
                              resetPatientId={() =>
                                setSelectedPatientId(undefined)
                              }
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  </React.Fragment>
                ),
              },
              {
                tabName: "Issues",
                test: "navigate-to-issues",
                tabContent: (
                  <React.Fragment>
                    <div>
                      <Grid container>
                        {showOldIssueListOnMobile ? (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ marginLeft: "-1%" }}
                            md={6}
                          >
                            <IssueList
                              setSelectedPatientId={setSelectedPatientId}
                              setSelectedVisitId={setSelectedVisitId}
                              setSelectedIssueId={setSelectedIssueId}
                              setSelectedIssueData={setSelectedIssueData}
                            />
                          </Grid>
                        ) : null}
                        {!!selectedIssueId ? (
                          <Grid item xs={12} sm={12} md={6}>
                            <AddDetailToIssue
                              selectedIssueId={selectedIssueId}
                              selectedIssueData={selectedIssueData}
                              resetPatientId={() =>
                                setSelectedIssueId(undefined)
                              }
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Grid>
        <ToastContainer />
      </Grid>
    </div>
  );
};

export default RaiseIssue;
