export function pwafire() {
  let deferredPrompt; // Allows to show the install prompt
  const installButton = document.getElementById("install_button");
  //installButton[0].style.color = 'red';
  if (installButton) {
    window.addEventListener("beforeinstallprompt", (e) => {
      document.getElementById("install_button").style.display = "block";
      //console.log("beforeinstallprompt fired");
      // Prevent Chrome 76 and earlier from automatically showing a prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Show the install button
      //installButton.hidden = false;
      installButton.addEventListener("click", installApp);
    });
    function installApp() {
      // Show the prompt
      deferredPrompt.prompt();
      //installButton.disabled = true;
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          document.getElementById("install_button").style.display = "none";
          alert("App setup accepted");
          //installButton.hidden = true;
        } else {
          document.getElementById("install_button").style.display = "block";
          alert("App setup rejected");
        }
        //installButton.disabled = false;
        deferredPrompt = null;
      });
    }
    window.addEventListener("appinstalled", (evt) => {
      //alert("App appinstalled successfully", evt);
    });
  } else {
  }
}
