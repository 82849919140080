import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import validate from "validate.js";
import forgotPassword from "../../assets/img/forgotPassword.jpg";
import lockIcon from "../../assets/img/lockIcon.png";
import API from "../../utils/baseUrl";
import ResetLinkMessage from "./ResetLinkMessage";
import Backdrop from "components/CustomBackdrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 30,
    backgroundColor: "white",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-around",
      padding: 30,
    },
  },
  image: {
    // marginTop: 10,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  heading: {
    fontSize: 29,
    marginTop: 35,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#84cfc4",
    fontWeight: 600,
    padding: 8,
    "&:hover": {
      backgroundColor: "#84cfc4",
    },
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const { className, ...rest } = props;
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [isShowBackdriop,setIsShowBackdriop]=useState(false)

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsShowBackdriop(true)
    try {
      const res = await fetch(`${API}/password_resets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formState.values.email }),
      });

      if (res.status !== 201) {
        const { message } = await res.json();
        console.log("message", message);
        toast.error(
          <p >{message}</p>,
          { autoClose: 3000 }
        );
      } else {
        setIsFormSubmit(true);
      }
    } catch (e) {
      throw new Error("Something went wrong");
    }finally{
      setIsShowBackdriop(false)
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Backdrop
        open={isShowBackdriop}
        handleClose={()=>setIsShowBackdriop(false)}
      />
      <div className={classes.root}>
        {isFormSubmit ? (
          <ResetLinkMessage email={formState.values.email} />
        ) : (
          <>
            <div className={classes.image}>
              <img
                src={forgotPassword}
                style={{ width: "45vw", opacity: "85%" }}
                alt="forgotpassword"
              />
            </div>
            <div>
              <div className={classes.paper}>
                <img src={lockIcon} style={{ height: 90 }} alt="" />
                <Typography className={classes.heading}>
                  Forgot your Password ?
                </Typography>
                <br />
                <Typography
                  style={{ color: "rgb(99, 115, 129)", textAlign: "center" }}
                >
                  Please enter the email address associated with your account
                  and We will email you a link to reset your password.
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Email"
                    margin="normal"
                    name="email"
                    required
                    onChange={handleChange}
                    value={formState.values.email || ""}
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!formState.isValid}
                    className={classes.submit}
                  >
                    Send Request
                  </Button>
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      fontWeight: 600,
                      marginTop: 12,
                    }}
                    href="/login"
                  >
                    <ArrowBackIosIcon style={{ width: 16, height: 16 }} />
                    <span style={{ fontSize: 14 }}>Return to Log in</span>
                  </a>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
