import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";

// Implement a Switch Router in the Login page so that
// the url will redirect to the dashboard page if a jwt exists
// in the localStorage
const BackArrow = props => {
  let { history, modeTextColor } = props;

  const handleBackButton = () => {
    console.log(history);
    console.log(history.pathname);
    history.goBack();
    console.log(history.pathname);
  };

  return (
    <ArrowBackIcon
    data-testid="back-arrow-icon"
      style={modeTextColor}
      id="backIcon"
      onClick={handleBackButton}
    />
  );
};

export default withRouter(BackArrow);
