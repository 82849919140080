import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import FeedbackIcon from "@material-ui/icons/Feedback";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { toast } from "react-toastify";
import { getRequest } from "../../api/Api";
import TextsmsIcon from "@material-ui/icons/Textsms";


const useStyles = makeStyles(() => ({}));

const MoreOptionComponent = (props) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { feedbackcheck, onClick, visitid, prescriptionurl, patientid } = props;

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const sendSms = () => {
    const endPoint = "send_prescription_link_to_patient";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patientid, visitid];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
      }
    });
  };

  const pdfopenHandler = (prescriptionurl) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    fetch(`${prescriptionurl}?prescription_url=1`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let link = document.createElement("a");
        link.setAttribute("rel", "noopener noreferrer");
        link.setAttribute("target", "about:blank");
        link.setAttribute("href", data.prescription_pdf_url);
        link.click();
      })
      .catch((error) => {
        if (error.response.data !== "") {
          alert(error.response.data.error);
        } else {
          alert(error.response.statusText);
        }
      });
  };

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          {!feedbackcheck ? (
            <React.Fragment>
              <ListItemText
                primary="Feedback"
                onClick={() => onClick(visitid)}
              />
            </React.Fragment>
          ) : (
            <div style={{ display: "flex" }}>
              <Typography
                style={{ marginTop: "2%", opacity: 0.5, cursor: "none" }}
              >
                Feedback
              </Typography>
              <DoneOutlineIcon style={{ color: "green" }} />
            </div>
          )}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => pdfopenHandler(prescriptionurl)}
            primary="Prescription"
          />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <TextsmsIcon />
          </ListItemIcon>
          <ListItemText onClick={sendSms} primary="Send sms" />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

MoreOptionComponent.propTypes = {
  className: PropTypes.string,
};

export default MoreOptionComponent;
