import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Divider } from "@material-ui/core";
import Custombuttons from "../../components/CustomButtons/Button";
import { Link as RouterLink } from "react-router-dom";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { getRequest } from "../../api/Api";
import DashboardOption from "./DahboardOption";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import Flowboard from "./Flowboard";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const [todayDashboardData, setTodayDashboardData] = useState({});
  const [yesterdayDashboardData, setYesterdayDashboardData] = useState({});

  useEffect(() => {
    let isMounted = true;
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_mso_dashboard", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setTodayDashboardData(data.today_data);
          setYesterdayDashboardData(data.yesterday_data);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes.dashboardRoot}>
      <Grid container spacing={1}>
        <Grid
          component={Box}
          item
          display={{ xs: "none", sm: "none", md: "block" }}
          md={4}
        >
          <DashboardOption
            yesterdayDashboardData={yesterdayDashboardData}
            date="Yesterday"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <DashboardOption
            todayDashboardData={todayDashboardData}
            date={moment().format("Do MMMM YYYY")}
          />
        </Grid>
        <Grid
          container
          item
          xs={6}
          sm={6}
          md={4}
          direction="column"
          justify="center"
          // alignItems="center"
        >
          <Custombuttons
            style={{ padding: "9px 9px" }}
            data-testid="new-patient-button"
            component={RouterLink}
            to="/create_patient"
          >
            New Patient
          </Custombuttons>
          <Custombuttons
            style={{ padding: "9px 9px" }}
            data-testid="existing-patient-button"
            component={RouterLink}
            to="/patient_list"
          >
            Existing Patient
          </Custombuttons>
          {/* Make this into Online Doctors ------ look into this later*/}
          <Custombuttons
            style={{ padding: "9px 9px" }}
            data-testid="view-appointment-button"
            component={RouterLink}
            to="/book_appointment_list"
          >
            View Appointments
          </Custombuttons>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        justify="space-evenly"
        alignItems="center"
        container
        // TODO - change this to use theme.spacing
        style={{ paddingTop: "10px" }}
      >
        <Typography
          variant="h4"
          style={{ color: "rgb(140 140 140)" }}
          component="h2"
        >
          Flowboard
        </Typography>
      </Grid>
      <Divider style={{ backgroundColor: "black" }} />
      <Box display={{ xs: "block", sm: "block", md: "block" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Flowboard />
          </Grid>
          <ToastContainer />
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard;
