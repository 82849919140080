import React from "react";
import { Typography, Divider, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -20,
  },
  warningIcon: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  oopsHeading: {
    color: "rgb(80 79 79)",
    fontWeight: 500,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 36,
    },
  },
  mainMessage: {
    color: "#464444",
    marginTop: 20,
    lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
}));

function PortalStatus() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.warningIcon}>
          <ErrorIcon style={{ fontSize: 80, color: "#FF5F51" }} />
          <Typography className={classes.oopsHeading}>Oops!</Typography>
        </div>
        <div>
          <Typography
            variant="h5"
            className={classes.mainMessage}
            component="p"
            align="center"
          >
            There is some error in your account. Please contact our technical
            team on
            <a style={{ textDecoration: "none",marginLeft:4 }} href={`tel:9569945532`}>
              9569945532
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
}

export default PortalStatus;
