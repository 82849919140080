import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Chips = props => {
  const classes = useStyles();
  const { label } = props;

  const [slotsInIntervals, setSlotsInIntervals] = useState([]);

  const breakTimeSlotes = timmm => {
    var timeStops = [];
    for (let i = 0; i < timmm.length; i++) {
      var startTime = timmm[i].slot_from;
      var endTime = timmm[i].slot_to;
      timeStops.push(`${startTime}-${endTime}`);
    }
    return timeStops;
  };

  useEffect(() => {
    let slots = breakTimeSlotes(label);
    setSlotsInIntervals(slots);
  }, []);

  return (
    <div className={classes.root}>
      {label.length > 0 ? (
        label.map(slot => (
          <Chip
            icon={<AccessAlarmIcon />}
            label={`${slot[0]}-${slot[1]}`}
            key={`${slot[0]}-${slot[1]}`}
            data-testid={`doctor-slots-${slot[0]}-${slot[1]}`}
            clickable
            color="secondary"
            style={{
              backgroundColor: "rgb(27 123 105)",
              color: "white",
            }}
          />
        ))
      ) : (
        <Alert severity="info">
          <AlertTitle>Oops</AlertTitle>
          <strong>No available slots for this day for the doctor</strong>
        </Alert>
      )}
    </div>
  );
};

export default Chips;
