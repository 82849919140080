import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import {
  Avatar,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";

import Loader from "../Loader/Loader";
import { abbreviatedName, ageCalculator } from "../../utils/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  itemDivider: {
    borderBottomColor: "rgb(175 157 157 / 20%)",
  },
}));

const PatientList = (props) => {
  const {
    patientListing,
    setSelectedPatientId,
    setSelectedVisitId,
    setPatientInfo,
  } = props;
  const [patientlistingLoader, setPatientlistingLoader] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    if (patientListing.lenght !== 0) {
      setPatientlistingLoader(false);
    }
  }, [patientListing]);
  return (
    <div className={classes.root}>
      {patientlistingLoader ? (
        <Loader />
      ) : (
        <CardContent className={classes.content}>
          <List className={classes.list}>
            {patientListing.map((patient, i) => (
              <ListItem
                classes={{ divider: classes.itemDivider }}
                divider={i < patientListing.length - 1}
                data-testid={`patient-list-${patient.id}`}
                key={patient.id}
              >
                <ListItemAvatar style={{ minWidth: 72, marginTop: "-7%" }}>
                  <Avatar
                    className={classes.avatar}
                    src={
                      patient.patient_profile_photo
                        ? patient.patient_profile_photo
                        : null
                    }
                    size="large"
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{ fontWeight: 500, fontSize: 13, marginTop: "4%" }}
                    >
                      {abbreviatedName(patient.patient_name)}
                      <span
                        style={{
                          fontWeight: 400,
                          color: "rgb(123 123 123)",
                          fontSize: 13,
                          marginLeft: "2%",
                        }}
                      >
                        ({ageCalculator(patient.dob)}/
                        {patient.gender[0].toUpperCase()})
                      </span>
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        type="body2"
                        style={{
                          color: "#272829",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        Registered-
                        {moment(patient.patient_created_at).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ color: "#546871", fontSize: 12 }}
                      >
                        Visit Date-{moment.utc(patient.visit_datetime)
                        .local()
                        .format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#546871",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        Visit Time - {moment.utc(patient.visit_datetime)
                        .local()
                        .format("HH:mm")}
                      </Typography>
                      <br />
                    </React.Fragment>
                  }
                />
                <div>
                  <Button
                    onClick={() => {
                      setSelectedPatientId(patient.patient_id);
                      setSelectedVisitId(patient.id);
                      setPatientInfo(patient.patient_name);
                    }}
                    data-testid={`raiseissue-button-${patient.id}`}
                  >
                    Raise
                  </Button>
                </div>
              </ListItem>
            ))}
          </List>
        </CardContent>
      )}
    </div>
  );
};

PatientList.propTypes = {
  className: PropTypes.string,
};

export default PatientList;
