import React, { useState, useEffect } from "react";
import { Grid, Typography, Card, Link, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useParams, useHistory } from "react-router-dom";
import { postRequest } from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import "../../components/toast.css";
import "react-toastify/dist/ReactToastify.min.css";
import Backdrop from "../../components/Backdrop/SimpleBackdrop";
import Modal from "../../components/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CampList from "./CampList";
import { getRequest } from "../../api/Api";
import SpecialCampForm from "./SpecialCampForm";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    // flexDirection: "row",
    justify: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  cardDesign: {
    width: "80%",
    minHeight: 180,
    cursor: "pointer",
  },
  heading: {
    textAlign: "center",
    width: "100%",
    fontSize: 16,
    fontWeight: 600,
    padding: 10,
  },
  campContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: -30,
  },
  subHeading: {
    textAlign: "center",
    width: "100%",
    fontSize: 14,
    fontWeight: 600,
    padding: 10,
  },
}));

const ConfirmAppointment = () => {
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  let { id } = useParams();
  const history = useHistory();
  const [isShowCampList, setIsShowCampList] = useState(false);
  const [campList, setCampList] = useState([]);
  const [isFemale, setIsFemale] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);
  const [requiresFemaleDoctor, setRequiresFemaleDoctor] = useState(false);

  useEffect(() => {
    let isMounted = true;
    getRequest("patients", `Bearer ${localStorage.getItem("jwt")}`, [id]).then(
      (data) => {
        if (data.error) {
          console.error(data.error);
        } else {
          if (isMounted) {
            setIsFemale(data.data.attributes.gender === "female");
            setCampList(data.data.attributes?.eligible_promo_codes);
          }
        }
      }
    );
    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleSpecialCamp = (campObj) => {
    let formData = new FormData();
    formData.append("appointment[camp_id]", campObj.campId);
    formData.append("appointment[patient_id]", id);
    formData.append("appointment[is_patient_arrived]", 1);
    formData.append("appointment[promo_code_id][]", [campObj.promoId]);
    formData.append("appointment[is_pregnant]", isPregnant);
    formData.append("appointment[requires_female_doctor]", requiresFemaleDoctor);

    let token = localStorage.getItem("jwt");
    const body = {
      token: `Bearer ${token}`,
      endPoint: "appointments",
      formData: formData,
    };

    setOpenBackdrop(true);
    postRequest(body)
      .then((data) => {
        console.log(data);
        if (data.error) {
          toast.error(<p>{data.error}</p>, { autoClose: 3000 });
          setOpenBackdrop(false);
          setIsShowCampList(false);
        } else {
          toast(<p>{data.message}</p>, {
            className: "custom",
            autoClose: 1000,
          });
          setIsShowCampList(false);
          setTimeout(() => {
            history.push("/book_appointment_list");
          }, 1000);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCampRequest = (campObj) => {
    if (campList.length > 1) {
      setIsShowCampList(true);
    } else {
      handleSpecialCamp(campObj);
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Grid
          item
          xs={6}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Card
            className={classes.card}
            component={Link}
            style={{ textDecoration: "none", minHeight: 180, width: "80%" }}
            href={`/book_appointment/${id}?type=specific`}
          >
            <Typography className={classes.heading}>
              Regular consultations
            </Typography>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Card
            className={isFemale ? classes.cardDesign : classes.card}
            style={!isFemale ? { minHeight: 180, width: "80%" } : {}}
            onClick={
              !isFemale
                ? () =>
                    handleCampRequest({
                      campId: campList[0]?.camp?.id,
                      promoId: campList[0]?.id,
                    })
                : null
            }
          >
            {isFemale && (
              <SpecialCampForm setIsPregnant={setIsPregnant} isFemale={true} setRequiresFemale={setRequiresFemaleDoctor} />
            )}
            {isFemale && <Divider />}
            <div
              className={classes.card}
              style={{ minHeight: 120, width: "100%" }}
              onClick={
                isFemale
                  ? () =>
                      handleCampRequest({
                        campId: campList[0]?.camp?.id,
                        promoId: campList[0]?.id,
                      })
                  : null
              }
            >
              <Typography align="center" className={classes.heading}>
                {campList.length > 1
                  ? `Eligible for Multiple Camps, Please Select One`
                  : `${campList[0]?.camp?.title}`}
              </Typography>
            </div>
          </Card>
        </Grid>
        <Backdrop
          msg="Please wait your appointment is getting booked"
          open={openBackdrop}
          handleClose={() => setOpenBackdrop(false)}
        />
        <ToastContainer />
        {isShowCampList && (
          <Modal
            title=""
            style={{ backgroundColor: "rgb(255 254 254)" }}
            width={400}
            open={isShowCampList}
          >
            <div>
              <div className={classes.campContainer}>
                <div />
                <Typography className={classes.heading}>
                  Select Camp for Appointment
                </Typography>
                <HighlightOffIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsShowCampList(false)}
                />
              </div>
              <div>
                {campList.map((camp, i) => (
                  <CampList
                    key={camp.id}
                    onSelectCamp={handleSpecialCamp}
                    divider={i < campList.length - 1}
                    campData={camp}
                    patientId={id}
                  />
                ))}
              </div>
            </div>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default ConfirmAppointment;
