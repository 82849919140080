import React from "react";
import "./CallerSymbol.css";
import CallIcon from "@material-ui/icons/Call";
import { Link } from "@material-ui/core";

const CallerSymbol = ({ handleVideoCallResponse }) => {
  const callPickerHandler = ()=>{
    localStorage.setItem("isCallExist", "0");
    handleVideoCallResponse()
  }
  return (
    <Link
      onClick={callPickerHandler}
      style={{ cursor: "pointer" }}
      data-testid={`doctor-call-link`}
    >
      <div className="content-center">
        <div className="pulse">
          {" "}
          <CallIcon style={{ color: "aliceblue" }} />
        </div>
      </div>
    </Link>
  );
};

export default CallerSymbol;
