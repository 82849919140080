import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  avatar: {
    [theme.breakpoints.up("xs")]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11),
      height: theme.spacing(11),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
  },
}));

const AvatarResponsive = props => {
  const classes = useStyles();
  return <Avatar alt={props.alt} src={props.src} className={classes.avatar} />;
};

export default AvatarResponsive;
