import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Webcam from "react-webcam";
import Button from "../../components/CustomButtons/Button";
import CameraIcon from "@material-ui/icons/Camera";
import CropIcon from "@material-ui/icons/Crop";
import UndoIcon from "@material-ui/icons/Undo";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Croppie from "croppie";
import { Grid, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { withHooksHOC } from "./withHookHoc";

// const downloadjs = require("downloadjs");

// interface IHooksHOCProps {
//   width: number;
// }

class WebcamCapture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      camIsOn: true,
      imageData: null,
      fileName: "",
      display: null,
      checked: false,
      cameraMode: "user",
    };
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();

    this.setState({ imageData: imageSrc });
  };

  undo = () => {
    this.setState({ imageData: null });
    document.getElementById("imageWindow").style.display = "inline-flex";
  };

  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  crop = () => {
    document.getElementById("imageWindow").style.display = "none";
    const el = document.getElementById("demo-basic");
    const vanilla = new Croppie(el, {
      viewport: { width: 200, height: 200, type: "square" },
      boundary: { width: 300, height: 300 },
      showZoomer: false,
      enableOrientation: false,
      enableResize: false,
    });
    console.log(this.state.imageData);
    vanilla.bind({
      url: this.state.imageData,
      orientation: 1,
    });

    const doCropBtn = document.getElementById("doCrop");
    const saveBtn = document.getElementById("saveBtn");
    document.getElementById("crop_id").style.display = "none";
    doCropBtn.style.display = "inline-flex";
    doCropBtn.addEventListener("click", () => {
      vanilla
        .result({ type: "base64", format: "png", size: "original" })
        .then((base64) => {
          document.getElementById("imgbase64").setAttribute("src", base64);

          var block = base64.split(";");
          var contentType = block[0].split(":")[1];
          var realData = block[1].split(",")[1];
          var blob = this.b64toBlob(realData, contentType);
          this.setState({ display: base64 });
          this.setState({ imageData: blob });
          doCropBtn.style.display = "none";
          document.getElementById("demo-basic").style.display = "none";
          saveBtn.style.display = "inline-flex";
        });
    });
  };

  handleSave = () => {
    // downloadjs(this.state.imageData, 'Patient', '../../assets/Patient image/png');
    if (this.props.location.patientid === undefined) {
      this.props.history.push({
        pathname: "/create_patient",
        patientData: this.props.location.patientData,
        image: this.state.imageData,
        displayImage: this.state.display,
      });
    } else {
      this.props.history.push({
        pathname: `/edit_patient/${this.props.location.patientid}`,
        image: this.state.imageData,
        displayImage: this.state.display,
      });
    }
  };

  handleBackButton = () => {
    if (this.props.location.patientid === undefined) {
      this.props.history.push({
        pathname: "/create_patient",
        patientData: this.props.location.patientData,
      });
    } else {
      this.props.history.push({
        pathname: `/edit_patient/${this.props.location.patientid}`,
      });
    }
  };

  toggleChecked = () => {
    console.log(this.state.checked);
    this.setState({ checked: !this.state.checked });
    console.log(this.state.checked);
    if (this.state.checked === false) {
      this.setState({ cameraMode: { exact: "environment" } });
    } else {
      this.setState({ cameraMode: "user" });
    }
  };

  render() {
    console.log(this.state.cameraMode);
    const videoConstraints = {
      width: this.props.width,
      height: this.props.height,
      facingMode: this.state.cameraMode,
    };
    const webcam = (
      <div id="webcam">
        <Webcam
          audio={false}
          width={350}
          height={300}
          // width={videoConstraints.width.width}
          // height={videoConstraints.width.width}
          ref={this.setRef}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
        />
      </div>
    );

    const photo = (
      <div id="still-photo">
        <img id="photo" alt="" src={this.state.imageData} />
      </div>
    );

    const campic = this.state.imageData === null ? webcam : photo;
    const buttons =
      this.state.imageData === null ? (
        <Button  data-testid="webcam-capture-button"
        onClick={this.capture} variant="contained">
          <CameraIcon />
          {" "}
          CAPTURE
        </Button>
      ) : (
        <Button data-testid="webcam-undo-button"
        onClick={this.undo} variant="contained">
          <UndoIcon />
          {" "}
          Undo
        </Button>
      );

    const doCropBtn = (
      <Button data-testid="webcam-crop-selection-button"
      style={{ display: "none" }} id="doCrop" variant="contained">
        <CropIcon />
        {" "}
        Crop Selection
      </Button>
    );

    const saveBtn = (
      <Button
        style={{ display: "none" }}
        onClick={this.handleSave}
        id="saveBtn"
        data-testid="webcam-save-button"
        variant="contained"
      >
        <SaveIcon />
        {" "}
        Save
      </Button>
    );
    const style = {
      backgroundColor: "#282c34",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "calc(10px + 2vmin)",
      color: "white",
      // textAlign:'center'
    };
    return (
      <div style={style}>
        <Grid container>
          <Grid
            container
            justify="flex-end"
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
          >
            <FormGroup style={{ marginTop: "1%" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.checked}
                    onChange={this.toggleChecked}
                    name="showSecurity"
                    // color="default"
                    style={{ color: "white" }}
                  />
                }
              />
            </FormGroup>
            <label
              style={{ marginTop: 20, marginRight: "5%" }}
              htmlFor="Rotate Camera"
            >
              Rotate Camera
            </label>
            <Button
              style={
                {
                  // marginLeft: "80%",
                  // marginTop: 16,
                }
              }
              data-testid="webcam-back-button"
              variant="contained"
              onClick={this.handleBackButton}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </Grid>
          <Grid
            container
            id="imageWindow"
            justify="center"
            item
            xs={12}
            sm={12}
            md={12}
          >
            {this.state.camIsOn && campic}
          </Grid>
          <Grid container justify="center" item xs={12} sm={12} md={12}>
            {this.state.camIsOn && buttons}
          </Grid>
          {this.state.imageData && (
            <>
              <Grid container justify="center" item xs={12} sm={12} md={12}>
                <Button
                  id="crop_id"
                  data-testid="webcam-crop-button"
                  onClick={() => this.crop()}
                  variant="contained"
                >
                  <EditIcon />
                  {" "}
                  Crop Photo...
                </Button>
              </Grid>
              <div id="demo-basic" />
              <Grid container justify="center" item xs={12} sm={12} md={12}>
                <img alt="" id="imgbase64" />
              </Grid>

              <Grid container justify="center" item xs={12} sm={12} md={12}>
                {doCropBtn}
              </Grid>
              <Grid container justify="center" item xs={12} sm={12} md={12}>
                {saveBtn}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withHooksHOC(WebcamCapture);
