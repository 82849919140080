import React from "react";
import "./PrescriptionLoader.css";
import { Tooltip } from "@material-ui/core";

const PrescriptionLoader = () => {
  return (
    <>
      <div class="prescriptionLoader">
        <div class="prescriptioncircle1 prescriptioncircle"></div>
        <div class="prescriptioncircle2 prescriptioncircle"></div>
        <div class="prescriptioncircle3 prescriptioncircle"></div>
        <div class="prescriptioncircle4 prescriptioncircle"></div>
        <div class="prescriptioncircle5 prescriptioncircle"></div>
        <div class="prescriptioncircle6 prescriptioncircle"></div>
        <div class="prescriptioncircle7 prescriptioncircle"></div>
        <div class="prescriptioncircle8 prescriptioncircle"></div>
        <div class="prescriptioncircle9 prescriptioncircle"></div>
        <div class="prescriptioncircle10 prescriptioncircle"></div>
        <div class="prescriptioncircle11 prescriptioncircle"></div>
        <div class="prescriptioncircle12 prescriptioncircle"></div>
      </div>
    </>
  );
};

export default PrescriptionLoader;
