/** @format */

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import theme from "./theme";
import LoginSwitcher from "./LoginSwitcher";
import LoginPage from "./views/Login/Login";
import { ProvideAuth } from "./ProvideAuth";
import Navbar from "./layouts/Dashboard/NavBar";
import WorkingRoutes from "./WorkingRoutes";
import "./validation/validate";
import "./mock";
import "./assets/scss/index.scss";
import CacheBuster from "./CacheBuster";
import { RefreshPrescription } from "./RefreshPrescription";
import ForgotPassword from "views/Login/ForgotPassword";
import ResetPassword from "views/Login/ResetPassword";

const App = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router>
                <Switch>
                  <Route path="/auth/password_resets/:id">
                    <ResetPassword />
                  </Route>
                  <Route path="/forgotPassword">
                    <ForgotPassword />
                  </Route>
                  <ProvideAuth>
                    <Route path="/login">
                      <LoginPage />
                    </Route>
                    <LoginSwitcher>
                      <RefreshPrescription>
                        <Navbar>
                          <WorkingRoutes />
                        </Navbar>
                      </RefreshPrescription>
                    </LoginSwitcher>
                  </ProvideAuth>
                </Switch>
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
