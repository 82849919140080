import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";

import ChatBoxHeader from "./ChatBoxHeader";
import ChatBoxDetailHolder from "./ChatBoxDetailHolder";
import ChatForm from "./ChatForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.white,
  },
}));

const ChatBox = (props) => {
  const { conversation, setSelectedUser, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <ChatBoxHeader
        setSelectedUser={setSelectedUser}
        conversation={conversation}
      />
      <Divider style={{ backgroundColor: "#dcdada" }} />
      <ChatBoxDetailHolder messages={conversation.messages} />
      <Divider style={{ backgroundColor: "#dcdada" }} />
      <ChatForm />
    </div>
  );
};

export default ChatBox;
