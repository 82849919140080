import React from "react";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/styles";
import moment from "moment";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  colors,
  Typography,
} from "@material-ui/core";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 18,
    backgroundColor: "#c12525",
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  active: {
    boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
    backgroundColor: colors.grey[50],
  },
  avatar: {
    height: 40,
    width: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  unread: {
    marginTop: 2,
    padding: 2,
    height: 18,
    minWidth: 18,
  },
}));

const UserListItem = (props) => {
  const { active, setSelectedUser, conversation, className, ...rest } = props;

  const classes = useStyles();
  const lastMessage = conversation.messages[conversation.messages.length - 1];

  return (
    <ListItem
      {...rest}
      button
      className={clsx(
        {
          [classes.active]: active,
        },
        className
      )}
      onClick={() => setSelectedUser(conversation.id)}
    >
      <ListItemAvatar>
        <Avatar
          alt="Person"
          className={classes.avatar}
          src={conversation.otherUser.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography type="body2" style={{ fontWeight: 600, fontSize: 15 }}>
            {conversation.otherUser.name}
          </Typography>
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: "h6",
        }}
        secondary={`${lastMessage.sender.name}: ${lastMessage.content}`}
        secondaryTypographyProps={{
          noWrap: true,
          variant: "body1",
        }}
      />
      <div className={classes.details}>
        <Typography noWrap variant="body2">
          {moment(lastMessage.created_at).format("HH:MM A")}
        </Typography>
        {conversation.unread > 0 && (
          <StyledBadge badgeContent={conversation.unread} color="secondary" />
        )}
      </div>
    </ListItem>
  );
};

export default UserListItem;
