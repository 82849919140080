import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardContent,
  Link,
  Typography,
  colors,
} from "@material-ui/core";
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexWrap: "wrap",
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  header: {
    maxWidth: "100%",
    width: 240,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      flexBasis: "100%",
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: "80px",
    height: "80px",
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
  actions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
}));

const PreBookingCard = (props) => {
  const {
    project,
    handleCount,
    handleAppointmentStatusOpen,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  const statusColors = {
    "Patient Not Present": colors.orange[600],
    Cancelled: colors.red[600],
    Confirmed: colors.green[600],
  };

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)} data-testid={`patient-prebooking-appointment-card-${project.attributes.patient.name.toLowerCase()}`}>
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Avatar
              alt="Author"
              className={classes.avatar}
              src={project.attributes.patient.profile_photo}
            ></Avatar>
            <div>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Patient Name
              </Typography>
              <Link
                color="textPrimary"
                component={RouterLink}
                noWrap
                to="#"
                variant="h5"
                style={{ fontSize: "unset", fontWeight: 300 }}
              >
                {project.attributes.patient.name}
              </Link>
            </div>
          </div>
          {project.attributes.doctor !== null ? (
            <React.Fragment>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Doctor
                </Typography>
                <Typography
                  style={{ fontSize: "unset", fontWeight: 300 }}
                  variant="h6"
                >
                  {project.attributes.doctor.name}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Fee
                </Typography>
                <Typography
                  style={{ fontSize: "unset", fontWeight: 300 }}
                  variant="h6"
                >
                  Rs {project.attributes.doctor.fee}
                </Typography>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Category
                </Typography>
                <Typography
                  style={{ fontSize: "unset", fontWeight: 300 }}
                  variant="h6"
                >
                  {project.attributes.doctor_category.category_title}
                </Typography>
              </div>
              <div className={classes.stats}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Fee
                </Typography>
                <Typography
                  style={{ fontSize: "unset", fontWeight: 300 }}
                  variant="h6"
                >
                  Rs {project.attributes.doctor_category.fee}
                </Typography>
              </div>
            </React.Fragment>
          )}
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Payment Received
            </Typography>
            <Typography
              style={{ fontSize: "unset", fontWeight: 300 }}
              variant="h6"
            >
              Rs{" "}
              {project.attributes.payment_received
                ? Math.max(0,project.attributes.payment_received)
                : project.attributes.is_patient_arrived === 1 ? `${0} (Follow-up)` : 0}
            </Typography>
          </div>
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Arrival Status
            </Typography>
            {project.attributes.is_patient_arrived !== 1 ? (
              <Typography
                style={{ fontSize: "unset", fontWeight: 400, color: "#de4315" }}
                variant="h6"
              >
                Not Arrived{" "}
              </Typography>
            ) : null}
          </div>
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Appointment Date Time
            </Typography>
            <Typography
              style={{ fontSize: "unset", fontWeight: 300 }}
              variant="h6"
            >
              {moment.utc(project.attributes.appointment_datetime)
               .local()
               .format("DD MMM YYYY HH:mm")}
            </Typography>
          </div>
          <div className={classes.stats}>
            <Typography style={{ fontWeight: 500 }} variant="body2">
              Status
            </Typography>
            {project.attributes.status.value === "Confirmed" ? (
              <Link
                color="inherit"
                style={{
                  color: statusColors[project.attributes.status.value],
                  fontSize: "15px",
                }}
                onClick={() =>
                  handleAppointmentStatusOpen(
                    project.attributes.id,
                    project.attributes.patient.id
                  )
                }
              >
                {project.attributes.status.value}
              </Link>
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  color: statusColors[project.attributes.status.value],
                  fontWeight: 300,
                }}
                variant="h6"
              >
                {" "}
                {project.attributes.status.value}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

PreBookingCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
};

export default PreBookingCard;
