import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  CardActions,
  IconButton,
  Collapse,
  CardContent,
  Hidden,
  // for testing
  Button,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Chip from "./Slots/Chip";
import Card from "../../components/Card/Card";
import Loader from "../../components/Loader/Loader";
import CardBody from "../../components/Card/CardBody";
import CardAvatar from "../../components/Card/CardAvatar";
import { getRequest } from "../../api/Api";
// import avatar from "../../assets/img/patient.png";
import avatar from "../../assets/img/doctorbio.png";
import styles from "../../assets/jss/material-dashboard-react/views/DoctorBio";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { daysArray, capitalizeFirstLetter } from "../../utils/helper";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);

const DoctorBio = (props) => {
  const classes = useStyles();
  const { selectedDoctorId, doctorLanguageForAppointment, user } = props;
  const [doctor, setDoc] = useState([]);
  const [isBioLoading, setIsBioLoading] = useState(false);
  const [isExpandLoading, setIsExpandLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [doctorQualifications, setDoctorQualification] = useState();
  const [consultationLanguage, setConsultationLanguage] = useState(null);
  const [doctorSchedule, setDoctorSchedule] = useState(Array(7).fill([]));
  const doctorBioRef = useRef(null);
  const [filteredLanguage, setFilteredLanguage] = useState("");

  const fetchDoctorDetail = () => {
    setIsBioLoading(true);
    const token = "Bearer " + localStorage.getItem("jwt");
    let params = [selectedDoctorId];
    getRequest("users", token, params).then((data) => {
      console.log(data);
      if (data.error) {
        console.log(data.error);
      } else {
        let doctorQualification = "";
        for (let i = 0; i < data.data.attributes?.user_qualifications.length; i++) {
          doctorQualification =
            doctorQualification +
            "," +
            data.data.attributes.user_qualifications[i].qualification.qualification;
        }
        doctorQualification = doctorQualification.substring(1);
        setDoctorQualification(doctorQualification);
        setDoc({ ...data.data.attributes });
        setIsBioLoading(false);
        setIsExpandLoading(false);
      }
    });
  };

  useEffect(() => {
    if (doctor) {
      if (doctor?.doctor_schedules?.length > 0) {
        let schedulesArray = Array(7).fill([]);
        doctor.doctor_schedules.forEach(
          (timeSlot) =>
            (schedulesArray[timeSlot.day_of_week] = [
              ...schedulesArray[timeSlot.day_of_week],
              [timeSlot.slot_from, timeSlot.slot_to],
            ])
        );
        setDoctorSchedule(schedulesArray);
      }
    }
  }, [doctor]);

  useEffect(() => {
    if (!doctorLanguageForAppointment) {
      let msoLanguage = user.attributes.default_language?.key;
      if (doctor.consulting_languages) {
        let filterLanguage = doctor.consulting_languages.find(
          (o) => o.language === msoLanguage
        );
        setFilteredLanguage(filterLanguage);
        let desiredLanguage = filterLanguage
          ? msoLanguage
          : doctor.default_language?.key;
        setConsultationLanguage(desiredLanguage);
      }
    } else {
      setConsultationLanguage(doctorLanguageForAppointment);
    }
  }, [user, doctor, doctorLanguageForAppointment]);

  useEffect(() => {
    fetchDoctorDetail();
    doctorBioRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctorId]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setIsExpandLoading(true);
      fetchDoctorDetail();
    }
  };

  const showWaitTime = (checkWaitTime, textToShow) => {
    return (
      <div style={{ marginTop: 3, display: "flex", flexDirection: "row" }}>
        {checkWaitTime ? (
          <>
            {" "}
            <ScheduleIcon style={{ fontSize: 15,}} />{" "}
            <span style={{ marginLeft: "1%",fontWeight:600  }}>
              {parseInt(checkWaitTime)} min {textToShow}
            </span>{" "}
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  const takeConformationOfLanguageHandler = () => {
    if (!doctorLanguageForAppointment) {
      if (filteredLanguage) {
        props.onBookAppointment(consultationLanguage);
      } else {
        if (
          window.confirm(
            `Your consultation language is ${consultationLanguage}. Do you wish to continue ?`
          )
        ) {
          props.onBookAppointment(consultationLanguage);
        }
      }
    } else {
      props.onBookAppointment(consultationLanguage);
    }
  };

  return (
    <div>
      {isBioLoading ? (
        <Loader />
      ) : (
        <Card
          style={{
            marginBottom: 0,
            marginTop: 0,
            // boxShadow:
            //   "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
          }}
          data-testid={`doctor-bio-${doctor.id}`}
        >
          <Grid container ref={doctorBioRef}>
            <Grid item xs={3} sm={3} md={3}>
              {doctor && (
                <CardAvatar
                  profile
                  style={{
                    boxShadow: "none",
                    margin: "initial",
                    // width: 0,
                    // borderRadius: "unset",
                  }}
                >
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {doctor.profile_photo ? (
                      <div
                        style={{
                          height: "132px",
                          width: "147px",
                        }}
                      >
                        <img
                          src={doctor.profile_photo}
                          className={classes.doctorImage}
                          style={{ height: "100%" }}
                          alt="..."
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          style={{ width: "100%", height: "100%", padding: 8 }}
                          // style={doctorImage}

                          src={avatar}
                          alt="..."
                        />
                      </div>
                    )}
                  </a>
                </CardAvatar>
              )}
            </Grid>
            <Grid container item xs={8} sm={8} md={8}>
              <React.Fragment>
                {doctor && (
                  <div
                    className={classes.stats}
                    style={{ marginTop: "3%", flexGrow: 1 }}
                  >
                    <Typography
                      className={classes.typo}
                      style={{
                        color: "rgb(14 89 111)",
                        fontSize: 20,
                        fontWeight: 400,
                      }}
                      variant="body2"
                    >
                      {doctor.name}
                    </Typography>
                    <div style={{ marginTop: 3 }}>
                      <Typography
                        className={classes.typoResult}
                        style={{
                          marginLeft: 4,
                          color: "#787887",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                        variant="h6"
                      >
                        {doctor.designation}
                        <span
                          style={{
                            marginLeft: 4,
                            fontSize: 13,
                            color: "#5d5050",
                            fontWeight: 400,
                          }}
                        >
                          ({doctorQualifications})
                        </span>
                      </Typography>
                    </div>
                    <div style={{ marginTop: 3 }}>
                      <Typography
                        className={classes.typoResult}
                        style={{
                          marginLeft: 4,
                          color: "#414146",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                        variant="h6"
                      >
                        ₹ {doctor.fee} Consultation fee
                      </Typography>
                    </div>
                    <div style={{ marginTop: 3 }}>
                      <Typography
                        className={classes.typoResult}
                        style={{
                          marginLeft: 4,
                          color: "#787887",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                        variant="h6"
                      >
                        {doctor.work_experience} Years Experience Overall
                      </Typography>
                    </div>
                    {showWaitTime(doctor.current_tentative_wait_time, "Wait time")}
                  </div>
                )}
              </React.Fragment>
            </Grid>
            <Hidden mdUp>
              <Grid
                container
                item
                direction="row-reverse"
                justify="flex-start"
                alignItems="flex-start"
                xs={1}
                sm={1}
                md={1}
              >
                <IconButton onClick={props.resetDoctorId}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
          {doctor && (
            <CardBody>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Registration No
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {doctor.medical_registration_no}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Detailed Experience
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {doctor.detailed_experience}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Awards and Achievements
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        <ul>{doctor.awards_n_achievements}</ul>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Patient Viewed
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        250
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Your preferred consultation language
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {consultationLanguage
                          ? capitalizeFirstLetter(consultationLanguage)
                          : ""}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          )}
          {/* Update this with appropriate styling */}
          <br />
          <CardActions disableSpacing style={{ marginTop: "-5%" }}>
            <Button
              data-testid="book-appointment-button"
              color="primary"
              onClick={() => {
                takeConformationOfLanguageHandler();
              }}
            >
              Book Appointment
            </Button>
            <Button
              data-testid="showdoctor-timings-button"
              className={classes.expand}
              onClick={handleExpandClick}
            >
              Show Timings
            </Button>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {isExpandLoading ? (
                <Loader />
              ) : (
                <List>
                  {doctorSchedule.map((value, index) => (
                    <ListItem data-testid={`doctor-slots-list-${value}`} key={index} divider>
                      <Grid item container md={2} sm={2} xs={3}>
                        <Typography
                          align="center"
                          style={{ fontSize: 16, fontWeight: 600 }}
                        >
                          {daysArray[index]}
                        </Typography>
                      </Grid>
                      <Grid item container md={10} sm={10} xs={9}>
                        <ListItemText primary={<Chip label={value} />} />
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          </Collapse>
        </Card>
      )}
    </div>
  );
};

export default DoctorBio;
