import React, { useEffect, Fragment, useState,useContext } from "react";
import ActionCable from "actioncable";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NotificationSound from "../../layouts/Dashboard/NotificationSound";
import CallAlert from "layouts/Dashboard/CallAlert";
import { useLocation } from "react-router-dom";
import { refreshContext } from "../../RefreshPrescription";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ActionCableNotificationChannel = ({ handleNotification, children }) => {
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(true);
  const [severity, setSeverity] = useState("info");
  const [soundStatus, setSoundStatus] = useState(false);
  const [countDownTime, setCountDownTime] = useState();
  const [countDownTimerOn, setCountDownTimerOn] = useState(false);
  const [openCallBackdrop, setOpenCallBackdrop] = useState(false);
  const { setPrescriptionCount } = useContext(refreshContext);
  const location = useLocation();
  const handleCloseCallBackdrop = () => {
    setOpenCallBackdrop(false);
  };
  const handleToggleCallBackdrop = (value) => {
    setOpenCallBackdrop(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  let token = localStorage.getItem("jwt");
  let cable = ActionCable.createConsumer(
    `${process.env.REACT_APP_SOCKET_URL}${token}`
  );
  useEffect(() => {
    cable.subscriptions.create("NotificationChannel", {
      initialized: () => console.log("Connecting....."),
      connected: () => console.log("Way to go! You did it!"),
      received: (message) => {
        handleNotification(message);
        // care should be taken to ensure when the changes are applied on the
        // backend regarding the type of the notification, this handling function
        // will have to be made more robust. Again using TSX would help, since things
        // are being passed around so much
        handleReceived(message);
      },
      disconnected: () => console.log("We are no longer connected! 😢"),
    });
    return function cleanup() {
      cable.disconnect();
    };
  });

  useEffect(() => {
    let interval = null;
    if (!countDownTime) {
      return;
    }
    if (countDownTimerOn && (message.notification_type === "incoming_call" 
        || localStorage.getItem('isCallExist') === '1')) {
      interval = setInterval(() => {
        setCountDownTime(countDownTime - 1);
      }, 1000);
    } else if (!countDownTimerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countDownTimerOn, countDownTime]);

  const handleReceived = (message) => {
    try {
      if (message) {
        console.log(message);
        setMessage(message);
        if(message.notification_type === "alert" && 
           message.hasOwnProperty('prescription_sent_to_mso') && 
           message.prescription_sent_to_mso === 1 && location.pathname === '/'){
            setPrescriptionCount(prev=>prev+1)
       }
        if (
          message.notification_type === "alert" &&
          message.hasOwnProperty("call_ended")
        ) {
          localStorage.setItem("callEndedStatus", message.call_ended);
          localStorage.setItem("isCallExist", "0");
          handleCloseCallBackdrop();
        }
        if (
          message.notification_type === "alert" && message.hasOwnProperty("doctor_window_closed") &&
           message.doctor_window_closed === 1
        ) {
          localStorage.setItem("callEndedStatus", "1");
          handleSoundStatus(true)
          localStorage.setItem("isCallExist", "0");
          handleCloseCallBackdrop();
        }
        if (message.notification_type === "incoming_call") {
          localStorage.setItem("vonageData", JSON.stringify(message.data));
          localStorage.setItem("isCallExist", "1");
          setCountDownTimerOn(true);
          handleToggleCallBackdrop(true);
          setCountDownTime(59);
          localStorage.setItem(
            "vonageSession",
            JSON.stringify(message.data.vonage_session_id)
          );
          localStorage.setItem("callEndedStatus", 0);
        }
        setOpenSnack(true);
      } else {
        setMessage("res not OK");
        setOpenSnack(true);
        setSeverity("warning");
      }
    } catch (err) {
      setMessage("Error from catch");
      setOpenSnack(true);
      setSeverity("error");
    }
  };

  const handleSoundStatus = (value) => {
    setSoundStatus(value);
  };

  const handleVideoCallResponse = () => {
    window.open(
      "/vonage",
      "_blank",
      "width = 600, height = 600,top=0, left=760"
    );
    setCountDownTimerOn(false);
    handleSoundStatus(true);
    handleCloseCallBackdrop();
  };

  return (
    <Fragment>
        <CallAlert
          handleVideoCallResponse={handleVideoCallResponse}
          open={openCallBackdrop}
          countDownTime={countDownTime}
        />
      {/* the handling of the incoming message can be improved */}
      {message ? (
        <>
          <NotificationSound
            notificationType={message.notification_type}
            handleSoundStatus={(value) => handleSoundStatus(value)}
            soundStatus={soundStatus}
            handleCloseCallBackdrop={handleCloseCallBackdrop}
            message={message}
            setCountDownTimerOn={setCountDownTimerOn}
          />
          {message.notification_type !== "incoming_call" && localStorage.getItem('isCallExist') !== '1' &&(
            <Snackbar
              open={openSnack}
              style={{ top: "10%" }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={50000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={severity}>
                {message.data}
              </Alert>
            </Snackbar>)}
          
        </>
      ) : null}
      {children}
    </Fragment>
  );
};

export default ActionCableNotificationChannel;
