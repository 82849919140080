import React, { useState, useEffect,useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";
import { getRequest } from "../../api/Api";
import { useParams } from "react-router-dom";
import PatientDetail from "./components/PatientDetail/PatientDetail";
import Loader from "../../components/Loader/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(3),
    marginTop: "-33px",
    padding: "8px",
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const PatientPage = props => {
  const classes = useStyles();
  const [patient, setPatient] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let { id } = useParams();

  const fetchPatientById = () => {
    const endPoint = "patients";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [id];
    getRequest(endPoint, token, param).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatient(data.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchPatientById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      {isLoading ? <Loader /> : <PatientDetail patient={patient} />}
    </div>
  );
};

export default PatientPage;
