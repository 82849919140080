import React, { useState, Fragment, useEffect } from "react";
import { Switch, useParams, useRouteMatch, Route } from "react-router-dom";
import {
  Hidden,
  Grid,
  makeStyles,
  Typography,
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Loader from "../components/Loader/Loader";
import PatientDetail from "./PatientPage/components/PatientDetail/PatientDetail";
import { getRequest } from "../api/Api";
import AvatarResponsive from "../components/AvatarResponsive";
import { ageCalculator, getDayOfDate, abbreviatedName,convertUtcTime } from "../utils/helper";
import VitalList from "./PatientPage/VitalVerticalTable";
import MedicalProblemList from "./PatientPage/MedicalProblemVerticalTable";
import FamilyHistoryData from "./PatientPage/FamilyHistoryVerticalTable";
import AllergyList from "./PatientPage/AllergyVerticalTable";
import DocumentList from "./PatientPage/DocumentVerticalTable";
import CurrentmedicationList from "./PatientPage/CurrentMedicationVerticalTable";
import LifestyleList from "./PatientPage/LifestyleVerticalTable";
import StepperComponent from "./PatientPage/Stepper";
import SendForReview from "./PatientPage/SendForReview";
import PatientVisitWiseData from "./PatientPage/PatientVisitWiseData";

const firstVisitDataFields = [
  {
    sectionName: "Medical Problem",
    component: (props) => (
      <MedicalProblemList key="Medical Problem" patient={props} />
    ),
  },
  {
    sectionName: "Current Medication",
    component: (props) => (
      <CurrentmedicationList key="Current Medication" patient={props} />
    ),
  },
  // TODO: Add lazy here in the component function
  {
    sectionName: "Vitals",
    component: (props) => <VitalList key="Vitals" patient={props} />,
  },
  {
    sectionName: "Lifestyle",
    component: (props) => <LifestyleList key="Lifestyle" patient={props} />,
  },
  {
    sectionName: "Family History",
    component: (props) => (
      <FamilyHistoryData key="Family History" patient={props} />
    ),
  },
  {
    sectionName: "Allergy",
    component: (props) => <AllergyList key="Allergy" patient={props} />,
  },
  {
    sectionName: "Document Upload",
    component: (props) => (
      <DocumentList key="Document Upload" patient={props} />
    ),
  },
];
const subsequentVisitDataFields = [
  "Medical Problem Status",
  "Medical Problem",
  "Current Medication",
  "Vitals",
  "Document Upload",
];

const useStyles = makeStyles((theme) => ({
  main_section: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1),
    },
    width: "100%",
    scrollbarGutter: true,
    // overscrollBehaviorY: "contain",
  },
  demographic_mobile: {
    [theme.breakpoints.only("md")]: {
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1),
    },
    "& p": {
      [theme.breakpoints.only("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    "& h6": {
      fontSize: "15px",
      [theme.breakpoints.only("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "17px",
      },
    },
  },
}));

export const ShowVisitDetails = (props) => {
  return firstVisitDataFields.map((item) => item.component(props));
};

const steps = [
  "MedicalProblemForm",
  "CurrentMedicationForm",
  "VitalsForm",
  "LifeStyleForm",
  "FamilyHistoryForm",
  "AllergyForm",
  "DocumentUpload",
];

const PatientPageMobile = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [activeStep, setActiveStep] = useState(0);

  const patientData = { ...patient.attributes };
  const [visitId, setVisitId] = useState(props.visitId || undefined);
  const [vitalLength, setVitalLength] = useState();
  const [medicalProblemLength, setMedicalProblemLength] = useState(undefined);
  const [isReviewOnly, setIsReviewOnly] = useState( JSON.parse(localStorage.getItem("isReviewOnly")) ?? false);
  const [isFoundPatientAppointment, setIsFoundPatientAppointment] = useState(
    false
  );
  // const [showStepper, setShowStepper] = useState(false);
  const [showStepper, setShowStepper] = useState(
    JSON.parse(localStorage.getItem('isShowStepper')) ?? false
  );
  const [patientInfo, setpatientInfo] = useState();
  const [correctionCount, setCorrectionCount] = useState(0);
  const [patientOldVisits, setPatientOldVisits] = useState([]);
  const theme = useTheme();
  const screenCheck = useMediaQuery(theme.breakpoints.down("sm"));

  // TODO this is an overkill, the visit dates is already present in the patient object
  // when the date is selected we can give a call to the backend

  useEffect(()=>{
    if(screenCheck){
      let isMounted = true;
      const endPoint = `patients`;
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [patient.attributes.id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.error(data.error);
        } else {
          if (isMounted) {
            // setpatientInfo(data.data);
          }
        }
      });
      return () => {
        isMounted = false;
      };
    }

  },[screenCheck])

  useEffect(()=>{
    let isMounted = true;
    if(screenCheck){
    const fetchPatientOldVisits = () => {
      const endPoint = `get_patient_timelines?patient_id=${patient.attributes.id}&visit_id=${patient.attributes.last_visit_id}`;
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setPatientOldVisits(data);
          }
        }
      });
    };
    fetchPatientOldVisits();
  }

    return () => {
      isMounted = false;
    };
  },[screenCheck])

  useEffect(() => {
    let isMounted = true;
    const fetchMedicalProblemInfo = () => {
      const endPoint = "get_patient_medical_problems_by_visit_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      // TODO This will break as well due to the match not being received
      let param = [patient.attributes.id, patient.attributes.last_visit_id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setMedicalProblemLength(data.data.length);
          }
        }
      });
    };

    const fetchVitals = () => {
      const endPoint = "get_patient_vitals_by_visit_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [patient.attributes.id, patient.attributes.last_visit_id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setVitalLength(data.data.length);
          }
        }
      });
    };

    fetchVitals();
    fetchMedicalProblemInfo();
    return () => {
      isMounted = false;
    };
  }, [patient.attributes.id, patient.attributes.last_visit_id, showStepper]);

  useEffect(() => {
    let isMounted = true;
    const endPoint = `patients`;
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.error(data.error);
      } else {
        if (isMounted) {
          setpatientInfo(data.data);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, [patient.attributes.id, correctionCount]);

  useEffect(() => {
    let isMounted = true;
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("appointments?page=1&items=50 ", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          for (var i = 0; i < data.data.data.length; i++) {
            if (
              data.data.data[i].attributes.patient.id === patient.attributes.id
            ) {
              setIsFoundPatientAppointment(true);
              break;
            }
          }
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      // patient.attributes.last_visit_id ||
      medicalProblemLength === 0 ||
      vitalLength === 0 ||
      showStepper
    ) {
      setShowStepper(true);
      localStorage.setItem("isShowStepper",true)
      localStorage.setItem("isReviewOnly",false)
    }
    if (
     (patient.attributes.last_visit.is_flagged === 1 ||
      patient.attributes.last_visit.is_sent_to_patient_pool === 1 ||
      medicalProblemLength > 0 ||
      vitalLength > 0) && !showStepper
    ) {
      setShowStepper(false);
      localStorage.setItem("isShowStepper",false)
      localStorage.setItem("isReviewOnly",true)
    }
  }, [patient, medicalProblemLength, vitalLength]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const correctionCountHandler = () => {
    setCorrectionCount(correctionCount + 1);
  };

  return (
    <Fragment>
      <div className={classes.main_section}>
        <Hidden lgUp>
          <Grid container>
            <Grid container flex-direction="row" item xs={8} sm={7} md={6}>
              <AvatarResponsive
                alt={`${patientData.name ? patientData.name : ""}`}
                src={`${patientData.profile_photo}`}
              />
              <Box className={classes.demographic_mobile}>
                <Typography variant="h6">
                  {`${patientData.title} ${abbreviatedName(
                    patientData.name ? patientData.name : ""
                  )}`}
                </Typography>
                <Typography>
                  {`${patientData.gender[0].toUpperCase()}`}/
                  {`${patientData.is_tentative ? "about" : ""} ${ageCalculator(
                    patientData.dob
                  )}`}
                </Typography>
                <Typography>
                  {patientData.marital_status?.value},{" "}
                  {patientData.city ? patientData.city.name : ""}
                </Typography>
                <Typography>
                  {patientData.mobile ||
                    patientData.alternate_contact_no ||
                    "No contact"}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              item
              xs={4}
              sm={5}
              md={6}
            >
              <FormControl>
                <InputLabel htmlFor="select-visit">Select Visit</InputLabel>
                <NativeSelect
                  value={visitId}
                  data-testid={`selectvisit-${
                    visitId ? visitId : patient.attributes.last_visit_id
                  }-dropdown`}
                  // CORRECT this by adding this data to history as well for
                  // easier routing and ensuring functionality of the back arrow
                  style={{ width: "8em" }}
                  onChange={(event) => setVisitId(event.target.value)}
                  inputProps={{
                    name: "visit",
                    id: "select-visit",
                  }}
                >
                  <option
                    key={patient.attributes.last_visit_id}
                    value={patient.attributes.last_visit_id}
                  >
                    Current Visit
                  </option>
                  {patientOldVisits.map((visit) => (
                    <option key={visit.id} value={visit.id}>{`${
                      convertUtcTime(visit.visit_datetime)
                    } ${getDayOfDate(visit.visit_datetime)}`}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <br />
          {patient.attributes.last_visit.is_sent_for_review === 1 ||
          patient.attributes.last_visit.is_sent_to_patient_pool === 1 ? (
            <Grid
              container
              justify="center"
              direction="row"
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  color: "brown",
                }}
              >
                The Visit is locked
              </Typography>
            </Grid>
          ) : null}
          {!patient.attributes.last_visit_id ||
          medicalProblemLength === 0 ||
          vitalLength === 0 ||
          showStepper ? (
            <StepperComponent
              handleBack={handleBack}
              visitId={visitId}
              handleNext={handleNext}
              patient={patient}
              setIsReviewOnly={setIsReviewOnly}
              isReviewOnly={isReviewOnly}
              activeStep={activeStep}
              steps={steps}
              setShowStepper={setShowStepper}
            />
          ) : patient.attributes.last_visit_id && !showStepper ? (
            <>
              {patient.attributes.last_visit.is_sent_for_review === 0 &&
              patient.attributes.last_visit.is_sent_to_patient_pool === 0 &&
              vitalLength > 0 &&
              medicalProblemLength > 0 ? (
                <SendForReview patient={patient} />
              ) : null}
              {isFoundPatientAppointment ? (
                <ShowVisitDetails
                  vitalLength={vitalLength}
                  medicalProblemLength={medicalProblemLength}
                  patientInfo={patientInfo}
                  visitId={
                    visitId !== undefined
                      ? visitId
                      : patient.attributes.last_visit_id
                  }
                  correctionCountHandler={correctionCountHandler}
                  correctionCount={correctionCount}
                  isReviewOnly={isReviewOnly}
                  patient={patient}
                />
              ) : (
                <>
                  <br />
                  <br />
                  <PatientVisitWiseData
                    visitId={
                      visitId !== undefined
                        ? visitId
                        : patient.attributes.last_visit_id
                    }
                    patient={patient}
                  />
                </>
              )}
            </>
          ) : null}
        </Hidden>
        <Hidden mdDown>
          <PatientDetail
            steps={steps}
            activeStep={activeStep}
            correctionCount={correctionCount}
            patient={patient}
            patientInfo={patientInfo}
            changeState={(value) => {
              console.log(value);
              setActiveStep(value);
            }}
          >
            {patient.attributes.last_visit_id && !showStepper ? (
              <>
                {patient.attributes.last_visit.is_sent_for_review === 0 &&
                patient.attributes.last_visit.is_sent_to_patient_pool === 0 &&
                vitalLength > 0 &&
                medicalProblemLength > 0 ? (
                  <SendForReview patient={patient} />
                ) : null}
                <ShowVisitDetails
                  vitalLength={vitalLength}
                  medicalProblemLength={medicalProblemLength}
                  visitId={
                    visitId !== undefined
                      ? visitId
                      : patient.attributes.last_visit_id
                  }
                  isReviewOnly={isReviewOnly}
                  patientInfo={patientInfo}
                  correctionCountHandler={correctionCountHandler}
                  correctionCount={correctionCount}
                  patient={patient}
                />
              </>
            ) : (
              <StepperComponent
                handleBack={handleBack}
                handleNext={handleNext}
                vitalLength={vitalLength}
                medicalProblemLength={medicalProblemLength}
                patient={patient}
                activeStep={activeStep}
                setIsReviewOnly={setIsReviewOnly}
                steps={steps}
                setShowStepper={setShowStepper}
              />
            )}
          </PatientDetail>
        </Hidden>
      </div>
    </Fragment>
  );
};

const PatientPage = () => {
  const { patientId } = useParams();
  // why to maintain the visitId state in this component??
  const [visitId, setVisitId] = useState(useParams().visitId || undefined);
  const [patient, setPatient] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setIsFetchingData(true);
    getRequest("patients", `Bearer ${localStorage.getItem("jwt")}`, [
      patientId,
    ]).then((data) => {
      if (data.error) {        
        console.error(data.error);
      } else {
        if (isMounted) {
          setPatient(data.data);
          setIsFetchingData(false);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, [patientId]);

  return isFetchingData ? (
    <Loader />
  ) : (
    <Fragment>
      {/* TODO - Change the name here to represent more accurately this component */}
      <PatientPageMobile patient={patient} visitId={visitId} />
    </Fragment>
  );
  // is there a way to check if this is a new visit or an old visit??
};

const PatientPageSwitch = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:visitId`}>
        <PatientPage />
      </Route>
      <Route path={`${path}`}>
        <PatientPage />
      </Route>
    </Switch>
  );
};

export default PatientPageSwitch;
