import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import DoctorImage from "../../assets/img/doctorbio.png";
import Badge from "@material-ui/core/Badge";
import StarIcon from "@material-ui/icons/Star";
import {
  Avatar,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Chip,
  Link,
  Grid,
  Card,
  Divider,
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { getRequest } from "../../api/Api";
import NoData from "../../assets/img/nodata.png";
import moment from "moment";
import TranslateIcon from "@material-ui/icons/Translate";
import ScheduleIcon from "@material-ui/icons/Schedule";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  nameStyl: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
      fontSize: 15, 
    },
    [theme.breakpoints.up("sm")]: {
      fontWeight: 600,
      fontSize: 15, 
      marginLeft: 2
    },
  },
  starIcon: {
    [theme.breakpoints.down("sm")]: {
      color: "#d27508",
      fontSize: 18
    },
    [theme.breakpoints.up("sm")]: {
      color: "#d27508",
       fontSize: 19
    },
  },
  experienceStyl: {
    [theme.breakpoints.down("sm")]: {
      color: "#546871",
      fontSize: 13,
      paddingTop: 3,
    },
    [theme.breakpoints.up("sm")]: {
      color: "#546871",
      fontSize: 14,
      paddingTop: 3,
    },
  },
  starStyl: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 2,
      fontSize: 13,
      marginLeft: 3,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 2,
      fontSize: 15,
      marginLeft: 3,
    },
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11),
      height: theme.spacing(11),
      marginLeft: 6,
    },
  },
  designation: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
      color: "#272829",
      fontSize: 12,
    },
    [theme.breakpoints.up("sm")]: {
      fontWeight: 600,
      color: "#272829",
      fontSize: 14,
    },
  },
  doctorFee: {
    marginLeft:3,
    cursor:'pointer',
    boxShadow:'0 4px 4px 0 rgb(0 0 0 / 14%)',
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: '#2979ff',
    color: "white",
    marginTop: "15%",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width:84,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
      width:100,
    },
  },
  languageStyl: {
    [theme.breakpoints.down("sm")]: {
      color: "#272829",
      fontSize: 12,
      wordBreak:'break-all',
      fontWeight: 500,
    },
    [theme.breakpoints.up("sm")]: {
      color: "#272829",
      fontSize: 14,
      fontWeight: 500,
      width:200,
      wordBreak:'break-all',
    },
  },
  feeDiv: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30%",
    },
    [theme.breakpoints.up("sm")]: {
    },
  },
  waitTimeValue: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      fontWeight: 600,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  waitTimeValue: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      fontWeight: 600,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  waitTimeTitle: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      marginLeft: 4,
      fontSize: 11,
      color: "black",
    },
    [theme.breakpoints.up("sm")]: {
      fontWeight: 500,
      marginLeft: 4,
      fontSize: 16,
      color: "black",
    },
  }
}));

const DoctorList = (props) => {
  const {
    setDoctorId,
    showAppointmentSection,
    setDoctorInfo,
    filterDoctors,
    isReset,
    setIsReset,
    isShowOnlineDoc,
    setIsShowOnlineDoc
  } = props;
  const [doctors, setDoctors] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);

  console.log(isShowOnlineDoc)
  console.log(filterDoctors)

  useEffect(() => {
    let isMounted = true;
    if (filterDoctors && !isReset) {
      if(isShowOnlineDoc){
        setDoctors(filterDoctors.filter(doc=>doc.attributes.is_online === 1))
      }else{
        setDoctors(filterDoctors);
      }
    } else {
      fetchDoctorsData(isMounted,'online')
    }
    return () => {
      isMounted = false;
    };
  }, [filterDoctors,isReset]);


  const classes = useStyles();

  const fetchDoctorsData = (isMounted,status)=>{
    setIsListLoading(true);
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest(`search_doctors`, token).then((data) => {
      if (data.error) {
        console.log(data.error);
        setIsListLoading(false);
      } else {
        if (isMounted) {
          let language;
          for (let i = 0; i < data.data.length; i++) {
            language=data.data[i].attributes.consulting_languages.map(el=>el.language_value)
              .toString();
            data.data[i].attributes.language = language;
          }
          if(isShowOnlineDoc && status === "online"){
            setDoctors(data.data.filter(doc=>doc.attributes.is_online === 1))
          }else{
            setDoctors(data.data);
          }
          setIsListLoading(false);
          setIsReset(false);
        }
      }
    });
  }
  return (
    <div className={classes.root}>
      {isListLoading ? (
        <Loader />
      ) : (
        <>
        { isShowOnlineDoc && 
        <div style={{ display:'flex',justifyContent:'space-between' }}>
          <div style={{ display:'flex' }}>
          <h4 style={{ fontSize:15,fontWeight:500,marginTop:6 }}> Filter : </h4>
          <Chip
            label='Online Doctors'
            style={{
              height: 25,
              fontSize: 12,
              fontWeight: 400,
              marginLeft:12,
              backgroundColor: "#b8e7d7",
            }}
          />
          </div>
          <div><CancelIcon onClick={()=>{fetchDoctorsData(true,'all');setIsShowOnlineDoc(false)}}/></div>
        </div> }
          {doctors.length !== 0 ? (
            <div>
              {doctors.map((doctor, i) => (
                <Card
                  key={doctor.attributes.id}
                  style={{
                    padding: 13,
                    marginTop:16,
                    borderBottom: "1px solid rgb(201 195 195)",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3}>
                      <div>
                        {doctor.attributes.is_online === 1 ? (
                          <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              className={classes.avatar}
                              src={
                                doctor.attributes.profile_photo
                                  ? doctor.attributes.profile_photo
                                  : DoctorImage
                              }
                              size="large"
                            />
                          </StyledBadge>
                        ) : (
                          <Avatar
                            className={classes.avatar}
                            src={
                              doctor.attributes.profile_photo
                                ? doctor.attributes.profile_photo
                                : DoctorImage
                            }
                            size="large"
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5}>
                      <Typography
                        type="body2"
                        className={classes.nameStyl}
                      >
                        {doctor.attributes.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.experienceStyl}
                      >
                        {doctor.attributes.work_experience} Yrs Exp.
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: 3,
                          marginLeft: -4,
                        }}
                      >
                        <>
                          <StarIcon  className={classes.starIcon}/>
                          <p className={classes.starStyl}>
                            {doctor.attributes.star_rating
                              ? doctor.attributes.star_rating
                              : ""}
                          </p>
                        </>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          paddingTop: 3,
                          marginLeft: -4,
                        }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.languageStyl}
                        >
                          <TranslateIcon
                            style={{
                              fontSize: 14,
                              marginLeft: 3,
                              color: "rgb(210, 117, 8)",
                            }}
                          />{" "}
                          {doctor.attributes.language}
                        </Typography>
                      </div>
                      <div
                        style={{
                          paddingTop: 10,
                          marginLeft: -4,
                          display: "flex",
                        }}
                      >
                        <ScheduleIcon
                          style={{ fontSize: 16, fontWeight: 600 }}
                        />
                        <div style={{ marginTop: -1, marginLeft: 3 }}>
                          <span className={classes.waitTimeValue}>
                            {doctor.attributes.current_tentative_wait_time} min
                          </span>
                          <span className={classes.waitTimeTitle}>
                            Wait time
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      container
                      direction="column"
                      alignItems="center"
                      style={{ padding: 28 }}
                    >
                      <div className={classes.feeDiv}>
                        <Typography
                          type="body2"
                          align="center"
                          className={classes.designation}
                        >
                          {doctor.attributes.designation}
                        </Typography>
                        <Divider
                          style={{
                            marginTop: "15%",
                            backgroundColor: "#d3d7db",
                          }}
                        />
                        <Link
                        style={{ textDecoration:'none' }}
                          onClick={() => {
                            setDoctorId(doctor.attributes.id);
                            setDoctorInfo({
                              id: doctor.attributes.id,
                              name: doctor.attributes.name,
                              fee: doctor.attributes.fee,
                              followUpAt: doctor.attributes.follow_up_time,
                            });
                            showAppointmentSection(false);
                          }}
                          data-testid={`viewdoctor-button-${doctor.attributes.name.toLowerCase()}`}>
                        <Typography
                          variant="body2"
                          align="center"
                          className={classes.doctorFee}
                        >
                          Fee ₹ {doctor.attributes.fee}
                        </Typography>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </div>
          ) : (
            <Grid
              justify="center"
              alignItems="center"
              item
              container
              xs={12}
              sm={12}
              md={12}
            >
              <img style={{ width: 290 }} src={NoData} alt="nodata" />
            </Grid>
          )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

DoctorList.propTypes = {
  className: PropTypes.string,
};

export default DoctorList;
