import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import CustomButton from "../../components/CustomButtons/Button";
import { Dialog, Grid, Typography, TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import axios from "../../utils/axios1";

import avatar from "../../assets/img/feedback.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
  1: "Poor",
  2: "Unsatisfactory",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};

const useStyles = makeStyles(theme => ({
  header: {
    margin: "0 auto",
    padding: 12,
    color: "blue",
  },
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  content: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const FeedbackModal = props => {
  const { open, onClose, visitId, handleCount } = props;
  const [recordValue, setRecordValue] = useState(0);
  const [msoAssistance, setMsoAssistance] = useState(0);
  const [videoQuality, setVideoQuality] = useState(0);
  const [hover, setHover] = useState(-1);
  const [hovermsoAssistance, setMsoAssistanceHover] = useState(-1);
  const [hoverVideo, setVideoHover] = useState(-1);
  const [remarkValue, setRemarkValue] = useState("");
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleRecord = newValue => {
    setRecordValue(newValue);
  };

  const handleAssistance = newValue => {
    setMsoAssistance(newValue);
  };

  const handleVideo = newValue => {
    setVideoQuality(newValue);
  };

  const handleRemark = value => {
    setRemarkValue(value);
  };

  const closeModal = () => {
    setRecordValue(0);
    setMsoAssistance(0);
    setVideoQuality(0);
    setRemarkValue("");
    setValue("");
    onClose();
  };

  const handleSubmit = () => {
    const mso_to_doctor_feedback = {
      visit_id: visitId,
      time_taken_for_patient_problems: recordValue,
      patient_satisfaction: msoAssistance,
      call_quality: videoQuality,
      prescription_wait_time_fair: value,
      remark: remarkValue,
    };
    // console.log(mso_to_doctor_feedback);
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      axios
        .post(
          "/mso_to_doctor_feedbacks",
          { mso_to_doctor_feedback: mso_to_doctor_feedback },
          { headers: { Authorization: token } },
        )
        .then(response => {
          alert(response.data.message);
          setRecordValue(0);
          setMsoAssistance(0);
          setVideoQuality(0);
          setRemarkValue("");
          setValue("");
          handleCount();
        })
        .catch(error => {
          if (error.response.data !== "") {
            alert(error.response.data.error);
          } else {
            alert(error.response.statusText);
          }
        });
    }
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography align="center" gutterBottom variant="h5">
            Feel free to drop us your feedback
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <img
              src={avatar}
              alt="feedback"
              style={{ width: 250, marginTop: 50 }}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={8}>
            <Grid item xs={12} sm={8} md={8} container direction="column">
              <Typography style={{ fontSize: 17 }} >
              1. Time spent by the doctor to listen to patients' problems and ask relevant history taking questions
              </Typography>
              <Typography style={{ fontSize: 17 }} >परामर्श के दौरान डॉक्टर ने मरीज़ से उनकी बीमारी और समस्याओं को सुनने में सही समय दिया ?</Typography>
              <div className={classes.rating}>
                <Rating
                  name="size-small"
                  value={recordValue}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleRecord(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                />
                {recordValue !== null && (
                  <Box ml={2}>{labels[hover !== -1 ? hover : recordValue]}</Box>
                )}
              </div>
            </Grid>
            <br />
            <Grid item xs={12} sm={8} md={8} container direction="column">
              <Typography style={{ fontSize: 17 }} >
                 2. How satisfied was the patient after the discussion with the doctor?
              </Typography>
              <Typography style={{ fontSize: 17 }}>परामर्श के बाद मरीज़ डॉक्टर से कितना संतुष्ट था ?</Typography>
              <div className={classes.rating}>
                <Rating
                  name="size-medium"
                  value={msoAssistance}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleAssistance(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setMsoAssistanceHover(newHover);
                  }}
                />
                {msoAssistance !== null && (
                  <Box ml={2}>
                    {
                      labels[
                        hovermsoAssistance !== -1
                          ? hovermsoAssistance
                          : msoAssistance
                      ]
                    }
                  </Box>
                )}
              </div>
            </Grid>
            <br />
            <Grid item xs={12} sm={8} md={8} container direction="column">
              <Typography style={{ fontSize: 17 }}>
               3. How was the call quality, audio and video?     
              </Typography>
              <Typography style={{ fontSize: 17 }}>परामर्श के दौरान ऑडियो और विडिओ की क्वालिटी (गुणवत्ता) कैसी थी ?</Typography>
              <div className={classes.rating}>
                <Rating
                  name="size-large"
                  value={videoQuality}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleVideo(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setVideoHover(newHover);
                  }}
                />
                {videoQuality !== null && (
                  <Box ml={2}>
                    {labels[hoverVideo !== -1 ? hoverVideo : videoQuality]}
                  </Box>
                )}
              </div>
            </Grid>
            <br />
            <Grid item xs={12} sm={8} md={8} container direction="column">
              <Typography style={{ fontSize: 17 }}>
              4. Wait time for prescription to be sent after video-call was fair?
              </Typography>
              <Typography style={{ fontSize: 17,}}>परामर्श के बाद पर्चा मिलने में लगने वाला समय ठीक था ?</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="choice"
                  name="choice1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <br />
            <Grid item xs={12} sm={8} md={8}>
              <Typography style={{ fontSize: 17 }}>Any Remark</Typography>
              <TextField
                id="outlined-multiline-static"
                // label="Remark"
                multiline
                value={remarkValue || ""}
                onChange={e => handleRemark(e.target.value)}
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <CustomButton
                onClick={handleSubmit}
                style={{
                  backgroundColor: "rgb(50, 123, 175)",
                }}
              >
                Submit
              </CustomButton>
              <CustomButton
                style={{ backgroundColor: "#a89d9d" }}
                onClick={closeModal}
              >
                Cancel
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

FeedbackModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default FeedbackModal;
