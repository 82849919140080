import API from "../utils/baseUrl";
import { slackDebugger } from "../utils/slackDebbuger";
import { errorResponsesArray } from "../utils/helper";

/************************************* GET REQUEST ******************************************/

export const getQueryRequest = (endPoint, params) => {
  let token = "Bearer " + localStorage.getItem("jwt");
  let url = `${API}/${endPoint}?${params}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `${token}`,
    },
  })
    .then((response) => {
      if (errorResponsesArray.includes(parseInt(response.status))) {
        slackDebugger({
          actionType: "errorLogging",
          errorMessage: response.statusText,
          endPoint: endPoint,
          errorCode: response.status,
          method: "GET",
        });
      }
      if (response.status === 200 || response.status === 422) {
        return response.json();
      } else {
        return new Promise((resolve, reject) => {
          resolve({ error: response.statusText });
        });
      }
    })
    .catch((err) => console.log(err));
};
