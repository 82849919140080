import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "../../components/toast.css";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import CardFooter from "../../components/Card/CardFooter";
import TextField from "@material-ui/core/TextField";
import MultiSelect from "react-multi-select-component";
import Button from "../CustomButtons/Button";
import { blockButton } from '../../utils/helper'
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { getRequest, putRequest } from "../../api/Api";
import AstrickInLabel from '../AstrickInLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    minWidth: 270,
    maxWidth: 300,
  },
  dropDownStyling: {
    [theme.breakpoints.down("sm")]: {
      width: 145,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 280,
    },
  },
  durationType: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 145,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 280,
    },
  },
  autocompleteStyling: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 360,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 815,
      // fontWeight: 600,
    },
  },
  multiSelectStyling: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -7,
      width: 320,
      marginTop: "4%",
      fontWeight: 600,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: -3,
      width: 400,
      fontWeight: 600,
      marginTop: "2%",
    },
  },
  labelRoot: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  asterisk: {
    color: "red"
  }
}));

const MedicalProblem = (props) => {
  const classes = useStyles();
  const {
    patient,
    steps,
    step,
    handleNext,
    setShowMedicalForm,
    handleBack,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
    correctionCountHandler,
  } = props;

  const [relievingFactor, setRelievingFactor] = useState([]);
  const [medicalInfo, setMedicalInfo] = useState([]);
  const [aggravatingFactor, setAggravatingFactor] = useState([]);

  const [associatedSymtom, setAssociatedSymtom] = useState([]);
  const [assoSymtom, setAssociatedSymtoms] = useState([]);
  const [duration,setDuration] = useState()

  const [options, setMedicalProblem] = useState([]);
  const [durationType, setDurationType] = useState([]);
  const [startDate, setStartDate] = useState();
  const [progressionList, setprogressionList] = useState([]);
  const [occurrence, setOccurrenceList] = useState([]);
  const [currentList, setCurrentStatusList] = useState([]);
  const [verbs, setVerbs] = useState([]);
  const [medicalRemark, setMedicalRemark] = useState();
  const [isShowComplaintTitle, setIsShowComplaintTitle] = useState(false);
  const { control, errors, getValues, setValue, handleSubmit } = useForm();

  useEffect(() => {
    let isMounted = true;

    const fetchMedicalProblems = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_medical_problems_dropdown", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setMedicalProblem(data);
          }
        }
      });
    };

    const fetchDurationType = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_duration_type_list", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setDurationType(data);
          }
        }
      });
    };

    const fetchProgressionList = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_progression_list", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setprogressionList(data);
          }
        }
      });
    };

    const fetchOccurenceList = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_occurrence_list", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setOccurrenceList(data);
          }
        }
      });
    };

    const fetchCurrentStatus = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_medical_problem_current_status_list", token).then(
        (data) => {
          if (data.error) {
            console.log(data.error);
          } else {
            if (isMounted) {
              setCurrentStatusList(data);
            }
          }
        }
      );
    };

    const fetchVerbs = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_verbs_dropdown", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setVerbs(data);
          }
        }
      });
    };

    const fetchMedicalProblemInfo = () => {
      const endPoint = "get_patient_medical_problems_by_visit_id";
      const token = "Bearer " + localStorage.getItem("jwt");
      console.log(patient);

      // let param = [props.match.params.id, props.match.params.type];
      let param = [patient.attributes.id, patient.attributes.last_visit_id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setMedicalInfo(data.data);
          }
        }
      });
    };

    fetchMedicalProblemInfo();
    fetchVerbs();
    fetchMedicalProblems();
    fetchDurationType();
    fetchProgressionList();
    fetchOccurenceList();
    fetchCurrentStatus();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAsscociatedSymptoms = (id) => {
    const endPoint = "get_asscociated_symptoms_by_id_dropdown";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAssociatedSymtoms(data);
      }
    });
  };

  const handleChanged = (value) => {
    if (value === null) {
      setAssociatedSymtoms([]);
      setValue("symptom_trans", "");
    } else {
      fetchAsscociatedSymptoms(value.id);
      if (value) {
        let foundValue = options.filter((obj) => obj.id === value.id);
        setValue("symptom_trans", foundValue[0].phrase_hindi || "");
      }
    }
  };

  useEffect(() => {
    if (medicalInfo.length !== 0) {
      if (medicalInfo[0].attributes.complaint_id !== null) {
        setValue(
          "chief_complaint",
          {
            id: medicalInfo[0].attributes.complaint_id.id,
            phrase_english:
              medicalInfo[0].attributes.complaint_id.phrase_english,
            phrase_hindi: medicalInfo[0].attributes.complaint_id.phrase_hindi,
          } || ""
        );
      } else {
        setIsShowComplaintTitle(true);
        setValue(
          "complaint_title",

          medicalInfo[0].attributes.complaint_title || ""
        );
      }
      setMedicalRemark(medicalInfo[0].attributes.remark);
      setValue("medical_id", medicalInfo[0].attributes.id || "");
      setDuration(medicalInfo[0].attributes.duration)
      if (medicalInfo[0].attributes.duration_type !== null) {
        setValue(
          "duration_type",
          medicalInfo[0].attributes.duration_type.key || ""
        );
      }
      if (medicalInfo[0].attributes.progression !== null) {
        setValue(
          "progression",
          medicalInfo[0].attributes.progression.key || ""
        );
      }
      if (medicalInfo[0].attributes.occurrence !== null) {
        setValue("onset", medicalInfo[0].attributes.occurrence.key || "");
      }
      setValue("observation", medicalInfo[0].attributes.observation || "");
      if (medicalInfo[0].attributes.current_status !== null) {
        setValue(
          "current_status",
          medicalInfo[0].attributes.current_status.key || ""
        );
      }
      let aggravatingFactors = [];
      if (medicalInfo[0].attributes.aggravating_factor !== null) {
        for (
          let i = 0;
          i < medicalInfo[0].attributes.aggravating_factor.length;
          i++
        ) {
          aggravatingFactors.push({
            label:
              medicalInfo[0].attributes.aggravating_factor[i].phrase_english,
            value: medicalInfo[0].attributes.aggravating_factor[i].id,
            key: medicalInfo[0].attributes.aggravating_factor[i].phrase_hindi,
          });
        }
      }
      setAggravatingFactor(aggravatingFactors);

      let relievingFactors = [];
      if (medicalInfo[0].attributes.relieving_factor !== null) {
        for (
          let i = 0;
          i < medicalInfo[0].attributes.relieving_factor.length;
          i++
        ) {
          relievingFactors.push({
            label: medicalInfo[0].attributes.relieving_factor[i].phrase_english,
            value: medicalInfo[0].attributes.relieving_factor[i].id,
            key: medicalInfo[0].attributes.relieving_factor[i].phrase_hindi,
          });
        }
      }
      setRelievingFactor(relievingFactors);
      if (medicalInfo[0].attributes.complaint_id !== null) {
        fetchAsscociatedSymptoms(medicalInfo[0].attributes.complaint_id.id);
      }
      let associatedSymtoms = [];
      if (medicalInfo[0].attributes.associated_symptom_ids !== null) {
        for (
          let i = 0;
          i < medicalInfo[0].attributes.associated_symptom_ids.length;
          i++
        ) {
          associatedSymtoms.push({
            label:
              medicalInfo[0].attributes.associated_symptom_ids[i]
                .phrase_english,
            value: medicalInfo[0].attributes.associated_symptom_ids[i].id,
            key:
              medicalInfo[0].attributes.associated_symptom_ids[i].phrase_english,
          });
        }
      }
      setAssociatedSymtom(associatedSymtoms);

      if (medicalInfo[0].attributes.start_date !== null) {
        setStartDate(medicalInfo[0].attributes.start_date);
      }
      if (medicalInfo[0].attributes.end_date !== null) {
        setValue("end_date", medicalInfo[0].attributes.end_date || "");
      }
    }
  }, [medicalInfo, progressionList, occurrence, currentList, setValue]);

  const aggravateFactor = (value) => {
    setAggravatingFactor(value);
  };

  const relievedFactor = (value) => {
    setRelievingFactor(value);
  };

  const handleAssociatedSymptom = (value) => {
    setAssociatedSymtom(value);
  };

  function SplitTime(numberOfHours) {
    var Days = Math.floor(numberOfHours / 24);
    var Remainder = numberOfHours % 24;
    var Hours = Math.floor(Remainder);
    return { Days: Days, Hours: Hours };
  }

  function dateFromDay(year, day) {
    var date = new Date(year, 0);
    return new Date(date.setDate(day));
  }
  
  const calculateDate = (value)=>{
    let now = new Date();
    let start = new Date(now.getFullYear(), 0, 0);
    let diff = now - start;
    let oneDay = 1000 * 60 * 60 * 24;
    let day = Math.floor(diff / oneDay);
    let finalNthDay = day - value;
    let result = dateFromDay(now.getFullYear(), finalNthDay);
    return result;
  }

  const handleDurationType = (value) => {
    let durationtype = value;
    let dersiredValue;
    if (durationtype === "hours") {
      let timeResult = SplitTime(duration).Days;
      dersiredValue = calculateDate(timeResult)
      setStartDate(moment(dersiredValue).format("YYYY-MM-DD"));
    } else if (durationtype === "weeks") {
      let weekIntoDays = duration * 7;
      dersiredValue = calculateDate(weekIntoDays)
      setStartDate(moment(dersiredValue).format("YYYY-MM-DD"));
    } else if (durationtype === "months") {
      let monthsIntoDays = duration * 30;
      dersiredValue = calculateDate(monthsIntoDays)
      setStartDate(moment(dersiredValue).format("YYYY-MM-DD"));
    } else if (durationtype === "years"){
      let yearsIntoDays = duration * 365;
      dersiredValue = calculateDate(yearsIntoDays)
      setStartDate(moment(dersiredValue).format("YYYY-MM-DD"));
    }else {
      let Days = duration;
      dersiredValue = calculateDate(Days)
      setStartDate(moment(dersiredValue).format("YYYY-MM-DD"));
    }
  };

  const onSubmit = (data) => {
    if (
      data.chief_complaint.phrase_english === undefined ||
      data.chief_complaint.phrase_english === ""
    ) {
      alert("Please select chief complaint");
      return false;
    }
    var aggravate_factor = "";
    for (let i = 0; i < aggravatingFactor.length; i++) {
      aggravate_factor = aggravate_factor + "," + aggravatingFactor[i].value;
    }
    aggravate_factor = aggravate_factor.substring(1);

    var relieve_factor = "";
    for (let i = 0; i < relievingFactor.length; i++) {
      relieve_factor = relieve_factor + "," + relievingFactor[i].value;
    }
    relieve_factor = relieve_factor.substring(1);

    var asso_id = "";
    for (let i = 0; i < associatedSymtom.length; i++) {
      asso_id = asso_id + "," + associatedSymtom[i].value;
    }
    asso_id = asso_id.substring(1);

  let assoSymp = "";
   if(associatedSymtom.length !== 0){
    assoSymp = associatedSymtom.map((el)=>el.key).toString()
   }else{
    assoSymp = ""
   }
    let patient_medical_problem = {
      visit_id: patient.attributes.last_visit_id,
      complaint_id: data.chief_complaint.id,
      complaint_title: data.chief_complaint.phrase_english,
      duration: duration,
      duration_type: data.duration_type,
      progression: data.progression,
      occurrence: data.onset,
      aggravating_factor: aggravate_factor,
      relieving_factor: relieve_factor,
      current_status: "status_quo",
      start_date: startDate,
      end_date: data.end_datetime,
      associated_symptom_ids: asso_id,
      associated_symptoms: assoSymp,
      observation: data.observation,
    };
    console.log(patient_medical_problem)
    if (data.medical_id !== "") {
      patient_medical_problem.id = data.medical_id;
    }
    var formData = new FormData();

    Object.keys(patient_medical_problem).forEach((key) => {
      formData.append(
        `patient[patient_medical_problems_attributes][0][${key}]`,
        patient_medical_problem[key]
      );
    });
    if (
      !isReviewOnly &&
      patient.attributes.last_visit.is_flagged === 0
      // medicalProblemLength === 0 &&
      // vitalLength === 0
    ) {
      handleNext();
    }else{
      blockButton('medicalproblem',true)
    }
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "save_patient_medical_problems",
      formData: formData,
      params: [patient.attributes.id],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        blockButton('medicalproblem',false)
        toast.error(<p>{data.error}</p>, { autoClose: 3000 })
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        if (
          isReviewOnly ||
          patient.attributes.last_visit.is_flagged === 1 ||
          medicalProblemLength > 0 ||
          vitalLength > 0
        ) {
          setTimeout(() => {
            setShowMedicalForm(false);
            correctionCountHandler();
          }, 1000);
        }
      }
    });
  };

  const resetDate = ()=>{
    setValue("duration_type","")
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "2" }}>
            <CardHeader
              style={{ width: "30%", padding: "7px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Medical Problem</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <div style={{ float: "right" }}>
                      {medicalRemark ? (
                        <Alert severity="warning">{medicalRemark}</Alert>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="medical_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                    {!isShowComplaintTitle ? (
                      <Controller
                        as={
                          <Autocomplete
                            options={options}
                            className={classes.autocompleteStyling}
                            getOptionLabel={(option) =>
                              option.phrase_english !== undefined
                                ? `${option.phrase_english}  (${option.phrase_hindi})`
                                : "" || ""
                            }
                            renderOption={(option) => (
                              <span>
                                {option.phrase_english}({option.phrase_hindi})
                              </span>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  style: { fontWeight: 600 },
                                  data_cy :`patient-chief-complaint-input`
                                }}
                                required
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                label="Chief Complaint"
                              />
                            )}
                          />
                        }
                        onChange={([, data]) => {
                          handleChanged(data);
                          return data;
                        }}
                        name="chief_complaint"
                        control={control}
                        defaultValue=""
                      />
                    ) : (
                      <Controller
                        as={<TextField />}
                        name="complaint_title"
                        control={control}
                        defaultValue=""
                        label="Chief Complaint"
                        type="text"
                        fullWidth
                      />
                    )}
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                  <TextField
                      style={{ width: "66%" }}
                      label="Duration"
                      helperText={errors.duration && errors.duration.message}
                      type="number"
                      required
                      onChange={(e)=>{setDuration(e.target.value);resetDate()}}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      inputProps={{
                        data_cy :`complaint-duration-input`,
                        min:0
                      }}
                      error={Boolean(errors.duration)}
                      name="duration"
                      rules={{ required: "Duration is required" }}
                      value={duration || ""}

                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl
                      className={classes.durationType}
                      error={Boolean(errors.duration_type)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Duration Type <AstrickInLabel/>
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {durationType.map((option) => (
                              <MenuItem 
                              data_cy ={`complaint-duration-type-${option.key}-option`}
                                key={option.key} value={option.key}>
                                {option.value}
                                <span
                                  style={{ fontWeight: 600, marginLeft: "5%" }}
                                >
                                  ({option.value_hindi})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="duration_type"
                        inputProps={{
                          data_cy :`complaint-duration-type-dropdown`
                        }}
                        rules={{ required: "Duration Type is required" }}
                        control={control}
                        onChange={([selected]) => {
                          handleDurationType(selected.target.value);
                          return selected;
                        }}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.duration_type && errors.duration_type.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl
                      // style={{ minWidth: 275 }}
                      className={classes.dropDownStyling}
                      error={Boolean(errors.progression)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Progression <AstrickInLabel/>
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {progressionList.map((option) => (
                              <MenuItem
                               data_cy ={`complaint-progression-${option.key}-option`}
                                key={option.value_hindi}
                                value={option.key}
                              >
                                {option.value}{" "}
                                <span
                                  style={{ fontWeight: 600, marginLeft: "5%" }}
                                >
                                  ({option.value_hindi})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy :`complaint-progression-dropdown`
                        }}
                        name="progression"
                        rules={{ required: "Progression is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.progression && errors.progression.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl
                      className={classes.dropDownStyling}
                      error={Boolean(errors.onset)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Occurrence <AstrickInLabel/>
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {occurrence.map((option) => (
                              <MenuItem
                                data_cy ={`complaint-onset-${option.key}-option`}
                                key={option.value_hindi}
                                value={option.key}
                              >
                                {option.value}{" "}
                                <span
                                  style={{ fontWeight: 600, marginLeft: "5%" }}
                                >
                                  ({option.value_hindi})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy :`complaint-onset-dropdown`
                        }}
                        name="onset"
                        rules={{ required: "Occurrence is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.onset && errors.onset.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.multiSelectStyling}>
                      <label style={{ fontWeight: 500 }}>
                        Aggravating Factor
                      </label>
                      <MultiSelect
                        required
                        id="aggravating_factor"
                        options={verbs.map((e) => ({
                          label: `${e.phrase_english} (${e.phrase_hindi})`,
                          value: e.id,
                          key: e.phrase_hindi,
                        }))}
                        hasSelectAll={false}
                        value={aggravatingFactor}
                        getOptionLabel={(option) => option.phrase_english}
                        onChange={aggravateFactor}
                        labelledBy="Aggravating Factor"
                        data_cy ={`complaint-aggravating-factor-dropdown`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={classes.multiSelectStyling}>
                      <label style={{ fontWeight: 500 }}>
                        Relieving Factor
                      </label>
                      <MultiSelect
                        required
                        id="relieving_factor"
                        options={verbs.map((e) => ({
                          label: `${e.phrase_english} (${e.phrase_hindi})`,
                          value: e.id,
                          key: e.phrase_hindi,
                        }))}
                        value={relievingFactor}
                        hasSelectAll={false}
                        getOptionLabel={(option) => option.phrase_english}
                        onChange={relievedFactor}
                        labelledBy="Relieving Factor"
                        data_cy ={`complaint-relieving-factor-dropdown`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.multiSelectStyling}>
                      <label style={{ fontWeight: 500 }}>
                        Associated Symtom
                      </label>
                      <MultiSelect
                        required
                        id="associated_symtoms"
                        getOptionLabel={(option) => option.phrase_english}
                        options={assoSymtom.map((e) => ({
                          label: `${e.phrase_english} (${e.phrase_hindi})`,
                          value: e.id,
                          key: e.phrase_english,
                        }))}
                        value={associatedSymtom}
                        hasSelectAll={false}
                        onChange={handleAssociatedSymptom}
                        labelledBy="Associated Symtom"
                        data_cy ={`complaint-associated-factor-dropdown`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      style={{ width: 115 }}
                      required
                      id="start_date"
                      name="start_date"
                      label="Start Date"
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      inputProps={{
                        data_cy :`complaint-start-date-input`,
                        readOnly: true,
                      }}
                      value={startDate || ""}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      name="end_datetime"
                      label="End Date"
                      style={{ width: 112, marginTop: "1%" }}
                      type="date"
                      className={classes.textField}
                      defaultValue=""
                      InputProps={{
                        readOnly: true,
                        data_cy :`complaint-end-date-input`,

                      }}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.Observation)}
                      name="observation"
                      control={control}
                      defaultValue=""
                      inputProps={{
                        data_cy :`complaint-observation-input`,
                      }}
                      multiline
                      rowsMax="4"
                      label="Observation"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  {isReviewOnly ||
                  patient.attributes.last_visit.is_flagged === 1 ||
                  medicalProblemLength > 0 ||
                  vitalLength > 0 ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      container
                      justify="flex-end"
                    >
                      <CardFooter className={classes.footer}>
                        <Button
                          data-testid="medicalproblem-submit-button"
                          id="medicalproblem"
                          style={{ width: 72 }}
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          style={{
                            color: "rgb(61, 170, 153)",
                            border: "1px solid rgb(226 226 226)",
                            backgroundColor: "rgb(255 255 255)",
                            boxShadow: "none",
                            width: 72,
                            marginLeft: "3%",
                          }}
                          data-testid="medical-problem-cancel-button"
                          onClick={() => setShowMedicalForm(false)}
                        >
                          Cancel
                        </Button>

                        <ToastContainer />
                      </CardFooter>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {step !== 0 && (
                        <Button
                          onClick={handleBack}
                          data-testid="medical-problem-back-button"
                          style={{ backgroundColor: "grey" }}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        // onClick={handleNext}
                        data-testid="medicalproblem-next-button"
                        type="submit"
                        style={{
                          backgroundColor: "#4d93dc",
                          marginLeft: "1%",
                        }}
                        className={classes.button}
                      >
                        {step === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default MedicalProblem;
