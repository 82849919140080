import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "../../components/toast.css";
import Alert from "@material-ui/lab/Alert";
import CardFooter from "../../components/Card/CardFooter";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import { getRequest, putRequest } from "../../api/Api";
import { blockButton } from '../../utils/helper'
import Button from "../CustomButtons/Button";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  danger: {
    color: "brown",
    marginTop: 4,
    fontSize: 15,
  },
  footer: {
    // [theme.breakpoints.up("xs")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
}));

const FamilyHistory = (props) => {
  const {
    patient,
    steps,
    step,
    handleNext,
    handleBack,
    setShowFamilyHistoryForm,
    medicalProblemLength,
    vitalLength,
    isReviewOnly,
    correctionCountHandler,
  } = props;
  const { control, handleSubmit, errors, setValue } = useForm();
  const [family, setFamily] = useState([]);
  const [diseaseName, setDiseaseName] = useState([]);
  const [fatherRemark, setFatherRemark] = useState();
  const [motherRemark, setMotherRemark] = useState();
  const [siblingRemark, setSiblingRemark] = useState();
  const [spouceRemark, setSpouceRemark] = useState();
  const classes = useStyles();


  const fetchFamilyHistory = () => {
    const endPoint = "get_patient_family_history_by_patient_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    // let param = [props.match.params.id];
    let param = [patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setFamily(data.data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchDiseaseName = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_family_history_disease_list", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setDiseaseName(data);
        }
      });
    };
    fetchDiseaseName();
    fetchFamilyHistory();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.attributes.id]);

  useEffect(() => {
    if (family.length !== 0) {
      for (let i = 0; i < family.length; i++) {
        if (family[i].attributes.relationship.key === "father") {
          setValue("father_disease", family[i].attributes.disease_name || "");
          setFatherRemark(family[i].attributes.remark);
          setValue("father_id", family[i].attributes.id || "");
          setValue("father_desc", family[i].attributes.description || "");
        } else if (family[i].attributes.relationship.key === "mother") {
          setValue("mother_disease", family[i].attributes.disease_name || "");
          setMotherRemark(family[i].attributes.remark);
          setValue("mother_id", family[i].attributes.id || "");
          setValue("mother_desc", family[i].attributes.description || "");
        } else if (family[i].attributes.relationship.key === "sibling") {
          setValue("sibling_disease", family[i].attributes.disease_name || "");
          setSiblingRemark(family[i].attributes.remark);
          setValue("sibling_id", family[i].attributes.id || "");
          setValue("sibling_desc", family[i].attributes.description || "");
        } else {
          setValue("spouce_disease", family[i].attributes.disease_name || "");
          setSpouceRemark(family[i].attributes.remark);
          setValue("spouce_id", family[i].attributes.id || "");
          setValue("spouce_desc", family[i].attributes.description || "");
        }
      }
    }
  }, [family, setValue]);

  const dataHandlingOfFamily = (familyData)=>{
    var formData = new FormData();
      
      Object.keys(familyData.father).forEach((key) => {
        formData.append(
          `patient[patient_family_histories_attributes][0][${key}]`,
          familyData.father[key]
        );
      });

      Object.keys(familyData.mother).forEach((key) => {
        formData.append(
          `patient[patient_family_histories_attributes][1][${key}]`,
          familyData.mother[key]
        );
      });

      Object.keys(familyData.sibling).forEach((key) => {
        formData.append(
          `patient[patient_family_histories_attributes][2][${key}]`,
          familyData.sibling[key]
        );
      });

      Object.keys(familyData.spouce).forEach((key) => {
        formData.append(
          `patient[patient_family_histories_attributes][3][${key}]`,
          familyData.spouce[key]
        );
      });
      let token = "Bearer " + localStorage.getItem("jwt");
      const body = {
        token: token,
        endPoint: "save_patient_family_histories",
        formData: formData,
        params: [patient.attributes.id],
      };
      putRequest(body).then((data) => {
        if (data.error) {
          blockButton('family',false)
          toast.error(<p>{data.error}</p>, { autoClose: 3000 });
        } else {
          toast(<p>{data.message}</p>, {
            className: "custom",
            autoClose: 1000,
          });
          if (
            isReviewOnly ||
            patient.attributes.last_visit.is_flagged === 1 ||
            medicalProblemLength > 0 ||
            vitalLength > 0
          ) {
            setTimeout(() => {
              setShowFamilyHistoryForm(false);
              correctionCountHandler();
            }, 1000);
          }
        }
      });
  }

  const onSubmit = (data) => {
    // var id = "";
    var father = {};
    var mother = {};
    var sibling = {};
    var spouce = {};
    father.relationship = "father";
    father.disease_name = data.father_disease;
    father.description = data.father_desc;
    if (data.father_id !== "") {
      father.id = data.father_id;
    }

    mother.relationship = "mother";
    mother.disease_name = data.mother_disease;
    mother.description = data.mother_desc;
    if (data.mother_id !== "") {
      mother.id = data.mother_id;
    }
    sibling.relationship = "sibling";
    sibling.disease_name = data.sibling_disease;
    sibling.description = data.sibling_desc;
    if (data.sibling_id !== "") {
      sibling.id = data.sibling_id;
    }
    spouce.relationship = "spouse";
    spouce.disease_name = data.spouce_disease;
    spouce.description = data.spouce_desc;
    if (data.spouce_id !== "") {
      spouce.id = data.spouce_id;
    }
    let familyData ={
      father:father,
      mother:mother,
      sibling:sibling,
      spouce:spouce
    }
    if (
      father.disease_name === "" &&
      father.description === "" &&
      mother.disease_name === "" &&
      mother.description === "" &&
      sibling.disease_name === "" &&
      sibling.description === "" &&
      spouce.disease_name === "" &&
      spouce.description === ""
    ) {
      dataHandlingOfFamily(familyData)
      handleNext();
    } else {    
      if (
        !isReviewOnly &&
        patient.attributes.last_visit.is_flagged === 0
        // medicalProblemLength === 0 &&
        // vitalLength === 0
      ) {
        handleNext();
      }else{
        blockButton('family',true)
      }
      dataHandlingOfFamily(familyData)
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "0px" }}>
            <CardHeader
              style={{ width: "30%", padding: "6px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Family History</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="father_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="mother_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="sibling_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<input />}
                      name="spouce_id"
                      control={control}
                      defaultValue=""
                      type="hidden"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        marginTop: "23px",
                      }}
                      variant="body2"
                    >
                      Father :
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 145 }}
                      error={Boolean(errors.father_disease)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Disease Name
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                              <MenuItem value="" key="">---- Select ----</MenuItem>
                            {diseaseName.map((option) => (
                              <MenuItem 
                                data_cy ={`father-disease-option-${option.key}`}
                                key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="father_disease"
                        inputProps={{
                          data_cy :`father-disease-dropdown`
                        }}
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.father_desc)}
                      name="father_desc"
                      control={control}
                      defaultValue=""
                      label="Description"
                      inputProps={{
                        data_cy :`father-description-input`
                      }}
                      multiline
                      rowsMax="4"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {fatherRemark ? (
                      <Alert severity="warning">{fatherRemark}</Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        marginTop: "23px",
                      }}
                      variant="body2"
                    >
                      Mother :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 145 }}
                      error={Boolean(errors.mother_disease)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Disease Name
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            <MenuItem value="" key="">---- Select ----</MenuItem>
                            {diseaseName.map((option) => (
                              <MenuItem 
                                data_cy ={`mother-disease-option-${option.key}`}
                                key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy :`mother-disease-dropdown`
                        }}
                        name="mother_disease"
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.mother_desc)}
                      name="mother_desc"
                      control={control}
                      defaultValue=""
                      inputProps={{
                        data_cy :`mother-description-input`
                      }}
                      label="Description"
                      multiline
                      rowsMax="4"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {motherRemark ? (
                      <Alert severity="warning">{motherRemark}</Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        marginTop: "23px",
                      }}
                      variant="body2"
                    >
                      Sibling :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 145 }}
                      error={Boolean(errors.sibling_disease)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Disease Name
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            <MenuItem value="" key="">---- Select ----</MenuItem>
                            {diseaseName.map((option) => (
                              <MenuItem 
                                data_cy ={`sibling-disease-option-${option.key}`}
                                key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy :`sibling-disease-dropdown`
                        }}
                        name="sibling_disease"
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.sibling_desc)}
                      name="sibling_desc"
                      control={control}
                      defaultValue=""
                      inputProps={{
                        data_cy :`sibling-description-input`
                      }}
                      label="Description"
                      multiline
                      rowsMax="4"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {siblingRemark ? (
                      <Alert severity="warning">{siblingRemark}</Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        marginTop: "23px",
                      }}
                      variant="body2"
                    >
                      Spouse :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 145 }}
                      error={Boolean(errors.spouce_disease)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Disease Name
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            <MenuItem value="" key="">---- Select ----</MenuItem>
                            {diseaseName.map((option) => (
                              <MenuItem 
                                data_cy ={`spouce-disease-option-${option.key}`}
                                key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy :`spouce-disease-dropdown`
                        }}
                        name="spouce_disease"
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.spouce_desc)}
                      name="spouce_desc"
                      control={control}
                      inputProps={{
                        data_cy :`spouce-description-input`
                      }}
                      defaultValue=""
                      label="Description"
                      multiline
                      rowsMax="4"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {spouceRemark ? (
                      <Alert severity="warning">{spouceRemark}</Alert>
                    ) : null}
                  </Grid>
                </Grid>
                {isReviewOnly ||
                patient.attributes.last_visit.is_flagged === 1 ||
                medicalProblemLength > 0 ||
                vitalLength > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justify="flex-end"
                  >
                    <CardFooter className={classes.footer}>
                      <Button
                        data-testid="familyhistory-submit-button"
                        style={{ width: 72 }}
                        type="submit"
                        id="family"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          color: "rgb(61, 170, 153)",
                          border: "1px solid rgb(226 226 226)",
                          backgroundColor: "rgb(255 255 255)",
                          boxShadow: "none",
                          width: 72,
                          marginLeft: "3%",
                        }}
                        data-testid="family-history-cancel-button"
                        onClick={() => setShowFamilyHistoryForm(false)}
                      >
                        Cancel
                      </Button>
                      <ToastContainer />
                    </CardFooter>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    {step !== 0 && (
                      <Button
                        onClick={handleBack}
                        data-testid="family-history-back-button"
                        style={{ backgroundColor: "grey" }}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      // onClick={handleNext}
                      data-testid="familyhistory-next-button"
                      type="submit"
                      style={{
                        backgroundColor: "#4d93dc",
                        marginLeft: "1%",
                      }}
                      className={classes.button}
                    >
                      {step === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                )}
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default FamilyHistory;
