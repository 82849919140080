import React, { useState,useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  Button,
  TextField,
} from "@material-ui/core";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import { ageCalculator, abbreviatedName } from "../../utils/helper";
import Avatar from "@material-ui/core/Avatar";
import debounce from 'lodash.debounce'
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "13px 15px",
    marginBottom: "2px",
    fontSize: "14px",
  },
}));

const FilterPatient = (props) => {
  const classes = useStyles();
  const { handleSubmit, errors, control } = useForm();
  const [filterBy, setFilterBy] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [patientId, setPatientId] = useState();
  const [nameOfPatient,setNameOfPatient] = useState('')



  const getPatientList = (name)=>{
    const params = {
      name: name,
    };
    let queryString = encodeGetParams(params);
    if (name) {
      getQueryRequest("search_patients", queryString).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setPatientList(data.data);
        }
      });
    }
    if (name === null || name === "") {
      setPatientList([]);
    }
  }

  const delayedRequest = useCallback(debounce(name=>getPatientList(name), 500), []);
  const handlePatientName = (name)=>{
    setNameOfPatient(name)
    delayedRequest(name)
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        data_cy = {`${props.test}-date-datepicker`}
        className={classes.input}
        type="text"
        readOnly={true}
      />
    );
  });

  const StartDate = () => {
    return (
      <React.Fragment>
        <Controller
          as={<ReactDatePicker />}
          error={Boolean(errors.start_date)}
          control={control}
          valueName="selected"
          onChange={([selected]) => selected}
          name="start_date"
          rules={{ required: "Start Date is required" }}
          filterDate={(date) => {
            return moment() > date;
          }}
          isClearable
          customInput={<CustomInput  test="start"/>}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          defaultValue=""
          dateFormat="yyyy/MM/dd "
          dropdownMode="select"
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
        {errors.start_date && (
          <div style={{ color: "red" }}>Start Date is required</div>
        )}
      </React.Fragment>
    );
  };
  const EndDate = () => {
    return (
      <React.Fragment>
        <Controller
          as={<ReactDatePicker />}
          error={Boolean(errors.end_date)}
          control={control}
          valueName="selected"
          onChange={([selected]) => selected}
          name="end_date"
          rules={{ required: "End Date is required" }}
          filterDate={(date) => {
            return moment() > date;
          }}
          isClearable
          customInput={<CustomInput test="end" />}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          defaultValue=""
          dateFormat="yyyy/MM/dd "
          dropdownMode="select"
          popperPlacement="bottom-end"
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
        {errors.end_date && (
          <div style={{ color: "red" }}>End Date is required</div>
        )}
      </React.Fragment>
    );
  };

  const handleReset = () => {
    setFilterBy("");
    setPatientId(undefined)
    props.setIsShowFilterData(false);
    props.setPatientListing([]);
  };

  const FilterButton = () => {
    return (
      <Button
      data-testid="filter-issue-button"
        variant="contained"
        color="secondary"
        type="submit"
      >
        Filter
      </Button>
    );
  };
  const ResetButton = () => {
    return (
      <Button
      data-testid="reset-filter-issue-button"
        variant="contained"
        style={{ backgroundColor: "#dcdde0", marginTop: "1%" }}
        onClick={handleReset}
      >
        Reset
      </Button>
    );
  };
  const getPatientId = (value) => {
    if (value) {
      setPatientId(value.attributes.id);
    }
  };

  const onSubmit = (data) => {
    if (filterBy === "by date") {
      props.setIsShowFilterData(true);
      props.handleFilterData(data, filterBy);
    } else {
       if (patientId === undefined) {
        alert("Please select Patient");
        return false;
      }
      props.setIsShowFilterData(true);
      props.handleFilterData(data, filterBy, patientId);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid container direction="row" item xs={12} sm={12} md={12}>
            <FormControl
              className={classes.formControl}
              style={{ minWidth: 125 }}
            >
              <InputLabel htmlFor="age-native-simple">Select By</InputLabel>
              <Select
                native
                inputProps={{
                  data_cy :`filterby-dropdown`,
                  name: "filterBy",
                  id: "filter",
                }}
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value)}
              >
                <option key={0} value="" />
                {filterOption.map((option) => (
                  <option 
                    data_cy ={`filterby-option-${option.key}`}
                    key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <br />
          {filterBy === "by date" && (
            <React.Fragment>
              <Grid spacing={1} container direction="row">
                <Grid container direction="column" item xs={6} sm={6} md={6}>
                  <label>Start Date : </label>
                  {StartDate()}
                </Grid>
                <Grid container direction="column" item xs={6} sm={6} md={6}>
                  <label>End Date : </label>
                  {EndDate()}
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-between"
                  xs={8}
                  sm={8}
                  md={6}
                >
                  <FilterButton />
                  <ResetButton />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {filterBy === "by patient" && (
            <React.Fragment>
              <Grid spacing={1} container direction="row">
                <Grid
                  style={{ marginTop: "2%" }}
                  container
                  direction="column"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Controller
                    as={
                      <Autocomplete
                        options={patientList}
                        getOptionLabel={(option) =>
                          option.attributes !== undefined
                            ? option.attributes.name
                            : "" || ""
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            <Avatar
                              src={option.attributes.profile_photo}
                              alt="pic"
                            />
                            <span>
                              {abbreviatedName(option.attributes.name)}
                            </span>
                            <span>
                              ({ageCalculator(option.attributes.dob)}/
                              {option.attributes.gender[0].toUpperCase()})
                            </span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              data_cy :`patient-name-input`
                            }}
                            onChange={(e) => handlePatientName(e.target.value)}
                            label="Select Patient"
                          />
                        )}
                      />
                    }
                    onChange={([, data]) => {
                      getPatientId(data);
                      return data;
                    }}
                    name="patient_id"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-between"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <FilterButton />
                  <ResetButton />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <br />
        </Grid>
      </form>
    </div>
  );
};

FilterPatient.propTypes = {
  className: PropTypes.string,
};

export default FilterPatient;

const filterOption = [
  { key: "by patient", value: "By Patient" },
  { key: "by date", value: "By Date" },
];
