import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useRouteMatch, Switch, Route } from "react-router-dom";
import axios from "utils/axios";
import UserList from "./UserList";
import ChatBox from "./ChatBox";
import ChatBoxImageHolder from "./ChatBoxImageHolder";
import SelectChatType from "../../components/SelectDataType";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "90vh",
    display: "flex",
    overflow: "hidden",
    marginLeft: 5,
    marginTop: 2,

    "@media (max-width: 863px)": {
      "& $userlist, & $NotificationDetails": {
        flexBasis: "100%",
        width: "100%",
        maxWidth: "none",
        flexShrink: "0",
        transform: "translateX(0)",
        height: "83vh",
      },
    },
  },
  openConversion: {
    "@media (max-width: 863px)": {
      "& $userlist, & $NotificationDetails": {
        transform: "translateX(-100%)",
      },
    },
  },
  userlist: {
    width: 300,
    flexBasis: 300,
    flexShrink: 0,
    "@media (min-width: 864px)": {
      borderRight: `1px solid #dcdada`,
    },
  },
  NotificationDetails: {
    flexGrow: 1,
  },
}));

const ChatByUsers = () => {
  const classes = useStyles();
  const [conversations, setConversations] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);

  useEffect(() => {
    let mounted = true;

    const fetchConversations = () => {
      axios.get("/api/chat/conversations").then((response) => {
        if (mounted) {
          setConversations(response.data.conversations);
        }
      });
    };

    fetchConversations();

    return () => {
      mounted = false;
    };
  }, []);

  let selectedConversation;
  if (selectedUser) {
    selectedConversation = conversations.find((c) => c.id === selectedUser);
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.openConversion]: selectedUser,
      })}
    >
      <UserList
        className={classes.userlist}
        selectedUser={selectedUser}
        conversations={conversations}
        setSelectedUser={setSelectedUser}
      />
      {selectedUser ? (
        <ChatBox
          className={classes.NotificationDetails}
          conversation={selectedConversation}
          setSelectedUser={setSelectedUser}
        />
      ) : (
        <ChatBoxImageHolder style={{ flexGrow: 1 }} />
      )}
    </div>
  );
};

const ChatSupport = () => {
  const query = new URLSearchParams(useLocation().search).get("type");

  if (query === "user") {
    return <ChatByUsers />;
  } else if (query === "store") {
    return <ChatByUsers />;
  } else {
    return <SelectChatType heading="" dataToShow={chatOption} />;
  }
};

const ChatSupportContainer = () => {
  let { path } = useRouteMatch();
  console.log(path);
  return (
    <Switch>
      <Route path={`${path}`}>
        <ChatSupport />
      </Route>
    </Switch>
  );
};

export default ChatSupportContainer;

const chatOption = [
  { displayName: "By User", submitValue: "user" },
  { displayName: "By Store", submitValue: "store" },
];
