import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import { getRequest } from "../../api/Api";
import Loader from "components/Loader/Loader";
import AllergyForm from "../../components/Forms/Allergy";
import EditIcon from "@material-ui/icons/Edit";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  table: {
    minWidth: 320,
  },
});

const useStyles = makeStyles(styles);

const AllergyVerticalTable = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [allergy, setAllergy] = useState([]);
  const [showAllergyForm, setShowAllergyForm] = useState(false);
  const [allergyCount, setAllergyCount] = useState();

  const fetchPatientAllergyById = () => {
    const endPoint = "get_patient_allergies_by_patient_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patient.patient.attributes.id];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAllergy(data.data);
      }
    });
  };
  useEffect(() => {
    if(!showAllergyForm){
    fetchPatientAllergyById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllergyForm]);

  useEffect(() => {
    if (patient.patientInfo) {
      if (patient.patientInfo.attributes.flagged_count.allergies > 0) {
        setAllergyCount(patient.patientInfo.attributes.flagged_count.allergies);
      } else {
        setAllergyCount(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patientInfo]);

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            {showAllergyForm ? (
              <AllergyForm
                patient={patient.patient}
                vitalLength={patient.vitalLength}
                medicalProblemLength={patient.medicalProblemLength}
                setShowAllergyForm={(value) => setShowAllergyForm(value)}
                correctionCountHandler={patient.correctionCountHandler}
                isReviewOnly={patient.isReviewOnly}
              />
            ) : (
              <Card
                style={{
                  boxShadow:
                    "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                }}
                data-testid={`allergy-detail-card`}
              >
                <CardHeader
                  style={{ width: "85px", marginTop: 0, marginLeft: 0 }}
                  color="success"
                >
                  <h4 className={classes.cardTitleWhite}>Allergy</h4>
                </CardHeader>
                {patient.patient.attributes.last_visit.is_sent_for_review ===
                  0 &&
                patient.patient.attributes.last_visit
                  .is_sent_to_patient_pool === 0 ? (
                  <Grid
                    item
                    container
                    justify="flex-end"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <EditIcon
                      data-testid="allergy-edit-icon"
                      onClick={() => setShowAllergyForm(true)}
                    />
                  </Grid>
                ) : null}
                <CardBody>
                  {allergyCount > 0 ? (
                    <Typography
                      align="center"
                      style={{ color: "red", fontSize: 15, fontWeight: 500 }}
                    >
                      Correction required
                    </Typography>
                  ) : null}
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            Observation
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allergy.map((allergy) => (
                          <TableRow hover key={allergy.attributes.id}>
                            <TableCell>
                              {allergy.attributes.observation}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default AllergyVerticalTable;
