import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Fab,
  Typography,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { getRequest } from "../../api/Api";
import { abbreviatedName } from "../../utils/helper";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 350,
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 300,
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 500,
    },
  },
}));

const PatientVistWiseData = ({ visitId, patient }) => {
  const classes = useStyles();
  const [visitData, setVisitData] = useState();
  const [prescriptionUrl, setPrescriptionUrl] = useState();
  const [cheifComplaintTitle, setCheifComplaintTitle] = useState("");
  console.log(visitId);

  useEffect(() => {
    let isMounted = true;
    if (visitId) {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest(`visits/${visitId}`, token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setVisitData(data.data);
            console.log(data);
          }
        }
      });
      getRequest(
        `get_prescription_by_visit_id/${patient.attributes.id}/${visitId}`,
        token
      ).then((data) => {
        if (data.error) {
          console.log(data.error);
          if (data.error === "Prescription has not yet been generated.") {
            setPrescriptionUrl(null);
          }
        } else {
          if (isMounted) {
            setPrescriptionUrl(data.prescription_pdf_url);
          }
        }
      });

      getRequest(
        `get_patient_medical_problems_by_visit_id/${patient.attributes.id}/${visitId}`,
        token
      ).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setCheifComplaintTitle(data.data[0].attributes.complaint_title);
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [patient, visitId]);

  const pdfopenHandler = (prescriptionurl) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    fetch(`${prescriptionurl}?prescription_url=1`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let link = document.createElement("a");
        link.setAttribute("rel", "noopener noreferrer");
        link.setAttribute("target", "about:blank");
        link.setAttribute("href", data.prescription_pdf_url);
        link.click();
      })
      .catch((error) => {
        if (error.response.data !== "") {
          alert(error.response.data.error);
        } else {
          alert(error.response.statusText);
        }
      });
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Doctor Name</TableCell>
              <TableCell>Visit Date</TableCell>
              <TableCell>Chief Complaint</TableCell>
              <TableCell align="right">Prescription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <div style={{ width: "100%", height: "100%" }}>
                  {abbreviatedName(
                    visitData 
                      ? visitData.attributes.doctor?.name
                      : ""
                  )}
                </div>
              </TableCell>
              <TableCell align="left">
                {visitData !== undefined ? moment.utc(visitData.attributes.visit_datetime)
                        .local()
                        .format("DD/MM/YYYY") : ""}
              </TableCell>
              <TableCell align="center">{cheifComplaintTitle}</TableCell>
              <TableCell align="right">
                {prescriptionUrl ? (
                  <Tooltip title="Prescription" aria-label="Prescription">
                    <Fab
                      onClick={() => pdfopenHandler(prescriptionUrl)}
                      className={classes.fab}
                    >
                      <AssignmentIcon />
                    </Fab>
                  </Tooltip>
                ) : (
                  <Typography>N.A</Typography>
                )}
              </TableCell>
            </TableRow>
            {/* ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PatientVistWiseData;
