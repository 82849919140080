import OT from "@opentok/client";
import axios from '../../utils/axios1'

const handleError = (error) => {
  if (error) {
    alert(error.message);
  }
};

let session, publisher, subscriber;

export const initializeSession = (userData) => {
  session = OT.initSession(userData.apiKey, userData.sessionId);
  publisher = OT.initPublisher(
    "publisher",
    {
      insertMode: "append",
      name: `${userData.name}`,
      style: { buttonDisplayMode: "off", nameDisplayMode: "on" },
      width: "100%",
      height: "100%",
    },
    handleError
  );
  session.on("streamCreated", (event) => {
    let vonageSession = localStorage.getItem('vonageSession')
    if(vonageSession){
    subscriber = session.subscribe(
      event.stream,
      "subscriber",
      {
        insertMode: "append",
        style: { buttonDisplayMode: "off" },
        width: "100%",
        height: "100%",
      },
      localStorage.setItem("vonageSession", ""),
      handleError
    );
    }
  });

  session.connect(userData.token, (error) => {
    if (error) {  
      handleError(error);
    } else {
      session.publish(publisher, handleError);
      console.log(userData.visitId)
      let jwt = localStorage.getItem("jwt")
      if (jwt && session.connection.connectionId) {
        axios.put(`/update_vonage_connections/${userData.visitId}`,
            {
              connection_type: "mso",
              connection_id:session.connection.connectionId
            },
            { headers: { Authorization: "Bearer " + jwt } }
          )
          .then((response) => {
            // alert(response.data.message);   
          })
          .catch((error) => {
            if (error.response.data !== "") {
              alert(error.response.data.error);
            } else {
              alert(error.response.statusText);
            }
          });
      }
    }
  });
};

export const stopStreaming = () => {
  console.log(session);
  session && session.unpublish(publisher);
};

export const toggleVideo = (state) => {
  publisher.publishVideo(state);
};
export const toggleAudio = (state) => {
  publisher.publishAudio(state);
};
export const toggleAudioSubscribtion = (state) => {
  console.log(subscriber);
  subscriber.subscribeToAudio(state);
};
export const toggleVideoSubscribtion = (state) => {
  console.log(subscriber);
  subscriber.subscribeToVideo(state);
};
