import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";

import Loader from "../Loader/Loader";
import { getRequest } from "../../api/Api";
import { abbreviatedName, ageCalculator } from "../../utils/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  itemDivider: {
    borderBottomColor: "rgb(175 157 157 / 20%)",
  },
}));

const IssueList = (props) => {
  const { setSelectedIssueId, setSelectedIssueData } = props;
  const [issuelistingLoader, setIssueLoader] = useState(false);
  const [issueList, setIssueList] = useState([]);

  const classes = useStyles();
  useEffect(() => {
    setIssueLoader(true);
    const token = "Bearer " + localStorage.getItem("jwt");
    const Endpoint = `issues?filter_by=raised_issues`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
        setIssueLoader(false);
      } else {
        setIssueList(data.data.data);
        setIssueLoader(false);
      }
    });
  }, []);
  return (
    <div className={classes.root}>
      {issuelistingLoader ? (
        <Loader />
      ) : (
        <CardContent className={classes.content}>
          <List className={classes.list}>
            {issueList.map((issue, i) => (
              <ListItem
                classes={{ divider: classes.itemDivider }}
                divider={i < issueList.length - 1}
                data-testid={`issue-list-${issue.attributes.id}`}
                key={issue.attributes.id}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{ fontWeight: 600, fontSize: 14, marginTop: "4%" }}
                    >
                      {issue.attributes.issue_type.value}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        type="body2"
                        style={{
                          color: "#272829",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        Patient :
                        <span
                          style={{
                            color: "#272829",
                            fontSize: 13,
                            fontWeight: 500,
                          }}
                        >
                          {abbreviatedName(issue.attributes.patient.name)}
                        </span>
                        <span
                          style={{
                            fontWeight: 400,
                            color: "rgb(123 123 123)",
                            fontSize: 13,
                            marginLeft: "2%",
                          }}
                        >
                          ({ageCalculator(issue.attributes.patient.dob)}/
                          {issue.attributes.patient.gender[0].toUpperCase()})
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#272829",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        Status : {issue.attributes.status.value}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "rgb(123 123 123)",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        Created on :
                        {moment(issue.attributes.created_at).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#272829",
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        Resolution Status :{" "}
                        {issue.attributes.resolution_current_status === null
                          ? "N.A"
                          : issue.attributes.resolution_current_status.value}
                      </Typography>
                      <br />
                    </React.Fragment>
                  }
                />
                <div>
                  <Button
                   data-testid={issue.attributes.resolution_current_status !== null
                    ? issue.attributes.resolution_current_status.key === 2
                      ? `view-issue-detail-button`
                      : `add-issue-detail-button`
                    : `add-issue-detail-button`}
                    onClick={() => {
                      setSelectedIssueId(issue.attributes.id);
                      setSelectedIssueData(
                        issue.attributes.resolution_current_status
                      );
                    }}
                  >
                    {issue.attributes.resolution_current_status !== null
                      ? issue.attributes.resolution_current_status.key === 2
                        ? `View Detail`
                        : `Add Detail`
                      : `Add Detail`}
                  </Button>
                </div>
              </ListItem>
            ))}
          </List>
        </CardContent>
      )}
    </div>
  );
};

IssueList.propTypes = {
  className: PropTypes.string,
};

export default IssueList;
