import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchCriteria from "../../components/FilterData";
import TransactionDetail from "./TransactionData";
import SecurityAmount from "./SecurityAmount";
import ShowCurrentBalance from "../../components/ShowCurrentBalance";
import { getRequest } from "../../api/Api";
import Loader from "../../components/Loader/Loader";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import { ToastContainer } from "react-toastify";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  searchHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
    },
  },
  transactionHeading: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "1%",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      marginTop: "1%",
    },
  },
  toggler: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: 10,
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      marginTop: 10,
    },
  },
});

const useStyles = makeStyles(styles);

const AccountingDetail = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [msoTransaction, setMsoTransaction] = useState([]);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [totalCountOfTransaction, setTotalCountOfTransaction] = useState();
  const [user, setUser] = useState();
  const [clearCounter, setClearCounter] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isHidePagination, setIsHidePagination] = useState(true);
  const [selectedDates, setSelectedDates] = useState("");

  useEffect(() => {
    let isMounted = true;

    const fetchUserDetail = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("current_user", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setUser(data.data);
          }
        }
      });
    };

    const fetchMsoTransaction = () => {
      // setIsLoadingTransaction(true);
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("mso_transactions", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setTotalCountOfTransaction(data.total_rows);
            // setIsLoadingTransaction(false);
            setIsHidePagination(true);
            setMsoTransaction(data.data.data);
          }
        }
      });
    };
    fetchMsoTransaction();
    fetchUserDetail();
    return () => {
      isMounted = false;
    };
  }, [clearCounter]);

  const handleChangePage = (event, page) => {
    setPage(page);
    let params = {};
    params.from_date = selectedDates ? selectedDates.start_date : "";
    params.to_date = selectedDates ? selectedDates.end_date : "";
    params.page = page + 1;
    params.items = rowsPerPage;
    let queryString = encodeGetParams(params);
    getQueryRequest("mso_transactions", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setMsoTransaction(data.data.data);
        setIsHidePagination(true);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    let params = {};
    params.page = page + 1;
    params.from_date = selectedDates ? selectedDates.start_date : "";
    params.to_date = selectedDates ? selectedDates.end_date : "";
    params.items = event.target.value;
    let queryString = encodeGetParams(params);

    getQueryRequest("mso_transactions", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setMsoTransaction(data.data.data);
        setIsHidePagination(true);
      }
    });
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
    if (checked) {
      setIsLoadingTransaction(true);
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("mso_transactions", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTotalCountOfTransaction(data.total_rows);
          setMsoTransaction(data.data.data);
          setIsHidePagination(true);
          setIsLoadingTransaction(false);
        }
      });
    }
  };

  const clearCounterHandler = () => {
    setSelectedDates("");
    setClearCounter(clearCounter + 1);
  };

  const searchTransactionHandler = (data) => {
    setSelectedDates(data);
    setIsLoadingTransaction(true);
    const token = "Bearer " + localStorage.getItem("jwt");
    let Endpoint = `mso_transactions?from_date=${data.start_date}&to_date=${data.end_date}`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setMsoTransaction(data.data.data);
        setTotalCountOfTransaction(data.total_rows);
        setIsHidePagination(true);
        setIsLoadingTransaction(false);
      }
    });
  };

  const downloadCsvHandler = (data) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    let Endpoint = `mso_transactions?download=1&from_date=${data.start_date}&to_date=${data.end_date}`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setIsHidePagination(false);
        setMsoTransaction(data.data);
      }
    });
  };

  return (
    <Paper>
      <div className={classes.root}>
        <Grid container>
          <ShowCurrentBalance user={user} />
          {!checked && (
            <Grid item xs={12} sm={12} md={9} className={classes.searchHeading}>
              <SearchCriteria
                sendData={(value) => searchTransactionHandler(value)}
                clearCounterHandler={clearCounterHandler}
                downLoadCsv={(value) => downloadCsvHandler(value)}
                buttonText=" Search"
                headingText="Search Transaction"
                testing="transaction"
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            container
            className={classes.toggler}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    name="showSecurity"
                    inputProps={{
                      data_cy: "show-security-input",
                    }}
                    color="primary"
                  />
                }
                label={"Show Security"}
              />
            </FormGroup>
          </Grid>
          {!checked ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                className={classes.transactionHeading}
              >
                <Typography variant="h4">My Transactions</Typography>
              </Grid>
              {isLoadingTransaction ? (
                <Loader />
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <TransactionDetail
                    tableHead={headCells}
                    tableData={msoTransaction}
                    checked={checked}
                    isHidePagination={isHidePagination}
                  />
                  {isHidePagination && (
                    <TablePagination
                      component="div"
                      count={parseInt(totalCountOfTransaction)}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[2, 10, 25, 50]}
                    />
                  )}
                </Grid>
              )}
            </>
          ) : (
            <SecurityAmount user={user} />
          )}
        </Grid>
        <ToastContainer />
      </div>
    </Paper>
  );
};

export default AccountingDetail;

const headCells = [
  { key: "transactionDate", label: "Transaction Date" },
  { key: "transactionBy", label: "Transaction By" },
  { key: "transactionType", label: "Transaction Type" },
  { key: "particular", label: "Particular" },
  { key: "promocode", label: "Promo code" },
  { key: "amount", label: "Amount" },
  { key: "status", label: "Status" },
  { key: "currentBalance", label: "Current Balance" },
];
