import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper } from "@material-ui/core";
import { getRequest } from "../../api/Api";
import { toast } from "react-toastify";
import { putRequest } from "../../api/Api";
import InputBox from "../Chat/InputBox";
import SendButton from "../Chat/SendButton";
import { blockButton } from '../../utils/helper'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
  },
}));

const AddDetailToIssueForm = (props) => {
  const classes = useStyles();
  const { selectedIssueId, resetPatientId } = props;
  const [description, setDescription] = useState([]);
  const [isssueFormData, setisssueFormData] = useState();

  useEffect(() => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const Endpoint = `issues/${selectedIssueId}`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setisssueFormData(data.data);
      }
    });
  }, [selectedIssueId]);

  const onSubmit = (data) => {
    var formData = new FormData();
    formData.append("issue[patient_id]", isssueFormData.attributes.patient.id);
    formData.append("issue[visit_id]", isssueFormData.attributes.visit.id);
    formData.append(
      "issue[issue_type]",
      isssueFormData.attributes.issue_type.key
    );
    formData.append(
      `issue[issue_descriptions_attributes][0][description]`,
      description
    );
    blockButton('adddetail',true);
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "issues",
      formData: formData,
      params: [isssueFormData.attributes.id],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        blockButton('adddetail',false);
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        resetPatientId();
      }
    });
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                  <InputBox
                    inputProps={{
                      data_cy : `resolution-description-input`
                    }}
                    changed={(e) => setDescription(e.target.value)}
                    placeholder="Type issue here"
                  />
                </Paper>
                <SendButton data-testid={`send-resolution-button`} id="adddetail" description={description} changed={onSubmit} />
              </div>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddDetailToIssueForm;
