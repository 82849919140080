import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import HistoryIcon from "@material-ui/icons/History";
import PercentageCard from "../PromoCode/PercentageCard/PercentageCard";
import RedeemIcon from "@material-ui/icons/Redeem";
import { Link as RouterLink } from "react-router-dom";
import HistoryModal from "../PromoCode/History/History";
import { getRequest } from "../../api/Api";
import { ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  buttonsPlacement: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  cardPlacement: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "6%",
    },
    [theme.breakpoints.up("sm")]: {},
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 10,
    },
  },
}));

const PromoCode = (props) => {
  const classes = useStyles();

  const [promo, setPromo] = useState();
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [promoCount, setPromoCount] = useState(0);

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleCount = () => {
    setPromoCount(promoCount + 1);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchPromocodes = () => {
      const endPoint = "get_active_promo_code";
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest(endPoint, token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setPromo(data.data);
          }
        }
      });
    };

    fetchPromocodes();
    return () => {
      isMounted = false;
    };
  }, [promoCount]);

  return (
    <div className={classes.root}>
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          className={classes.buttonsPlacement}
          direction="row"
        >
          <Button
            color="primary"
            className={classes.button}
            size="small"
            variant="outlined"
            data-testid="create-promocode-button"
            component={RouterLink}
            to="/create_promocode"
          >
            <RedeemIcon /> Create Promo Code
          </Button>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={handlePricingOpen}
            data-testid="view-promocode-history-button"
          >
            <HistoryIcon /> Promo Code History
          </Button>
        </Grid>
          <ToastContainer/>
        <Grid item md={3} sm={4} xs={12} className={classes.cardPlacement}>
          <PercentageCard handleCount={handleCount} promo={promo} />
        </Grid>
      </Grid>
      {promo === null || promo === undefined ? null : (
        <HistoryModal
          onClose={handlePricingClose}
          open={pricingModalOpen}
          promoId={promo.attributes.id}
        />
      )}
    </div>
  );
};
export default PromoCode;
