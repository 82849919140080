import React from "react";
import {
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "../../utils/axios1";
import Switch from "@material-ui/core/Switch";

const handleModeChange = (value, onModeChange) => {
  console.log(value);
  console.log("handleModeChange is being run");
  const user = {
    is_practice_mode: value,
  };
  if (localStorage.getItem("jwt")) {
    let token = "Bearer " + localStorage.getItem("jwt");
    let id = localStorage.getItem("user");
    axios
      .put(
        `/toggle_live_practice_mode/${id} `,
        { user: user },
        { headers: { Authorization: token } },
      )
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("mode", value);
          onModeChange(value);
        } else {
          console.error("Call the ADMIN NOW!!!!");
        }
      })
      .catch(error => {
        console.error(error);
        // implicit nesting here can be problematic
        if (error.response.data !== "") {
          toast.error(<p>{error.response.data.error}</p>, {
            autoClose: 3000,
          });
        } else {
          alert(error.response.statusText);
        }
      });
  }
};

export const ModeSelectDropdown = ({ msoMode, onModeChange, style }) => {
  return (
    <FormControl
      variant="outlined"
      style={{ borderWidth: "1px", borderRadius: "2", borderColor: "black" }}
    >
      <Select
        native
        inputProps={{
          data_cy :`mso-mode-change-${msoMode}-dropdown`
        }}
        value={msoMode}
        onChange={e => handleModeChange(parseInt(e.target.value), onModeChange)}
        style={style}
      >
        {mode.map(option => (
          <option
            style={{ color: "black" }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const ModeSelectToggle = ({ msoMode, onModeChange }) => {
  console.log(`rendering toggle with ${msoMode}`);
  console.log(`and type of ${typeof msoMode}`);
  console.log(msoMode);
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={!!msoMode}
            onChange={() => handleModeChange(msoMode ? 0 : 1, onModeChange)}
            name="modeChange"
            color="primary"
          />
        }
        label={"Practice Mode"}
      />
    </FormGroup>
  );
};

const mode = [
  {
    value: 0,
    label: "Live Mode",
  },
  {
    value: 1,
    label: "Practice Mode",
  },
];
