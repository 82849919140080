import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import SearchBar from "../../components/SearchBar/SearchBar";
import ProjectCard from "./AppointmentCard/AppointmentCard";
import PreBookingCard from "./PreBookingCard/PreBookingCard";
import Loader from "../../components/Loader/Loader";
import NoData from "../../assets/img/nodata.png";
import { useForm, Controller } from "react-hook-form";
import SearchIcon from "@material-ui/icons/Search";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getRequest } from "../../api/Api";
import { encodeGetParams } from "../../utils/encodeParams";
import { ToastContainer } from "react-toastify";

import { getQueryRequest } from "../../api/QueryRequest";
import {
  Typography,
  Button,
  TablePagination,
  TextField,
} from "@material-ui/core";
import AppointmentStatusChange from "./ChangeAppointmentStatus";

import BookIcon from "@material-ui/icons/Book";

const styles = (theme) => ({
  root: {
    // padding: "16px",
  },
  results: {
    marginTop: -8,
    float: "right",
  },
  results1: {
    marginTop: -12,
  },
  paginate: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #d8d3d3",
    padding: "15px 15px",
    marginTop: -6,
    fontSize: "14px",
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  customTab: {
    [theme.breakpoints.up("sm")]: {
      width: "46%",
    },
    [theme.breakpoints.up("md")]: {
      width: "35%",
    },
  },
  doctorSearch: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: -10,
    },
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -2,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: -5,
    },
  },
});
const useStyles = makeStyles(styles);

const AppointmentList = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [appointments, setAppointments] = useState([]);
  const [preAppoinments, setPreAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [count, setCount] = useState(0);
  
  const { handleSubmit, errors, control,watch } = useForm();
  const doctorName = watch('doctor')
  const [totalCount, setTotalCount] = useState();
  const [appointmentStatusModalOpen, setAppointmentStatusModalOpen] = useState(
    false
  );
  const [updateAppointmentData, setUpdateAppointmentData] = useState();

  const handleChangePage = (event, page) => {
    setPage(page);
    const params = {
      page: page + 1,
      items: rowsPerPage,
    };
    let queryString = encodeGetParams(params);
    getQueryRequest("appointments", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAppointments(data.data.data);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);

    const params = {
      page: page + 1,
      items: event.target.value,
    };
    let queryString = encodeGetParams(params);
    getQueryRequest("appointments", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAppointments(data.data.data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchAppointment = () => {
      setLoading(true);
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("appointments", token).then((data) => {
        if (data.error) {
          console.log(data.error);
          setLoading(false);
        } else {
          if (isMounted) {
            setAppointments(data.data.data);
            setTotalCount(data.total_rows);
            setLoading(false);
          }
        }
      });
    };
    const fetchDoctors = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_doctors_dropdown", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setDoctors(data);
          }
        }
      });
    };
    const fetchPreAppointment = () => {
      setLoading(true);
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("pre_booking_list", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setPreAppointments(data.data.data);
            setLoading(false);
          }
        }
      });
    };
    fetchAppointment();
    fetchDoctors();
    fetchPreAppointment();
    return () => {
      isMounted = false;
    };
  }, [count]);

  const handleCount = () => {
    setCount(count + 1);
  };

  const handleAppointmentStatusOpen = (appointmentId, patientId) => {
    setUpdateAppointmentData({
      appointmentId: appointmentId,
      patientId: patientId,
    });
    setAppointmentStatusModalOpen(true);
  };

  const handleAppointmentStatusClose = () => {
    setAppointmentStatusModalOpen(false);
  };

  useEffect(() => {
    setFilteredPatients(
      appointments.filter((project) => {
        return project.attributes.patient.name
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    );
  }, [search, appointments]);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        data_cy = {`start-date-datepicker`}
        type="text"
        placeholder="Select Start Date *"
        readOnly={true}
      />
    );
  });

  const EndCustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        data_cy = {`end-date-datepicker`}
        type="text"
        placeholder="Select End Date *"
        readOnly={true}
      />
    );
  });
  const onSubmit = (data) => {
    if (data.start_date !== undefined) {
      if (data.end_date === undefined) {
        alert("Please select end Date");
        return false;
      }
    }
    if (data.start_date !== undefined) {
      var startDate = moment(data.start_date).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }
    if (data.end_date !== undefined) {
      var endDate = moment(data.end_date).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }
    if (data.doctor.id !== undefined) {
      var doctorId = data.doctor.id;
    } else {
      doctorId = "";
    }

    const params = {
      page: "1",
      items: "10",
      start_date: startDate,
      end_date: endDate,
      doctor_id: doctorId,
    };
    let queryString = encodeGetParams(params);
    getQueryRequest("pre_booking_list", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPreAppointments(data.data.data);
      }
    });
  };

  return (
    <div className={classes.root} style={{ marginTop: "9px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CustomTabs
              className={classes.customTab}
              // style={{
              //   width: "35%",
              //   padding: 1,
              // }}
              headerColor="success"
              tabs={[
                {
                  tabName: "Current Booking",
                  test:"navigate-to-current-booking",
                  tabIcon: BookIcon,
                  tabContent: (
                    <React.Fragment>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <SearchBar
                              datatestid="search-appointment-input"
                                onSearch={(e) => setSearch(e.target.value)}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              container
                              justify="flex-end"
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              {/* <Link
                                color="inherit"
                                component={RouterLink}
                                style={{ marginBottom: "1%" }}
                                // to="/"
                                variant="h6"
                              >
                                <Tooltip
                                  title="Book Appointment"
                                  aria-label="Book Appointment"
                                >
                                  <Fab className={classes.fab}>
                                    <BookIcon />
                                  </Fab>
                                </Tooltip>
                              </Link> */}
                            </Grid>
                            <br />
                            <Grid
                              container
                              justify="flex-end"
                              item
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <div className={classes.results1}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="body2"
                                >
                                  {filteredPatients.length} Records found. Page{" "}
                                  {page + 1} of{" "}
                                  {Math.ceil(
                                    filteredPatients.length / rowsPerPage
                                  )}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              sx={12}
                              sm={12}
                              md={12}
                              item
                              container
                              justify="center"
                            >
                              {filteredPatients.length === 0 && (
                                <div>
                                  <img
                                    src={NoData}
                                    style={{ width: 300 }}
                                    alt="nodata"
                                  />
                                </div>
                              )}
                            </Grid>
                            {filteredPatients.map((project) => (
                              <Grid
                                item
                                key={project.id}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <ProjectCard
                                  handleCount={handleCount}
                                  key={project.id}
                                  project={project}
                                  handleAppointmentStatusOpen={
                                    handleAppointmentStatusOpen
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>

                          <div className={classes.paginate}>
                            <TablePagination
                              component="div"
                              count={parseInt(totalCount)}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              rowsPerPageOptions={[2, 10, 25, 50, 100]}
                            />
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ),
                },
                {
                  tabName: "Pre Booking",
                  test:"navigate-to-pre-booking",
                  tabIcon: TurnedInNotIcon,
                  tabContent: (
                    <React.Fragment>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={1} md={1}>
                                <p style={{ marginTop: 11, marginLeft: 10 }}>
                                  {" "}
                                  Filter by :
                                </p>
                              </Grid>
                              <Grid item xs={6} sm={2} md={2}>
                                <Controller
                                  as={<ReactDatePicker />}
                                  error={Boolean(errors.start_Date)}
                                  control={control}
                                  valueName="selected"
                                  onChange={([selected]) => selected}
                                  name="start_date"
                                  isClearable
                                  customInput={<CustomInput />}
                                  peekNextMonth
                                  minDate={moment().toDate()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dateFormat="yyyy/MM/dd "
                                  dropdownMode="select"
                                  popperPlacement="bottom-start"
                                />
                              </Grid>
                              <Grid item xs={6} sm={2} md={2}>
                                <Controller
                                  as={<ReactDatePicker />}
                                  error={Boolean(errors.end_Date)}
                                  control={control}
                                  valueName="selected"
                                  onChange={([selected]) => selected}
                                  name="end_date"
                                  minDate={moment().toDate()}
                                  isClearable
                                  customInput={<EndCustomInput />}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dateFormat="yyyy/MM/dd "
                                  dropdownMode="select"
                                  popperPlacement="bottom-start"
                                />
                              </Grid>
                              <Grid item xs={12} sm={2} md={2}>
                                <Controller
                                  as={
                                    <Autocomplete
                                      options={doctors}
                                      getOptionLabel={(option) =>
                                        option.name || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          className={classes.doctorSearch}
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            data_cy :`doctor-name-dropdown`
                                          }}
                                          label="Select Doctor"
                                          variant="outlined"
                                          style={{
                                            backgroundColor: "white",
                                          }}
                                        />
                                      )}
                                    />
                                  }
                                  onChange={([, data]) => {
                                    return data;
                                  }}
                                  name="doctor"
                                  control={control}
                                  defaultValue=""
                                />
                              </Grid>
                              <Grid item xs={12} sm={2} md={2}>
                                <Button
                                  variant="contained"
                                  data-testid="search-appointment-button"
                                  type="submit"
                                  style={{ height: "100%" }}
                                  color="secondary"
                                  className={classes.searchButton}
                                  startIcon={<SearchIcon />}
                                >
                                  Search
                                </Button>
                              </Grid>
                              <Grid
                                sx={12}
                                sm={12}
                                md={12}
                                item
                                container
                                justify="center"
                              >
                                {preAppoinments.length === 0 && (
                                  <div>
                                    <img
                                      src={NoData}
                                      style={{ width: 300 }}
                                      alt="nodata"
                                    />
                                  </div>
                                )}
                              </Grid>
                              {preAppoinments.map((project) => (
                                <Grid
                                  item
                                  key={project.id}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <PreBookingCard
                                    key={project.id}
                                    project={project}
                                    handleCount={handleCount}
                                    handleAppointmentStatusOpen={
                                      handleAppointmentStatusOpen
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>

                            <div className={classes.paginate}>
                              <TablePagination
                                component="div"
                                count={preAppoinments.length}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              />
                            </div>
                          </form>
                        </div>
                      )}
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Grid>
          <AppointmentStatusChange
            open={appointmentStatusModalOpen}
            onClose={handleAppointmentStatusClose}
            handleCount={handleCount}
            updateAppointmentData={updateAppointmentData}
          />
        </Grid>
      )}
      <ToastContainer />
    </div>
  );
};

export default AppointmentList;
