import React, { useState, useRef, useEffect } from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import "./Accordian.css";
const Accordion = props => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active ? `150px` : `0px`;
  }, [contentRef, active]);

  const toogleActive = () => {
    setActive(!active);
    if(typeof props.onPress === "function"){
      props.onPress();
    }
  };
  const titleStyle = {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 3,
    color:props.color ?? "#111213"
  };

  return (
    <div className="accordion-section">
      <button className="accordion-title" onClick={() => toogleActive()}>
        <p style={titleStyle}>{props.title}</p>
        <span className={active ? "accordion-icon rotate" : "accordion-icon"}>
          {props.icon ? props.icon : <ArrowRightIcon />}
        </span>
      </button>

      <div ref={contentRef} className="accordion-content">
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
