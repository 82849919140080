import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import { getRequest } from "../../api/Api";
import Loader from "components/Loader/Loader";
import EditIcon from "@material-ui/icons/Edit";
import DocumentUpload from "../../components/FormsTable/Documents";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  icon: {
    cursor: "pointer",
  },
  fab: {
    margin: 2,
    backgroundColor: "#66a668",
    width: 50,
    height: 42,
  },
  table: {
    minWidth: 320,
  },
});

const useStyles = makeStyles(styles);

const DocumentVerticalTable = (props) => {
  const classes = useStyles();
  const { patient } = props;
  const [documents, setDocument] = useState([]);
  const [showdocumentsForm, setShowdocumentsForm] = useState(false);
  const [documentCount, setDocumentCount] = useState();

  const fetchPatientDocumentsById = () => {
    const endPoint = "get_patient_documents_by_visit_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patient.patient.attributes.id, patient.visitId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setDocument(data.data);
      }
    });
  };
  useEffect(() => {
    fetchPatientDocumentsById();
    if (patient.patient.attributes !== undefined) {
      if (patient.patient.attributes.flagged_count.documents > 0) {
        setDocumentCount(patient.patient.attributes.flagged_count.documents);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showdocumentsForm]);
  return (
    <Fragment>
      {documents ? (
        <div className={classes.root}>
          <Grid>
            <Grid item xs={12} sm={12} md={12}>
              {showdocumentsForm ? (
                <DocumentUpload
                  vitalLength={patient.vitalLength}
                  medicalProblemLength={patient.medicalProblemLength}
                  patient={patient.patient}
                  setShowdocumentsForm={(value) => setShowdocumentsForm(value)}
                  isReviewOnly={patient.isReviewOnly}
                />
              ) : (
                <Card
                  style={{
                    boxShadow:
                      "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                  }}
                  data-testid={`document-detail-card`}
                >
                  <CardHeader
                    style={{ width: "105px", marginTop: 0, marginLeft: 0 }}
                    color="success"
                  >
                    <h4 className={classes.cardTitleWhite}>Documents</h4>
                  </CardHeader>
                  {patient.patient.attributes.last_visit.is_sent_for_review ===
                    0 &&
                  patient.patient.attributes.last_visit
                    .is_sent_to_patient_pool === 0 ? (
                    <Grid
                      item
                      container
                      justify="flex-end"
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <EditIcon
                        data-testid="documents-edit-icon"
                        onClick={() => setShowdocumentsForm(true)}
                      />
                    </Grid>
                  ) : null}
                  <CardBody>
                    {documentCount > 0 ? (
                      <Typography
                        align="center"
                        style={{ color: "red", fontSize: 15, fontWeight: 500 }}
                      >
                        Correction required
                      </Typography>
                    ) : null}
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ backgroundColor: "white" }}>
                              Document Type
                            </TableCell>
                            <TableCell style={{ backgroundColor: "white" }}>
                              Document
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documents.map((doc) => (
                            <TableRow hover key={doc.id}>
                              <TableCell>
                                {doc.attributes.document_type}
                              </TableCell>
                              <TableCell>
                                <a
                                  href={doc.attributes.document}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  view
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardBody>
                </Card>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default DocumentVerticalTable;
