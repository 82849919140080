import API from "../utils/baseUrl";
import { slackDebugger } from "../utils/slackDebbuger";
import { errorResponsesArray } from "../utils/helper";
/************************************* GET REQUEST ******************************************/

export const getRequest = (endPoint, token, params) => {
  let url;
  if (params !== undefined) {
    var s = "";
    for (var i = 0; i < params.length; i++) {
      s += "/" + params[i];
    }
    url = `${API}/${endPoint}` + s;
  } else {
    url = `${API}/${endPoint}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `${token}`,
    },
  })
    .then((response) => {
      if (errorResponsesArray.includes(parseInt(response.status))) {
        slackDebugger({
          actionType: "errorLogging",
          errorMessage: response.statusText,
          endPoint: endPoint,
          errorCode: response.status,
          method: "GET",
        });
      }
      if (response.status === 200 || response.status === 422) {
        return response.json();
      } else {
        return new Promise((resolve, reject) => {
          resolve({ error: response.statusText });
        });
      }
    })
    .catch((err) => console.log(err));
};

/************************************* POST REQUEST ******************************************/

export const postRequest = (body) => {
  let url;
  url = `${API}/${body.endPoint}`;
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `${body.token}`,
    },
    body: body.formData,
  })
    .then((response) => {
      if (errorResponsesArray.includes(parseInt(response.status))) {
        slackDebugger({
          actionType: "errorLogging",
          errorMessage: response.statusText,
          endPoint: body.endPoint,
          errorCode: response.status,
          method: "POST",
        });
      }
      if (response.status === 201 || response.status === 422) {
        return response.json();
      } else {
        return new Promise((resolve, reject) => {
          resolve({ error: response.statusText });
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

/************************************* PUT REQUEST ******************************************/

export const putRequest = (body) => {
  let url;
  if (body.params !== undefined) {
    var s = "";
    for (var i = 0; i < body.params.length; i++) {
      s += "/" + body.params[i];
    }
    url = `${API}/${body.endPoint}` + s;
  } else {
    url = `${API}/${body.endPoint}`;
  }
  return fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `${body.token}`,
    },
    body: body.formData,
  })
    .then((response) => {
      if (errorResponsesArray.includes(parseInt(response.status))) {
        slackDebugger({
          actionType: "errorLogging",
          errorMessage: response.statusText,
          endPoint: body.endPoint,
          errorCode: response.status,
          method: "PUT",
        });
      }
      if (response.status === 200 || response.status === 422) {
        return response.json();
      } else {
        return new Promise((resolve, reject) => {
          resolve({ error: response.statusText });
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
