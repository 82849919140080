import { colors } from "@material-ui/core";

const buildGradient = (start, end) =>
  `linear-gradient(180deg, ${start} 0%, ${end} 100%)`;

const grey = buildGradient(colors.grey[400], colors.grey[600]);
const blue = buildGradient(colors.blue[500], colors.blue[400]);
const indigo = buildGradient(colors.indigo[400], colors.indigo[600]);
const green = buildGradient(colors.green[400], colors.green[600]);
const orange = buildGradient(colors.orange[400], colors.orange[700]);
const red = buildGradient(colors.red[500], colors.red[700]);
const custom = buildGradient("#4e606f", "#206dab");
const table = buildGradient("#f9f9f9", "#f1f1f1");

export default {
  grey,
  blue,
  indigo,
  green,
  orange,
  red,
  custom,
  table,
};
