import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import "react-vertical-timeline-component/style.min.css";
import TimelineModal from "./TimelineData/TimelineModal";
import { Link, Typography } from "@material-ui/core";
import moment from "moment";
import { getRequest } from "../../../api/Api";

const Timeline = (props) => {
  const [timelineModalOpen, setTimelineModalOpen] = useState(false);
  const [visitIdTimeline, setVisitIdTimeline] = useState();
  const [vitalData, setVitalData] = useState([]);
  const [medicalProblemData, setmedicalProblemData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [visitDateTimeline, setVisitDateTimeline] = useState();

  const { patient } = props;

  const fetchPatientVitalsById = (patientId, visitId) => {
    const endPoint = "get_patient_vitals_by_visit_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patientId, visitId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setVitalData(data.data);
      }
    });
  };

  const fetchMedicalProblemInfo = (patientId, visitId) => {
    const endPoint = "get_patient_medical_problems_by_visit_id";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patientId, visitId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data.data);
        setmedicalProblemData(data.data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchTimelineData = () => {
      const endPoint = `get_patient_timelines?patient_id=${patient.attributes.id}&visit_id=${patient.attributes.last_visit_id}`;
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTimelineData(data);
        }
      });
    };
    fetchTimelineData();
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimelineOpen = (patientId, visitId, date) => {
    setTimelineModalOpen(true);
    setVisitIdTimeline(visitId);
    fetchPatientVitalsById(patientId, visitId);
    setVisitDateTimeline(date);
    fetchMedicalProblemInfo(patientId, visitId);
  };

  const handleTimelineClose = () => {
    setTimelineModalOpen(false);
  };

  return (
    <div>
      <React.Fragment>
        {timelineData.length !== 0 ? (
          <VerticalTimeline style={{ marginTop: -10 }}>
            {timelineData.map((item) => (
              <VerticalTimelineElement
                key={item.id}
                data-testid={`timeline-data-view-link-${moment
                  .utc(item.visit_datetime)
                  .local()
                  .format("Do MMM")}`}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#4c80d8",
                  color: "white",
                  fontSize: 3,
                  borderRadius: "50%",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                  marginTop: -7,
                }}
                layout="1-column-right"
                iconStyle={{
                  background: "rgb(50 73 95)",
                  color: "#fff",
                  width: 37,
                  height: 37,
                  left: "57%",
                }}
                icon={<FileCopyIcon />}
              >
                <Link
                  color="inherit"
                  data-testid={`timeline-data-view-link-${moment
                    .utc(item.visit_datetime)
                    .local()
                    .format("Do MMM")}`}
                  variant="h6"
                  style={{
                    fontSize: 12,
                    marginLeft: "21%",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleTimelineOpen(
                      patient.attributes.id,
                      item.id,
                      item.visit_datetime
                    )
                  }
                >
                  {moment
                    .utc(item.visit_datetime)
                    .local()
                    .format("Do MMM YYYY")}
                </Link>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        ) : (
          <div
            style={{
              position: "absolute",
              width: 280,
              height: 200,
              left: "32%",
              top: "42%",
            }}
          >
            <Typography
              style={{
                fontSize: 15,
                fontWeight: 400,
                color: "rgb(149, 154, 162)",
              }}
            >
              First Visit
            </Typography>
          </div>
        )}
      </React.Fragment>
      <TimelineModal
        patientId={patient.attributes.id}
        vitalData={vitalData}
        visitId={visitIdTimeline}
        onClose={handleTimelineClose}
        open={timelineModalOpen}
        medicalProblemData={medicalProblemData}
        displayDate={visitDateTimeline}
      />
    </div>
  );
};

export default Timeline;
