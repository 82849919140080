import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Grid,Link } from "@material-ui/core";
import moment from "moment";
import Card from "../../../components/Card/Card";
import CardAvatar from "../../../components/Card/CardAvatar";
import CardBody from "../../../components/Card/CardBody";
import styles from "../../../assets/jss/material-dashboard-react/views/PromoCard";
import Custombuttons from "../../../components/CustomButtons/Button";
import GroupIcon from "@material-ui/icons/Group";
import avatar from "../../../assets/img/chikitsamitra.png";
import axios from '../../../utils/axios1'

const useStyles = makeStyles(styles);
const PercentageCard = (props) => {
  const { promo,handleCount } = props;
  console.log(promo);
  const classes = useStyles();

  const checkPrmomcodeAppointmentCount = (promocodeId)=>{
    let jwt = localStorage.getItem("jwt") 
    if (jwt) {
      axios
        .get(
          `/get_upcoming_promo_code_appointments_count/${promocodeId}`,
          { headers: { Authorization: "Bearer " + jwt } }
        )
        .then((response) => {
           let count = response.data.appointment_count
           let message = count > 0 ? `${count} Appointment(s) linked with this Promocode, Do you want to Change the Status of Promo code` :`Do you want to Change the Status of Promo code`
           changeStatus(promocodeId,message)
        })
        .catch((error) => {
          if (error.response.data !== "") {
            alert(error.response.data.error);
          } else {
            alert(error.response.statusText);
          }
        });
      }
  }

  const changeStatus = (id,message) => {
    let jwt = localStorage.getItem("jwt") 
    if (promo.attributes.status === 1) {
      var status = 0;
    } else {
      status = 1;
    }
    const promo_code = {
      status: status,
    };
    if (window.confirm(message)) {
      if (jwt) {
        axios
          .put(
            `/change_promo_code_status/${id}`,
            { promo_code: promo_code },
            { headers: { Authorization: "Bearer " + jwt } }
          )
          .then((response) => {
            alert(response.data.message);
            handleCount();
          })
          .catch((error) => {
            if (error.response.data !== "") {
              alert(error.response.data.error);
            } else {
              alert(error.response.statusText);
            }
          });
      }
    }
  };

  return (
    <React.Fragment>
      {promo !== null ? (
        <Card profile className={classes.card}  data-testid={`promocode-card`}>
          {promo !== undefined ? (
            <React.Fragment>
              <h4 className={classes.cardTitleWhite} data-testid={`promocode-discount-${promo.attributes.discount}`}>
                {promo.attributes.discount}% OFF
              </h4>

              <CardAvatar
                profile
                style={{
                  margin: "7px auto 0",
                  maxWidth: 100,
                  boxShadow: "none",
                }}
              >
                <img
                  src={avatar}
                  alt="..."
                  style={{
                    width: 100,
                    marginLeft: 3,
                    marginBottom: -6,
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
              </CardAvatar>
              <CardBody profile style={{ marginTop: -22 }}>
                <h6
                  className={classes.cardCategory}
                  style={{ fontWeight: 700, fontSize: 16 }}
                >
                  {" "}
                  {promo.attributes.title}
                </h6>
                <br />
                <div className={classes.stats}>
                  <Typography style={{fontWeight: 400 }}>Start:</Typography>
                  <Typography style={{ fontWeight: 600, marginLeft: 5 }}>
                    {moment.utc(promo.attributes.start_datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </Typography>
                </div>
                <br />
                <div className={classes.stats}>
                  <Typography style={{ fontWeight: 400  }}>End:</Typography>
                  <Typography style={{ fontWeight: 600, marginLeft: 5 }}>
                    {moment.utc(promo.attributes.end_datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </Typography>
                </div>
                <br />
                <div className={classes.stats}>
                  <Typography style={{ fontWeight: 500 }}>
                    Max Usage:{" "}
                  </Typography>
                  <Typography style={{ fontWeight: 600, marginLeft: 5 }}>{promo.attributes.max_usage}</Typography>
                </div>
                <br />
                <br />
                <div
                  className={classes.stats}
                  style={{
                    border: "2px dotted red",
                    padding: 10,
                    backgroundColor: "beige",
                  }}
                >
                  Code: {promo.attributes.code}
                </div>
                <br />
                <br />
                <div className={classes.stats} style={{ marginTop: 4 }}>
                <Typography style={{ fontWeight: 400, marginTop: -1 }}>
                  Status:{" "}
                </Typography>
                  <Link
                    color="inherit"
                    variant="h6"
                    data-testid={`promocode-statuschange-link-${promo.attributes.title ? promo.attributes.title.toLowerCase(): ''}`} 
                    style={{ color: "green", fontWeight: 500, marginLeft: 5 }}
                    onClick={() => {
                      checkPrmomcodeAppointmentCount(promo.attributes.id);
                    }}
                  >
                  {promo.attributes.status === 1 ? 'Active' : ' Inactive'}
                  </Link>
                </div>
                <br/>
                <div
                  className={classes.stats}
                  data-testid={`promocode-people-used-${promo.attributes.used_count}`}
                  style={{ color: "#808E99", fontSize: 13 }}
                >
                  <GroupIcon />
                  {promo.attributes.used_count} People Used
                </div>
                <br />
                {promo.attributes.is_approved === 0 ? (
                  <div
                    className={classes.stats}
                    style={{ color: "red", fontSize: 16 }}
                  >
                    Unapproved
                  </div>
                ) : (
                  <div
                    className={classes.stats}
                    style={{ color: "green", fontSize: 16 }}
                  >
                    Approved
                  </div>
                )}
              </CardBody>
              {promo.attributes.is_approved === 0 ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  justify="center"
                  alignItems="center"
                  container
                >
                  <Custombuttons
                    style={{
                      fontSize: "14px",
                      marginTop: -4,
                    }}
                    data-testid={`edit-promocode-button`} 
                    component={RouterLink}
                    to={`/edit_promocode/${promo.attributes.id}`}
                  >
                    Edit
                  </Custombuttons>
                </Grid>
              ) : null}
            </React.Fragment>
          ) : null}
        </Card>
      ) : null}
    </React.Fragment>
  );
};

export default PercentageCard;
