import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Divider, Typography } from "@material-ui/core";
import Card from "../Card/Card";
import AddDetailToIssueForm from "./AddDetailToissueForm";
import IssueContentHolder from "./IssueContentHolder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AddDetailToIssue = (props) => {
  const classes = useStyles();
  const { selectedIssueId, resetPatientId, selectedIssueData } = props;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card style={{ marginTop: "1%" }} data-testid={`add-detail-to-issue-card`}>
            <IssueContentHolder
              selectedIssueId={selectedIssueId}
              resetPatientId={resetPatientId}
            />
            <Divider />

            {selectedIssueData !== null ? (
              selectedIssueData.key === 2 ? (
                <div style={{ padding: 15, textAlign: "center" }}>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 600, color: "#1466ad" }}
                  >
                    Resolution Provided
                  </Typography>
                </div>
              ) : (
                <AddDetailToIssueForm
                  resetPatientId={resetPatientId}
                  selectedIssueId={selectedIssueId}
                />
              )
            ) : (
              <AddDetailToIssueForm
                resetPatientId={resetPatientId}
                selectedIssueId={selectedIssueId}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddDetailToIssue;
