import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  guidlineRoot: {
    backgroundColor: "#f9f8f8",
    border: "1px solid #ece6e6",
    [theme.breakpoints.down("md")]: {
      padding: "14px 10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "14px 17px",
    },
  },
  content: {
    lineHeight: 1.4,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
}));

const rules = [
  {
    id: 1,
    description: "Must contain 8 or more characters with at least one digit.",
    regex: /^(?=.*\d).{8,}$/,
  },
  {
    id: 2,
    description: "Must contain one lower case character.",
    regex: /^(?=.*[a-z]).+$/,
  },
  {
    id: 3,
    description: "Must contain one upper case character.",
    regex: /^(?=.*[A-Z]).+$/,
  },
  {
    id: 4,
    description: "Must contain a symbol.",
    regex: /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).+$/,
  },
];

const PasswordGuidlines = ({ password, checkRules }) => {
  const classes = useStyles();

  useEffect(() => {
    const isRuleFollowed = rules?.every((rule) => password?.match(rule.regex));
    checkRules(isRuleFollowed);
  }, [password]);

  return (
    <div className={classes.guidlineRoot}>
      <h4 style={{ color: "#4c4a4a", marginTop: 0 }}>Password Guidelines</h4>
      {rules.map((rule) => (
        <div
          className={classes.content}
          style={{
            color: password?.match(rule.regex) ? "#007160" : "#4c4a4a",
            marginTop:5
          }}
          key={rule.id}
        >
          {password?.match(rule.regex) ? (
            <span style={{ color: "#188071" }}>&#10004;</span>
          ) : null}{" "}
          <span style={{ marginLeft: 2 }}>{rule.description}</span>
        </div>
      ))}
    </div>
  );
};

export default PasswordGuidlines;
