import React, { useState, useEffect } from "react";
import { getRequest } from "../../api/Api";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import moment from "moment";
import Nodata from "../../assets/img/nodata.png";

const useStyles = makeStyles((theme) => ({
  typo: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      color: "#903a61",
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontWeight: 500,
      color: "#903a61",
      fontSize: "16px",
    },
  },
  typoResult: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: 500,
      marginLeft: "2px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      fontWeight: 500,
      marginLeft: "2px",
    },
  },
  cardTitleWhite: {
    color: "white",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  stats: {
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
}));

const CampDetail = (props) => {
  const [campDetail, setCampDetail] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    const fetchCamp = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_mso_camp", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setCampDetail(data.data);
            console.log(data.data);
          }
        }
      });
    };
    fetchCamp();
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div style={{ padding: 14 }}>
      <Grid container spacing={2}>
      {campDetail.map((campDetail,index) => (
        <Grid item key={campDetail.id} xs={12} sm={6} md={6}>
          <Card
            style={{
              boxShadow:
                "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
                minHeight:396
            }}
            data-testid={`camp-detail-card`}
          >
            <CardHeader
              style={{ width: "147px", padding: "14px" }}
              color="success"
            >
              <h4 className={classes.cardTitleWhite}>Camp {index + 1}</h4>
            </CardHeader>
            <CardBody>
              {campDetail && campDetail.length !== 0 ? (
                <Grid container>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Camp Type
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {campDetail.attributes.title}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Camp Type
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {campDetail.attributes.camp_type}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Camp Location
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {campDetail.attributes.location}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Promo Code
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {campDetail.attributes.promo_code.title} (
                        {campDetail.attributes.promo_code.discount} % Discount)
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Start Date
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {moment(campDetail.attributes.start_datetime).format(
                          "DD MMMM YYYY"
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        End Date
                      </Typography>
                      <br />
                      <Typography className={classes.typoResult} variant="h6">
                        {moment(campDetail.attributes.end_datetime).format(
                          "DD MMMM YYYY"
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Doctors
                      </Typography>
                      <br />
                      <Typography
                        className={classes.typoResult}
                        style={{ marginLeft: 18 }}
                        variant="h6"
                      >
                        <ol>
                          {campDetail.attributes.camp_staffs.map((doctor) => (
                            <li key={doctor.id}>{doctor.name}</li>
                          ))}
                        </ol>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.stats}>
                      <Typography className={classes.typo} variant="body2">
                        Centres
                      </Typography>
                      <br />
                      <Typography
                        className={classes.typoResult}
                        style={{ marginLeft: 18 }}
                        variant="h6"
                      >
                        <ol>
                          {campDetail.attributes.camp_centres.map((centre) => (
                            <li key={centre.id}>{centre.centre_title}</li>
                          ))}
                        </ol>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <CardBody>
                  <Grid sx={12} sm={12} md={12} item container justify="center">
                    <div>
                      <img src={Nodata} style={{ width: 300 }} alt="nodata" />
                    </div>
                  </Grid>
                </CardBody>
              )}
            </CardBody>
          </Card>
        </Grid>))}
      </Grid>
    </div>
  );
};

export default CampDetail;
