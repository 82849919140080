import React, { useState, useEffect } from "react";
import Ringtone from "./Sounds/Ringing.wav";
import Notification from "./Sounds/Notification.wav";
import Alert from "./Sounds/Alert.wav";
import { putRequest } from "../../api/Api";

const NotificationSound = ({
  notificationType,
  message,
  soundStatus,
  handleSoundStatus,
  handleCloseCallBackdrop,
  setCountDownTimerOn,
}) => {
  const [audio, setAudio] = useState();
  const [playingSound, setPlayingSound] = useState(false);
  const [startTime, setStartTime] = useState();
  const [timeout, setClrtime] = useState();
  const handleSoundNotification = () => {
    setPlayingSound(!playingSound);
  };

  useEffect(() => {
    if (notificationType === "update") {
      setAudio(new Audio(Notification));
    } else if (notificationType === "alert") {
      setAudio(new Audio(Alert));
    } else if (notificationType === "incoming_call") {
      setAudio(new Audio(Ringtone));
    } else if (notificationType === "regular_notification") {
      setAudio(new Audio(Notification));
    }
    setPlayingSound(true);
    handleSoundNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationType]);

  const unAnsweredCall = (appointmentId, patientId, status) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    var formData = new FormData();
    formData.append("status", status);
    formData.append("call_unanswered", 1);

    const body = {
      token: token,
      endPoint: "update_flow_board_status",
      formData: formData,
      params: [appointmentId, patientId],
    };
    putRequest(body).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCountDownTimerOn(false);
        handleCloseCallBackdrop();
        localStorage.setItem("isCallExist", "0");
      }
    });
  };

  useEffect(() => {
    if (playingSound) {
      if (notificationType === "incoming_call") {
        PlayRingtone();
      } else if (
        notificationType === "update" ||
        notificationType === "alert" ||
        notificationType === "regular_notification"
      ) {
        PlaySound();
        setTimeout(() => {
          PauseSound();
        }, 2000);
      }
    }
  }, [playingSound]);

  var timeInterval;
  var timeToStopRing;
  var totalTimeForCall = 1 * 60 * 1000;
  const PlayRingtone = () => {
    timeInterval = setInterval(() => {
      PlaySound();
    }, 1000);
    setStartTime(timeInterval);
    timeToStopRing = setTimeout(CallNotAnswered, totalTimeForCall);
    setClrtime(timeToStopRing);
  };

  const PauseSound = () => {
    audio.pause();
    setPlayingSound(false);
    audio.addEventListener("ended", () => setPlayingSound(false));
    return () => {
      audio.removeEventListener("ended", () => setPlayingSound(false));
    };
  };

  const CallNotAnswered = () => {
    clearInterval(timeInterval);
    clearTimeout(timeToStopRing);
    if (playingSound) {
      unAnsweredCall(
        message.appointment_data.appointment_id,
        message.appointment_data.patient_id,
        message.appointment_data.current_flowboard_status
      );
      PauseSound();
    }
  };

  const CallAnswered = () => {
    clearInterval(startTime);
    clearTimeout(timeout);
    // if (playingSound) {
    PauseSound();
    // }
  };

  const PlaySound = () => {
    if (playingSound) {
      audio
        .play()
        .then(() => {
          console.log("Playback resumed successfully");
        })
        .catch((err) => console.log(err));
      audio.addEventListener("ended", () => setPlayingSound(false));
      return () => {
        audio.removeEventListener("ended", () => setPlayingSound(false));
      };
    }
  };
  useEffect(() => {
    if (soundStatus) {
      CallAnswered();
      handleSoundStatus(false);
    }
  }, [soundStatus]);

  return <div></div>;
};

export default NotificationSound;
