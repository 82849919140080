import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Slider,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
// import Rating from "@material-ui/lab/Rating";
import MultiSelect from "react-multi-select-component";
import { Rating } from "@material-ui/lab";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import { getRequest } from "../../api/Api";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function valuetext(value) {
  return `${value}`;
}

const docAvailability = [
  {key:'Online',value:'Online'},
  {key:'All',value:'All'}
]

const FilterDoctorForm = (props) => {
  const classes = useStyles();
  const [sliderValue, setsliderValue] = useState([50, 1000]);
  const [experienceValue, setExperienceValue] = useState([1, 50]);
  const [ratingValue, setRatingValue] = useState(0);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [defLanguage, setDefLanguage] = useState([]);
  const [language,setLanguage] = useState('')
  const { setFilterDoctor, setFilterByOption,setDoctorLanguageForAppointment, setIsReset,setIsShowOnlineDoc } = props;
  const { handleSubmit, watch, errors, control } = useForm();
  const [certificates, setCertificates] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [docAvailabilityStatus,setDocAvailabilityStatus] = useState("")

  const doctorNameValue = watch("name");

  const handleExperience = (event, newValue) => {
    setExperienceValue(newValue);
  };
  const handleQualification = (value) => {
    setQualifications(value);
  };

  const handlefee = (event, newValue) => {
    setsliderValue(newValue);
  };
  const handleRating = (event, newValue) => {
    setRatingValue(newValue);
  };
  const handleSpeciality = (e) => {
    setSelectedSpeciality(e.target.value);
  };

  const handleLanguage= (e) => {
    setLanguage(e.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest(`get_doctor_designation_list`, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setDesignationList(data);
        }
      }
    });
    getRequest(`get_qualifications/doctor`, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setCertificates(data);
        }
      }
    });
    const loadDefaultLanguage = () => {
      getRequest("get_default_languages").then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
          setDefLanguage(data);
          }
        }
      });
    };
    loadDefaultLanguage();
    return () => {
      isMounted = false;
    };
  }, []);

  const resetValues = () => {
    setRatingValue(0);
    setSelectedSpeciality("");
    setLanguage('')
    setsliderValue([50, 1000]);
    setDoctorLanguageForAppointment(null)
    setQualifications([])
  };

  const onSubmit = (data) => {
    let ratings;
    if (ratingValue === 0 || ratingValue === null) {
      ratings = "";
    } else {
      ratings = ratingValue;
    }
    var form = new FormData()
    qualifications.map((obj) => {
      form.append(`qualification_id[]`, obj.value);
    });
    console.log(docAvailabilityStatus)
    // setIsShowOnlineDoc(true)
    if(docAvailabilityStatus && docAvailabilityStatus === 'Online'){
      setIsShowOnlineDoc(true)
    }else{
      setIsShowOnlineDoc(false)
    }
    form.append(`name`, data.name);
    form.append(`consulting_language`, language);
    form.append(`designation`, selectedSpeciality);
    form.append(`min_star_rating`, ratings);
    form.append(`from_fee`, sliderValue[0]);
    if(sliderValue[1] === 1000){
      form.append(`to_fee`, 5000);
    }else{
      form.append(`to_fee`, sliderValue[1]);
    }    
    form.append(`from_work_ex`, experienceValue[0]);
    form.append(`to_work_ex`, experienceValue[1]);
    const queryString = new URLSearchParams(form).toString();
    getQueryRequest("search_doctors", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let language ;
        for(let i=0; i< data.data.length ; i++){
         language = Array.prototype.map.call(data.data[i].attributes.consulting_languages, s => s.language_value).toString()
          data.data[i].attributes.language = language
        }
        setFilterDoctor(data.data);
      }
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <List className={classes.list} disablePadding>
            <ListItem divider>
                <Grid item xs={12} sm={12} md={12}>
                <FormControl
                    className={classes.formControl}
                    style={{ minWidth: 170 }}
                  >
                    <InputLabel htmlFor="speciality-native-simple">
                      Language
                    </InputLabel>
                    <Select
                      native
                      inputProps={{
                        data_cy : `default-language-dropdown`,
                        name: "language",
                        id: "language-native-simple",
                      }}
                      value={language}
                      onChange={handleLanguage}
                    >
                      <option key={0} value="" />
                      {defLanguage.map((option) => (
                        <option
                          data_cy ={`default-language-option-${option.key}`}
                          key={option.value} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{ minWidth: 170 }}
                  >
                    <InputLabel htmlFor="speciality-native-simple">
                      Speciality
                    </InputLabel>
                    <Select
                      native
                      inputProps={{
                        data_cy : `speciality-dropdown`,
                        name: "speciality",
                        id: "speciality-native-simple",
                      }}
                      value={selectedSpeciality}
                      onChange={handleSpeciality}
                    >
                      <option key={0} value="" />
                      {designationList.map((option) => (
                        <option 
                          data_cy ={`speciality-option-${option.value.toLowerCase()}`}
                          key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{ minWidth: 170 }}
                  >
                    <InputLabel htmlFor="availability-native-simple">
                     Availability
                    </InputLabel>
                    <Select
                      native
                      inputProps={{
                        data_cy : `availability-dropdown`,
                        name: "availability",
                        id: "availability-native-simple",
                      }}
                      value={docAvailabilityStatus}
                      onChange={(e)=>setDocAvailabilityStatus(e.target.value)}
                    >
                      <option key={0} value="" />
                      {docAvailability.map((option) => (
                        <option 
                          key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    as={<TextField />}
                    error={Boolean(errors.name)}
                    name="name"
                    control={control}
                    inputProps={{
                      data_cy :  
                       `searchby-name-input`
                    
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    defaultValue=""
                    label="Search by name"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography gutterBottom style={{ fontSize: 12 }}>
                    Rating
                  </Typography>
                  <Rating
                    name="rating"
                    value={ratingValue}
                    onChange={handleRating}
                    data_cy={`rating-input`}
                  />
                </Grid>
              </ListItem>
              <ListItem>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography gutterBottom style={{ fontSize: 12 }}>
                    Fees
                  </Typography>
                  <Slider
                    value={sliderValue}
                    onChange={handlefee}
                    getAriaValueText={valuetext}
                    style={{ width: 200 }}
                    aria-labelledby="discrete-slider-custom"
                    data_cy={`feerange-input`}
                    step={50}
                    min={50}
                    max={1000}
                    valueLabelDisplay="auto"
                    // marks={fees}
                  />
                  <h3 style={{ fontSize:16,fontWeight:600 }}>{`₹ ${sliderValue[0]}`} - {`₹ ${sliderValue[1]}`}</h3>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography gutterBottom style={{ fontSize: 12 }}>
                    Experience
                  </Typography>
                  <Slider
                    value={experienceValue}
                    onChange={handleExperience}
                    getAriaValueText={valuetext}
                    style={{ width: 200 }}
                    aria-labelledby="discrete-slider-custom"
                    data_cy={`experiencerange-input`}
                    step={1}
                    min={1}
                    max={50}
                    valueLabelDisplay="auto"
                  />
                <h3 style={{ fontSize:16,fontWeight:600 }}>{experienceValue[0]}-{experienceValue[1]} years</h3>

                </Grid>
              </ListItem>
              <ListItem>
                <div>
              <div style={{ fontWeight: 500 }}>
                  Qualification
                </div>
              <div style={{ width:200,marginTop:8 }}>
                <MultiSelect
                  required
                  id="qualification"
                  options={certificates.map((e) => ({
                    label: `${e.qualification}`,
                    value: e.id,
                    key: e.qualification,
                  }))}
                  hasSelectAll={false}
                  value={qualifications}
                  getOptionLabel={(option) => option.qualification}
                  onChange={handleQualification}
                  labelledBy="Qualification"
                  data_cy ={`qualification-dropdown`}
                />
              </div>
              </div>
              </ListItem>
            </List>
            <br />
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              xs={12}
              sm={12}
              md={12}
            >
              <Button
                style={{ backgroundColor: "#2979ff", color: "white" }}
                type="submit"
                // onfocus={handleAccordian("panel1")}
                data-testid="filter-doctor-button"
              >
                Filter
              </Button>
              <Button
                data-testid="resetfilter-doctor-button"
                style={{ backgroundColor: "#dcdde0" }}
                onClick={() => {
                  resetValues();
                  setFilterDoctor(undefined);
                  setIsReset(true);
                }}
              >
                Reset
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

FilterDoctorForm.propTypes = {
  className: PropTypes.string,
};

export default FilterDoctorForm;

