import React, { useState, useEffect, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Grid from "@material-ui/core/Grid";
import "./RadiologyReport.css";
import { DirectUpload } from "@rails/activestorage";
import baseUrl from "../../utils/baseUrl";
import { getRequest } from "../../api/Api";

import "../../components/toast.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import avtar from "../../assets/img/radio.png";

const styles = theme => ({
  root: {
    padding: "16px",
  },
});
const useStyles = makeStyles(styles);

class Uploader {
  constructor(file, url, index) {
    this.file = file;
    this.url = url;
    this.index = index;
    this.uploadObj = new DirectUpload(this.file, this.url, this);
    this.progressElement = document.getElementById(
      `direct-uploads-${this.index}`,
    );
    this.progressBar = document.getElementById(
      `direct-uploads-progress-${this.index}`,
    );
  }

  uploads(file) {
    return new Promise((resolve, reject) => {
      this.uploadObj.create((error, blob) => {
        if (error) {
          this.progressElement.classList.add("direct-uploads--error");
          this.progressElement.setAttribute("title", error);
          reject(error);
        } else {
          this.progressElement.classList.add("direct-uploads--complete");
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          // hiddenField.id = `radiodocument_${this.index}`;
          hiddenField.setAttribute("class", `radiodocument_`);
          document.getElementById("radio").appendChild(hiddenField);
          resolve("Success");
        }
      });
    });
  }

  directUploadDidProgress(event) {
    this.progressBar.style.width = `${(100 * event.loaded) / event.total}%`;
  }

  directUploadWillStoreFileWithXHR(request) {
    this.progressElement.classList.remove("direct-uploads--pending");
    request.upload.addEventListener("progress", event =>
      this.directUploadDidProgress(event),
    );
  }
}

const RadiologyReport = props => {
  const { patientid, visitid, handleRadioCount } = props;
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [acceptedRadiologyData, setAcceptedRadiologyData] = useState([]);
  const fetchDocumentTitle = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_patient_document_type_list", token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setList(data[1]);
      }
    });
  };

  useEffect(() => {
    fetchDocumentTitle();
  }, []);

  const radioInputFile = createRef();
  const addProgressBar = (file, index, handler) => {
    handler.insertAdjacentHTML(
      "afterend",
      `<br/>
     <div id="direct-uploads-${index}" class="direct-uploads direct-uploads--pending">
     <div id="direct-uploads-progress-${index}" class="direct-uploads__progress" 
     style="width: 0%"></div>
     <span class=direct-uploads__filename">${file.name}</span>
     </div>`,
    );
  };

  var radiologyTemp = [];
  const differenceOf2ArraysOfRadiology = (array1, array2) => {
    for (var i in array1) {
      if (array2.indexOf(array1[i]) === -1) radiologyTemp.push(array1[i]);
    }
    for (i in array2) {
      if (array1.indexOf(array2[i]) === -1) radiologyTemp.push(array2[i]);
    }
    return radiologyTemp.sort((a, b) => a - b);
  };

  const handleRadioFile = formData => {
    return new Promise((resolve, reject) => {
      const promises = [];
      for (let i = 0; i < radioInputFile.current.files.length; i++) {
        addProgressBar(
          radioInputFile.current.files[i],
          i,
          radioInputFile.current,
        );
        if (radioInputFile.current.files[i].size <= 10000000) {
          promises.push(uploadFiles(radioInputFile.current.files[i], i));
        } else {
          alert(
            `Your file ${radioInputFile.current.files[i].name} was not uploaded because it exceeds the 10 MB size limit.`,
          );
        }
      }
      Promise.all(promises)
        .then(() => {
          var patientRadiologyDocument = document.getElementsByClassName(
            `radiodocument_`,
          );
          var radioFiles = [];
          for (let i = 0; i < patientRadiologyDocument.length; i++) {
            setAcceptedRadiologyData(prevAcceptedData => [
              ...prevAcceptedData,
              patientRadiologyDocument[i].value,
            ]);
            radioFiles.push(patientRadiologyDocument[i].value);
          }
          console.log(radioFiles);
          console.log(acceptedRadiologyData);
          differenceOf2ArraysOfRadiology(radioFiles, acceptedRadiologyData);
          var radioReport = [];
          for (let i = 0; i < radiologyTemp.length; i++) {
            radioReport.push({
              documents: radiologyTemp[i],
              visit_id: visitid,
              document_type: list.key,
            });
            Object.keys(radioReport[i]).forEach(key => {
              formData.append(
                `patient[patient_documents_attributes][${i}][${key}]`,
                radioReport[i][key],
              );
            });
          }
        })
        .then(() => resolve("looped through all files!"))
        .catch(error => reject(error));
    });
  };

  const uploadFiles = (file, index) => {
    return new Promise((resolve, reject) => {
      const URL = `${baseUrl}/rails/active_storage/direct_uploads`;
      new Uploader(file, URL, index)
        .uploads(file)
        .then(message => resolve(message))
        .catch(error => reject(error));
    });
  };

  const handle = event => {
    var formData = new FormData();

    handleRadioFile(formData)
      .then(message => {
        // console.log(patientid);
        if (
          localStorage.getItem("jwt") !== "" ||
          localStorage.getItem("jwt") !== undefined
        ) {
          let token = "Bearer " + localStorage.getItem("jwt");
          fetch(`${baseUrl}/save_patient_documents/${patientid}`, {
            method: "PUT",
            headers: {
              Authorization: token,
            },
            body: formData,
          }).then(response => {
            response.json().then(data => {
              if (response.status === 200) {
                toast(<p>{data.message}</p>, {
                  className: "custom",
                  autoClose: 1000,
                });
                handleRadioCount();
              } else {
                toast.error(<p>{data.error}</p>, { autoClose: 3000 });
              }
            });
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  return (
    <div className={classes.root} style={{ marginTop: "-20px" }}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            style={{
              marginTop: "14px",
              boxShadow:
                "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
            }}
          >
            <img
              src={avtar}
              style={{ height: 165, width: "208px", margin: "0 auto" }}
              alt="document"
            />
            <div style={{ textAlign: "center", fontSize: 16, fontWeight: 500 }}>
              <label>Select Radiology Report</label>
            </div>
            <CardBody>
              <form id="radio">
                <Grid item xs={12} sm={12} md={12} container justify="center">
                  <div className="fileUpload">
                  <span style={{ cursor:'pointer' }}>Upload</span>
                    <input
                      type="file"
                      onChange={handle}
                      multiple={true}
                      data_cy ="radiology-upload-input"
                      accept=".pdf,.jpg,.jpeg,.png"
                      className="upload"
                      ref={radioInputFile}
                    />
                  </div>
                </Grid>
                <ToastContainer />
                <br />
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RadiologyReport;
