import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  indicator: {
    backgroundColor: "#3daa99",
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const {
    patient,
    steps,
    patientInfo,
    correctionCount,
    activeStep,
    changeState,
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [count, setCount] = useState(0);
  const [medicalProblemCount, setMedicalProblemCount] = useState();
  const [currentMedicationCount, setCurrentMedicationCount] = useState();
  const [vitalCount, setVitalCount] = useState();
  const [familyHistoryCount, setfamilyHistoryCount] = useState();
  const [lifestyleCount, setLifestyleCount] = useState();
  const [allergyCount, setAllergyCount] = useState();
  const [documentCount, setDocumentCount] = useState();

  useEffect(() => {
    setValue(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (patientInfo !== undefined) {
      if (patientInfo.attributes.flagged_count.medical_problems > 0) {
        setMedicalProblemCount(
          patientInfo.attributes.flagged_count.medical_problems
        );
      } else {
        setMedicalProblemCount(0);
      }
      if (patientInfo.attributes.flagged_count.current_medications > 0) {
        setCurrentMedicationCount(
          patientInfo.attributes.flagged_count.current_medications
        );
      } else {
        setCurrentMedicationCount(0);
      }
      if (patientInfo.attributes.flagged_count.vitals > 0) {
        setVitalCount(patientInfo.attributes.flagged_count.vitals);
      } else {
        setVitalCount(0);
      }
      if (patientInfo.attributes.flagged_count.lifestyles > 0) {
        setLifestyleCount(patientInfo.attributes.flagged_count.lifestyles);
      } else {
        setLifestyleCount(0);
      }
      if (patientInfo.attributes.flagged_count.family_histories > 0) {
        setfamilyHistoryCount(
          patientInfo.attributes.flagged_count.family_histories
        );
      } else {
        setfamilyHistoryCount(0);
      }
      if (patientInfo.attributes.flagged_count.allergies > 0) {
        setAllergyCount(patientInfo.attributes.flagged_count.allergies);
      } else {
        setAllergyCount(0);
      }
      if (patientInfo.attributes.flagged_count.documents > 0) {
        setDocumentCount(patientInfo.attributes.flagged_count.documents);
      } else {
        setDocumentCount(0);
      }
    }
  }, [patientInfo, correctionCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeState(newValue);
  };

  const handleCount = () => {
    setCount(count + 1);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{ indicator: classes.indicator }}
          // style={{ color: "#3daa99" }}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{ minWidth: "140px" }}
            data-testid="medicalproblem-scrollable-tab"
            label={
              <Badge badgeContent={medicalProblemCount} color="error">
                Medical Problem
              </Badge>
            }
            {...a11yProps(0)}
          />
          <Tab
            style={{ minWidth: "140px" }}
            data-testid="currentmedication-scrollable-tab"
            label={
              <Badge badgeContent={currentMedicationCount} color="error">
                Current Medication
              </Badge>
            }
            {...a11yProps(1)}
          />

          <Tab
            style={{ minWidth: "90px" }}
            data-testid="vital-scrollable-tab"
            label={
              <Badge badgeContent={vitalCount} color="error">
                Vitals
              </Badge>
            }
            {...a11yProps(2)}
          />
          <Tab
            style={{ minWidth: "100px" }}
            data-testid="lifestyle-scrollable-tab"
            label={
              <Badge badgeContent={lifestyleCount} color="error">
                Lifestyle
              </Badge>
            }
            {...a11yProps(3)}
          />
          <Tab
            style={{ minWidth: "140px" }}
            data-testid="familyhistory-scrollable-tab"
            label={
              <Badge badgeContent={familyHistoryCount} color="error">
                Family History
              </Badge>
            }
            {...a11yProps(4)}
          />
          <Tab
            style={{ minWidth: "100px" }}
            data-testid="allergy-scrollable-tab"
            label={
              <Badge badgeContent={allergyCount} color="error">
                Allergy
              </Badge>
            }
            {...a11yProps(5)}
          />
          <Tab
            style={{ minWidth: "120px" }}
            label={
              <Badge badgeContent={documentCount} color="error">
                Document
              </Badge>
            }
            {...a11yProps(6)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={activeStep}>
        {props.children}
      </TabPanel>
    </div>
  );
}
