import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import PatientImage from "../../assets/img/patient1.jpg";
import {
  Avatar,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { abbreviatedName, ageCalculator } from "../../utils/helper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "3%",
  },
  itemDivider: {
    borderBottomColor: "rgb(175 157 157 / 20%)",
  },
}));

const PatientMobileViewList = (props) => {
  const { patients, appointmentType, updatePatientId } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CardContent className={classes.content}>
        <List className={classes.list}>
          {patients.map((patient, i) => (
            <ListItem
              classes={{ divider: classes.itemDivider }}
              divider={i < patients.length - 1}
              data-testid={patient.attributes.id}
              key={patient.attributes.id}
            >
              <ListItemAvatar style={{ minWidth: 72, marginTop: "-7%" }}>
                <Avatar
                  className={classes.avatar}
                  src={
                    patient.attributes.profile_photo
                      ? patient.attributes.profile_photo
                      : PatientImage
                  }
                  size="large"
                />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    type="body2"
                    style={{ fontWeight: 500, fontSize: 15, marginTop: "4%" }}
                  >
                    {abbreviatedName(patient.attributes.name)}
                    <span
                      style={{
                        fontWeight: 400,
                        color: "rgb(123 123 123)",
                        fontSize: 15,
                        marginLeft: "2%",
                      }}
                    >
                      ({ageCalculator(patient.attributes.dob)}/
                      {patient.attributes.gender[0].toUpperCase()})
                    </span>
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 400,
                        color: "#4c5f6d",
                        fontSize: 13,
                      }}
                    >
                      Registered:
                      <span style={{ marginLeft: "2%", color: "#2b3c2e" }}>
                        {moment(patient.attributes.created_at).format(
                          "Do MMM YYYY"
                        )}
                      </span>
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 400,
                        color: "#4c5f6d",
                        fontSize: 13,
                      }}
                    >
                      Last Visit:
                      <span style={{ marginLeft: "2%", color: "#2b3c2e" }}>
                      {patient.attributes.last_visit_datetime 
                          ? moment.utc(patient.attributes.last_visit_datetime)
                          .local()
                          .format("DD/MM/YYYY")
                          : ""}
                      </span>
                    </Typography>
                    <br />
                    <Grid
                      container
                      item
                      // style={{ flexGrow: 1 }}
                      justify="space-around"
                      alignItems="center"
                      xs={12}
                      direction="row"
                    >
                      {appointmentType ? (
                        <Button
                          data-testid={`selectpatient-button-${patient.attributes.name.toLowerCase()}`}
                          onClick={() => updatePatientId(patient.attributes.id)}
                          style={{
                            right: "25%",
                          }}
                        >
                          Select Patient
                        </Button>
                      ) : (
                        <Fragment>
                          {patient.attributes.last_visit_id !== null && (
                            <Button
                              color="inherit"
                              style={{
                                marginLeft: "-7%",
                                fontWeight: 500,
                                fontSize: 13,
                              }}
                              component={RouterLink}
                              data-testid={`patient-view-button-${patient.attributes.name.toLowerCase()}`}
                              to={`/patient_page/${patient.attributes.id}`}
                            >
                              View
                            </Button>
                          )}
                          <Button
                            color="inherit"
                            component={RouterLink}
                            to={`/edit_patient/${patient.attributes.id}`}
                            // variant="h6"
                            data-testid={`patient-edit-button-${patient.attributes.name.toLowerCase()}`}
                            style={{
                              marginLeft: "-7%",
                              fontWeight: 500,
                              fontSize: 13,
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            color="inherit"
                            component={RouterLink}
                            to={`${
                              "eligible_camps" in patient.attributes &&
                              patient.attributes.eligible_camps.length !== 0
                                ? `/confirm_appointment_case/${patient.attributes.id}`
                                : `/book_appointment/${patient.attributes.id}?type=specific`
                            }`}                           
                            data-testid={`patient-book-appointment-button-${patient.attributes.name.toLowerCase()}`}
                            style={{
                              marginLeft: "-7%",
                              fontWeight: 500,
                              fontSize: 13,
                            }}
                          >
                            Book Appointment
                          </Button>
                        </Fragment>
                      )}
                    </Grid>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </div>
  );
};

PatientMobileViewList.propTypes = {
  className: PropTypes.string,
};

export default PatientMobileViewList;
