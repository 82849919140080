import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
import { CSVLink } from "react-csv";

const TransactionData = (props) => {
  const {
    tableHead,
    tableData,
    checked,
    isHidePagination,
    tableHeaderColor,
  } = props;
  const [tableRecords, setTableRecords] = useState([]);
  const csvInstance = useRef();

  useEffect(() => {
    let transactionArray = [];
    for (let i = 0; i < tableData.length; i++) {
      let promoCode = "";
      if (
        tableData[i].attributes.fee_type.key === "consultation_fee" ||
        tableData[i].attributes.fee_type.key === "refund"
      ) {
        tableData[i].attributes.remark = tableData[i].attributes.remark
          ? JSON.parse(tableData[i].attributes.remark)
          : "";
        if (
          tableData[i].attributes.remark.hasOwnProperty("promo_codes") &&
          Object.keys(tableData[i].attributes.remark.promo_codes).length !==
            0 &&
          tableData[i].attributes.remark.promo_codes.constructor === Object
        ) {
          promoCode = Array.prototype.map
            .call(
              tableData[i].attributes.remark.promo_codes.data,
              (s) => `${s.attributes.title}(${s.attributes.discount} %)`
            )
            .toString();
          tableData[i].attributes.promoString = promoCode;
        } else {
          console.log("No Data Present");
          promoCode = "";
        }
      }
      transactionArray.push({
        id: tableData[i].attributes.id,
        transactionDate: moment(tableData[i].attributes.created_at).format(
          "YYYY-MM-DD"
        ),
        transactionBy: tableData[i].attributes.transacted_by.name,
        transactionType: tableData[i].attributes.transaction_type.value,
        particular: `${tableData[i].attributes.fee_type.value}\u00A0
        ${
          tableData[i].attributes.remark
            ? tableData[i].attributes.fee_type.key === "consultation_fee" ||
              tableData[i].attributes.fee_type.key === "refund"
              ? tableData[i].attributes.remark.doctor_name
                ? `(Appointment id -${
                    tableData[i].attributes.remark.appointment_id
                      ? tableData[i].attributes.remark.appointment_id
                      : ""
                  }/${tableData[i].attributes.remark.doctor_name})`
                : `(Appointment id -${
                    tableData[i].attributes.remark.appointment_id
                      ? tableData[i].attributes.remark.appointment_id
                      : ""
                  }/${tableData[i].attributes.remark.doctor_category_name})`
              : `(${tableData[i].attributes.remark})`
            : ""
        }`,
        promocode: tableData[i].attributes.promoString,
        amount: tableData[i].attributes.amount,
        status: tableData[i].attributes.status.value,
        currentBalance: tableData[i].attributes.balance_after_transaction,
      });
    }
    setTableRecords(transactionArray);
  }, [tableData, checked]);

  useEffect(() => {
    if (!isHidePagination && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
      }, 1000);
    }
  }, [isHidePagination]);

  return (
    <div>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ marginTop: "2%" }}>
            {!isHidePagination && (
              <CSVLink
                data={tableRecords}
                headers={tableHead}
                filename={"account-detail.csv"}
                ref={csvInstance}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <TransactionTable
        tableData={tableRecords}
        tableHead={tableHead}
        tableHeaderColor={tableHeaderColor}
      />
    </div>
  );
};

export default TransactionData;
