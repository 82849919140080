import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import baseUrl from "../../utils/baseUrl";
import CustomButton from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import CardAvatar from "../../components/Card/CardAvatar";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { DirectUpload } from "@rails/activestorage";
import "../../components/toast.css";
import { getRequest, putRequest } from "../../api/Api";
import momentTimeZone from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Backdrop from "../../components/Backdrop/SimpleBackdrop";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Hidden,
} from "@material-ui/core";
import { blockButton } from "../../utils/helper";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "17px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 150,
  },
  danger: {
    color: "brown",
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    marginBottom: "2px",
    fontSize: "14px",
  },
  labelRoot: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
});

const useStyles = makeStyles(styles);

const EditPatient = (props) => {
  let { id } = useParams();
  const { handleSubmit, errors, control, watch, setValue } = useForm();

  const [cities, setCities] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [genders, setGender] = useState([]);
  const [patient, setPatient] = useState([]);
  const [states, setState] = useState([]);
  const [isAge, setIsAge] = useState();
  // const [iscity, setIsCity] = useState();
  const [salutation, setSalutation] = useState([]);
  const [age, setAge] = useState();
  const [dob, setDob] = useState();
  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [imageFileName, setImageFileName] = useState("");
  const [isButtonDisable,setIsButtonDisable] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const loadStates = () => {
    getRequest("get_states").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setState(data);
      }
    });
  };

  const loadMaritalStatus = () => {
    getRequest("get_marital_status").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setMaritalStatus(data);
      }
    });
  };

  const loadGender = () => {
    getRequest("get_genders").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setGender(data);
      }
    });
  };

  const loadSalutation = () => {
    getRequest("get_salutation_title_list").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setSalutation(data);
      }
    });
  };

  const getCity = (state_id) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    const cityEndpoint = "get_cities";
    let param = [state_id];
    getRequest(cityEndpoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCities(data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchPatientById = () => {
      const endPoint = "patients";
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [id];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setPatient(data.data);
        }
      });
    };
    fetchPatientById();
    loadStates();
    loadMaritalStatus();
    loadGender();
    loadSalutation();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVal = (is_tentative_age) => {
    setIsAge(is_tentative_age);
  };

  useEffect(() => {
    if (patient.length !== 0) {
      if (patient.attributes.state !== null) {
        setValue("state_id", patient.attributes.state.id || "");
        getCity(patient.attributes.state.id);
      }
      if (patient.attributes.city !== null) {
        setValue("city_id", patient.attributes.city.id || "");
      }
      if (
        patient.attributes.marital_status !== undefined &&
        patient.attributes.marital_status !== null
      ) {
        var obj = patient.attributes.marital_status;
        var key = Object.keys(obj)[0];
        var value = obj[key];
        setValue("marital_status", value || "");
      }
      if (patient.attributes.is_tentative !== undefined) {
        if (patient.attributes.is_tentative === 1) {
          setValue("is_tentative_age", true);
          setIsAge(true);
          var today = new Date();
          var birthDate = new Date(patient.attributes.dob);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          setAge(age);
        } else {
          setIsAge(false);
          setDob(moment(patient.attributes.dob).toDate());
        }
      }
      setValue("name", patient.attributes.name || "");
      setValue("title", patient.attributes.title || "");
      setValue("mobile", patient.attributes.mobile || "");
      setValue("email", patient.attributes.email || "");
      setValue("gender", patient.attributes.gender || "");
      setValue("address", patient.attributes.address || "");
      if (patient.attributes.pincode !== null) {
        setValue("pincode", patient.attributes.pincode || "");
      }
      setValue(
        "alternate_contact_number",
        patient.attributes.alternate_contact_number || ""
      );
      setValue(
        "alternate_contact_person",
        patient.attributes.alternate_contact_person || ""
      );

      setValue(
        "alternate_contact_relationship",
        patient.attributes.alternate_contact_relationship || ""
      );
    }
  }, [patient, setValue]);

  const handleImage = (e) => {
    const [file] = e.target.files;
    setImageFileName(file?.name ?? '');
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const promises = [];
  const handleImageUpload = (image) => {
    if (image.length !== 0) {
      if (image[0].size <= 8000000) {
        promises.push(uploadFile(image[0]));
      } else {
        alert(
          `Profile Picture was not uploaded because it exceeds the 8 MB size limit.`
        );
        return false;
      }
    } else {
      promises.push(uploadFile(new File([props.location.image], "patient")));
    }
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const URL = `${baseUrl}/rails/active_storage/direct_uploads`;
      const upload = new DirectUpload(file, URL);
      upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.id = `profile_pic`;
          document.querySelector("form").appendChild(hiddenField);
          resolve("Success");
        }
      });
    });
  };

  const blockSpecialChar = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      return false;
    } else {
      // eslint-disable-next-line no-control-regex
      var regex = new RegExp("^[.a-zA-Z0-9\b _ _%]+$");
      var key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  };

  const Dob = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={3} md={3}>
          <Controller
            as={<ReactDatePicker />}
            error={Boolean(errors.dob)}
            control={control}
            valueName="selected"
            onChange={([selected]) => selected}
            name="dob"
            rules={{ required: "DOB is required" }}
            isClearable
            defaultValue={dob}
            customInput={<CustomInput />}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="yyyy-MM-dd"
            dropdownMode="select"
            popperPlacement="bottom-start"
            popperModifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
              },
            }}
          />
          {errors.dob && <div style={{ color: "red" }}>DOB is required</div>}
        </Grid>
      </React.Fragment>
    );
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        data_cy={`patient-dob-datepicker`}
        className={classes.input}
        type="text"
        placeholder="Select DOB"
        readOnly={true}
      />
    );
  });

  const handleWebcam = () => {
    props.history.push({
      pathname: "/webcam",
      patientid: id,
    });
  };
  const ImageComponent = () => {
    return (
      <React.Fragment>
        <input
          type="file"
          accept="image/*"
          onChange={handleImage}
          ref={imageUploader}
          data_cy={`upload-image-input`}
          style={{
            display: "none",
          }}
        />
        <div
          style={{
            height: "132px",
            width: "147px",
            backgroundColor: "#489a9c",
          }}
          onClick={() => imageUploader.current.click()}
        >
          {patient.attributes !== undefined ? (
            <img
              ref={uploadedImage}
              src={props.location.displayImage ? props.location.displayImage : patient.attributes.profile_photo}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "acsolute",
                cursor: "pointer",
              }}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  const onSubmit = (data) => {
    let result = handleImageUpload(imageUploader.current.files);
    if (result === false) {
      return false;
    }

    if (data.is_tentative_age === true) {
      data.is_tentative_age = 1;
    } else {
      data.is_tentative_age = 0;
    }
    blockButton("register", true);
    setIsButtonDisable(true)
    Promise.all(promises)
      .then(() => {
        var formData = new FormData();
        formData.append("patient[title]", data.title);
        formData.append("patient[name]", data.name);
        if (data.email) {
          formData.append("patient[email]", data.email);
        }
        if (data.mobile) {
          formData.append("patient[mobile]", data.mobile);
        }
        formData.append("patient[gender]", data.gender);
        if (data.address) {
          formData.append("patient[address]", data.address);
        }
        if (data.city_id) {
          formData.append("patient[city_id]", data.city_id);
        }
        if (data.state_id) {
          formData.append("patient[state_id]", data.state_id);
        }
        if (data.is_tentative_age === 0) {
          formData.append(
            "patient[dob]",
            moment(data.dob).format("YYYY-MM-DD")
          );
        } else {
          formData.append("patient[age]", data.age);
        }
        formData.append("patient[is_tentative]", data.is_tentative_age);
        formData.append("patient[marital_status]", data.marital_status);
        formData.append("patient[timezone]", momentTimeZone.tz.guess());
        if (data.pincode) {
          formData.append("patient[pincode]", data.pincode);
        }
        if (data.alternate_contact_number) {
          formData.append(
            "patient[alternate_contact_number]",
            data.alternate_contact_number
          );
        }
        if (data.alternate_contact_person) {
          formData.append(
            "patient[alternate_contact_person]",
            data.alternate_contact_person
          );
        }
        if (data.alternate_contact_relationship) {
          formData.append(
            "patient[alternate_contact_relationship]",
            data.alternate_contact_relationship
          );
        }
        if (
          imageUploader.current.files.length !== 0 ||
          props.location.image !== undefined
        ) {
          formData.append(
            "patient[profile_photo]",
            document.getElementById(`profile_pic`).value
          );
        }
        let token = "Bearer " + localStorage.getItem("jwt");
        const body = {
          token: token,
          endPoint: "patients",
          formData: formData,
          params: [id],
        };

        handleToggle();
        putRequest(body).then((data) => {
          if (data.error) {
            toast.error(<p>{data.error}</p>, { autoClose: 3000 });
            blockButton("register", false);
            setIsButtonDisable(false)
            handleClose();
          } else {
            toast(<p>{data.message}</p>, {
              className: "custom",
              autoClose: 1000,
            });
            handleClose();
            setTimeout(() => {
              props.history.push(`/patient_list`);
            }, 1000);
          }
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={classes.root}>
      <Backdrop open={open} handleClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={9}>
            <Card style={{ marginTop: "23px" }}>
              <CardHeader
                style={{ width: "147px", padding: "14px" }}
                color="success"
              >
                <h4 className={classes.cardTitleWhite}>Edit Patient</h4>
              </CardHeader>
              <Hidden lgUp>
                <Grid
                  item
                  container
                  justify="flex-end"
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  sm={12}
                >
                  <CardAvatar
                    profile
                    style={{ margin: "-4px 7px 0", boxShadow: "none" }}
                  >
                    {ImageComponent()}
                  </CardAvatar>
                  {/* <Button color="primary" onClick={handleWebcam}>
                      Capture Image
                    </Button> */}
                </Grid>
              </Hidden>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={2} md={2}>
                    <FormControl
                      style={{ minWidth: 80 }}
                      error={Boolean(errors.title)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Title *
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {salutation.map((option) => (
                              <MenuItem
                                data_cy={`patient-title-option-${option.key?.toLowerCase()}`}
                                key={option.key}
                                value={option.key}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="title"
                        rules={{ required: "Title is required" }}
                        control={control}
                        defaultValue=""
                        inputProps={{
                          data_cy: `patient-title-dropdown`,
                        }}
                      />
                      <FormHelperText>
                        {errors.title && errors.title.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} sm={10} md={10}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.name)}
                      name="name"
                      rules={{
                        required: "Patient Name is required",
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Only text is allowed",
                        },
                      }}
                      inputProps={{
                        data_cy: `patient-name-input`,
                      }}
                      control={control}
                      defaultValue=""
                      label="Full Name *"
                      type="text"
                      helperText={errors.name && errors.name.message}
                      fullWidth
                      onKeyPress={blockSpecialChar}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.mobile)}
                      name="mobile"
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 10,
                      }}
                      control={control}
                      defaultValue=""
                      label="Mobile"
                      type="text"
                      helperText={errors.mobile && errors.mobile.message}
                      fullWidth
                      inputProps={{
                        data_cy: `patient-mobile-input`,
                        maxLength: 10,
                      }}
                    />

                    {errors.mobile && errors.mobile.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Number length should be 10
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.email)}
                      name="email"
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      control={control}
                      inputProps={{
                        data_cy: `patient-email-input`,
                      }}
                      defaultValue=""
                      label="Email"
                      type="email"
                      helperText={errors.email && errors.email.message}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <label>Tentative Age</label>
                    <Controller
                      control={control}
                      as={<Checkbox />}
                      inputProps={{
                        data_cy: `patient-is-tentative-age-checkbox`,
                      }}
                      name="is_tentative_age"
                      defaultValue={false}
                      onChange={([e]) => {
                        getVal(e.target.checked);
                        return e;
                      }}
                      // checked={patient.tentative_age || false }
                    />
                  </Grid>
                  {isAge === false && Dob()}
                  {isAge === true && (
                    <Grid item xs={12} sm={3} md={3}>
                      <Controller
                        as={<TextField />}
                        error={Boolean(errors.age)}
                        name="age"
                        rules={{
                          required: "Age is required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only Numbers are allowed",
                          },
                        }}
                        inputProps={{
                          data_cy: `patient-age-input`,
                        }}
                        control={control}
                        defaultValue={age || ""}
                        label="Age"
                        type="text"
                        helperText={errors.age && errors.age.message}
                        fullWidth
                        // onKeyDown={Alpha}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 120 }}
                      error={Boolean(errors.marital_status)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Marital Status *
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {maritalStatus.map((option) => (
                              <MenuItem
                                data_cy={`patient-marital-status-option-${option.key}`}
                                key={option.key}
                                value={option.key}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy: `patient-marital-status-dropdown`,
                        }}
                        name="marital_status"
                        rules={{ required: "Marital Status is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.marital_status && errors.marital_status.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <FormControl
                      style={{ minWidth: 120 }}
                      error={Boolean(errors.gender)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Gender *
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {genders.map((option) => (
                              <MenuItem
                                data_cy={`patient-gender-option-${option.key}`}
                                key={option.key}
                                value={option.key}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="gender"
                        inputProps={{
                          data_cy: `patient-gender-dropdown`,
                        }}
                        rules={{ required: "Gender is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.gender && errors.gender.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      name="address"
                      control={control}
                      inputProps={{
                        data_cy: `patient-address-input`,
                      }}
                      defaultValue=""
                      label="Address"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <FormControl style={{ minWidth: 125 }}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {states.map((option) => (
                              <MenuItem
                                data_cy={`patient-state-option-${option.id}`}
                                key={option.id}
                                value={option.id}
                              >
                                {option.state_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy: `patient-state-dropdown`,
                        }}
                        name="state_id"
                        control={control}
                        onChange={([selected]) => {
                          getCity(selected.target.value);
                          return selected;
                        }}
                        defaultValue=""
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 125 }}
                      error={Boolean(errors.city_id)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {cities.map((option) => (
                              <MenuItem
                                data_cy={`patient-city-option-${option.id}`}
                                key={option.id}
                                value={option.id}
                              >
                                {option.city_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy: `patient-city-dropdown`,
                        }}
                        name="city_id"
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.pincode)}
                      name="pincode"
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 6,
                      }}
                      control={control}
                      defaultValue=""
                      label="Pincode"
                      type="text"
                      helperText={errors.pincode && errors.pincode.message}
                      fullWidth
                      inputProps={{
                        data_cy: `patient-pincode-input`,
                        maxLength: 6,
                      }}
                    />

                    {errors.pincode && errors.pincode.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Pincode length should be 6
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      name="alternate_contact_person"
                      error={Boolean(errors.alternate_contact_person)}
                      control={control}
                      defaultValue=""
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      inputProps={{
                        data_cy: `patient-alternate-contact-person-input`,
                      }}
                      rules={{
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Only text is allowed",
                        },
                      }}
                      helperText={
                        errors.alternate_contact_person &&
                        errors.alternate_contact_person.message
                      }
                      label="Alternate Contact Person"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      name="alternate_contact_relationship"
                      error={Boolean(errors.alternate_contact_relationship)}
                      control={control}
                      defaultValue=""
                      rules={{
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Only text is allowed",
                        },
                      }}
                      inputProps={{
                        data_cy: `patient-alternate-contact-relationship-input`,
                      }}
                      helperText={
                        errors.alternate_contact_relationship &&
                        errors.alternate_contact_relationship.message
                      }
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      label="Alternate Contact Relationship"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.alternate_contact_number)}
                      name="alternate_contact_number"
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 10,
                      }}
                      control={control}
                      defaultValue=""
                      label="Alternate Contact Number"
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      type="text"
                      helperText={
                        errors.alternate_contact_number &&
                        errors.alternate_contact_number.message
                      }
                      fullWidth
                      inputProps={{
                        data_cy: `patient-alternate-contact-number-input`,
                        maxLength: 10,
                      }}
                    />

                    {errors.alternate_contact_number &&
                      errors.alternate_contact_number.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          Number length should be 10
                        </span>
                      )}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} container item justify="flex-end">
                  <CardFooter>
                    <CustomButton
                      data-testid="save-patient-button"
                      style={{ backgroundColor: "rgb(61, 170, 153)" }}
                      id="register"
                      disabled={isButtonDisable}
                      type="submit"
                    >
                      Save
                    </CustomButton>
                    <CustomButton
                      style={{
                        color: "rgb(61, 170, 153)",
                        border: "1px solid rgb(226 226 226)",
                        backgroundColor: "rgb(255 255 255)",
                        boxShadow: "none",
                        marginLeft: "3%",
                      }}
                      component={RouterLink}
                      to="/patient_list"
                    >
                      Cancel
                    </CustomButton>
                    <ToastContainer />
                  </CardFooter>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} sm={3} md={3}>
              <Card
                style={{
                  marginTop: "45px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 2px 8px, rgba(0, 0, 0, 0.22) 0px 10px 12px",
                }}
                className={classes.card}
              >
                <CardAvatar profile>{ImageComponent()}</CardAvatar>
                <CardBody>
                  <Grid container spacing={0}>
                    <Grid
                      container
                      justify="center"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <CustomButton
                        onClick={handleWebcam}
                        data-testid="capture-patient-image-button"
                      >
                        Capture Image
                      </CustomButton>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          </Hidden>
        </Grid>
      </form>
    </div>
  );
};

export default EditPatient;
