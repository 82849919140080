import React, { useEffect } from "react";
import { Grid, Checkbox } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  formGroup: {
    padding: "10px 10px",
  },
}));

function SpecialCampForm(props) {
  const { isFemale, setIsPregnant, setRequiresFemale } = props;
  const classes = useStyles();
  const { control, watch } = useForm();

  const isPregnant = watch("isPregnant");
  const requiresFemale = watch("requiresFemale");

  useEffect(() => {
    setIsPregnant(isPregnant);
    setRequiresFemale(requiresFemale);
  }, [isPregnant, requiresFemale]);

  return (
    <div>
      {isFemale && (
        <Grid className={classes.formGroup} item xs={12} sm={12} md={12}>
          <div>
            <label>Is Pregnant</label>
            <Controller
              control={control}
              as={<Checkbox />}
              name="isPregnant"
              defaultValue={false}
            />
          </div>
          <div>
            <label>Requires Female Doctor</label>
            <Controller
              control={control}
              as={<Checkbox />}
              name="requiresFemale"
              defaultValue={false}
            />
          </div>
        </Grid>
      )}
    </div>
  );
}

export default SpecialCampForm;
