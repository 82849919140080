import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
// import moment from "moment";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  inner: {
    display: "flex",
  },
  body: {
    backgroundColor: "rgb(52 144 130)",
    maxWidth: 450,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  otherUser: {
    display: "flex",
    justifyContent: "flex-end",
    "& $body": {
      backgroundColor: "#dedede",
      color: theme.palette.text.primary,
    },
  },
  content: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ChatBoxDetail = (props) => {
  const { message } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.otherUser]: message.hasOwnProperty("sender")
          ? message.sender.otherUser
          : "",
      })}
    >
      <div className={classes.inner}>
        <div>
          <div className={classes.body}>
            <Typography
              style={{
                color: message.sender.otherUser ? "black" : "white",
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {message.sender.otherUser ? message.sender.name : "You"}
            </Typography>
            <div className={classes.content}>
              <Typography color="inherit" variant="body1">
                {message.content}
              </Typography>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.time} variant="body2">
              {message.created_at}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxDetail;
