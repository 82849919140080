import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../../../../components/Card/Card";
import CardAvatar from "../../../../components/Card/CardAvatar";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import Timeline from "../../Timeline/Timeline";
import avatar from "../../../../assets/img/patient.png";
import styles from "../../../../assets/jss/material-dashboard-react/views/PatientViewCard";
import PatientTab from "../../../../components/PatientTab/PatientTab";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import "../../../../assets/css/toast.css";
import LockIcon from "@material-ui/icons/Lock";
import TodayIcon from "@material-ui/icons/Today";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "../Scroll.css";
import { capitalizeFirstLetter,ageCalculator } from "../../../../utils/helper";

const useStyles = makeStyles(styles);

const PatientDetail = (props) => {
  const { patient, activeStep, steps } = props;

  const classes = useStyles();

  const TimeLineComponent = (patient) => {
    return (
      <Card
        style={{
          marginTop: "-10px",
          marginLeft: "9px",
        }}
      >
        <CardHeader color="success" style={{ padding: 5, marginTop: -16 }}>
          <h4 className={classes.cardTitleWhite} style={{ fontWeight: 500 }}>
            Patient Timeline
          </h4>
        </CardHeader>
        <div className="scrollbar" id="style-1" style={{ maxHeight: "278px" }}>
          <div className="force-overflow" style={{ minHeight: 450 }}>
            {/* <CardBody> */}
            <div style={{ marginLeft: 6 }}>
              <Timeline patient={patient} />
            </div>
            {/* </CardBody> */}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={3}>
          {patient.attributes !== undefined ? (
            <React.Fragment>
              <Card profile className={classes.card} style={{ marginTop: 28 }}>
                <CardAvatar profile style={{ margin: "-31px auto 0px" }}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {patient.attributes.profile_photo !== null ? (
                      <img
                        src={patient.attributes.profile_photo}
                        style={{ width: 132, height: 147 }}
                        alt="..."
                      />
                    ) : (
                      <img src={avatar} alt="..." />
                    )}
                  </a>
                </CardAvatar>
                <CardBody style={{ marginTop: "-17px" }}>
                  <h6
                    className={classes.cardCategory}
                    style={{ textAlign: "center", fontWeight: 600 }}
                  >
                    {patient.attributes.name}
                  </h6>
                  {/* <br /> */}
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats} style={{ marginTop: 4 }}>
                        <TodayIcon />
                        <span style={{ fontWeight: 600 }}>Age/Gender: </span>
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {ageCalculator(patient.attributes.dob)}/
                          {capitalizeFirstLetter(patient.attributes.gender)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <SupervisedUserCircleIcon />
                        <span style={{ fontWeight: 600 }}>Marital Status:</span>
                        <span style={{ marginLeft: 5 }}>
                          {patient.attributes.marital_status?.value}
                        </span>
                      </div>
                    </Grid>
                    {patient.attributes.city !== null ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.stats}>
                          <LocationOnIcon />
                          <span style={{ fontWeight: 600 }}>City:</span>
                          <span style={{ marginLeft: 5 }}>
                            {patient.attributes.city?.name}
                          </span>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.stats}>
                          <LocationOnIcon />
                          <span style={{ fontWeight: 600 }}>City:</span>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <PhoneAndroidIcon />
                        <span style={{ fontWeight: 600 }}>Number:</span>
                        <span style={{ marginLeft: 5 }}>
                          {patient.attributes.mobile}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <EmailIcon />
                        <span style={{ fontWeight: 600 }}>Email:</span>
                        <span style={{ marginLeft: 5 }}>
                          {patient.attributes.email}
                        </span>
                      </div>
                    </Grid>
                    <br />
                    {patient.attributes.last_visit.is_sent_for_review === 1 ||
                    patient.attributes.last_visit.is_sent_to_patient_pool ===
                      1 ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <div
                          style={{
                            marginLeft: 32,
                            marginTop: -4,
                          }}
                        >
                          <LockIcon style={{ marginLeft: 50 }} />
                          <Typography
                            style={{
                              // fontSize: 15,
                              fontWeight: 600,
                              color: "brown",
                            }}
                          >
                            The Visit is locked
                          </Typography>
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </CardBody>
              </Card>
              {/* <br/> */}
              {TimeLineComponent(patient)}
            </React.Fragment>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <PatientTab
            patient={patient}
            {...props}
            activeStep={activeStep}
            steps={steps}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(PatientDetail);
