/** @format */

import React from "react";

const AstrickInLabel = () => {
  return (
    <>
      <span style={{ color: "red", fontSize: 14, fontWeight: 400 }}>*</span>
    </>
  );
};

export default AstrickInLabel;
