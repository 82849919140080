import React from "react";
import { Dialog, Typography, Avatar, Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import DoctorImage from "../../assets/img/doctorbio.png";
import { makeStyles } from "@material-ui/styles";
import CallerSymbol from "components/CallerSymbol/CallerSymbol";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  dialogPaper: {
    height: "80vh",
    width: "75vh",
    backgroundColor: "beige",
  },
}));

const CallAlert = ({
  open,
  // blockUi,
  countDownTime,
  handleVideoCallResponse,
}) => {
  const classes = useStyles();
  const data = JSON.parse(localStorage.getItem('vonageData'))
  return (
    <Dialog
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogPaper }}
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "20px" }}
          >
            Call from
          </Typography>
          <div style={{ marginTop: "4%" }}>
            <Avatar
              className={classes.avatar}
              src={data ? data.doctor_profile_photo : DoctorImage}
              size="large"
            />
          </div>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Typography
            align="center"
            // gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "21px" }}
          >
            Dr. {data?.doctor_name}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "21px", fontWeight: 600 }}
          >
            {countDownTime}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          alignItems="center"
        >
          <Typography
            align="center"
            variant="h5"
            style={{ marginTop:'3%',fontSize: "21px" }}
          >
           <span style={{ fontSize: "18px",fontWeight:400,color: "#585757" }}>Patient: </span>{data?.patient_name}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <>
          <Typography
            align="center"
            variant="h5"
            style={{ marginTop: "4%", fontStyle: "italic", color: "grey" }}
          >
            Click below to answer
          </Typography>
          <CallerSymbol handleVideoCallResponse={handleVideoCallResponse} />
          </>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CallAlert;
