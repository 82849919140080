import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Divider } from "@material-ui/core";
import Accordian from "../../components/Accordian/Accordian";
import { Route, Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CachedIcon from "@material-ui/icons/Cached";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ModeSelectToggle } from "../../components/Navbar/ModeSelect";
import RedeemIcon from "@material-ui/icons/Redeem";
import ChatIcon from "@material-ui/icons/Chat";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CHIKITSA_SATHI_GUIDELINE_URL,DEFAULT_GUIDELINE_URL } from '../../utils/guidelinesUrl'



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: (0, 5, 0, 5),
  },
  grow: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AccountPage(props) {
  const classes = useStyles();
  const msoPlanTitle = localStorage.getItem("ECONSULT_STORE_PLAN_TITLE")

  const guidlineUrl =
  msoPlanTitle === "chikitsa_sathi_5k"
    ? CHIKITSA_SATHI_GUIDELINE_URL
    : DEFAULT_GUIDELINE_URL;

  function handleGuidelines(){
    window.open(guidlineUrl, "_blank");
  }

  return (
    <Hidden lgUp>
      <div className={classes.root}>
        <Accordian title="My Account">
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button to={`/account/profile`} component={Link}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
            <Divider />
            <ListItem button to={`/account/account_detail`} component={Link}>
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Account Details" />
            </ListItem>
          </List>
        </Accordian>
        <Accordian title="App Setting">
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button to={`/account/change_password`} component={Link}>
              <ListItemIcon>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Mode" />
              <div className={classes.grow} />
              <ModeSelectToggle
                msoMode={props.msoMode}
                onModeChange={props.onModeChange}
              />
            </ListItem>
          </List>
        </Accordian>
        <Accordian title="Promo Code">
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button to={`/account/promocode`} component={Link}>
              <ListItemIcon>
                <RedeemIcon />
              </ListItemIcon>
              <ListItemText primary="Promo code" />
            </ListItem>
          </List>
        </Accordian>
        <Accordian title="Camp Details">
          <List component="nav" aria-label="">
            <ListItem button to={`/account/camp`} component={Link}>
              <ListItemIcon>
                <LocalPharmacyIcon />
              </ListItemIcon>
              <ListItemText primary="Camp Request" />
            </ListItem>
          </List>
        </Accordian>
        <Accordian data-testid="raiseissue-button" title="Raise Issue">
          <List component="nav" aria-label="">
            <ListItem
              data-testid="registeranissue-button"
              button
              to={`/account/raise_issue`}
              component={Link}
            >
              {/* <ListItemIcon>
                <RedeemIcon />
              </ListItemIcon> */}
              <ListItemText primary="Register an Issue" />
            </ListItem>
          </List>
        </Accordian>
        <Accordian color="#dd8121" onPress={handleGuidelines} title="Guidelines for e-Clinic">
        </Accordian>
        <Accordian title="Chat Support COMING SOON!">
          <List component="nav" aria-label="">
            <ListItem button to={`/account/chat_support`} component={Link}>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="Start Chat" />
            </ListItem>
          </List>
        </Accordian>
      </div>
    </Hidden>
  );
}

export default AccountPage;
