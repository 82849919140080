import React, { useState, useEffect } from "react";
import { getRequest } from "../../api/Api";
import PromocodeHistory from "./PromocodeHistory";
import Loader from "../../components/Loader/Loader";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import { TablePagination, Grid } from "@material-ui/core";
import FilterPromoHistory from "../../components/FilterData";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  searchHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
    },
  },
});

const useStyles = makeStyles(styles);

const PromocodeHistoryContainer = () => {
  const classes = useStyles();
  const [promocodeHistory, setPromocodeHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [IsLoading, setIsLoading] = useState(false);
  const [clearCounter, setClearCounter] = useState(0);
  const [selectedDates, setSelectedDates] = useState();

  useEffect(() => {
    let isMounted = true;
    const fetchPromocodeHistory = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("get_expired_promo_codes", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setPromocodeHistory(data.data.data);
            setTotalCount(data.total_rows);
            console.log(data.data.data);
          }
        }
      });
    };
    fetchPromocodeHistory();
    return () => {
      isMounted = false;
    };
  }, [clearCounter]);

  const handleChangePage = (event, page) => {
    setPage(page);
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;
    params.from_date = selectedDates ? selectedDates.start_date : '';
    params.to_date = selectedDates ? selectedDates.end_date : '';
    let queryString = encodeGetParams(params);
    getQueryRequest("get_expired_promo_codes", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPromocodeHistory(data.data.data);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    let params = {};
    params.page = page + 1;
    params.items = event.target.value;
    params.from_date = selectedDates ? selectedDates.start_date : '';
    params.to_date = selectedDates ? selectedDates.end_date : '';
    let queryString = encodeGetParams(params);
    getQueryRequest("get_expired_promo_codes", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPromocodeHistory(data.data.data);
      }
    });
  };

  const filterPromoHistoryHandler = (data) => {
    setSelectedDates(data)
    setIsLoading(true);
    const token = "Bearer " + localStorage.getItem("jwt");
    let Endpoint = `get_expired_promo_codes?from_date=${data.start_date}&to_date=${data.end_date}`;
    let params = [];
    getRequest(Endpoint, token, params).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setIsLoading(false);
        setPromocodeHistory(data.data.data);
        setTotalCount(data.total_rows);
      }
    });
  };

  const clearCounterHandler = () => {
    setClearCounter(clearCounter + 1);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.searchHeading}>
          <FilterPromoHistory
            sendData={(value) => filterPromoHistoryHandler(value)}
            clearCounterHandler={clearCounterHandler}
            buttonText="Filter"
            headingText="Filter Criteria"
            testing ="promocode"
          />
        </Grid>
        {IsLoading ? (
          <Loader />
        ) : (
          <Grid item xs={12} sm={12} md={12}>
            <PromocodeHistory
              headCells={headCells}
              tableData={promocodeHistory}
            />
            <TablePagination
              component="div"
              count={parseInt(totalCount)}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PromocodeHistoryContainer;

const headCells = [
  { key: "title", label: "Title" },
  { key: "discount", label: "Discount" },
  { key: "code", label: "Code" },
  { key: "maxUsage", label: "Max Usage" },
  { key: "usedCount", label: "Used Count" },
  { key: "status", label: "Status" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
];
