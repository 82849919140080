import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import VitalsForm from "../../components/Forms/Vitals";
import MedicalProblemForm from "../../components/Forms/MedicalProblem";
import FamilyHistoryForm from "../../components/Forms/FamilyHistory";
import LifeStyleForm from "../../components/Forms/LifeStyle";
import AllergyForm from "../../components/Forms/Allergy";
import CurrentMedicationForm from "../../components/Forms/CurrentMedication";
import DocumentUpload from "../../components/FormsTable/Documents";
import { ShowVisitDetails } from "../PatientPageNew";
import { withRouter } from "react-router";
import SendForReview from "./SendForReview";

const useStyles = makeStyles(theme => ({
  layout: {
    marginBottom: theme.spacing(3),
    // padding: theme.spacing(3),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 45,
    height: 30,
    marginTop: "-2%",
  },
}));

function getStepContent(
  step,
  steps,
  handleNext,
  handleBack,
  patient,
  setShowStepper,
  setIsReviewOnly,
  isReviewOnly,
) {
  switch (step) {
    case 0:
      return (
        <MedicalProblemForm
          step={step}
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 1:
      return (
        <CurrentMedicationForm
          step={step}
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 2:
      return (
        <VitalsForm
          step={step}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 3:
      return (
        <LifeStyleForm
          step={step}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 4:
      return (
        <FamilyHistoryForm
          step={step}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 5:
      return (
        <AllergyForm
          step={step}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          patient={patient}
          isReviewOnly={isReviewOnly}
        />
      );
    case 6:
      return (
        <DocumentUpload
          step={step}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          patient={patient}
          setShowStepper={setShowStepper}
          setIsReviewOnly={setIsReviewOnly}
          isReviewOnly={isReviewOnly}
        />
      );

    default:
      throw new Error("Unknown step");
  }
}

const StepperComponent = props => {
  const classes = useStyles();
  const {
    activeStep,
    handleNext,
    handleBack,
    steps,
    vitalLength,
    medicalProblemLength,
    isReviewOnly,
    setShowStepper,
    setIsReviewOnly,
  } = props;

  //   // Use an array of arrays where the first value handles the index and
  //   // second value holds the array on which the iteration is to be done
  //   //  containing each of the values given in the index.

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <main className={classes.layout}>
            {/* <Paper > */}
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {/* <ShowVisitDetails
                    vitalLength={vitalLength}
                    medicalProblemLength={medicalProblemLength}
                    visitId={props.patient.attributes.last_visit_id}
                    isReviewOnly={isReviewOnly}
                    patient={props.patient}
                  /> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(
                    activeStep,
                    steps,
                    handleNext,
                    handleBack,
                    props.patient,
                    setShowStepper,
                    setIsReviewOnly,
                    isReviewOnly,
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
            {/* </Paper> */}
          </main>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(StepperComponent);
