import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useTable from "../../../../components/UseTable/useTable";
import { TableBody, TableRow, TableCell, Grid } from "@material-ui/core";

const styles = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 7,
    marginTop: -30,
    float: "right",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles(styles);

const headCells = [
  { id: "complaint", label: "Complaint" },
  { id: "progression", label: "Progression" },
  { id: "occurence", label: "Occurence" },
  { id: "aggravating", label: "Aggravating Factor" },
  { id: "relieving", label: "Relieving Factor" },
  { id: "current", label: "Current Status" },
  { id: "duration", label: "Duration" },
  { id: "associated", label: "Associated Sym" },
];

const MedicalProblemData = (props) => {
  const { medicalProblemData } = props;
  const classes = useStyles();

  const { TblContainer, TblHead } = useTable(medicalProblemData, headCells);

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.results}>
            <TblContainer>
              <TblHead style={{ backgroundColor: "rgb(175 170 170)" }} />
              <TableBody>
                {medicalProblemData.map((item) => (
                  <StyledTableRow key={item.id}>
                    {item.attributes.complaint_title !== null ? (
                      <TableCell>{item.attributes.complaint_title}</TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    {item.attributes.progression !== null ? (
                      <TableCell>{item.attributes.progression.value}</TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    {item.attributes.occurrence !== null ? (
                      <TableCell>{item.attributes.occurrence.value}</TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      {medicalProblemData[0].attributes.aggravating_factor !==
                      null ? (
                        <ul>
                          {medicalProblemData[0].attributes.aggravating_factor.map(
                            (item) => (
                              <li key={item.id}>{item.phrase_english}</li>
                            )
                          )}
                        </ul>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {medicalProblemData[0].attributes.relieving_factor !==
                      null ? (
                        <ul>
                          {medicalProblemData[0].attributes.relieving_factor.map(
                            (item) => (
                              <li key={item.id}>{item.phrase_english}</li>
                            )
                          )}
                        </ul>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item.attributes.current_status !== null
                        ? item.attributes.current_status.value
                        : ""}
                    </TableCell>
                    <TableCell>{`${
                      item.attributes.duration !== null
                        ? item.attributes.duration
                        : ""
                    } ${
                      item.attributes.duration_type !== null
                        ? item.attributes.duration_type.value
                        : ""
                    }`}</TableCell>
                    <TableCell>
                      {medicalProblemData[0].attributes
                        .associated_symptom_ids !== null ? (
                        <ul className="ulmedical">
                          {medicalProblemData[0].attributes.associated_symptom_ids.map(
                            (item) => (
                              <li className="limedical" key={item.id}>
                                {item.phrase_english}
                              </li>
                            )
                          )}
                        </ul>
                      ) : null}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MedicalProblemData;
