import React from "react";
import useWindowSize from "./useWindowSize";

export const withHooksHOC = Component => {
  return props => {
    const screenWidth = useWindowSize();

    return <Component width={screenWidth} {...props} />;
  };
};
