import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ListItem, ListItemText, List, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  primaryHeading: { fontWeight: 500, fontSize: 16 },
  list: { display: "flex", gap: 12, cursor: "pointer" },
}));

function CampList(props) {
  const { campData, divider, onSelectCamp, patientId } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCampSelect = ({ campId, promoId }) => {
    if (campData.camp.permits_doctor_selection) {
      history.push(`/book_appointment/${patientId}?type=specific`);
    } else {
      onSelectCamp({ campId, promoId });
    }
  };
  return (
    <List>
      <ListItem
        onClick={() =>
          handleCampSelect({
            campId: campData?.camp?.id,
            promoId: campData?.id,
          })
        }
        className={classes.list}
        divider={divider}
      >
        <ListItemText
          primary={
            <Typography type="body2" className={classes.primaryHeading}>
              {campData.camp.title}
            </Typography>
          }
          primaryTypographyProps={{
            noWrap: true,
            variant: "h6",
          }}
          secondary={`${campData.camp.description}`}
          secondaryTypographyProps={{
            variant: "body1",
          }}
        />
      </ListItem>
    </List>
  );
}

export default CampList;
