import React from "react";
import PropTypes from "prop-types";
import { Dialog, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import WarningImage from "../../../assets/img/warning-emoji.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { postRequest } from "../../../api/Api";
import { blockButton  } from '../../../utils/helper'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelVisitAlert = (props) => {
  const { message, handleRedirection, dataToSend, onClose, open,isCurrentTime } = props;
  const handleCloseDialogBox = () => {

    if(isCurrentTime){
      let dateOfAppointment = moment.utc(dataToSend.formData.get( "appointment[appointment_datetime]"))
      .local()
      .format("YYYY-MM-DD")
      dateOfAppointment += " " + moment().format("HH:mm");;
      const newDate = new Date(dateOfAppointment).toISOString();
      dataToSend.formData.set(
        "appointment[appointment_datetime]",newDate);
    }
    dataToSend.formData.append("appointment[confirm_cancel]", 1);
    const body = {
      token: dataToSend.token,
      endPoint: "appointments",
      formData: dataToSend.formData,
    };
    blockButton('visit',true);
    postRequest(body).then((data) => {
      if (data.error) {
        blockButton('visit',false);
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
        setTimeout(() => {
          onClose();
          handleRedirection();
        }, 1000);
      }
    });
  };

  return (
    <Dialog onClose={onClose} TransitionComponent={Transition} open={open}>
      <div>
        <div style={{ marginTop: "4%" }}>
          <img
            src={WarningImage}
            style={{ width: "20%", marginLeft: "40%" }}
            alt="logo"
          />
        </div>
        <div style={{ marginTop: "-5%" }}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            style={{ marginTop: "4%", fontSize: "21px" }}
          >
            Opps
          </Typography>
        </div>
        <CardBody>
          <Typography
            variant="subtitle2"
            style={{ textAlign: "center", marginTop: "-2%" }}
          >
            {message}
          </Typography>
        </CardBody>
        <Button
          style={{
            backgroundColor: "rgb(49 75 169)",
            left: "34%",
            marginBottom: "2%",
          }}
          id="visit"
          onClick={() => handleCloseDialogBox()}
        >
          Yes
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(189 189 189)",
            left: "35%",
            marginBottom: "2%",
          }}
          onClick={onClose}
        >
          No
        </Button>
      </div>
    </Dialog>
  );
};

CancelVisitAlert.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default CancelVisitAlert;
