import React, { useState, Fragment, useRef } from "react";
import {
  Button,
  Badge,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ActionCableNotificationChannel from "../ActionCable/ActionCableNotificationChannel";

const notificationOptions = [
  "Another Notification from nurse",
  "Another Notification from admin",
];

const NotificationIndicator = ({ modeTextColor }) => {
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);
  // notifications state should be renamed to better represent the idea that it will
  // only take in strings, more precisely an array of strings. Using TSX is what will
  // really give us great experience with these issues
  const [notifications, setNotifications] = useState(notificationOptions);
  // make notifications a state that is updated based on the ActionCable consumer

  const addNewNotification = ({ data }) => {
    setNotifications(notifications => [data, ...notifications]);
  };

  const handleClose = event => {
    if (
      notificationRef.current &&
      notificationRef.current.contains(event.target)
    ) {
      return;
    }
    setShowNotification(false);
  };

  const handleToggle = () => {
    setShowNotification(prevOpen => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowNotification(false);
    }
  }

  const createNotificationItems = notifications => {
    return notifications.map((notification, index) => {
      console.log(notifications);
      console.log(notification);
      let content = "";
      if (notification.trim().length > 30) {
        content = `${notification.trim().slice(0, 25)}...`;
      } else {
        content = notification.trim();
      }
      return (
        <MenuItem key={index} onClick={handleClose}>
          {content}
        </MenuItem>
      );
    });
  };

  return (
    <Fragment>
      <ActionCableNotificationChannel
        handleNotification={data => addNewNotification(data)}
      >
        <Button
          ref={notificationRef}
          aria-controls={showNotification ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge color="error" badgeContent={notifications.length} max={20}>
            <NotificationsIcon style={modeTextColor} />
          </Badge>
        </Button>

        <Popper
          open={showNotification}
          anchorEl={notificationRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={showNotification}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {createNotificationItems(notifications)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ActionCableNotificationChannel>
    </Fragment>
  );
};

export default NotificationIndicator;
