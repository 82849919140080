import React, { useState, useEffect } from "react";
import "./UserProfile.css";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import baseUrl from "../../utils/baseUrl";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import CardAvatar from "../../components/Card/CardAvatar";
import { DirectUpload } from "@rails/activestorage";
import TextField from "@material-ui/core/TextField";
import { Alert, AlertTitle } from "@material-ui/lab";
import "../../assets/css/toast.css";
import WcIcon from "@material-ui/icons/Wc";
import TodayIcon from "@material-ui/icons/Today";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LanguageIcon from "@material-ui/icons/Language";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import {  blockButton } from '../../utils/helper'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getRequest, postRequest } from "../../api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const styles = (theme) => ({
  root: {
    padding: "16px",
  },
  card: {
    boxShadow: "0 2px 8px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22)",
  },
  divider: {
    position: "absolute",
    margin: "12px",
    maxWidth: "60px",
    borderRadius: "100%",
    // border: '1px dashed #aaa',
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCategoryWhite: {
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardCategory: {
    color: "black",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    fontWeight: 400,
    marginBottom: "0",
    // textShadow: '2px 2px 5px grey'
  },
  stats: {
    color: "#352e2e",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "26px",
    marginLeft: "-10px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "5px",
    },
  },
  danger: {
    color: "brown",
    marginTop: 4,
    fontSize: 15,
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    marginBottom: "2px",
    fontSize: "14px",
    marginTop: 9,
  },
});

const useStyles = makeStyles(styles);

const UserProfile = (props) => {
  const { control, handleSubmit, errors, watch, setValue } = useForm();

  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [cities, setCities] = useState([]);
  const [states, setState] = useState([]);
  const [defLanguage, setDefLanguage] = useState([]);
  const [user, setUser] = useState([]);
  const [tempUser, setTempUser] = useState([]);
  const [imageFileName, setImageFileName] = useState("");

  const loadStates = () => {
    getRequest("get_states").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setState(data);
      }
    });
  };
  const loadDefaultLanguage = () => {
    getRequest("get_default_languages").then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setDefLanguage(data);
      }
    });
  };

  const fetchUserDetail = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("current_user", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setUser(data.data);
      }
    });
  };

  const fetchTempUser = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_current_temp_user_details", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setTempUser(data.data);
      }
    });
  };

  const getCity = (state_id) => {
    const cityEndpoint = "get_cities";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [state_id];
    getRequest(cityEndpoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCities(data);
      }
    });
  };

  useEffect(() => {
    loadStates();
    loadDefaultLanguage();
    fetchUserDetail();
    fetchTempUser();
  }, []);

  useEffect(() => {
    if (user.length !== 0) {
      if (user.is_qualified_mso !== undefined) {
      }
      setValue("name", user.attributes.name || "");
      if (user.attributes.dob !== null) {
        setValue("dob", moment(user.attributes.dob).toDate() || "");
      }
      setValue("mobile", user.attributes.mobile || "");
      setValue("email", user.attributes.email || "");
      let genderValue = !user.attributes.gender
        ? null
        : user.attributes.gender === "male"
        ? "Male"
        : "Female";
      setValue("gender", genderValue || "");
      setValue("address", user.attributes.address || "");
      if (user.attributes.pincode !== null) {
        setValue("pincode", user.attributes.pincode || "");
      }
      if (user.attributes.state !== null) {
        setValue("state_id", user.attributes.state.id || "");
        getCity(user.attributes.state.id);
      }
      if (user.attributes.city !== null) {
        setValue("city_id", user.attributes.city.id || "");
      }
      setValue("alternate_contact_no", user.alternate_contact_no || "");
      if (user.attributes.default_language !== null) {
        setValue(
          "default_language",
          user.attributes.default_language.key || ""
        );
      }
    }
  }, [setValue, user]);

  const handleImage = (e) => {
    const [file] = e.target.files;
    setImageFileName(file.name);

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const promises = [];
  const handleImageUpload = (image) => {
    if (image.length !== 0) {
      promises.push(uploadFile(image[0]));
    }
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const URL = `${baseUrl}/rails/active_storage/direct_uploads`;
      const upload = new DirectUpload(file, URL);
      upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          const hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.id = `profile_pic`;
          document.querySelector("form").appendChild(hiddenField);
          resolve("Success");
        }
      });
    });
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        className={classes.input}
        type="text"
        data_cy = {`mso-profile-dob-input`}
        placeholder="Select DOB"
        readOnly={true}
      />
    );
  });

  const onSubmit = (data) => {
    handleImageUpload(imageUploader.current.files);
    if (user.attributes.is_qualified_mso !== undefined) {
      var is_qualified = user.attributes.is_qualified_mso.key;
    }

    Promise.all(promises)
      .then(() => {
        var formData = new FormData();

        // formData.append("temp_user[name]", data.name);
        if (data.email) {
          formData.append("temp_user[email]", data.email);
        }
        if (data.mobile) {
          formData.append("temp_user[mobile]", data.mobile);
        }
        // formData.append("temp_user[gender]", data.gender);
        if (data.address) {
          formData.append("temp_user[address]", data.address);
        }
        if (data.city_id) {
          formData.append("temp_user[city_id]", data.city_id);
        }
        if (data.state_id) {
          formData.append("temp_user[state_id]", data.state_id);
        }
        if (data.dob) {
          formData.append(
            "temp_user[dob]",
            moment(data.dob).format("YYYY-MM-DD")
          );
        }
        if (data.pincode) {
          formData.append("temp_user[pincode]", data.pincode);
        }
        if (data.default_language) {
          formData.append("temp_user[default_language]", data.default_language);
        }
        if (user.attributes.role !== null) {
          formData.append("temp_user[role]", user.attributes.role.role);
        }
        formData.append("temp_user[is_qualified_mso]", is_qualified);
        formData.append("temp_user[user_id]", user.attributes.id);

        if (imageUploader.current.files.length !== 0) {
          formData.append(
            "temp_user[profile_photo]",
            document.getElementById(`profile_pic`).value
          );
        }
        blockButton('profile',true);
        let token = "Bearer " + localStorage.getItem("jwt");
        const body = {
          token: token,
          endPoint: "edit_user_profile",
          formData: formData,
        };
        postRequest(body).then((data) => {
          if (data.error) {
            blockButton('profile',false);
            toast.error(<p>{data.error}</p>, { autoClose: 3000 });
          } else {
            toast(<p>{data.message}</p>, {
              className: "custom",
              autoClose: 1000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={9}>
            <Card data-testid={`mso-profile-card`}>
              <CardBody>
                <Grid container spacing={2}>
                  {tempUser !== null && tempUser.attributes !== undefined ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ float: "right" }}>
                        {tempUser.attributes.approval_status.key === 0 ? (
                          <Alert severity="warning">
                            Profile is yet to be Approved
                          </Alert>
                        ) : tempUser.attributes.approval_status.key === 2 ? (
                          <Alert severity="error">
                            <AlertTitle style={{ color: "brown" }}>
                              Oops Profile is Rejected !
                            </AlertTitle>
                            <h4 style={{ fontWeight: 500 }}>
                              {tempUser.attributes.remark}
                            </h4>
                          </Alert>
                        ) : (
                          <Alert severity="success">Profile is Approved</Alert>
                        )}
                      </div>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      name="name"
                      control={control}
                      inputProps={{
                        data_cy :`mos-profile-name-input`,
                        readOnly: true,
                      }}
                      defaultValue=""
                      label="Full Name"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      name="mobile"
                      error={Boolean(errors.mobile)}
                      control={control}
                      defaultValue=""
                      label="Mobile"
                      inputProps={{
                        data_cy : `mso-profile-mobile-input`,
                        maxLength: 10,
                      }}
                      rules={{
                        required: "Mobile Number is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 10,
                      }}
                      type="text"
                      helperText={errors.mobile && errors.mobile.message}
                      fullWidth
                    />
                    {errors.mobile && errors.mobile.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Number length should be 10
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      as={<TextField />}
                      name="email"
                      control={control}
                      defaultValue=""
                      inputProps={{
                        data_cy : `mso-profile-email-input`,
                        readOnly: true,
                      }}
                      label="Email"
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<ReactDatePicker />}
                      error={Boolean(errors.dob)}
                      control={control}
                      valueName="selected"
                      onChange={([selected]) => selected}
                      name="dob"
                      rules={{ required: "DOB is required" }}
                      filterDate={(date) => {
                        return moment() > date;
                      }}
                      isClearable
                      customInput={<CustomInput />}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="yyyy/MM/dd "
                      dropdownMode="select"
                      popperPlacement="bottom-start"
                    />
                    {errors.dob && (
                      <div style={{ color: "red" }}>DOB is required</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      name="gender"
                      control={control}
                      defaultValue=""
                      label="Gender"
                      inputProps={{
                        data_cy : `mso-profile-gender-input`,
                        readOnly: true,
                      }}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.address)}
                      name="address"
                      rules={{ required: "Address is required" }}
                      control={control}
                      inputProps={{
                        data_cy : `mso-profile-address-input`
                      }}
                      defaultValue=""
                      label="Address"
                      type="text"
                      helperText={errors.address && errors.address.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 170 }}
                      error={Boolean(errors.state_id)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {states.map((option) => (
                              <MenuItem 
                                data_cy ={`mso-profile-state-option-${option.id}`}
                                key={option.id} value={option.id}>
                                {option.state_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy : `mso-profile-state-dropdown`
                        }}
                        name="state_id"
                        rules={{ required: "State is required" }}
                        control={control}
                        onChange={([selected]) => {
                          getCity(selected.target.value);
                          return selected;
                        }}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.state_id && errors.state_id.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      style={{ minWidth: 170 }}
                      error={Boolean(errors.city_id)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Controller
                        as={
                          <Select>
                            {cities.map((option) => (
                              <MenuItem 
                                data_cy ={`mso-profile-city-option-${option.id}`}
                                key={option.id} value={option.id}>
                                {option.city_name}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy : `mso-profile-city-dropdown`
                        }}
                        name="city_id"
                        rules={{ required: "City is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.city_id && errors.city_id.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Controller
                      as={<TextField />}
                      error={Boolean(errors.pincode)}
                      name="pincode"
                      rules={{
                        required: "Pincode is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only Numbers are allowed",
                        },
                        minLength: 6,
                      }}
                      control={control}
                      defaultValue=""
                      label="Pincode"
                      inputProps={{
                        data_cy : `mso-profile-pincode-input`,
                        maxLength: 6,
                      }}
                      type="text"
                      helperText={errors.pincode && errors.pincode.message}
                      fullWidth
                    />

                    {errors.pincode && errors.pincode.type === "minLength" && (
                      <span style={{ color: "red" }}>
                        Pincode length should be 6
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      style={{ minWidth: 300 }}
                      error={Boolean(errors.default_language)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Default Language
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            {defLanguage.map((option) => (
                              <MenuItem 
                                data_cy ={`mso-profile-default-language-option-${option.key}`}
                                key={option.value} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        inputProps={{
                          data_cy : `mso-profile-default-language-dropdown`
                        }}
                        name="default_language"
                        rules={{ required: "Default Language is required" }}
                        control={control}
                        defaultValue=""
                      />
                      <FormHelperText>
                        {errors.default_language &&
                          errors.default_language.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <CardFooter style={{ float: "right" }}>
                  <Button  data-testid="updat-mso-profile-button" id="profile" type="submit">Update</Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            {user.attributes !== undefined ? (
              <Card className={classes.card} data-testid={`mso-profile-info-card`}>
                <CardAvatar profile style={{ margin: "-44px auto 0px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImage}
                    ref={imageUploader}
                    data_cy={`upload-image-input`}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    style={{
                      height: "132px",
                      width: "147px",
                      backgroundColor: "#489a9c",
                    }}
                    onClick={() => imageUploader.current.click()}
                  >
                    {user.attributes !== undefined ? (
                      <img
                        ref={uploadedImage}
                        // value={patient.profile_photo}
                        src={user.attributes.profile_photo}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "acsolute",
                          cursor: "pointer",
                        }}
                      />
                    ) : null}
                  </div>
                </CardAvatar>
                <CardBody>
                  <h6
                    className={classes.cardCategory}
                    style={{ textAlign: "center", fontWeight: 600 }}
                  >
                    {user.attributes.name}
                  </h6>
                  <br />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <TodayIcon />
                        <span style={{ fontWeight: 600 }}>Dob:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.dob}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <WcIcon />
                        <span style={{ fontWeight: 600 }}>Gender:</span>
                        <span style={{ marginLeft: 5 }}>
                          {!user.attributes.gender
                            ? null
                            : user.attributes.gender === "male"
                            ? "Male"
                            : "Female"}{" "}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <LanguageIcon />
                        <span style={{ fontWeight: 600 }}>Language:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.default_language.value}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <LocationOnIcon />
                        <span style={{ fontWeight: 600 }}>Role:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.role.role_title}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <PhoneAndroidIcon />
                        <span style={{ fontWeight: 600 }}>Mobile:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.mobile}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.stats}>
                        <EmailIcon />
                        <span style={{ fontWeight: 600 }}>Email:</span>
                        <span style={{ marginLeft: 5 }}>
                          {user.attributes.email}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </CardBody>
                <ToastContainer />
              </Card>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default UserProfile;
