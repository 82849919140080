import {
  grayColor,
  defaultFont,
} from "../../assets/jss/material-dashboard-react";

const tableStyle = (theme) => ({
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "white",
    backgroundColor: "rgb(145 155 152)",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.87rem",
    fontWeight: 400,
  },
  tableResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: 500,
      width: "100%",
      marginTop: theme.spacing(3),
      overflow: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      // maxHeight: 520,
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  tableHeadRow: {
    height: "40px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
});

export default tableStyle;
