/** @format */

import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Hidden,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TablePagination,
  Fab,
} from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { getRequest } from "../../api/Api";
import FlowboardStatusChange from "./FlowboardStatusChange";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import FeedBackModal from "./FeedbackModal";
import MoreOptionComponent from "./MoreoptionComponent";
import { abbreviatedName } from "../../utils/helper";
import { Link as RouterLink } from "react-router-dom";
import TextsmsIcon from "@material-ui/icons/Textsms";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import moment from "moment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { postRequest } from "../../api/Api";
import PrescriptionLoader from "../../components/PrescriptionLoader/PrescriptionLoader";
import { refreshContext } from "../../RefreshPrescription";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 350,
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  patientAvail: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 34,
    height: 30,
    margin: "2%",
  },
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      height: 500,
      // maxHeight: 200,
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 500,
    },
  },
  appAndWaitTime: {
    display: "flex",
    flexDirection: "column",
  },
  columnTable: {
    [theme.breakpoints.down("sm")]: {
      padding: 11,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 16,
    },
  },
}));

const FlowBoard = () => {
  const classes = useStyles();
  const [flowboardData, setFlowboardData] = useState([]);
  const [count, setCount] = useState(0);
  const [feedbackModalOpen, setfeedbackModalOpen] = useState(false);
  const [visitId, setVisitId] = useState();
  const { prescriptioncount } = useContext(refreshContext);

  const [flowboardStatusModalOpen, setFlowboardStatusModalOpen] = useState(
    false
  );
  const [updateFlowboardData, setUpdateFlowboardData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState();
  const [refreshCount, setRefreshCount] = useState(0);

  const getFlowboardData = (isMounted) => {
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;

    let queryString = encodeGetParams(params);
    getQueryRequest("get_flow_board_list", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setFlowboardData(data.data.data);
          setTotalCount(data.total_rows);
        }
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    let interval;
    interval = setInterval(() => {
      getFlowboardData(isMounted);
      setRefreshCount(refreshCount + 1);
    }, 900000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  useEffect(() => {
    let isMounted = true;
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;

    let queryString = encodeGetParams(params);
    getQueryRequest("get_flow_board_list", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (isMounted) {
          setFlowboardData(data.data.data);
          setTotalCount(data.total_rows);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, [count,prescriptioncount]);

  const handleChangePage = (event, page) => {
    setPage(page);
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;

    let queryString = encodeGetParams(params);
    getQueryRequest("get_flow_board_list", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setFlowboardData(data.data.data);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    let params = {};
    params.page = page + 1;
    params.items = event.target.value;
    let queryString = encodeGetParams(params);
    getQueryRequest("get_flow_board_list", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setFlowboardData(data.data.data);
      }
    });
  };

  const sendsms = (patientId, visitId) => {
    console.log(patientId);
    console.log(visitId);
    const endPoint = "send_prescription_link_to_patient";
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [patientId, visitId];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 1000,
        });
      }
    });
  };

  const makePatientAvailable = (patientId) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    let formData = new FormData();
    formData.append(
      "patient_availability_status_history[patient_id]",
      patientId
    );
    formData.append(
      "patient_availability_status_history[availability_status]",
      "available"
    );
    const body = {
      token: token,
      endPoint: "patient_availability_status_histories",
      formData: formData,
    };
    postRequest(body).then((data) => {
      if (data.error) {
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        handleCount();
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 2000,
        });
      }
    });
  };

  const handleCount = () => {
    setCount(count + 1);
  };

  const handlefeedbackopen = (visitId) => {
    setfeedbackModalOpen(true);
    setVisitId(visitId);
  };

  const handleFeedbackClose = () => {
    setfeedbackModalOpen(false);
  };

  const handleFlowboardStatusOpen = (appointmentId, patientId) => {
    setUpdateFlowboardData({
      appointmentId: appointmentId,
      patientId: patientId,
    });
    setFlowboardStatusModalOpen(true);
  };

  const handleFlowboardStatusClose = () => {
    setFlowboardStatusModalOpen(false);
  };

  const FlowboardStatusComponent = ({ flowboardData }) => {
    return (
      <>
        {flowboardData.attributes.flow_board ? (
          <React.Fragment>
            <TableCell
              style={{ fontSize: 13 }}
              className={classes.columnTable}
              align="right"
            >
              {flowboardData.attributes.flow_board.status ===
                "Waiting for doctor" ||
              flowboardData.attributes.flow_board.status ===
                "Details being recorded" ? (
                <Link
                  color="inherit"
                  data-testid={`flowboard-status-change-link-${flowboardData.attributes.flow_board.status.toLowerCase()}`}
                  style={{ cursor: "pointer" }}
                  variant="h6"
                  onClick={() =>
                    handleFlowboardStatusOpen(
                      flowboardData.attributes.id,
                      flowboardData.attributes.patient.id
                    )
                  }
                >
                  {flowboardData.attributes.flow_board.status}
                </Link>
              ) : (
                <>
                  <span style={{ color: "red" }}>
                    {flowboardData.attributes.flow_board.status}
                  </span>
                  {flowboardData.attributes.flow_board.status_key === 8 && (
                    <Link data-testid={`patient-available-link`}>
                      <Tooltip
                        title="Make Patient Available"
                        aria-label="Make Patient Available"
                        onClick={() =>
                          makePatientAvailable(
                            flowboardData.attributes.patient.id
                          )
                        }
                      >
                        <Fab className={classes.patientAvail}>
                          <CheckCircleOutlineIcon />
                        </Fab>
                      </Tooltip>
                    </Link>
                  )}
                </>
              )}
            </TableCell>
          </React.Fragment>
        ) : null}
      </>
    );
  };

  return (
    <>
      <TablePagination
        component="div"
        count={parseInt(totalCount)}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
      <Paper style={{ width: "100%" }}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Patient</TableCell>
                <Hidden smDown>
                  <TableCell align="left">Doctor</TableCell>
                  <TableCell align="center">Appointment Time</TableCell>
                  <TableCell align="right">Current Status</TableCell>
                </Hidden>
                <Hidden mdUp>
                  <TableCell align="center">App. Time</TableCell>
                  <TableCell align="center">Wait Time</TableCell>
                  <TableCell align="right">Status</TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell align="center">Tentative Wait Time</TableCell>
                  <TableCell align="right">Feedback</TableCell>
                  <TableCell align="right">Send sms</TableCell>
                  <TableCell align="right">Prescription</TableCell>
                </Hidden>
                <Hidden mdUp>
                  <TableCell align="right">Action</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>

            <TableBody>
              {flowboardData.map((flowboardData) => (
                <TableRow key={flowboardData.attributes.visit_id}>
                  <TableCell
                    className={classes.columnTable}
                    component="th"
                    scope="row"
                  >
                    <Link
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      data-testid={`navigate-to-patient-page`}
                      variant="h6"
                      href={`/patient_page/${flowboardData.attributes.patient.id}`}
                    >
                      {/* TODO - See if this hack can be improved */}
                      <div style={{ width: "100%", height: "100%" }}>
                        {abbreviatedName(flowboardData.attributes.patient.name)}
                      </div>
                    </Link>
                  </TableCell>
                  <Hidden smDown>
                    <TableCell className={classes.columnTable} align="left">
                      {flowboardData.attributes.doctor
                        ? abbreviatedName(flowboardData.attributes.doctor.name)
                        : flowboardData.attributes.doctor_category
                            .category_title}
                    </TableCell>
                    <TableCell className={classes.columnTable} align="center">
                      {moment
                        .utc(flowboardData.attributes.appointment_datetime)
                        .local()
                        .format("HH:mm")}
                    </TableCell>
                    <FlowboardStatusComponent flowboardData={flowboardData} />
                  </Hidden>
                  <Hidden mdUp>
                    <TableCell className={classes.columnTable} align="center">
                      {moment
                        .utc(flowboardData.attributes.appointment_datetime)
                        .local()
                        .format("HH:mm")}
                    </TableCell>
                    <TableCell className={classes.columnTable} align="center">
                      {flowboardData.attributes.visit?.tentative_wait_time} min
                    </TableCell>
                    <FlowboardStatusComponent flowboardData={flowboardData} />
                  </Hidden>

                  <Hidden smDown>
                    <TableCell className={classes.columnTable} align="center">
                      {flowboardData.attributes.visit?.tentative_wait_time} min
                    </TableCell>
                    {flowboardData.attributes.flow_board ===
                    null ? null : flowboardData.attributes.flow_board
                        .status_key === 7 ? (
                      <React.Fragment>
                        {flowboardData.attributes.flow_board.status_key === 7 &&
                        flowboardData.attributes.mso_to_doctor_feedback ===
                          null ? (
                          <TableCell
                            className={classes.columnTable}
                            align="right"
                          >
                            <Link data-testid={`flowboard-feedback-link`}>
                              <Tooltip
                                title="Feedback"
                                aria-label="Feedback"
                                onClick={() =>
                                  handlefeedbackopen(
                                    flowboardData.attributes.visit_id
                                  )
                                }
                              >
                                <Fab className={classes.fab}>
                                  <FeedbackIcon />
                                </Fab>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell
                            className={classes.columnTable}
                            align="right"
                          >
                            <DoneOutlineIcon style={{ color: "green" }} />
                          </TableCell>
                        )}
                      </React.Fragment>
                    ) : (
                      <TableCell className={classes.columnTable}></TableCell>
                    )}
                    {flowboardData.attributes.flow_board ===
                    null ? null : flowboardData.attributes.flow_board
                        .status_key === 7 ? (
                      <React.Fragment>
                        {flowboardData.attributes.flow_board.status_key ===
                        7 ? (
                          <TableCell
                            className={classes.columnTable}
                            align="right"
                          >
                            <Link
                              data-testid={`flowboard-send-sms-link`}
                              onClick={() =>
                                sendsms(
                                  flowboardData.attributes.patient.id,
                                  flowboardData.attributes.visit_id
                                )
                              }
                            >
                              <Tooltip title="Send sms" aria-label="Send sms">
                                <Fab className={classes.fab}>
                                  <TextsmsIcon />
                                </Fab>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <TableCell className={classes.columnTable}></TableCell>
                    )}
                    {flowboardData.attributes.flow_board ===
                    null ? null : flowboardData.attributes.flow_board
                        .status_key === 7 ? (
                      <React.Fragment>
                        {flowboardData.attributes.flow_board.status_key === 7 &&
                        flowboardData.attributes.is_prescription_sent_to_mso ===
                          1 ? (
                          <TableCell
                            className={classes.columnTable}
                            align="right"
                          >
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              data-testid={`flowboard-view-prescription-link`}
                              href={
                                flowboardData.attributes.prescription_pdf_url
                              }
                            >
                              <Tooltip
                                title="Prescription"
                                aria-label="Prescription"
                              >
                                <Fab className={classes.fab}>
                                  <AssignmentIcon />
                                </Fab>
                              </Tooltip>
                            </a>
                          </TableCell>
                        ) : (
                          <PrescriptionLoader />
                        )}
                      </React.Fragment>
                    ) : (
                      <TableCell className={classes.columnTable}></TableCell>
                    )}
                  </Hidden>
                  <Hidden mdUp>
                    <TableCell className={classes.columnTable} align="right">
                      {flowboardData.attributes.flow_board.status_key === 7 &&
                      flowboardData.attributes.is_prescription_sent_to_mso ===
                        1 ? (
                        <MoreOptionComponent
                          feedbackcheck={
                            flowboardData.attributes.mso_to_doctor_feedback
                          }
                          prescriptionurl={
                            flowboardData.attributes.prescription_pdf_url
                          }
                          visitid={flowboardData.attributes.visit_id}
                          onClick={(value) => handlefeedbackopen(value)}
                          patientid={flowboardData.attributes.patient.id}
                        />
                      ) : (
                        <PrescriptionLoader />
                      )}
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
            <FlowboardStatusChange
              open={flowboardStatusModalOpen}
              onClose={handleFlowboardStatusClose}
              handleCount={handleCount}
              updateFlowboardData={updateFlowboardData}
            />
            <FeedBackModal
              onClose={handleFeedbackClose}
              open={feedbackModalOpen}
              visitId={visitId}
              handleCount={handleCount}
            />
          </Table>
        </TableContainer>
        <ToastContainer />
      </Paper>
    </>
  );
};

export default FlowBoard;
