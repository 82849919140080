import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  inner: {
    display: "flex",
    // maxWidth: 500
  },
  body: {
    backgroundColor: "#cfe2c5",
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ResolutionContent = (props) => {
  const { resolutionData } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div>
          <div className={classes.body}>
            <Typography
              style={{
                color: "black",
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {resolutionData.attributes.status.value}
            </Typography>
            <div className={classes.content}>
              <Typography color="inherit" variant="body1">
                {resolutionData.attributes.comment}
              </Typography>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.time} variant="body2">
              {moment(resolutionData.attributes.created_at).format(
                "YYYY-MM-DD"
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionContent;
